import { gql, useMutation } from '@apollo/client';

export interface ISendEmailToResetPasswordResponse {
  sendEmailToResetPassword: string;
}

export interface ISendEmailToResetPasswordVariables {
  email: string;
  type?: 'v2';
}

const SEND_EMAIL_TO_RESET_PASSWORD = gql`
  mutation sendEmailToResetPassword($email: String!, $type: String) {
    sendEmailToResetPassword(email: $email, type: $type)
  }
`;

export const useSendEmailToResetPasswordMutation = () => {
  return useMutation<ISendEmailToResetPasswordResponse, ISendEmailToResetPasswordVariables>(
    SEND_EMAIL_TO_RESET_PASSWORD
  );
};
