export const NICE_TO_KNOW_ITEMS: {
  id: string;
  category: string;
  title: string;
  icon: 'go';
}[] = [
  {
    id: '1',
    title: 'New coping strategy: Breathing',
    icon: 'go',
    category: 'New feature',
  },
  {
    id: '2',
    title: 'Set homework together at the end of the session',
    icon: 'go',
    category: 'Tip',
  },
  {
    id: '3',
    title: 'Patients with active mood logs are on fire',
    icon: 'go',
    category: 'Dataproof',
  },
];
