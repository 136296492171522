import React from 'react';
import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { SummaryCard } from '@limbic-for-therapists/components';
import { TouchableOpacity } from 'react-native';
import { TouchableActiveOpacity } from '../../consts';
import { TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config';
import { CopingStrategyResponse } from '../CopingStrategyResponse/CopingStrategyResponse';
import Icon from 'react-native-vector-icons/Ionicons';

const HOMEWORK_ICON_SIZE = 24;

interface ICopingStrategyPreviewProps {
  id: string;
  message: string;
  emotion: string;
  emotionCounter: number;
  triggeredCounter: number;
  likedCounter: number;
  onPress?: (id: string) => void;
  style?: StyleProp<ViewStyle>;
}

export const CopingStrategyPreview = ({
  id,
  message,
  emotion,
  emotionCounter,
  triggeredCounter,
  likedCounter,
  style,
  onPress,
}: ICopingStrategyPreviewProps) => {
  const emotionCounterString = `${emotionCounter}x`;
  const emotionString = `felt ${emotion}`.toLowerCase();
  const triggeredCounterString = `${triggeredCounter}x`;
  const likedCounterString = `${likedCounter}x`;
  return (
    <TouchableOpacity
      onPress={() => onPress?.(id)}
      activeOpacity={TouchableActiveOpacity.light}
      style={style}
      testID="coping-strategy-preview"
    >
      <SummaryCard
        title={message}
        subtitle={`when feeling ${emotion?.toLowerCase()} - Set by you`}
        subtitleVariant={TextVariants.XS}
        leftAccessory={
          <Icon name="help-buoy-outline" color={Colors.darkGrey3} size={HOMEWORK_ICON_SIZE} />
        }
      >
        <View style={styles.copingStrategyPreviewContainer}>
          <CopingStrategyResponse title={emotionCounterString} subtitle={emotionString} />
          <CopingStrategyResponse title={triggeredCounterString} subtitle="strategy suggested" />
          <CopingStrategyResponse
            highlighted
            title={likedCounterString}
            subtitle="liked the strategy"
            showRightArrow={false}
          />
        </View>
      </SummaryCard>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  copingStrategyPreviewContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 16,
  },
});
