import Aeroport from './Aeroport.ttf';
import AeroportBold from './Aeroport-Bold.ttf';
import AeroportMedium from './Aeroport-Medium.ttf';
import AeroportLight from './Aeroport-Light.ttf';
import FlowCircular from './Flow-Circular.otf';
import SyneBold from './Syne-Bold.ttf';
import SyneExtra from './Syne-Extra.ttf';
import SyneRegular from './Syne-Regular.ttf';

export {
  Aeroport,
  AeroportBold,
  AeroportMedium,
  AeroportLight,
  FlowCircular,
  SyneBold,
  SyneExtra,
  SyneRegular,
};
