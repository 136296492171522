import React from 'react';
import {
  Period,
  useAttachInterventionToPatientMutation,
  Weekday,
} from '@limbic-for-therapists/backend';
import { DayCircle, SetRecurrenceRule, SuccessModal } from '@limbic-for-therapists/components';
import { getLongDate, toggleItemInArray } from '@limbic-for-therapists/shared';
import dayjs, { Dayjs } from 'dayjs';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTitanApolloClient } from '../../backend/helpers/getApolloClient';
import { DatePicker } from '../../components/DatePicker/DatePicker';
import { useModalContext } from '../../context/Modal';
import { useToastContext } from '../../context/Toast';

dayjs.extend(isTomorrow);

const DAYS = [Weekday.Mo, Weekday.Tu, Weekday.We, Weekday.Th, Weekday.Fr, Weekday.Sa, Weekday.Su];

export default function SetInterventionTriggers({
  interventionId,
  signupCode,
}: {
  interventionId: string;
  signupCode: string;
}) {
  const { setContent, dismissModal } = useModalContext();
  const { setErrorToast } = useToastContext();
  const { search } = useLocation();
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [periods, setPeriods] = useState<Set<Period>>(new Set());
  const [selectedDays, setSelectedDays] = useState<Weekday[]>([]);
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [datePickerUpdateField, setDatePickerUpdateField] = useState<'startDate' | 'endDate'>(
    'startDate'
  );
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [attachInterventionMutation] = useAttachInterventionToPatientMutation(
    getTitanApolloClient()
  );

  const newPatientName = useMemo(() => {
    return new URLSearchParams(search).get('newPatientName') || undefined;
  }, [search]);

  const isToday = useMemo(() => {
    if (!startDate) return false;
    return dayjs().isSame(startDate, 'day');
  }, [startDate]);

  const isReady = useMemo(
    () => !!startDate && !!periods.size && (!isRecurring || (!!endDate && !!selectedDays.length)),
    [startDate, periods, isRecurring, endDate, selectedDays]
  );

  const datePickerDate = useMemo(
    () => (datePickerUpdateField === 'startDate' ? startDate : endDate),
    [datePickerUpdateField, startDate, endDate]
  );

  const startTitle = startDate ? getLongDate(startDate) : 'Pick a start date';
  const endTitle = endDate ? getLongDate(endDate) : 'Pick an end date';

  const handlePressedPickADate = useCallback((field) => {
    setDatePickerUpdateField(field);
    setDatePickerVisible(true);
  }, []);

  const handlePressedToday = useCallback(() => {
    setStartDate(!isToday ? dayjs() : undefined);
  }, [isToday]);

  const handleSelectedDate = useCallback(
    (date: Dayjs) => {
      if (datePickerUpdateField === 'startDate') {
        setStartDate(date);
      }
      if (datePickerUpdateField === 'endDate') {
        setEndDate(date);
      }
      setDatePickerVisible(false);
    },
    [datePickerUpdateField, setDatePickerVisible]
  );

  const handlePressedDay = useCallback(
    (day: Weekday) => {
      setSelectedDays(toggleItemInArray(selectedDays, day));
    },
    [selectedDays]
  );

  const handleSubmit = useCallback(async () => {
    if (!startDate) throw new Error('start date must be selected at this point');
    if (!periods.size) throw new Error('at least one period must be selected at this point');

    try {
      await attachInterventionMutation({
        interventionId,
        signupCode,
        startDate,
        endDate,
        periods,
        repeatDays: selectedDays,
      });
      setContent(
        <Success
          onClose={() => {
            dismissModal();
            window.location.href = `/dashboard/patient/${signupCode}`;
          }}
          newPatientName={newPatientName}
        />
      );
    } catch (e) {
      setErrorToast(e.message);
    }
  }, [
    interventionId,
    signupCode,
    startDate,
    endDate,
    periods,
    selectedDays,
    setErrorToast,
    attachInterventionMutation,
    dismissModal,
    setContent,
    newPatientName,
  ]);

  const handleTogglePeriod = useCallback(
    (period: Period) => {
      const newPeriods = new Set(periods);
      if (newPeriods.has(period)) {
        newPeriods.delete(period);
      } else {
        newPeriods.add(period);
      }

      setPeriods(newPeriods);
    },
    [periods, setPeriods]
  );

  return (
    <>
      <SetRecurrenceRule
        startTitle={startTitle}
        endTitle={endTitle}
        startDate={startDate}
        endDate={endDate}
        isToday={isToday}
        actionTitle="Save"
        isRecurring={isRecurring}
        periods={periods}
        isReady={isReady}
        onToggleRecurring={() => setIsRecurring(!isRecurring)}
        onPressedToday={handlePressedToday}
        onPressedPickAStartDate={() => handlePressedPickADate('startDate')}
        onPressedPickAnEndDate={() => handlePressedPickADate('endDate')}
        onSubmit={handleSubmit}
        onTogglePeriod={handleTogglePeriod}
        days={DAYS.map((day) => (
          <DayCircle
            day={day}
            onPress={() => handlePressedDay(day)}
            selected={selectedDays}
            key={day.toString()}
          />
        ))}
        newPatientName={newPatientName}
      />
      <DatePicker
        date={datePickerDate}
        active={datePickerVisible}
        onClose={() => setDatePickerVisible(false)}
        onDayChange={handleSelectedDate}
      />
    </>
  );
}

function Success({
  onClose,
  newPatientName = '',
}: {
  onClose: () => void;
  newPatientName?: string;
}) {
  const message = newPatientName
    ? `Once ${newPatientName} installs the app, the activity will be available for them to complete.`
    : `This activity is scheduled, the patient will be prompted at the start date`;
  return <SuccessModal title="The activity is ready" message={message} onClose={onClose} />;
}
