import React from 'react';
import { View, TouchableOpacity, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Colors } from '../../config';
import { WithTestID } from '../../utils/withTestID';

interface ModalContentContainerProps {
  children: React.ReactNode;
  onClose?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const ModalContentContainer = ({
  style,
  children,
  onClose,
  testID,
}: WithTestID<ModalContentContainerProps>) => {
  return (
    <View style={styles.modalContainer} testID={testID}>
      <TouchableOpacity
        activeOpacity={1}
        style={styles.backgroundOuterContainer}
        onPress={onClose}
        testID="modal-content-background"
      >
        <View style={styles.backgroundContainer}></View>
      </TouchableOpacity>
      <View style={[styles.container, style]}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  backgroundOuterContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  backgroundContainer: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    borderRadius: 16,
    backgroundColor: Colors.white,
    padding: 16,
    zIndex: 2,
  },
});
