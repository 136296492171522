import { Colors, Loading } from '@limbic-for-therapists/components';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import { LogInForm } from '../components/LogInForm/LogInForm';
import { RequestPasswordForm } from '../components/RequestPasswordForm/RequestPasswordForm';
import { SignUpForm } from '../components/SignUpForm/SignUpForm';
import { useActiveClinicianContext } from '../context/ActiveClinician';
import { AccountScreen } from '../screens/AccountScreen';
import { DashboardScreen } from '../screens/DashboardScreen';
import { InterventionScheduleScreen } from '../screens/InterventionScheduleScreen/InterventionScheduleScreen';
import { PsychoedScheduleScreen } from '../screens/PsychoedScheduleScreen';
import { InterventionLibraryScreen } from '../screens/PsychoedScheduleScreen/InterventionLibraryScreen';
import { SignUpOrLogInScreen } from '../screens/SignUpOrLogInScreen';
import { TransferClinicianScreen } from '../screens/TransferClinicianScreen';

const RootRouter = () => {
  const { fetchActiveClinician, activeClinician } = useActiveClinicianContext();
  const resolvedActiveClinician = activeClinician !== undefined;

  // First load - check if logged in already
  useEffect(() => {
    fetchActiveClinician();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!resolvedActiveClinician) return <Loading backgroundColor={Colors.purple} />;

  return (
    <React.Fragment>
      <Switch>
        <Route path="/auth">
          <SignUpOrLogInScreen>
            <Switch>
              <UnauthenticatedRoute
                exact
                path="/auth/login"
                isAuthenticated={!!activeClinician}
                defaultAuthenticatedPath="/dashboard"
              >
                <LogInForm />
              </UnauthenticatedRoute>

              <UnauthenticatedRoute
                exact
                path="/auth/register"
                isAuthenticated={!!activeClinician}
                defaultAuthenticatedPath="/dashboard"
              >
                <SignUpForm />
              </UnauthenticatedRoute>

              <UnauthenticatedRoute
                exact
                path="/auth/forgot"
                isAuthenticated={!!activeClinician}
                defaultAuthenticatedPath="/dashboard"
              >
                <RequestPasswordForm />
              </UnauthenticatedRoute>

              <Route>
                <Redirect to="/auth/login" />
              </Route>
            </Switch>
          </SignUpOrLogInScreen>
        </Route>
        <Route path="/dashboard">
          <Switch>
            <AuthenticatedRoute
              exact
              path="/dashboard/patient/:signUpCode/library"
              isAuthenticated={!!activeClinician}
              defaultUnauthenticatedPath="/auth/login"
            >
              <InterventionLibraryScreen />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              exact
              path="/dashboard/patient/:signUpCode/schedule-intervention/:interventionId"
              isAuthenticated={!!activeClinician}
              defaultUnauthenticatedPath="/auth/login"
            >
              <InterventionScheduleScreen />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              exact
              path="/dashboard/patient/:signUpCode/schedule-psychoed/:interventionId"
              isAuthenticated={!!activeClinician}
              defaultUnauthenticatedPath="/auth/login"
            >
              <PsychoedScheduleScreen />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              exact
              path="/dashboard/settings"
              isAuthenticated={!!activeClinician}
              defaultUnauthenticatedPath="/auth/login"
            >
              <AccountScreen />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              exact
              path="/dashboard/transfer-patient"
              isAuthenticated={!!activeClinician}
              defaultUnauthenticatedPath="/auth/login"
            >
              <TransferClinicianScreen />
            </AuthenticatedRoute>

            <AuthenticatedRoute
              exact
              path="/dashboard"
              isAuthenticated={!!activeClinician}
              defaultUnauthenticatedPath="/auth/login"
            >
              <DashboardScreen />
            </AuthenticatedRoute>
            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Route>
        <Route>
          <Redirect to="/auth" />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export { RootRouter };
