import React from 'react';
import { View, StyleSheet } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Tag, TagVariant } from '../Tag/Tag';

interface PositiveActivityLogProps {
  activity: string;
  emoji: string;
}

export const PositiveActivityLog = ({ activity, emoji }: PositiveActivityLogProps) => {
  return (
    <View style={styles.positiveActivityContainer} testID="positive-activity-log">
      <Tag
        variant={TagVariant.GreenFilled}
        textVariant={TextVariants.Base}
        rounded
        leftAccessory={<LimbicText variant={TextVariants.L}>{emoji}</LimbicText>}
      >
        {activity}
      </Tag>
    </View>
  );
};

const styles = StyleSheet.create({
  positiveActivityContainer: {
    marginRight: 10,
    marginBottom: 10,
  },
});
