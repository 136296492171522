import React from 'react';
import { Helmet } from 'react-helmet';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { Colors } from '@limbic-for-therapists/components';
import { View } from 'react-native';
import { NavigationBar, NAVIGATION_BAR_HEIGHT } from './NavigationBar';
import { getPageTitle } from '../consts/pageTitle';

interface ScreenContainerProps {
  backgroundColor: Colors;
  children: React.ReactNode;
  backPath?: string;
  navBarRightAccessory?: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  id?: string;
  refreshOnBack?: boolean;
}

const ScreenContainer = ({
  backgroundColor,
  backPath,
  children,
  navBarRightAccessory,
  title,
  header,
  style,
  id,
  refreshOnBack = false,
}: ScreenContainerProps) => {
  return (
    <>
      <Helmet>
        <title>{getPageTitle(title)}</title>
      </Helmet>
      <View style={[styles.container, { backgroundColor }, style]} nativeID={id}>
        <NavigationBar
          header={header}
          backPath={backPath}
          rightAccessory={navBarRightAccessory}
          refreshOnBack={refreshOnBack}
        />
        {children}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100%',
    paddingTop: NAVIGATION_BAR_HEIGHT,
    paddingBottom: 60,
  },
});

export { ScreenContainer };
