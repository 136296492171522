import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, TouchableOpacity } from 'react-native';
import { Colors } from '../../config';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface Props {
  id: string;
  fromDate: string;
  toDate: string;
  logCount: number;
  wasRead: boolean;
  questionnaires: string[];
  moodLogGraph?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  size?: 'large' | 'small';
  onPress?: (id: string) => void;
}

export const ReportCard = ({
  id,
  fromDate,
  toDate,
  logCount,
  wasRead,
  moodLogGraph,
  // questionnaires,
  style,
  size,
  onPress,
  testID,
}: WithTestID<Props>) => {
  // const lastQuestionnaire = questionnaires[questionnaires.length - 1];
  // const hasMultipleQs = questionnaires.length > 1;
  const isSmall = size === 'small';
  return (
    <TouchableOpacity
      testID={testID}
      delayPressIn={0}
      activeOpacity={0.7}
      style={[styles.container, style]}
      onPress={() => onPress?.(id)}
    >
      <LimbicText
        variant={isSmall ? TextVariants.M : TextVariants.L}
        color={Colors.white}
        style={{ lineHeight: isSmall ? 16 : 24 }}
      >
        {fromDate}
      </LimbicText>
      <LimbicText variant={isSmall ? TextVariants.M : TextVariants.L} color={Colors.white} bold>
        {toDate}
      </LimbicText>
      <View style={styles.infoContainer}>
        <View>
          <LimbicText variant={isSmall ? TextVariants.S : TextVariants.Base} color={Colors.white}>
            {String(logCount)} logs
          </LimbicText>
          {!wasRead && (
            <View style={styles.unreadContainer}>
              <LimbicText variant={TextVariants.XS}>UNREAD</LimbicText>
            </View>
          )}
        </View>
        {/* {hasMultipleQs && (
          <View
            style={[styles.questionnaireContainer, styles.multipleQuestionnairesBackContainer]}
          />
        )}
        {!!lastQuestionnaire && (
          <View
            style={[
              styles.questionnaireContainer,
              hasMultipleQs && styles.multipleQuestionnairesOffset,
            ]}
          >
            <LimbicText variant={TextVariants.XS} style={styles.questionnaireText}>
              {lastQuestionnaire}
            </LimbicText>
          </View>
        )} */}
      </View>
      {moodLogGraph ? <View style={styles.logGraphContainer}>{moodLogGraph}</View> : null}
    </TouchableOpacity>
  );
};

ReportCard.defaultProps = {
  size: 'large',
};

const styles = StyleSheet.create({
  container: {
    width: 194,
    backgroundColor: Colors.darkGrey2,
    flexGrow: 0,
    borderRadius: 16,
    padding: 16,
  },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  unreadContainer: {
    marginTop: 8,
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 12,
    backgroundColor: Colors.white,
  },
  questionnaireContainer: {
    height: 45,
    width: 45,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 35,
    backgroundColor: Colors.pink2,
  },
  multipleQuestionnairesOffset: {
    right: 8,
  },
  multipleQuestionnairesBackContainer: {
    position: 'absolute',
    backgroundColor: Colors.pink2,
    opacity: 0.7,
    right: 0,
  },
  questionnaireText: {
    fontSize: 8,
    color: Colors.white,
    transform: [{ rotate: '15deg' }],
  },
  logGraphContainer: {
    paddingTop: 24,
    overflow: 'hidden',
  },
});
