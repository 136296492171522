import { PositivityColors } from '@limbic-for-therapists/components';

export interface IValueRange<T = string> {
  title: string;
  range: [number, number];
  description: T;
  color: PositivityColors;
}

export function getValueRange<T = string>(
  value: number,
  ranges: IValueRange<T>[] = []
): IValueRange<T> {
  let valueToSearch = value;
  const min = Math.min.apply(null, ranges.map((r) => r.range).flat());
  if (valueToSearch < min) {
    valueToSearch = min;
  }
  const max = Math.max.apply(null, ranges.map((r) => r.range).flat());
  if (valueToSearch > max) {
    valueToSearch = max;
  }
  return ranges.find(
    (r) =>
      valueToSearch >= Math.min.apply(null, r.range) &&
      valueToSearch <= Math.max.apply(null, r.range)
  )!;
}
