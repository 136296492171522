import React from 'react';
import { View, StyleSheet, Image, ImageSourcePropType, StyleProp, TextStyle } from 'react-native';
import { Colors, getShadowStyles } from '../../config';
import { MAX_BORDER_RADIUS } from '../../consts/borderRadius';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const IMAGE_HEIGHT = 220;
const STEP_NUMBER_CONTAINER_SIZE = 50;

interface TutorialCardProps {
  stepNumber?: number;
  stepIcon?: React.ReactNode;
  description?: string | React.ReactNode;
  backgroundColor?: Colors;
  imageBackground?: React.ReactNode;
  image?: ImageSourcePropType;
  textVariant?: TextVariants;
  textStyle?: StyleProp<TextStyle>;
}

export const TutorialCard = ({
  stepNumber = 0,
  description,
  stepIcon,
  backgroundColor,
  imageBackground,
  image,
  textVariant = TextVariants.M,
  textStyle,
}: TutorialCardProps) => {
  return (
    <View style={[styles.container, { backgroundColor }]}>
      {image ? (
        <View style={styles.imageContainer}>
          {imageBackground ? (
            <View style={styles.imageBackgroundContainer}>{imageBackground}</View>
          ) : null}
          <View style={styles.imageInnerContainer}>
            <Image resizeMode="cover" source={image} style={styles.image} />
          </View>
        </View>
      ) : null}
      <View style={styles.instructionsContainer}>
        <View style={styles.stepContainer}>
          <View style={styles.stepNumberContainer}>
            {stepIcon ? (
              stepIcon
            ) : (
              <LimbicText bold variant={TextVariants.L}>
                {stepNumber}
              </LimbicText>
            )}
          </View>
        </View>
        <View style={styles.descriptionContainer}>
          {typeof description === 'string' ? (
            <LimbicText variant={textVariant} style={textStyle}>
              {description}
            </LimbicText>
          ) : (
            description
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: Colors.white,
    flexDirection: 'column',
    ...getShadowStyles(Colors.black, 0.2, 1.41, 0, 1),
  },
  imageContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: IMAGE_HEIGHT,
    overflow: 'hidden',
  },
  imageInnerContainer: {
    width: '80%',
    height: '90%',
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    overflow: 'hidden',
    ...getShadowStyles(Colors.black, 0.3, 18),
  },
  imageBackgroundContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white,
  },
  instructionsContainer: {
    flexDirection: 'row',
    paddingVertical: 20,
    alignItems: 'center',
  },
  stepContainer: {
    paddingHorizontal: 20,
  },
  stepNumberContainer: {
    backgroundColor: Colors.grey2,
    borderRadius: MAX_BORDER_RADIUS,
    height: STEP_NUMBER_CONTAINER_SIZE,
    width: STEP_NUMBER_CONTAINER_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionContainer: {
    paddingHorizontal: 20,
    width: 0,
    flexGrow: 1,
  },
});
