import React from 'react';
import { Colors } from '@limbic-for-therapists/components';
import { ScreenContainer } from '../../components/ScreenContainer';
import SetInterventionTriggers from './SetInteventionTriggers';
import { GridColumn, GridContainer, GridRow } from '../../components/ResponsiveGrid';
import { useParams } from 'react-router-dom';

export function InterventionScheduleScreen() {
  const { interventionId, signUpCode } = useParams<{
    interventionId: string;
    signUpCode: string;
  }>();

  return (
    <ScreenContainer backgroundColor={Colors.grey1} title="Activities" backPath="../library">
      <GridContainer
        gridColumns={{
          xs: 4,
        }}
      >
        <GridRow>
          <GridColumn span={4}>
            <SetInterventionTriggers interventionId={interventionId} signupCode={signUpCode} />
          </GridColumn>
        </GridRow>
      </GridContainer>
    </ScreenContainer>
  );
}
