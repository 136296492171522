import React from 'react';
import { View, StyleSheet } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config/colors';
import { EmotionCircle } from '../EmotionCircle/EmotionCircle';
import { NumberCircle, NumberCircleProps } from '../NumberCircle/NumberCircle';

type NumberProps = NumberCircleProps;

const Number = ({ ...rest }: NumberProps) => {
  return (
    <View style={styles.numberContainer}>
      <NumberCircle {...rest} />
    </View>
  );
};

interface IEmotionBeforeAfterProps {
  emoji?: string;
  emotion: string;
  color?: string;
  size: 'sm' | 'md' | 'lg' | 'xlg';
}

const EmotionBeforeAfter = (props: IEmotionBeforeAfterProps) => {
  const { emoji, emotion, color, size } = props;
  return (
    <View style={styles.emotionContainer}>
      {!!emoji && <EmotionCircle emoji={emoji} color={color} size={size} />}
      <View style={styles.textContainer}>
        <LimbicText variant={TextVariants.Base} color={Colors.darkGrey2} center>
          {emotion}
        </LimbicText>
      </View>
    </View>
  );
};

interface IExpectationProps {
  expectation: string;
}

const Expectation = (props: IExpectationProps) => {
  const { expectation } = props;
  return (
    <View style={styles.emotionContainer}>
      <View style={styles.textContainer}>
        <LimbicText variant={TextVariants.Base} color={Colors.darkGrey2} left>
          {expectation}
        </LimbicText>
      </View>
    </View>
  );
};

const NoShow = () => {
  return (
    <View style={styles.noShowContainer}>
      <LimbicText variant={TextVariants.XS} color={Colors.darkGrey1}>
        No Record
      </LimbicText>
    </View>
  );
};

interface IDateProps {
  date: string;
}

const Date = (props: IDateProps) => {
  const { date } = props;
  return (
    <View style={styles.emotionContainer}>
      <View style={styles.textContainer}>
        <LimbicText variant={TextVariants.XS} color={Colors.darkGrey1} left>
          {date}
        </LimbicText>
      </View>
    </View>
  );
};

export const ActivityTableRow = {
  Number,
  Emotion: EmotionBeforeAfter,
  Expectation,
  NoShow,
  Date,
};

const styles = StyleSheet.create({
  emotionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  numberContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
  },
  textContainer: {
    flexBasis: 0,
    flexGrow: 1,
    paddingRight: 15,
  },
  emoji: {
    marginRight: 10,
  },
  noShowContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 24,
  },
});
