import dayjs from 'dayjs';
import { useMemo } from 'react';
import { cutoffs } from '../consts/questionnaireCutoffs';
import { MoodEventType } from '../models/MoodEventType';
import { useListQuestionnaires } from '../queries/listQuestionnaires';
import { getQuestionnaireScoreRange } from '../utils/getQuestionnaireScoreRange';

export const useQuestionnairePreviewContent = (
  id: string,
  score: number,
  signUpCode: string,
  moodEventType: MoodEventType
) => {
  const listQuestionnaires = useListQuestionnaires({ signUpCode, moodEventType });
  const questions = useMemo(
    () => listQuestionnaires.data?.listQuestionnaires?.data || [],
    [listQuestionnaires.data?.listQuestionnaires?.data]
  );

  // NOTE: by default questionnaires are sorted by `createdAt` DESC, so it must be reversed.
  // graph scores should be relative to the date of the "current" questionnaire, in the case
  // of looking back over previous questionnaires, so the array should only contain scores
  // older than and up to the current questionnaire.
  const scores = useMemo(() => {
    const currentIndex = questions.findIndex((q) => q.id === id);
    // only include scores starting from the current questionnaire
    const scores = questions.slice(currentIndex).map((item) => {
      const { id: scoreId, moodQuestionnaireProperties } = item;
      const value = moodQuestionnaireProperties?.reduce((t, c) => t + (c.points || 0), 0) || 0;
      return { id: scoreId, value, tooltip: dayjs(item.createdAt).format('MMM DD') };
    });
    return scores.reverse();
  }, [id, questions]);

  const progress = useMemo(() => {
    let isIncreased = false;
    let isDecreased = false;
    const index = scores.findIndex((s) => s.id === id);
    if (index > 0) {
      const prev = scores[index - 1].value;
      isIncreased = prev < score;
      isDecreased = prev > score;
    }
    return { isIncreased, isDecreased };
  }, [id, score, scores]);

  const range = useMemo(
    () => getQuestionnaireScoreRange(moodEventType, score),
    [moodEventType, score]
  );
  const cutoff = cutoffs[moodEventType];

  return { range, scores, cutoff, progress, questions };
};
