import { IAccessReferral } from '@limbic-for-therapists/backend';
import React from 'react';
import { StyleSheet, View, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';
import { TextInput } from '../TextInput/TextInput';

interface NewLookupAccessReferralProps {
  lookup?: string;
  onChangeLookup: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  results?: IAccessReferral[];
  loading?: boolean;
  onSelectReferral: (referral: IAccessReferral) => void;
  bottomAccessory?: JSX.Element;
}

export const NewLookupAccessReferral = ({
  lookup,
  onChangeLookup,
  bottomAccessory,
}: NewLookupAccessReferralProps) => {
  return (
    <ModalContent scrollable style={styles.modalContainer}>
      <ModalContent.Body style={styles.modalBody}>
        <TextInput
          onChange={onChangeLookup}
          value={lookup}
          style={styles.input}
          placeholder="Start typing name"
          icon="search"
          fullWidth
          isIconLeft
          iconSize={12}
        />
      </ModalContent.Body>

      {!!bottomAccessory && <View style={styles.bottomAccessory}>{bottomAccessory}</View>}
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  tabRow: {
    flexDirection: 'row',
  },
  titleText: {
    fontSize: 20,
  },
  container: {
    marginTop: 0,
    paddingTop: 0,
  },
  subTitleText: {
    color: '#4F557D',
    paddingVertical: 0,
    paddingHorizontal: 0,
    fontSize: 14,
    fontWeight: '400',
  },
  modalContainer: {
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  modalBody: {
    paddingTop: 0,
    paddingBottom: 8,
    paddingLeft: 0,
    paddingRight: 0,
  },
  input: {
    flex: 1,
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.darkGrey1,
    height: 32,
    paddingLeft: 32,
  },
  bottomAccessory: {
    marginTop: 48,
  },
});
