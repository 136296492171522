import { IInterventionFragment, MediaType, StepType } from '@limbic-for-therapists/backend';
import {
  LimbicText,
  ModalContent,
  TextVariants,
  AutoHeightImage,
} from '@limbic-for-therapists/components';
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { ChatPreview, MessageAuthor } from '../../../../components/ChatPreview/ChatPreview';
import { OutboundLink } from '../../../../components/Link/Link';

function UserResponseMessage({ children }: { children: React.ReactNode }) {
  return (
    <LimbicText variant={TextVariants.M} right>
      {children}
    </LimbicText>
  );
}

function UserResponse({ step }: { step: IInterventionFragment['steps'][number] }): JSX.Element {
  switch (step.type) {
    case StepType.freeText:
      return (
        <UserResponseMessage>The user will respond with a free text answer.</UserResponseMessage>
      );
    case StepType.thought:
      return <UserResponseMessage>The user will respond with a thought.</UserResponseMessage>;
    case StepType.activity:
      return <UserResponseMessage>The user will respond with an activity.</UserResponseMessage>;
    case StepType.emotion:
      return <UserResponseMessage>The user will respond with an emotion.</UserResponseMessage>;
    case StepType.multipleChoice:
    case StepType.singleChoice:
      return (
        <View style={styles.answerList}>
          {step.answers?.map((answer) => (
            <View key={answer} style={styles.answerPill}>
              <UserResponseMessage>{answer}</UserResponseMessage>
            </View>
          ))}
        </View>
      );
    default:
      return <UserResponseMessage>Unknown step with type {step.type}</UserResponseMessage>;
  }
}

type EmptyStateParams = {
  onAddStep: () => void;
};

function EmptyState({ onAddStep }: EmptyStateParams) {
  return (
    <>
      <ChatPreview
        messages={[
          { value: 'Limbic will send a message', author: MessageAuthor.limbic },
          {
            value: <LimbicText variant={TextVariants.M}>The user will answer it here.</LimbicText>,
            author: MessageAuthor.user,
          },
        ]}
        emptyState={null}
      ></ChatPreview>
      <View style={styles.actionButton}>
        <ModalContent.CTAButton
          title="Lets configure the first message"
          onPress={() => onAddStep()}
        ></ModalContent.CTAButton>
      </View>
    </>
  );
}

type InterventionStepsSummaryParams = {
  steps: IInterventionFragment['steps'];
  onAddStep: () => void;
  onSave: () => void;
};

export default ({ steps, onAddStep, onSave }: InterventionStepsSummaryParams) => {
  return (
    <ModalContent scrollable>
      <ModalContent.Title>What should it say?</ModalContent.Title>

      {steps.length === 0 ? (
        <EmptyState onAddStep={onAddStep}></EmptyState>
      ) : (
        <>
          <ChatPreview
            messages={steps.flatMap((step) => {
              const limbicMessage = {
                value: <Text>{step.prompt}</Text>,
                author: MessageAuthor.limbic,
              };

              if (step.type === StepType.informational) {
                return [limbicMessage];
              }

              if (step.type === StepType.media) {
                if (!step.mediaUrl) {
                  throw new Error('Unreachable');
                }
                if (step.mediaType === MediaType.image) {
                  return [
                    {
                      value: <AutoHeightImage width={220} uri={step.mediaUrl} />,
                      author: MessageAuthor.limbic,
                    },
                  ];
                } else if (step.mediaType === MediaType.pdf) {
                  return [
                    {
                      value: <OutboundLink href={step.mediaUrl}>Download PDF</OutboundLink>,
                      author: MessageAuthor.limbic,
                    },
                  ];
                } else {
                  throw new Error('Unreachable');
                }
              }

              return [
                limbicMessage,
                {
                  value: <UserResponse step={step} />,
                  author: MessageAuthor.user,
                },
              ];
            })}
            emptyState={null}
          ></ChatPreview>

          <View style={styles.actionButton}>
            <ModalContent.CTAButton
              title="Add another message"
              onPress={onAddStep}
              outline
              neutral
            ></ModalContent.CTAButton>
            <View style={styles.ctaSeparator} />
            <ModalContent.CTAButton title="Save it" onPress={onSave}></ModalContent.CTAButton>
          </View>
        </>
      )}
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  actionButton: {
    marginTop: 24,
  },
  answerList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  answerPill: {
    borderWidth: 1,
    borderColor: 'rgb(239, 240, 247)',
    borderRadius: 100,
    paddingVertical: 6,
    paddingHorizontal: 18,
    marginLeft: 6,
    marginVertical: 4,
  },
  ctaSeparator: {
    height: 8,
  },
});
