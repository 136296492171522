import { gql, useMutation } from '@apollo/client';

export interface IResetClinicianPasswordResponse {
  resetClinicianPassword: string;
}

export interface IResetClinicianPasswordVariables {
  newPassword: string;
  resetToken: string;
}

const RESET_CLINICIAN_PASSWORD = gql`
  mutation resetClinicianPassword($newPassword: String!, $resetToken: String!) {
    resetClinicianPassword(newPassword: $newPassword, resetToken: $resetToken)
  }
`;

export const useResetClinicianPasswordMutation = () => {
  return useMutation<IResetClinicianPasswordResponse, IResetClinicianPasswordVariables>(
    RESET_CLINICIAN_PASSWORD
  );
};
