import { Colors } from '@limbic-for-therapists/components';
import { IPatientFragment } from '../fragments';

export enum DashboardSectionType {
  Report = 'report',
  CS = 'cs',
  All = 'all',
  Pending = 'pending',
}

type PatientCardProps = {
  tagTitle: string;
  tagBackgroundColor: Colors;
  tagIcon: string;
  bottomLine: string | undefined;
};

const getPatientCardPropsForPatient = (patient: IPatientFragment): PatientCardProps | null => {
  if (patient.hasSessionDate)
    return {
      tagTitle: 'Set a Date',
      tagBackgroundColor: Colors.pink4,
      tagIcon: 'calendar',
      bottomLine: patient.signupCode,
    };
  else if (patient.pendingCopingStrategies.data.length)
    return {
      tagTitle: 'Coping Request',
      tagBackgroundColor: Colors.pink2,
      tagIcon: 'loader',
      bottomLine: patient.signupCode,
    };
  else if (patient.unreadReportsCounter)
    return {
      tagTitle: 'Report Ready',
      tagBackgroundColor: Colors.green1,
      tagIcon: 'loader',
      bottomLine: patient.signupCode,
    };
  return {
    tagTitle: 'No Reports',
    tagBackgroundColor: Colors.grey3,
    tagIcon: 'loader',
    bottomLine: patient.signupCode,
  };
};

export const getPatientCardProps = (
  patient: IPatientFragment,
  type: DashboardSectionType
): PatientCardProps | null => {
  switch (type) {
    case DashboardSectionType.Report:
      return {
        tagTitle: 'Report Ready',
        tagBackgroundColor: Colors.green1,
        tagIcon: 'loader',
        bottomLine: patient.signupCode,
      };
    case DashboardSectionType.CS:
      return {
        tagTitle: 'Coping Request',
        tagBackgroundColor: Colors.pink2,
        tagIcon: 'loader',
        bottomLine: patient.signupCode,
      };
    case DashboardSectionType.Pending:
      return {
        tagTitle: 'Inviting Client',
        tagBackgroundColor: Colors.pink2,
        tagIcon: 'loader',
        bottomLine: patient.signupCode,
      };
    case DashboardSectionType.All:
      return getPatientCardPropsForPatient(patient);
    default:
      return null;
  }
};
