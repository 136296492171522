import {
  Colors,
  getShadowStyles,
  LimbicText,
  TextVariants,
} from '@limbic-for-therapists/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { AnimatedLimbicBalloon } from '../AnimatedLimbicBalloon/AnimatedLimbicBalloon';

interface MessageProps {
  children: React.ReactNode;
  author: MessageAuthor;
}

function Message({ children, author }: MessageProps) {
  if (author === MessageAuthor.limbic) {
    return (
      <View style={styles.messageLine}>
        <AnimatedLimbicBalloon flip size={50} animationId="idle"></AnimatedLimbicBalloon>
        <View style={styles.messageBox}>
          <LimbicText variant={TextVariants.M}>{children}</LimbicText>
        </View>
      </View>
    );
  } else {
    return (
      <View style={[styles.messageLine, styles.messageBoxRight]}>
        <View style={styles.messageBox}>{children}</View>
        <Icon name={'person-circle-outline'} size={42} color={Colors.pink2} />
      </View>
    );
  }
}

export enum MessageAuthor {
  limbic,
  user,
}

interface ChatPreviewParams {
  messages?: Array<{
    value: React.ReactNode;
    author: MessageAuthor;
  }>;
  emptyState: React.ReactNode;
}

export function ChatPreview({ messages, emptyState }: ChatPreviewParams) {
  if (!messages || !messages.length) return <View>{emptyState}</View>;

  return (
    <View style={styles.chat}>
      {messages.map((message, i) => (
        <Message key={i} author={message.author}>
          {message.value}
        </Message>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  chat: {
    flexShrink: 0,
  },
  messageLine: {
    marginTop: 24,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 'auto',
  },
  messageBox: {
    marginLeft: 8,
    marginRight: 8,
    borderWidth: 1,
    flexShrink: 1,
    borderColor: 'rgb(239, 240, 247)',
    padding: 12,
    borderRadius: 8,
    flexGrow: 0,
    ...getShadowStyles(Colors.black, 0.2, 4, 0, 4),
  },
  messageBoxRight: {
    justifyContent: 'flex-end',
  },
});
