import React, { useRef, useEffect } from 'react';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { View, StyleSheet, Animated } from 'react-native';
import { Colors, PositivityColors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { NotchIndicator } from '../NotchIndicator/NotchIndicator';

const MOOD_INDICATOR_TRANSITION_TIME = 500;

interface Props {
  score: number;
  maxScore?: number;
  title?: string;
  description?: string;
  color: Colors | PositivityColors;
  isIncreased?: boolean;
  isDecreased?: boolean;
  size?: 'sm' | 'md';
}

export const QuestionnaireScoreIndicator = (props: Props) => {
  const {
    score,
    maxScore,
    title,
    description,
    color,
    isIncreased,
    isDecreased,
    size = 'md',
  } = props;
  const animatedValue = useRef<Animated.Value>(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animatedValue.current, {
      toValue: 1,
      useNativeDriver: false,
      duration: MOOD_INDICATOR_TRANSITION_TIME,
    }).start();
  }, []);

  const opacity = animatedValue.current;
  const scale = animatedValue.current.interpolate({ inputRange: [0, 1], outputRange: [0.9, 1] });

  return (
    <Animated.View style={[styles.container, { opacity, transform: [{ scale }] }]}>
      {(!!isIncreased || !!isDecreased) && (
        <View style={styles.iconContainer}>
          <Ionicons
            name={isIncreased ? 'chevron-up-outline' : 'chevron-down-outline'}
            size={16}
            color={color || Colors.grey3}
          />
        </View>
      )}
      <View style={styles.numberContainer}>
        <LimbicText variant={size === 'sm' ? TextVariants.Base : TextVariants.XL}>
          {score}
        </LimbicText>
      </View>
      <View style={styles.depressionRankContainer}>
        <NotchIndicator
          value={score}
          maxValue={maxScore}
          color={color || Colors.grey3}
          notchCount={5}
          animationDelay={MOOD_INDICATOR_TRANSITION_TIME}
        />
      </View>
      {title ? (
        <View style={styles.detailsContainer}>
          {size !== 'sm' && (
            <LimbicText variant={TextVariants.M} color={Colors['darkGrey3-sixty']}>
              {title}
            </LimbicText>
          )}
          <LimbicText
            variant={TextVariants.Base}
            color={Colors.darkGrey3}
            style={styles.rangeDescription}
          >
            {description}
          </LimbicText>
        </View>
      ) : null}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: 10,
  },
  numberContainer: {
    marginRight: 12,
  },
  depressionRankContainer: {
    marginRight: 10,
  },
  rangeDescription: {
    marginTop: 2,
  },
  detailsContainer: {
    flexDirection: 'column',
  },
});
