import MaterialIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import FeatherIcons from 'react-native-vector-icons/Fonts/Feather.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import {
  Aeroport,
  AeroportBold,
  AeroportLight,
  AeroportMedium,
  FlowCircular,
  SyneBold,
  SyneExtra,
  SyneRegular,
} from '@limbic-for-therapists/components';

export const injectFonts = () => {
  const fontStyles = `
    @font-face {
        src: url('${MaterialIcons}');
        font-family: MaterialCommunityIcons;
    }

    @font-face {
        src: url('${Ionicons}');
        font-family: Ionicons;
    }

    @font-face {
        src: url('${FeatherIcons}');
        font-family: Feather;
    }

    @font-face {
        font-family: 'Aeroport';
        src: url('${AeroportMedium}');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Aeroport';
        src: url('${AeroportBold}');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Aeroport';
        src: url('${Aeroport}');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Aeroport';
        src: url('${AeroportLight}');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Syne';
        src: url('${SyneRegular}');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Syne Extra';
        src: url('${SyneExtra}');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Flow Circular';
        src: url('${FlowCircular}');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Syne';
        src: url('${SyneBold}');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
  `;
  const style = document.createElement('style');
  style.appendChild(document.createTextNode(fontStyles));
  document.head.appendChild(style);
};
