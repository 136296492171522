import React, { useCallback } from 'react';
import { Modal, StyleSheet } from 'react-native';
import { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { ModalContentContainer } from '../ModalContentContainer/ModalContentContainer';
import { Colors } from '../../config';

export type SelectInputItem<T extends string | number> = { label: string; value: T };

interface SelectInputProps<T extends string | number> {
  selectedItem?: SelectInputItem<T> | null;
  overrideOpen?: boolean;
  values: SelectInputItem<T>[];
  renderField?: boolean;
  onPressItem?: (item: SelectInputItem<T>) => void;
}

export const SelectInput = <T extends string | number>({
  selectedItem,
  overrideOpen,
  values,
  renderField = true,
  onPressItem,
}: SelectInputProps<T>) => {
  const [open, setOpen] = useState<boolean>(false);
  const isOpen = overrideOpen || open;

  const handlePressedItem = useCallback(
    (item: SelectInputItem<T>) => {
      setOpen(false);
      onPressItem?.(item);
    },
    [onPressItem]
  );

  return (
    <View>
      {renderField ? (
        <View style={styles.inputContainer}>
          <TouchableOpacity onPress={() => setOpen(true)}>
            <LimbicText variant={TextVariants.Base}>
              {selectedItem ? selectedItem.label : 'Nothing Selected'}
            </LimbicText>
          </TouchableOpacity>
        </View>
      ) : null}
      {isOpen ? (
        <Modal transparent animationType="fade">
          <ModalContentContainer
            onClose={() => setOpen(false)}
            style={styles.modalContentContainer}
          >
            {values.map((value) => (
              <View style={styles.selectItem} key={value.value}>
                <TouchableOpacity onPress={() => handlePressedItem(value)}>
                  <LimbicText variant={TextVariants.Base} bold={value === selectedItem}>
                    {value.label}
                  </LimbicText>
                </TouchableOpacity>
              </View>
            ))}
          </ModalContentContainer>
        </Modal>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    borderRadius: 8,
    borderColor: Colors.grey3,
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  selectItem: {
    paddingVertical: 8,
    borderBottomColor: Colors.grey4,
    borderBottomWidth: 1,
  },
  modalContentContainer: {
    padding: 16,
    maxHeight: 500,
    width: 500,
  },
});
