/* eslint-disable @typescript-eslint/no-explicit-any */
export enum ModalPrompts {
  NewPatient = 'NewPatient',
  PatientOptions = 'PatientOptions',
  ChangeSessionDate = 'ChangeSessionDate',
  CopingStrategy = 'CopingStrategy',
  CopingStrategyOptions = 'CopingStrategyOptions',
  BehaviouralAction = 'BehaviouralAction',
  BehaviouralActionOptions = 'BehaviouralActionOptions',
  Questionnaire = 'Questionnaire',
  GenerateReport = 'GenerateReport',
  QuestionnaireOptions = 'QuestionnaireOptions',
  ThoughtRecord = 'ThoughtRecord',
}

export interface IModalPromptResolve<T = any> {
  success: boolean;
  data?: T;
}

export interface ModalPromptProps {
  onGoBack: () => void;
  onPopToTop: () => void;
  onSubmit: (data: any) => void;
  onCancel: () => void;
}
