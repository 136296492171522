import { ApolloClient, gql, useQuery } from '@apollo/client';
import { IPsychoedFragment } from '../fragments';

export const PSYCHOEDS_QUERY = gql`
  query AllPsychoeds {
    allPsychoeds {
      id
      name
      description
      thumbnail {
        id
        url
        preferredHeight
        preferredWidth
        svg
        altText
      }
      estimatedMinutes
      slides {
        id
        title
        subtitle
        headerImage {
          id
          url
          preferredHeight
          preferredWidth
          svg
          altText
        }
        blocks {
          id
          position
          type
          text
          image {
            id
            url
            preferredHeight
            preferredWidth
            svg
            altText
          }
        }
        hidden
        position
      }
      visibility
      clinicId
      copiedFromId
      authorId
      archived
      draft
    }
  }
`;

export interface ListPsychoedsResponse {
  allPsychoeds: IPsychoedFragment[];
}

export function useListPsychoeds<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) {
  const { data, ...rest } = useQuery<ListPsychoedsResponse>(PSYCHOEDS_QUERY, {
    client: titanClient,
  });
  return { psychoeds: data?.allPsychoeds, ...rest };
}
