import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

/** Utilities */

interface Props {
  children: React.ReactNode;
}

export const ModalOptions = (props: Props) => {
  const { children } = props;
  return <View style={styles.container}>{children}</View>;
};

interface OptionItemProps {
  textVariant?: TextVariants;
  text?: string;
  color?: Colors;
  onPress?: () => void;
}

const OptionItem = ({ textVariant = TextVariants.L, text, color, onPress }: OptionItemProps) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.itemContainer}>
      <LimbicText doubleHeight variant={textVariant} color={color || Colors.darkGrey2}>
        {text}
      </LimbicText>
      <View style={styles.divider} />
    </TouchableOpacity>
  );
};

ModalOptions.Option = OptionItem;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
    paddingTop: 8,
  },
  itemContainer: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  divider: {
    marginTop: 16,
    height: 1,
    backgroundColor: Colors.grey2,
  },
});
