/* eslint-disable global-require */
export const Icons = {
  dis: require('../assets/images/dis.png'),
  notification: require('../assets/images/notification.png'),
  help: require('../assets/images/help.png'),
  right: require('../assets/images/right.png'),
  user: require('../assets/images/user.png'),
  balloon: require('../assets/images/balloon.png'),
  balloonstart: require('../assets/images/balloonstart.png'),
  balloonrelax: require('../assets/images/balloonrelax.png'),
  go: require('../assets/images/go.png'),
  activity: require('../assets/images/activity.png'),
  coping: require('../assets/images/coping.png'),
  questionnaire: require('../assets/images/questionairre.png'),
  activityWhite: require('../assets/images/activity_white.png'),
  copingWhite: require('../assets/images/coping_white.png'),
  questionnaireWhite: require('../assets/images/questionairre_white.png'),
  scheduled: require('../assets/images/scheduled.png'),
  newPatientArrow: require('../assets/images/new-patient-arrow.png'),
  mail: require('../assets/images/mail.png'),
  users: require('../assets/images/users.png'),
};
