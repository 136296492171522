import { ViewTabs } from '@limbic-for-therapists/components';
import { BehaviouralActionTypes, IClinician } from '@limbic-for-therapists/backend';
import { useCallback } from 'react';

type AnalyticEventName =
  | 'dashboard-toggled-tab'
  | 'user-logged-in'
  | 'user-registered'
  | 'report-viewed'
  | 'report-behavioural-activity-viewed'
  | 'report-coping-strategy-viewed'
  | 'report-questionnaire-viewed'
  | 'report-mood-log-viewed'
  | 'report-mood-log-scrolled'
  | 'patient-viewed'
  | 'generate-report'
  | 'change-session-date'
  | 'add-coping-strategy'
  | 'schedule-behavioural-activity'
  | 'schedule-questionnaire'
  | 'invite-patient-started'
  | 'invite-patient'
  | 'rate-report'
  | 'user-reset-password'
  | 'add-custom-intervention'
  | 'worksheets-homework-clicked'
  | 'behavioural-activity-homework-clicked'
  | 'coping-strategy-homework-clicked'
  | 'questionnaire-homework-clicked'
  | 'export-patient-clicked'
  | 'generate-report-clicked';

interface AnalyticBase<T extends AnalyticEventName, Q = undefined> {
  eventName: T;
  params?: Q;
}

export type AnalyticEvent = [
  AnalyticBase<'dashboard-toggled-tab', { tab: ViewTabs }>,
  AnalyticBase<'user-logged-in', { userId: string | null }>,
  AnalyticBase<'user-registered', { userId: string | null }>,
  AnalyticBase<'report-viewed', { reportId: string }>,
  AnalyticBase<
    'report-questionnaire-viewed',
    {
      id: string;
      reportId: string;
    }
  >,
  AnalyticBase<
    'report-behavioural-activity-viewed',
    {
      id: string;
      reportId: string;
      type: BehaviouralActionTypes;
    }
  >,
  AnalyticBase<
    'report-coping-strategy-viewed',
    {
      id: string | null;
      isDefaultCopingStrategies: boolean;
      reportId: string;
    }
  >,
  AnalyticBase<
    'report-mood-log-viewed',
    {
      reportId: string;
    }
  >,
  AnalyticBase<'report-mood-log-scrolled', { reportId: string | undefined }>,
  AnalyticBase<'rate-report', { reportId: string; rating: number }>,
  AnalyticBase<'patient-viewed', { patientId: string }>,
  AnalyticBase<'generate-report', { patientId: string; fromDate: Date; toDate: Date }>,
  AnalyticBase<'add-coping-strategy', { patientId: string; emotion: string }>,
  AnalyticBase<
    'schedule-behavioural-activity',
    { patientId: string; type: BehaviouralActionTypes }
  >,
  AnalyticBase<'change-session-date', { patientId: string; toDate: Date }>,
  AnalyticBase<'schedule-questionnaire', { patientId: string; types: string[] }>,
  AnalyticBase<'invite-patient-started'>,
  AnalyticBase<
    'invite-patient',
    {
      patientId: string;
      questionnaireTypes?: string[];
      name?: string;
      email?: string;
      phone?: string | null;
    }
  >,
  AnalyticBase<'user-reset-password'>,
  AnalyticBase<'add-custom-intervention', { patientId: string }>,
  AnalyticBase<'worksheets-homework-clicked', { patientId: string }>,
  AnalyticBase<'behavioural-activity-homework-clicked', { patientId: string }>,
  AnalyticBase<'coping-strategy-homework-clicked', { patientId: string }>,
  AnalyticBase<'questionnaire-homework-clicked', { patientId: string }>,
  AnalyticBase<'export-patient-clicked', { patientId: string }>,
  AnalyticBase<'generate-report-clicked', { patientId: string }>
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAnalytics = (mixpanel: any) => {
  const logAnalytic = useCallback(
    (event: AnalyticEvent[number]) => {
      if (!mixpanel) {
        console.log('Mixpanel unavailble while tracking', event.eventName, event.params);
        return;
      }
      try {
        mixpanel.track(event.eventName, event.params);
      } catch (e) {
        console.log('Error tracking event', event.eventName, event.params);
      }
    },
    [mixpanel]
  );

  const setUserProperties = useCallback(
    (clinician: IClinician) => {
      if (!mixpanel) return;
      try {
        mixpanel.identify(clinician.id);
        mixpanel.people.set({
          email: clinician.email,
          name: clinician.name,
          clinicId: clinician.clinic?.id,
          clinicName: clinician.clinic?.name,
        });
      } catch (e) {}
    },
    [mixpanel]
  );

  return { logAnalytic, setUserProperties };
};

export { useAnalytics };
