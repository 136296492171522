/* eslint-disable @typescript-eslint/no-explicit-any */
const { NODE_ENV } = process.env;
export type Thing = { [key: string]: unknown };

export function invariant<T = Thing>(
  condition: any,
  message?: string,
  ...args: any[]
): asserts condition is T {
  if (NODE_ENV !== 'production') {
    if (message === undefined) {
      throw new Error('invariant requires an error message argument');
    }
  }

  if (!condition) {
    let error;
    if (message === undefined) {
      error = new Error(
        'Minified exception occurred; use the non-minified dev environment ' +
          'for the full error message and additional helpful warnings'
      );
    } else {
      let argIndex = 0;
      // eslint-disable-next-line no-plusplus
      error = new Error(message.replace(/%s/g, () => args[argIndex++]));
      error.name = 'Internal App issue';
    }

    (error as any).framesToPop = 1; // we don't care about invariant's own frame
    throw error;
  }
}
