import { gql, useMutation } from '@apollo/client';

const DISCHARGE_PATIENT = gql`
  mutation dischargePatient($signUpCode: String!) {
    dischargePatient(code: $signUpCode) {
      id
    }
  }
`;

export interface IDischargePatientResponse {
  dischargePatient: { id: string };
}

export interface IDischargePatientVariables {
  signUpCode: string;
}

export const useDischargePatientMutation = () => {
  return useMutation<IDischargePatientResponse, IDischargePatientVariables>(DISCHARGE_PATIENT);
};
