/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { noop } from '@limbic-for-therapists/components';
import { createContext } from 'react';
import { useModalContext } from './Modal';
import { ModalNavigationBar } from '../components/ModalNavigationBar/ModalNavigationBar';
import { View } from 'react-native';

interface FlowContextProps<T> {
  values: T;
  setValues: (partial: Partial<T>) => void;
  moveToStep: (index: number) => void;
  moveToNextStep: () => void;
  moveToPreviousStep: () => void;
  finishFlow: (success?: boolean) => void;
}

const FlowContext = createContext<FlowContextProps<unknown>>({
  values: {},
  setValues: noop,
  moveToStep: noop,
  moveToNextStep: noop,
  moveToPreviousStep: noop,
  finishFlow: noop,
});

interface FlowContextProviderProps<T extends Record<string, any>> {
  defaultValues: T;
  hideBackButtonOnLastStep?: boolean;
  backButtonGoesToStep?: number;
  children: React.ReactNode | React.ReactNode[] | ((values: T) => React.ReactFragment);
  finishFlow?: (success?: boolean) => void;
  testID?: string;
}

export const FlowContextProvider = <T extends Record<string, any>>({
  children,
  hideBackButtonOnLastStep,
  backButtonGoesToStep,
  defaultValues,
  finishFlow,
  testID,
}: FlowContextProviderProps<T>) => {
  const { dismissModal } = useModalContext();
  const [values, setInternalValues] = useState<T>(defaultValues);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  const filteredChildren = useMemo(() => {
    const allChildren: React.ReactNode[] =
      typeof children === 'function' ? children(values)?.props?.children || [] : children;
    return Array.isArray(allChildren) ? allChildren.filter((el) => !!el) : [allChildren];
  }, [children, values]);

  const activeStep = useMemo(
    () => filteredChildren[activeStepIndex],
    [activeStepIndex, filteredChildren]
  );
  const showBackButton =
    activeStepIndex > 0 && hideBackButtonOnLastStep
      ? activeStepIndex < filteredChildren.length - 1
      : true;

  const handleFinishFlow = useCallback(
    (success?: boolean) => {
      finishFlow?.(success);
    },
    [finishFlow]
  );

  const moveToStep = useCallback((index: number) => {
    setActiveStepIndex(index);
  }, []);

  const moveToNextStep = useCallback(() => {
    const nextStepIdx = activeStepIndex + 1;
    const totalItems = Array.isArray(filteredChildren) ? filteredChildren.length : 1;
    if (nextStepIdx < totalItems) moveToStep(nextStepIdx);
  }, [activeStepIndex, filteredChildren, moveToStep]);

  const moveToPreviousStep = useCallback(() => {
    const prevStepIdx = activeStepIndex - 1;
    if (prevStepIdx < 0) {
      dismissModal();
      return;
    }
    moveToStep(prevStepIdx);
  }, [activeStepIndex, dismissModal, moveToStep]);

  const setValues = useCallback(
    (value: Partial<T>) => {
      setInternalValues({ ...values, ...value });
    },
    [values]
  );

  const handleBackButtonPress = useCallback(() => {
    if (backButtonGoesToStep !== undefined) {
      moveToStep(backButtonGoesToStep);
      return;
    }
    moveToPreviousStep();
  }, [backButtonGoesToStep, moveToPreviousStep, moveToStep]);

  return (
    <FlowContext.Provider
      value={{
        finishFlow: handleFinishFlow,
        moveToStep,
        values,
        setValues,
        moveToNextStep,
        moveToPreviousStep,
      }}
    >
      <View testID={testID} style={{ flexShrink: 1 }}>
        <ModalNavigationBar
          leftButton={
            showBackButton ? (
              <ModalNavigationBar.BackButton onPress={handleBackButtonPress} />
            ) : undefined
          }
          rightButton={<ModalNavigationBar.CloseButton onPress={finishFlow} />}
        />

        {activeStep}
      </View>
    </FlowContext.Provider>
  );
};

export const useFlowContext = <T extends Record<string, any>>() =>
  useContext(FlowContext) as FlowContextProps<T>;
