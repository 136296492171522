import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Colors, LimbicText, TextVariants, TutorialCard } from '@limbic-for-therapists/components';
import { GridColumn, GridContainer, GridRow } from '../components/ResponsiveGrid';
import { ScreenContainer } from '../components/ScreenContainer';
import LinearGradient from 'react-native-web-linear-gradient';
import Icon from 'react-native-vector-icons/Ionicons';
import { responsiveValue } from '../consts/dimensions';
import { useScreenDimensionsContext } from '../context/ScreenDimensions';
import { OutboundLink } from '../components/Link/Link';
import { STAGE } from '../consts/stage';

const ADMIN_PORTAL_LINK = {
  production: 'https://limbic-clinic-admin-portal.herokuapp.com/',
  staging: 'https://limbic-clinic-admin-portal.herokuapp.com/',
  development: 'https://limbic-clinic-admin-portal.herokuapp.com/',
};

const TRANSFER_CLINICIAN_STEPS = [
  {
    stepNumber: 1,
    description: `Find the patient you wish to transfer over in Limbic in IAPTUS. You can search using a patient's full name, mobile no., email address or postcode.`,
    imageURL: `/img/transfer-patient/step-2.png`,
  },
  {
    stepNumber: 2,
    description: `In IAPTUS, look up the Limbic Code.\nThis is listed in under Referral Notes.`,
    imageURL: `/img/transfer-patient/step-3.png`,
  },
  {
    stepNumber: 3,
    description: (
      <LimbicText variant={TextVariants.Base}>
        Next, click{' '}
        <LimbicText variant={TextVariants.Base} color={Colors.pink1} bold>
          <OutboundLink href={ADMIN_PORTAL_LINK[STAGE]} target="_blank">
            here
          </OutboundLink>
        </LimbicText>{' '}
        to access the Clinic Admin Portal.
      </LimbicText>
    ),
  },
  {
    stepNumber: 4,
    description: `Log in to the admin dashboard, then search for the patient with the Limbic Code.`,
    imageURL: `/img/transfer-patient/step-4.png`,
  },
  {
    stepNumber: 5,
    description: `Press “Change clinician” and apply the right clinician to the patient.`,
    imageURL: `/img/transfer-patient/step-5.png`,
  },
];

const ICON_SIZE = 24;

export const TransferClinicianScreen = () => {
  const { currentSize } = useScreenDimensionsContext();

  const stepRows = useMemo(
    () => [
      TRANSFER_CLINICIAN_STEPS.slice(0, 2),
      [TRANSFER_CLINICIAN_STEPS[2]],
      TRANSFER_CLINICIAN_STEPS.slice(3),
    ],
    []
  );

  return (
    <ScreenContainer
      backgroundColor={Colors.grey1}
      backPath="/dashboard"
      title="Transfer Patient to New Clinician"
    >
      <GridContainer>
        <GridRow>
          <GridColumn span={12}>
            <LimbicText bold variant={TextVariants.XL}>
              Transfer Patient to New Clinician
            </LimbicText>
            <LimbicText variant={TextVariants.Base}>
              In order to re-assign patients to other clinicians, follow the following steps.
            </LimbicText>
          </GridColumn>
        </GridRow>
        {stepRows.map((row, idx) => (
          <GridRow
            withGutter={responsiveValue(currentSize, false, true)}
            key={idx}
            style={styles.row}
            wrap="wrap"
          >
            {row.map((step) => (
              <GridColumn
                span={responsiveValue(currentSize, 12, 12 / row.length)}
                style={{ marginTop: responsiveValue(currentSize, 10, 0) }}
                key={step.stepNumber}
              >
                <TutorialCard
                  backgroundColor={Colors.white}
                  stepNumber={step.stepNumber}
                  description={step.description}
                  image={step.imageURL ? { uri: step.imageURL } : undefined}
                  imageBackground={
                    <LinearGradient
                      useAngle={true}
                      angle={240}
                      colors={[`#FA7E7B`, '#ED84B2']}
                      style={{ flexGrow: 1 }}
                    />
                  }
                />
              </GridColumn>
            ))}
          </GridRow>
        ))}
        <GridRow style={styles.row}>
          <GridColumn span={12}>
            <TutorialCard
              backgroundColor={Colors.green3}
              description="All done!"
              textVariant={TextVariants.L}
              textStyle={styles.doneCardText}
              stepIcon={<Icon name="checkmark-outline" color={Colors.green1} size={ICON_SIZE} />}
            />
          </GridColumn>
        </GridRow>
      </GridContainer>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  row: {
    marginTop: 20,
  },
  doneCardText: {
    color: Colors.white,
  },
});
