import {
  IInterventionFragment,
  IInterventionOrPsychoedFragment,
  IPsychoedFragment,
  useListInterventions,
  useListPsychoeds,
} from '@limbic-for-therapists/backend';
import {
  AsyncData,
  Button,
  Colors,
  EmptyState,
  Header,
  InterventionCard,
  LimbicText,
  List,
  PsychoedCard,
  TextInput,
  TextVariants,
} from '@limbic-for-therapists/components';
import Fuse from 'fuse.js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { useHistory, useParams, useLocation } from 'react-router';
import { titanClient } from '../../backend/client';
import { InterventionPreview } from '../../components/InterventionPreview/InterventionPreview';
import { GridColumn, GridContainer, GridRow } from '../../components/ResponsiveGrid';
import { ScreenContainer } from '../../components/ScreenContainer';
import { useModalContext } from '../../context/Modal';
import { AddCustomInterventionFlow } from '../../flows/AddCustomInterventionFlow/AddCustomInterventionFlow';
import { useToastContext } from '../../context/Toast';

function Intervention({
  intervention,
  isSelected,
  onPress,
}: {
  intervention: IInterventionFragment;
  isSelected: boolean;
  onPress: () => void;
}) {
  return (
    <GridRow style={{ marginBottom: 24 }} withGutter>
      <GridColumn span={12}>
        <InterventionCard selected={isSelected} intervention={intervention} onPress={onPress} />
      </GridColumn>
    </GridRow>
  );
}

function Psychoed({
  intervention,
  isSelected,
  onPress,
  setSearchTerm,
}: {
  intervention: IPsychoedFragment;
  isSelected: boolean;
  onPress: () => void;
  setSearchTerm: (term: string) => void;
}) {
  return (
    <GridRow style={{ marginBottom: 24 }} withGutter>
      <GridColumn span={12}>
        <PsychoedCard
          setSearchTerm={setSearchTerm}
          selected={isSelected}
          psychoed={intervention}
          onPress={onPress}
        />
      </GridColumn>
    </GridRow>
  );
}

const interventionToFragment = (
  intervention: any[],
  type: 'intervention' | 'psychoed'
): IInterventionOrPsychoedFragment[] =>
  intervention.map((i) => ({ ...i, type })) as IInterventionOrPsychoedFragment[];

export const InterventionLibraryScreen = () => {
  const { interventions, loading, error } = useListInterventions(titanClient);
  const { psychoeds } = useListPsychoeds(titanClient);
  const { signUpCode } = useParams<{ signUpCode: string }>();
  const { search } = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const { setContent } = useModalContext();
  const history = useHistory();
  const { setErrorToast } = useToastContext();
  const [fuse] = useState(
    new Fuse<IInterventionOrPsychoedFragment>([], { keys: ['name', 'description', 'type'] })
  );
  const [fuseVersion, setFuseVersion] = useState(0);
  const [visibleInterventions, setVisibleInterventions] = useState<
    IInterventionOrPsychoedFragment[]
  >([]);

  const selectedInterventionId = useMemo(() => {
    return new URLSearchParams(search).get('selected') || undefined;
  }, [search]);

  const setSelectedInterventionId = useCallback(
    (id?: string) => {
      const searchParams = new URLSearchParams(search);
      if (id) {
        searchParams.set('selected', id);
      } else {
        searchParams.delete('selected');
      }

      history.replace({
        search: searchParams.toString(),
      });
    },
    [history, search]
  );

  const newPatientName = useMemo(() => {
    return new URLSearchParams(search).get('newPatientName') || undefined;
  }, [search]);

  const publishedInterventions = useMemo(
    // TODO: show draft interventions in the future
    () => interventions?.filter((i) => !i.draft),
    [interventions]
  );

  const publishedPsychoeds = useMemo(
    () => psychoeds?.filter((i) => !i.draft && !i.archived),
    [psychoeds]
  );

  useEffect(() => {
    if (publishedInterventions || publishedPsychoeds) {
      fuse.setCollection([]);

      if (publishedInterventions) {
        interventionToFragment(publishedInterventions, 'intervention').forEach((i) => fuse.add(i));
      }

      if (publishedPsychoeds) {
        interventionToFragment(publishedPsychoeds, 'psychoed').forEach((i) => fuse.add(i));
      }
    } else {
      fuse.remove(() => true);
    }

    setFuseVersion((currentVersion) => currentVersion + 1);
    // setFuseVersion and fuse never change, this is here to make eslint happy.
    // If for some reason those start changing this could the source of the bug
  }, [publishedInterventions, publishedPsychoeds, setFuseVersion, fuse]);

  useEffect(() => {
    if (searchTerm) {
      setVisibleInterventions(fuse.search(searchTerm).map(({ item }) => item));
    } else {
      const interventionsToSet = [];

      if (publishedInterventions)
        interventionsToSet.push(...interventionToFragment(publishedInterventions, 'intervention'));
      if (publishedPsychoeds)
        interventionsToSet.push(...interventionToFragment(publishedPsychoeds, 'psychoed'));

      setVisibleInterventions(interventionsToSet);
    }
    // fuse never changes, this is here to make eslint happy.
    // If for some reason it starts changing this could the source of the bug
  }, [searchTerm, fuseVersion, publishedInterventions, fuse, publishedPsychoeds]);

  const selectedIntervention = useMemo(
    () =>
      selectedInterventionId?.startsWith('intervention')
        ? publishedInterventions?.find((i) => i.id === selectedInterventionId?.split('-')[1])
        : publishedPsychoeds?.find((i) => i.id === selectedInterventionId?.split('-')[1]),
    [publishedInterventions, publishedPsychoeds, selectedInterventionId]
  );

  useEffect(() => {
    if (selectedInterventionId && !selectedIntervention) {
      setErrorToast('Homework not found in library');
      setSelectedInterventionId(undefined);
    }
  }, [selectedIntervention, selectedInterventionId, setSelectedInterventionId, setErrorToast]);

  const renderInterventions = useCallback(
    () => (
      <React.Fragment>
        <View style={styles.container}>
          <View
            style={{
              flexShrink: 0,
              flexDirection: 'column',
              flexGrow: 1,

              maxHeight: '100%',
              marginRight: 16,
            }}
          >
            <View style={styles.listContainer}>
              <Header
                tag="Step 1"
                title={
                  newPatientName
                    ? `Choose an Intervention for ${newPatientName}`
                    : 'Choose an Intervention'
                }
                subtitle="Choose from a list of preconfigured Interventions based on industry standards"
              />
              <View style={styles.searchFieldContainer}>
                <TextInput
                  testID="search-input"
                  nativeID="search-input"
                  placeholder="Search"
                  icon="search"
                  value={searchTerm}
                  onChangeText={setSearchTerm}
                  style={styles.searchField}
                />
              </View>
              <ScrollView>
                <List
                  list={visibleInterventions}
                  renderItem={(intervention) =>
                    intervention.type === 'intervention' ? (
                      <Intervention
                        key={'intervention-' + intervention.id}
                        isSelected={
                          (selectedIntervention as IInterventionFragment)?.steps &&
                          intervention.id === selectedIntervention?.id
                        }
                        intervention={intervention as IInterventionFragment}
                        onPress={() => setSelectedInterventionId('intervention-' + intervention.id)}
                      />
                    ) : (
                      <Psychoed
                        key={'psychoed-' + intervention.id}
                        setSearchTerm={setSearchTerm}
                        isSelected={
                          (selectedIntervention as IPsychoedFragment)?.slides &&
                          intervention.id === selectedIntervention?.id
                        }
                        intervention={intervention as IPsychoedFragment}
                        onPress={() => setSelectedInterventionId('psychoed-' + intervention.id)}
                      />
                    )
                  }
                  emptyState={<EmptyState title="Activities" text="No activities yet" />}
                />
                <View style={styles.editButton}>
                  <LimbicText variant={TextVariants.Base}>
                    can&apos;t find an activity that fits the patient&apos;s needs?
                  </LimbicText>
                  <Pressable
                    onPress={() =>
                      setContent(
                        <AddCustomInterventionFlow
                          signUpCode={signUpCode}
                          onInterventionPublished={(id) =>
                            setSelectedInterventionId('psychoed-' + id)
                          }
                        />,
                        undefined,
                        false
                      )
                    }
                  >
                    <LimbicText
                      variant={TextVariants.Base}
                      style={{ textDecorationLine: 'underline' }}
                      center
                    >
                      create a new one
                    </LimbicText>
                  </Pressable>
                </View>
              </ScrollView>
            </View>
            <View style={{ alignSelf: 'flex-start', marginTop: 12 }}>
              <Button
                icon={'chevron-right'}
                title={'Schedule intervention'}
                disabled={!selectedIntervention}
                onPress={() =>
                  selectedInterventionId?.startsWith('intervention')
                    ? history.push({
                        pathname: `./schedule-intervention/${selectedIntervention?.id}`,
                        search,
                      })
                    : history.push({
                        pathname: `./schedule-psychoed/${selectedIntervention?.id}`,
                        search,
                      })
                }
              ></Button>
            </View>
          </View>

          <InterventionPreview intervention={selectedIntervention} />
        </View>
      </React.Fragment>
    ),
    [
      searchTerm,
      visibleInterventions,
      selectedInterventionId,
      setSelectedInterventionId,
      selectedIntervention,
      setContent,
      signUpCode,
      history,
      newPatientName,
      search,
    ]
  );

  return (
    <ScreenContainer
      backgroundColor={Colors.grey1}
      title="Activities"
      backPath={`/dashboard/patient/${signUpCode}`}
      style={styles.screen}
      refreshOnBack={true}
    >
      <GridContainer style={{ flexShrink: 1 }}>
        <AsyncData loading={loading} error={error} render={renderInterventions} />
      </GridContainer>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  screen: {
    height: '100vh',
    maxHeight: '100vh',
    paddingBottom: 8,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexShrink: 1,
    marginBottom: 8,
  },
  listContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
    maxHeight: '100%',
    marginRight: 16,
  },
  editButton: {
    alignSelf: 'center',
  },
  searchFieldContainer: {
    marginBottom: 24,
  },
  searchField: {
    backgroundColor: 'white',
  },
});
