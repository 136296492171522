import React from 'react';
import { StyleProp, ViewStyle, View, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { TouchableActiveOpacity } from '../../consts';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const ICON_SIZE = 24;

interface QuestionnairePreviewProps {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  title: string;
  subtitle: string;
  bottomAccessory?: React.ReactNode;
  content?: React.ReactNode;
}

const DefaultContent = () => (
  <View style={styles.contentCenteredContainer}>
    <LimbicText variant={TextVariants.Base} color={Colors.darkGrey3} style={{ padding: '2rem 0' }}>
      Click to view questionnaire
    </LimbicText>
  </View>
);

export const QuestionnairePreviewContainer = ({
  onPress,
  style,
  title,
  subtitle,
  bottomAccessory,
  content,
}: QuestionnairePreviewProps) => {
  const contentNode = content || <DefaultContent />;
  return (
    <TouchableOpacity
      style={style}
      activeOpacity={TouchableActiveOpacity.light}
      onPress={onPress}
      testID="questionnaire-preview"
    >
      <SummaryCard
        title={title}
        subtitle={subtitle}
        leftAccessory={<Icon name="checkmark-outline" color={Colors.darkGrey3} size={ICON_SIZE} />}
        bottomAccessory={bottomAccessory}
      >
        {contentNode}
      </SummaryCard>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  contentCenteredContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '16px',
    paddingBottom: '16px',
    overflow: 'hidden',
  },
});
