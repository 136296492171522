type ValidStage = 'production' | 'staging' | 'development';

const VALID_STAGES: string[] = ['production', 'staging', 'development'];

export const STAGE = ((): ValidStage => {
  const stage = process.env.REACT_APP_STAGE;
  if (VALID_STAGES.includes(stage ?? '')) return stage as ValidStage;

  throw new Error(
    'Please set the REACT_APP_STAGE environment variable explicitly, avaiable options are: development, staging, and production'
  );
})();
