import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { getShadowStyles } from '../../config/shadow';
import { WithTestID } from '../../utils/withTestID';

interface SpeechBubbleWithDetailsProps {
  subtitle?: string;
  title?: string;
  backgroundColor?: string;
  leftAccessory?: React.ReactNode;
  children?: React.ReactNode;
}

export const SpeechBubbleWithDetails = ({
  children,
  subtitle,
  title,
  backgroundColor,
  leftAccessory,
  testID,
}: WithTestID<SpeechBubbleWithDetailsProps>) => {
  return (
    <View testID={testID} style={[styles.container, { backgroundColor }]}>
      {children ? <View style={styles.innerContainer}>{children}</View> : null}
      <View style={styles.bottomContainer}>
        {leftAccessory ? <View style={styles.leftAccessoryContainer}>{leftAccessory}</View> : null}
        <View style={styles.textContainer}>
          {subtitle ? (
            <LimbicText variant={TextVariants.XS} color={Colors.white}>
              {subtitle}
            </LimbicText>
          ) : null}
          {title ? (
            <LimbicText variant={TextVariants.Base} color={Colors.white}>
              {title}
            </LimbicText>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    ...getShadowStyles(Colors.black, 0.2, 10),
    flexGrow: 1,
  },
  innerContainer: {
    borderRadius: 8,
    padding: 16,
    backgroundColor: Colors.white,
    flexGrow: 1,
  },
  leftAccessoryContainer: {
    marginRight: 6,
  },
  bottomContainer: {
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 12,
    paddingTop: 12,
  },
  textContainer: {
    width: 0,
    flexGrow: 1,
    flexDirection: 'column',
  },
});
