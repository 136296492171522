import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config/colors';
import { TitleBar, TitleBarProps } from '../TitleBar/TitleBar';
import { Line } from '../Line/Line';

interface SummaryCardProps extends TitleBarProps {
  children?: React.ReactNode;
  showLine?: boolean;
  bottomAccessory?: React.ReactNode;
}

export const SummaryCard = ({
  title,
  subtitle,
  subtitleVariant,
  leftAccessory,
  rightAccessory,
  showLine = true,
  children,
  bottomAccessory,
}: SummaryCardProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.titleBarContainer}>
        <TitleBar
          title={title}
          subtitle={subtitle}
          leftAccessory={leftAccessory}
          rightAccessory={rightAccessory}
          subtitleVariant={subtitleVariant}
        />
      </View>
      {showLine ? <Line color={Colors.grey2} /> : null}
      <View style={styles.contentContainer}>{children}</View>
      {bottomAccessory ? <View style={styles.bookendContainer}>{bottomAccessory}</View> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    backgroundColor: Colors.white,
    flexDirection: 'column',
  },
  titleBarContainer: {
    padding: 16,
  },
  bookendContainer: {
    padding: 16,
  },
  leftAccessoryContainer: {
    marginRight: 10,
  },
  rightAccessoryContainer: {
    marginLeft: 10,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  contentContainer: {
    paddingTop: 10,
    paddingHorizontal: 16,
  },
  textContainer: {
    flexDirection: 'column',
    flexBasis: 0,
    flexGrow: 1,
  },
});
