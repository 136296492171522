import { gql, useMutation } from '@apollo/client';
import { IUpdateClinicianResponse } from './updateClinician';

export interface IUpdateClinicianPasswordResponse {
  updateClinicianPassword: { id: string; name: string; email: string };
}

const UPDATE_CLINICIAN_PASSWORD = gql`
  mutation updateClinicianPassword($email: String!, $oldPassword: String!, $newPassword: String!) {
    updateClinicianPassword(email: $email, oldPassword: $oldPassword, newPassword: $newPassword) {
      id
      name
      email
    }
  }
`;

export interface IUpdateClinicianPasswordVariables {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export const useUpdateClinicianPasswordMutation = () => {
  return useMutation<IUpdateClinicianResponse, IUpdateClinicianPasswordVariables>(
    UPDATE_CLINICIAN_PASSWORD
  );
};
