import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

interface PublicRouteProps extends RouteProps {
  isAuthenticated: boolean;
  defaultUnauthenticatedPath: string;
}

/**
 * A route that is only accessible to authenticated users. Unauthenticated
 * users will be redirected to the route passed as a parameter. The inaccessable
 * route will be passed as a state parameter to the redirect.
 */
const AuthenticatedRoute: FC<PublicRouteProps> = (props) => {
  const { isAuthenticated, defaultUnauthenticatedPath, children, ...rest } = props;

  if (!isAuthenticated)
    return (
      <Route
        {...rest}
        exact
        render={({ location }) => (
          <Redirect
            to={{
              pathname: defaultUnauthenticatedPath || '/',
              state: { from: location },
            }}
          />
        )}
      />
    );

  return <Route {...rest}>{children}</Route>;
};

export default AuthenticatedRoute;
