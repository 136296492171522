import React from 'react';
import { Colors } from '../../config';

const ASPECT_RATIO = 0.233;

interface LogoProps {
  width: number;
  color: Colors;
}

export const Logo = ({ width, color }: LogoProps) => {
  return (
    <svg
      width={width}
      height={width * ASPECT_RATIO}
      viewBox="0 0 107 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="evenodd">
        <path
          d="M17.669 20.626a5.66 5.66 0 0 0-1.616-2.754l-4.213-4a1.892 1.892 0 0 1 0-2.744l4.213-4a5.725 5.725 0 0 0 1.354-1.955c.072-.173.16-.438.262-.796l.675.641 3.194 3.034c2.583 2.453 2.583 6.443 0 8.9l-3.87 3.674zM9.48 24.114l-.97-.921-.03-.028c-1.234-1.188-1.913-2.754-1.913-4.424 0-1.307.417-2.554 1.194-3.604a.797.797 0 0 1 1.189-.104l.084.08 4.962 4.712a2.93 2.93 0 0 1 0 4.289c-1.248 1.181-3.272 1.181-4.516 0zM8.511 1.81l.97-.924c1.244-1.181 3.268-1.181 4.516 0a2.93 2.93 0 0 1 0 4.29l-5.056 4.8-.034.033a.743.743 0 0 1-1.104-.09 6.028 6.028 0 0 1-1.235-3.66c0-1.667.68-3.236 1.914-4.42l.03-.029zM1.94 16.951c-2.587-2.456-2.587-6.446 0-8.9l1.722-1.636a8.652 8.652 0 0 0 1.51 4.742 2.388 2.388 0 0 1-.01 2.7 8.658 8.658 0 0 0-1.5 4.73L1.94 16.952z"
          fill={color}
        />
        <path
          d="M43.83 18.321v2.719c-1.806 0-3.351-.63-4.635-1.895-1.284-1.265-1.928-2.792-1.928-4.572V3.506s1.008-.005 1.377-.005h.037c.37 0 1.377.005 1.377.005s-.004 8.3 0 11.067c.002 1.024.365 1.936 1.097 2.663.733.722 1.63 1.085 2.675 1.085M49.366 3.5v2.751h-2.792v-2.75h2.792zm0 4.604v12.937h-2.792V8.104h2.792zM54.786 21.041h-2.792v-7.535c0-1.483.536-2.755 1.607-3.818 1.071-1.056 2.363-1.584 3.875-1.584 1.808 0 3.171.592 4.091 1.775.911-1.183 2.271-1.775 4.08-1.775 1.511 0 2.803.528 3.874 1.584 1.071 1.063 1.607 2.335 1.607 3.818v7.535h-2.78v-7.497c0-.685-.25-1.356-.727-1.853a2.632 2.632 0 0 0-1.974-.835c-.773 0-1.426.277-1.961.833a2.666 2.666 0 0 0-.729 1.852v7.5h-2.792v-7.5c0-.685-.25-1.355-.728-1.852a2.62 2.62 0 0 0-1.961-.833c-.773 0-1.426.277-1.961.833a2.666 2.666 0 0 0-.729 1.852v7.5zM80.191 10.878c-1.048 0-1.94.363-2.678 1.09-.73.726-1.094 1.605-1.094 2.638 0 1.026.365 1.902 1.094 2.628.737.726 1.63 1.09 2.678 1.09 1.041 0 1.93-.364 2.667-1.09.737-.726 1.106-1.602 1.106-2.628 0-1.033-.37-1.912-1.106-2.639-.737-.726-1.626-1.089-2.667-1.089zM75.041 3.5h1.378v5.727c.859-.748 2.116-1.123 3.772-1.123 1.808 0 3.354.633 4.639 1.898 1.283 1.266 1.926 2.789 1.926 4.57 0 1.782-.643 3.306-1.926 4.571-1.285 1.266-2.83 1.898-4.639 1.898-1.808 0-3.35-.629-4.626-1.887-1.285-1.25-1.93-2.766-1.938-4.548V3.5h1.413zM91.891 3.5v2.751H89.1v-2.75h2.791zm0 4.604v12.937H89.1V8.104h2.791zM104.15 15.914c-.18.483-.465.923-.861 1.319-.738.726-1.51 1.09-2.558 1.09-1.049 0-1.942-.362-2.676-1.089-.732-.727-1.096-1.6-1.096-2.628 0-1.033.364-1.913 1.096-2.64.734-.724 1.627-1.089 2.676-1.089 1.049 0 1.82.364 2.558 1.087.38.38.658.802.84 1.265h2.913c-.249-1.217-.845-2.294-1.793-3.227-1.285-1.267-2.71-1.897-4.518-1.897-1.808 0-3.355.632-4.637 1.898-1.285 1.264-1.926 2.788-1.926 4.57 0 1.781.641 3.305 1.926 4.571 1.282 1.264 2.829 1.896 4.637 1.896 1.808 0 3.233-.634 4.518-1.897.947-.936 1.544-2.013 1.792-3.23h-2.89z"
          fill={color}
        />
      </g>
    </svg>
  );
};
