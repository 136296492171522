import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface SetSessionDateProps {
  isFirstTime?: boolean;
  submitTitle: string;
  type?: 'change-next' | 'change-all';
  buttonTitle: string;
  frequencyString?: string;
  frequencies: React.ReactNode;
  isUpcomingSession: boolean;
  loading: boolean;
  onPressedPickDay: () => void;
  onSetCustomFrequency: () => void;
  onSubmit: () => void;
}

export const SetSessionDate = ({
  isFirstTime,
  frequencyString,
  frequencies,
  buttonTitle,
  submitTitle,
  type,
  isUpcomingSession,
  loading,
  onPressedPickDay,
  onSetCustomFrequency,
  onSubmit,
}: SetSessionDateProps) => {
  return (
    <>
      <ModalContent scrollable contentContainerStyle={styles.container}>
        <ModalContent.Title>
          {isFirstTime ? 'Set session date' : 'Change session data'}
        </ModalContent.Title>

        {!!isFirstTime && (
          <ModalContent.Body>
            It’s good to set a default for yourself to assure there will be a report delivered on
            time for you. A report will be created 24h before the session. You can always make a
            change to the session date.
          </ModalContent.Body>
        )}

        {type !== 'change-next' && (
          <View style={styles.frequencyContainer}>
            <ModalContent.Body style={isFirstTime && styles.bodyText}>
              What&apos;s the frequency of the session?
            </ModalContent.Body>
            <View style={styles.tagContainer}>{frequencies}</View>
            <TouchableOpacity
              onPress={onSetCustomFrequency}
              style={styles.interval}
              testID="set-session-date-set-other-interval-button"
            >
              <LimbicText
                variant={TextVariants.Base}
                color={Colors.darkGrey1}
                style={styles.intervalText}
              >
                Set other repeating interval
              </LimbicText>
            </TouchableOpacity>
          </View>
        )}

        <View style={styles.nextSessionDateContainer}>
          <ModalContent.Body style={isFirstTime && styles.bodyText}>
            {isUpcomingSession || type === 'change-next' ? 'Upcoming' : 'Last'} session
          </ModalContent.Body>

          <ModalContent.CTAButton
            fixed
            small
            title={buttonTitle}
            icon="calendar"
            iconProps={{ size: 16, color: Colors.white }}
            style={[styles.tag, styles.pickDateButton]}
            textStyle={[styles.pickDateButtonText]}
            onPress={onPressedPickDay}
          />
          {frequencyString ? (
            <ModalContent.Body>
              {type !== 'change-next' && `Sessions are ${frequencyString}.\n`}
              The reports are sent one day before each session.
            </ModalContent.Body>
          ) : null}
        </View>

        <ModalContent.CTAButton
          title={submitTitle}
          onPress={onSubmit}
          loading={loading}
          testID="set-session-date-submit-button"
        />
      </ModalContent>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  bodyText: {
    color: Colors.darkGrey2,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tag: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  interval: {
    alignSelf: 'flex-end',
  },
  intervalText: {
    textDecorationLine: 'underline',
    fontSize: 12,
    lineHeight: 18,
  },
  frequencyContainer: {
    paddingBottom: 24,
  },
  nextSessionDateContainer: {
    paddingBottom: 24,
  },
  pickDateButton: {
    alignSelf: 'flex-start',
    borderWidth: 1,
    backgroundColor: Colors.white,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
});
