import { ApolloClient, gql, useQuery } from '@apollo/client';
import { fragments, IInterventionFragment } from '../fragments';

export const GET_INTERVENTION = gql`
  query getIntervention($id: ID!) {
    intervention(id: $id) {
      ...Intervention
    }
  }
  ${fragments.intervention}
`;

export interface IGetInterventionResponse {
  intervention: IInterventionFragment;
}

export function useGetIntervention<TCacheShape extends Record<string, unknown>>(
  id: string | null | undefined,
  titanClient: ApolloClient<TCacheShape>
) {
  const { data, refetch, loading } = useQuery<IGetInterventionResponse>(GET_INTERVENTION, {
    variables: { id },
    skip: id == null,
    client: titanClient,
  });
  return { getIntervention: refetch, intervention: data?.intervention, loading };
}
