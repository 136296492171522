import { ApolloClient, gql, useQuery } from '@apollo/client';
import { fragments, IInterventionFragment } from '../fragments';

export const LIST_INTERVENTIONS = gql`
  query listInterventions {
    interventions {
      ...Intervention
    }
  }
  ${fragments.intervention}
`;

export interface ListInterventionsResponse {
  interventions: IInterventionFragment[];
}

export function useListInterventions<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) {
  const { data, ...rest } = useQuery<ListInterventionsResponse>(LIST_INTERVENTIONS, {
    client: titanClient,
  });
  return { interventions: data?.interventions, ...rest };
}
