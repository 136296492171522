import React, { SVGProps } from 'react';

export const MedicalDeviceManufactureDateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <path
        d="m135.36 118.66v-82.034h33.408v126.74h-137.53v-44.706l18.981-32.876 16.629 27.978 16.629-27.978 16.629 27.978 16.629-27.978 18.271 31.647"
        fill="none"
        stroke="#000"
        strokeWidth="5"
      />
    </svg>
  );
};
