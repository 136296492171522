const AUTH_TOKEN_KEY = '@limbic-for-therapists/auth-token';
const USER_TYPE_KEY = '@limbic-for-therapists/user-permissions';

const getAuthToken = () => {
  if (!window) return null;
  return window.localStorage.getItem(AUTH_TOKEN_KEY);
};

const setAuthToken = (authToken: string | null) => {
  if (!authToken) return window.localStorage.removeItem(AUTH_TOKEN_KEY);
  return window.localStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

const setPermissions = (permissions: string[] | null) => {
  if (!permissions) return window.localStorage.removeItem(USER_TYPE_KEY);
  return window.localStorage.setItem(USER_TYPE_KEY, JSON.stringify(permissions));
};

const getPermissions = (): string[] => {
  try {
    if (!window) throw new Error();
    const permissions = window.localStorage.getItem(USER_TYPE_KEY);
    if (!permissions) throw new Error();
    return JSON.parse(permissions);
  } catch {
    return [];
  }
};

export { getAuthToken, setAuthToken, getPermissions, setPermissions };
