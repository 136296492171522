import React, { useCallback } from 'react';
import { EditEmail } from '@limbic-for-therapists/components';
import { RequiredString, validate } from '@limbic-for-therapists/shared';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useToastContext } from '../context/Toast';
import { useActiveClinicianContext } from '../context/ActiveClinician';
import { useUpdateClinician } from '@limbic-for-therapists/backend';

const validationSchema = yup.object().shape({
  email: RequiredString('Please supply a valid email.'),
});

interface EditEmailScreenProps {
  onSuccess: () => void;
}

export const EditEmailScreen = ({ onSuccess }: EditEmailScreenProps) => {
  const { activeClinician } = useActiveClinicianContext();
  const { setErrorToast } = useToastContext();
  const formProps = useForm<{ email: string }>({
    defaultValues: { email: activeClinician?.email },
  });
  const { updateClinician, loading } = useUpdateClinician();

  const handleSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        await validate(validationSchema, { email });
        await updateClinician({ variables: { email } });
        onSuccess();
      } catch (e) {
        setErrorToast(e.message);
      }
    },
    [onSuccess, setErrorToast, updateClinician]
  );

  return <EditEmail loading={loading} formProps={formProps} onSubmit={handleSubmit} />;
};
