import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config/colors';
import { getShadowStyles } from '../../config/shadow';

interface SpeechBubbleProps {
  children: React.ReactNode;
}

export const SpeechBubble = ({ children }: SpeechBubbleProps) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    padding: 16,
    backgroundColor: Colors.white,
    ...getShadowStyles(Colors.black, 0.2, 10),
  },
});
