import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Modal,
  Dimensions,
  Animated,
  StatusBar,
} from 'react-native';
import { noop } from '../../utils/noop';
import { Colors } from '../../config/colors';
import { ModalHandler } from './ModalHandler';
import { getShadowStyles } from '../../config';

const { height: screenHeight } = Dimensions.get('screen');
const { height: windowHeight } = Dimensions.get('window');
const navbarHeight = screenHeight - windowHeight + (StatusBar.currentHeight || 0);

interface Props {
  visible?: boolean;
  children: ReactElement | ReactElement[];
  onClose?: () => void;
  onOverlayPress?: () => void;
}

export const LimbicModal = React.forwardRef<View, Props>(
  ({ visible = false, children, onOverlayPress, onClose }, ref) => {
    const [internalVisible, setInternalVisible] = useState<boolean>(false);
    const backgroundAnimationValue = useRef<Animated.Value>(new Animated.Value(0));
    const modalAnimationValue = useRef<Animated.Value>(new Animated.Value(0));

    useEffect(() => {
      if (visible) setInternalVisible(visible);
      Animated.stagger(100, [
        Animated.spring(backgroundAnimationValue.current, {
          useNativeDriver: false,
          toValue: visible ? 1 : 0,
        }),
        Animated.spring(modalAnimationValue.current, {
          useNativeDriver: false,
          toValue: visible ? 1 : 0,
        }),
      ]).start(() => {
        if (!visible) setInternalVisible(false);
      });
    }, [internalVisible, visible]);

    return (
      <Modal
        animationType="none"
        transparent
        visible={internalVisible}
        onRequestClose={onClose}
        onDismiss={onClose}
        presentationStyle="overFullScreen"
        style={{ height: screenHeight }}
      >
        <View style={styles.modalContainer}>
          <Animated.View
            style={[
              styles.overlay,
              {
                opacity: backgroundAnimationValue.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 1],
                }),
              },
            ]}
          >
            <TouchableOpacity style={styles.overlay} activeOpacity={0.8} onPress={onOverlayPress} />
          </Animated.View>

          <Animated.View
            ref={ref}
            style={[
              styles.container,
              {
                transform: [
                  {
                    translateY: modalAnimationValue.current.interpolate({
                      inputRange: [0, 1],
                      outputRange: [screenHeight, 0],
                    }),
                  },
                ],
              },
            ]}
          >
            <ModalHandler />
            {children}
            <View style={styles.spacer} />
          </Animated.View>
        </View>
      </Modal>
    );
  }
);

LimbicModal.defaultProps = {
  onClose: noop,
};

const styles = StyleSheet.create({
  modalContainer: {
    height: screenHeight,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  overlay: {
    flexGrow: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  spacer: {
    backgroundColor: Colors.white,
    height: 150,
    position: 'absolute',
    bottom: -150,
    width: '100%',
  },
  container: {
    backgroundColor: Colors.white,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    paddingBottom: navbarHeight,
    ...getShadowStyles(Colors.white, 0.3, 24),
  },
});
