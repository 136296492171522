import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { Platform } from 'react-native';
import { View, StyleSheet, ViewStyle, Animated, StyleProp, FlatList } from 'react-native';
import { ColumnProps } from './Shared';
import { TableRow } from './TableRow';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config/colors';
import { Line, LineProps } from '../Line/Line';

export interface TableProps {
  columns: ColumnProps[];
  data: React.ReactNode[][];
  tableStyle?: StyleProp<ViewStyle>;
  rowContainerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  listEmptyComponent?: React.ReactElement;
  listFooterComponent?: React.ReactElement;
  separatorLineProps?: LineProps;
  stickyHeader?: boolean;
  animateIn?: boolean;
  onPressRow?: (index: number) => void;
}

export const Table = ({
  columns,
  data,
  rowContainerStyle,
  tableStyle,
  separatorLineProps,
  contentContainerStyle = {},
  stickyHeader = true,
  animateIn = true,
  listEmptyComponent,
  listFooterComponent,
  onPressRow,
}: TableProps) => {
  const animatedValues = useRef<Animated.Value[]>(data.map(() => new Animated.Value(0)));
  const header = useMemo(
    () => (
      <>
        <TableRow
          columns={columns}
          content={columns.map((column) => (
            <LimbicText key={column.title} variant={TextVariants.XS} color={Colors.grey3}>
              {column.title.toUpperCase()}
            </LimbicText>
          ))}
        />
        <View style={styles.spacer} />
        <Line color={Colors.grey3} size="sm" />
      </>
    ),
    [columns]
  );

  const renderItem = useCallback(
    ({ item, index }) => {
      const animatedValue = animatedValues.current[index];
      const opacity = animateIn && animatedValue ? animatedValue : 1;
      const top =
        animateIn && animatedValue
          ? animatedValue.interpolate({ inputRange: [0, 1], outputRange: [50, 0] })
          : 0;
      return (
        <Animated.View key={index} style={{ opacity, top }} testID="table-row">
          <TableRow
            content={item}
            columns={columns}
            containerStyle={rowContainerStyle}
            onPress={onPressRow ? () => onPressRow(index) : undefined}
          />
          {!!separatorLineProps && <Line {...separatorLineProps} />}
        </Animated.View>
      );
    },
    [animateIn, columns, onPressRow, rowContainerStyle, separatorLineProps]
  );

  useEffect(() => {
    if (!animateIn) return;
    Animated.stagger(
      200,
      animatedValues.current.map((el) =>
        Animated.timing(el, {
          toValue: 1,
          duration: 600,
          useNativeDriver: false,
        })
      )
    ).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {stickyHeader && header}
      <FlatList
        data={data}
        showsVerticalScrollIndicator={Platform.select({ web: true, default: false })}
        keyExtractor={(_, idx) => `${idx}`}
        contentContainerStyle={contentContainerStyle}
        renderItem={renderItem}
        style={tableStyle}
        ListHeaderComponent={!stickyHeader ? header : undefined}
        ListEmptyComponent={listEmptyComponent}
        ListFooterComponent={listFooterComponent}
      />
    </>
  );
};

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 8,
  },
});
