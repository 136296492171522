import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { Button } from '../Button/Button';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { TextInput } from '../TextInput/TextInput';

interface EditNameProps {
  formProps: UseFormMethods<{ firstName: string; surname: string }>;
  loading: boolean;
  onSubmit: (values: { firstName: string; surname: string }) => void;
}

export const EditName = ({ formProps, loading, onSubmit }: EditNameProps) => {
  const { control, handleSubmit } = formProps;
  return (
    <View style={styles.container}>
      <View>
        <Controller
          control={control}
          name="firstName"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              testID="edit-name-first-name"
              style={styles.textInput}
              label="First Name"
              value={value}
              onChangeText={(value) => onChange(value)}
              onBlur={onBlur}
            />
          )}
        />
        {formProps.errors['firstName'] ? (
          <LimbicText center variant={TextVariants.S} color={Colors.pink5}>
            {formProps.errors['firstName']}
          </LimbicText>
        ) : null}
        <Controller
          control={control}
          name="surname"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              testID="edit-name-surname"
              style={styles.textInput}
              label="Surname"
              value={value}
              onChangeText={(value) => onChange(value)}
              onBlur={onBlur}
            />
          )}
        />
        {formProps.errors['surname'] ? (
          <LimbicText center variant={TextVariants.S} color={Colors.pink5}>
            {formProps.errors['surname']}
          </LimbicText>
        ) : null}
      </View>
      <View style={styles.button}>
        <Button
          testID="edit-name-submit-button"
          fixed
          variant="main"
          title="Save"
          onPress={handleSubmit(onSubmit)}
          loading={loading}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    marginVertical: 22,
  },
  textInput: {
    borderBottomWidth: 1,
    flex: 1,
    height: 44,
    borderColor: Colors.grey3,
  },
  button: {
    marginTop: 16,
    paddingBottom: 2,
  },
});
