type ExtrapolateParam = 'clamp' | 'clampLeft' | 'clampRight' | null;

interface Options {
  from: [number, number];
  to: [number, number];
  extrapolate?: ExtrapolateParam;
}

/**
 * Given a numeric value and two min/max sets, it returns the
 * value which corresponds to the value mapped by the interpolation
 * of the two sets. Passing extrapolate "clamp" will make sure the
 * output set is not exceeded on both sides, whereas "clampLeft" and
 * "clampRight" ensure the result stays within the corresponding bound
 * of the output range.
 * @param value {number} The value to map based on provided input/output
 * @param options {Options}
 * @returns {number}
 */
export function interpolateMinMax(value: number, options: Options): number {
  const { from = [0, 1], to = [0, 100], extrapolate = null } = options || {};
  const [fromMin, fromMax] = from;
  const [toMin, toMax] = to;
  if (fromMin === fromMax) {
    return value;
  }
  if (toMin === toMax) {
    return toMin;
  }
  const isReverse = toMin > toMax;
  const valueOffset = value - fromMin;
  const rangeOffset = (toMax - toMin) / (fromMax - fromMin);
  const result = valueOffset * rangeOffset + toMin;

  if (extrapolate === 'clampLeft') {
    if (isReverse ? result > toMin : result < toMin) {
      return toMin;
    }
  }
  if (extrapolate === 'clampRight') {
    if (isReverse ? result < toMax : result > toMax) {
      return toMax;
    }
  }
  if (extrapolate === 'clamp') {
    if (isReverse) {
      return Math.max(toMax, Math.min(result, toMin));
    }
    return Math.max(toMin, Math.min(result, toMax));
  }

  return result;
}
