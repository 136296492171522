import React, { useState, useContext, createContext, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Animated } from 'react-native';
import { ModalContainer } from '../components/Modal/ModalContainer';
import { ModalOverlay } from '../components/Modal/ModalOverlay';
import { responsiveValue } from '../consts/dimensions';
import { fixedPositionStyle, pointerEventsStyle, timeout } from '@limbic-for-therapists/shared';
import { useScreenDimensionsContext } from './ScreenDimensions';
import { FeatureFlagsFilterProvider } from './FeatureFlags';

export const MODAL_MAX_HEIGHT = '98%';
const MODAL_MAX_WIDTH = 700;

type CustomDimensions = {
  minWidth?: number | string;
  width?: number | string;
  height?: number | string;
};

interface ModalContextProps {
  children?: React.ReactNode;
  onContentChanged?: (hasContent: boolean) => void;
}

const ModalContext = createContext<{
  content?: React.ReactNode;
  setContent: (
    content?: React.ReactNode,
    customDimensions?: CustomDimensions,
    showCloseButton?: boolean,
    closeOnBackgroundClick?: boolean
  ) => void;
  dismissModal: () => void;
}>({
  content: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setContent: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dismissModal: () => {},
});

export const ModalContextProvider = ({ children, onContentChanged }: ModalContextProps) => {
  const { currentSize } = useScreenDimensionsContext();
  const backgroundAnimatedValue = useRef<Animated.Value>(new Animated.Value(0));
  const containerAnimatedValue = useRef<Animated.Value>(new Animated.Value(0));
  const [content, setContent] = useState<React.ReactNode | undefined>();
  const [showCloseButton, setShowCloseButton] = useState<boolean>(true);
  const [isBackgroundClickable, setIsBackgroundClickable] = useState<boolean>(false);
  const [customDimensions, setCustomDimensions] = useState<CustomDimensions | undefined>();

  const [internalShowCloseButton, setInternalShowCloseButton] = useState<boolean>(true);
  const [internalCustomDimensions, setInternalCustomDimensions] = useState<
    CustomDimensions | undefined
  >();
  const [internalContent, setInternalContent] = useState<React.ReactNode | undefined>();

  useEffect(() => {
    Animated.timing(backgroundAnimatedValue.current, {
      toValue: !!content ? 1 : 0,
      useNativeDriver: false,
    }).start();

    Animated.spring(containerAnimatedValue.current, {
      toValue: !!content ? 1 : 0,
      useNativeDriver: false,
    }).start();
  }, [content]);

  const handleSetContent = useCallback(
    (
      content?: React.ReactNode,
      customDimensions?: CustomDimensions | undefined,
      showCloseButton?: boolean,
      closeOnBackgroundClick?: boolean
    ) => {
      setContent(content);
      setCustomDimensions(customDimensions);
      setShowCloseButton(typeof showCloseButton === 'undefined' ? true : showCloseButton);
      if (closeOnBackgroundClick) setIsBackgroundClickable(true);
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (!content) await timeout(500);
      setInternalShowCloseButton(showCloseButton);
      setInternalCustomDimensions(customDimensions);
      setInternalContent(content);
    })();
    if (onContentChanged) onContentChanged(!!content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, onContentChanged]);

  const values = {
    content,
    setContent: handleSetContent,
    dismissModal: () => {
      handleSetContent(undefined);
      setIsBackgroundClickable(false);
    },
  };

  return (
    <ModalContext.Provider value={values}>
      <FeatureFlagsFilterProvider>
        {children}
        <Animated.View
          style={[
            {
              opacity: backgroundAnimatedValue.current.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
            },
            fixedPositionStyle,
            pointerEventsStyle(internalContent ? 'auto' : 'none'),
            styles.backgroundContainer,
          ]}
        >
          <ModalOverlay>
            <Animated.View
              style={[
                styles.container,
                {
                  transform: [
                    {
                      scale: containerAnimatedValue.current.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0.8, 1],
                      }),
                    },
                  ],
                  height: internalCustomDimensions?.height || 'auto',
                  maxWidth: internalCustomDimensions?.width || MODAL_MAX_WIDTH,
                  width: responsiveValue(
                    currentSize,
                    '100%',
                    internalCustomDimensions?.width || 'auto'
                  ),
                  minWidth: responsiveValue(
                    currentSize,
                    '100%',
                    internalCustomDimensions?.minWidth || 'auto'
                  ),
                },
              ]}
            >
              <ModalContainer
                showCloseButton={internalShowCloseButton}
                isBackgroundClickable={isBackgroundClickable}
              >
                {internalContent}
              </ModalContainer>
            </Animated.View>
          </ModalOverlay>
        </Animated.View>
      </FeatureFlagsFilterProvider>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

const styles = StyleSheet.create({
  backgroundContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
  },
  container: {
    maxHeight: MODAL_MAX_HEIGHT,
    display: 'flex',
    alignItems: 'center',
  },
});
