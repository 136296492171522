import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Colors, Icons } from '../../config';
import { getIcon } from '../../utils/getIcon';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const ICON_SIZE = {
  xs: 60,
  sm: 100,
  md: 150,
};

interface EmptyStateProps {
  iconName?: keyof typeof Icons;
  customIcon?: React.ReactNode;
  title: string;
  text: React.ReactNode;
  bottomAccessory?: React.ReactNode;
  size?: keyof typeof ICON_SIZE;
}

export const EmptyState = ({
  iconName = 'balloon',
  title,
  text,
  bottomAccessory,
  size = 'md',
  customIcon,
}: EmptyStateProps) => {
  const Icon = getIcon(iconName);
  const iconSize = ICON_SIZE[size];

  return (
    <View style={styles.placeholderContainer}>
      <View style={[styles.imageContainer, { width: iconSize, height: iconSize }]}>
        {customIcon || (
          <Image resizeMode="contain" source={Icon} style={{ width: '100%', height: '100%' }} />
        )}
      </View>
      <LimbicText bold variant={TextVariants.M}>
        {title}
      </LimbicText>
      <LimbicText center variant={TextVariants.M} color={Colors.darkGrey1}>
        {text}
      </LimbicText>
      {bottomAccessory}
    </View>
  );
};

const styles = StyleSheet.create({
  placeholderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  newPatientArrowIcon: {
    left: 14,
  },
});
