import { IInterventionFragment, IPsychoedFragment } from '@limbic-for-therapists/backend';
import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Colors, getShadowStyles } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface InterventionCardArgs {
  intervention: IInterventionFragment;
  selected: boolean;
  onPress?: () => void;
}

interface PsychoedCardArgs {
  psychoed: IPsychoedFragment;
  selected: boolean;
  onPress?: () => void;
  setSearchTerm: (searchTerm: string) => void;
}

export const InterventionCard = ({ intervention, selected, onPress }: InterventionCardArgs) => (
  <Pressable
    disabled={!onPress}
    onPress={onPress}
    style={({ pressed }) => {
      if (pressed) return [styles.card, styles.cardPressed];
      if (selected) return [styles.card, styles.cardSelected];
      return [styles.card];
    }}
  >
    <View style={styles.mainInfo}>
      <View style={styles.nameAndTagContainer}>
        <LimbicText variant={TextVariants.Base} color={selected ? Colors.white : undefined}>
          {intervention.name}
        </LimbicText>
        <View style={[styles.tag, selected ? styles.tagGreen : styles.tagGreenTransparent]}>
          <LimbicText variant={TextVariants.S} color={undefined}>
            Activity
          </LimbicText>
        </View>
      </View>
      <LimbicText variant={TextVariants.S} color={selected ? Colors.white : undefined}>
        {intervention.description}
      </LimbicText>
    </View>
    <View style={styles.secondaryInfo}>
      <LimbicText variant={TextVariants.S} color={selected ? Colors.white : undefined}>
        {intervention.steps.length} {intervention.steps.length === 1 ? 'message' : 'messages'}
      </LimbicText>
    </View>
  </Pressable>
);

export const PsychoedCard = ({ psychoed, selected, onPress }: PsychoedCardArgs) => (
  <Pressable
    disabled={!onPress || selected}
    onPress={onPress}
    style={({ pressed }) => {
      if (pressed && !selected) return [styles.card, styles.cardPressed];
      if (selected) return [styles.card, styles.cardSelected];
      return [styles.card];
    }}
  >
    <View style={styles.mainInfo}>
      <View style={styles.nameAndTagContainer}>
        <LimbicText variant={TextVariants.Base} color={selected ? Colors.white : undefined}>
          {psychoed.name}
        </LimbicText>

        <View style={[styles.tag, selected ? styles.tagPink : styles.tagPinkTransparent]}>
          <LimbicText variant={TextVariants.S} color={undefined}>
            Education
          </LimbicText>
        </View>
      </View>
      <LimbicText variant={TextVariants.S} color={selected ? Colors.white : undefined}>
        {psychoed.description}
      </LimbicText>
    </View>
    <View style={styles.secondaryInfo}>
      <LimbicText variant={TextVariants.S} color={selected ? Colors.white : undefined}>
        {psychoed.slides.length} {psychoed.slides.length === 1 ? 'slide' : 'slides'}
      </LimbicText>
    </View>
  </Pressable>
);

const styles = StyleSheet.create({
  nameAndTagContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tag: {
    height: 18,
    backgroundColor: `${Colors.pink1}80`,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    padding: 6,
    marginLeft: 8,
  },

  tagPink: {
    backgroundColor: `${Colors.pink1}`,
  },
  tagPinkTransparent: {
    backgroundColor: `${Colors.pink1}80`,
  },
  tagGreen: {
    backgroundColor: `${Colors.green1}`,
  },
  tagGreenTransparent: {
    backgroundColor: `${Colors.green1}80`,
  },
  card: {
    flexDirection: 'row',
    backgroundColor: Colors.white,
    padding: 16,
    borderRadius: 8,
    justifyContent: 'space-between',
    ...getShadowStyles(Colors.black, 0.2, 1.41, 0, 1),
  },
  cardPressed: {
    backgroundColor: Colors.grey1,
  },
  cardSelected: {
    backgroundColor: Colors.darkGrey2,
  },
  mainInfo: {
    flexDirection: 'column',
  },
  secondaryInfo: {
    flexDirection: 'column',
  },
});
