import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';

const ANIMATIONS = {
  happy: '/animations/happy.json',
  idle: '/animations/idle.json',
  inquisitive: '/animations/inquisitive.json',
  sad: '/animations/sad.json',
  questioning: '/animations/questioning.json',
  thinking: '/animations/thinking.json',
  typing: '/animations/typing.json',
};

type AnimatedLimbicBalloonType = keyof typeof ANIMATIONS;

interface AnimatedLimbicBalloonProps {
  animationId: AnimatedLimbicBalloonType;
  size?: number;
  flip?: boolean;
  onPress?: (() => void) | null;
}

const AnimatedLimbicBalloon = ({
  animationId,
  size = 100,
  flip = false,
  onPress,
}: AnimatedLimbicBalloonProps) => {
  const [activeAnimationId, setActiveAnimationId] =
    useState<AnimatedLimbicBalloonType>(animationId);

  const cycleAnimations = useCallback(() => {
    const animationKeys = Object.keys(ANIMATIONS);
    const idx = animationKeys.indexOf(activeAnimationId);

    let newIdx = idx + 1;
    if (newIdx > animationKeys.length - 1) newIdx = 0;

    setActiveAnimationId(animationKeys[newIdx] as AnimatedLimbicBalloonType);
  }, [activeAnimationId]);

  useEffect(() => {
    setActiveAnimationId(animationId);
  }, [animationId]);

  return (
    <TouchableOpacity
      onPress={typeof onPress === 'undefined' ? cycleAnimations : onPress || undefined}
      activeOpacity={1}
    >
      {Object.keys(ANIMATIONS).map((key) => (
        <View
          key={key}
          style={{
            width: size,
            height: size,
            display: activeAnimationId === key ? 'flex' : 'none',
            transform: [flip ? { scaleX: -1 } : { scaleX: 1 }],
          }}
        >
          <LottieAnimation
            path={ANIMATIONS[key as AnimatedLimbicBalloonType]}
            playing={activeAnimationId === key}
          />
        </View>
      ))}
    </TouchableOpacity>
  );
};

export { AnimatedLimbicBalloon };
