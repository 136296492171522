import { Colors } from '@limbic-for-therapists/components';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { useModalContext } from '../../context/Modal';
import { ModalNavigationBar } from '../ModalNavigationBar/ModalNavigationBar';

const ICON_SIZE = 24;

interface ModalContainerProps {
  children: React.ReactNode;
  showCloseButton: boolean;
  isBackgroundClickable?: boolean;
}

export const ModalContainer = ({
  children,
  showCloseButton,
  isBackgroundClickable = false,
}: ModalContainerProps) => {
  const { dismissModal } = useModalContext();
  const modalContentEl = useRef<any>(null);

  // Handle close modal when clicking outside of it's content
  useEffect(() => {
    if (!isBackgroundClickable) return;
    const listener = (event: any) => {
      event.stopPropagation();
      if (!modalContentEl.current || modalContentEl.current.contains(event.target)) {
        return;
      }
      dismissModal();
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [modalContentEl, dismissModal, isBackgroundClickable]);

  return (
    <View style={styles.outerContainer} ref={modalContentEl}>
      {showCloseButton ? (
        <ModalNavigationBar
          rightButton={<ModalNavigationBar.CloseButton onPress={dismissModal} />}
        />
      ) : null}
      <View style={{ flexGrow: 1, flexShrink: 1 }}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flexDirection: 'column',
    borderRadius: 16,
    backgroundColor: Colors.white,
    overflow: 'hidden',
    flexGrow: 1,
    width: '90%',
    flexShrink: 1,
    minWidth: 400,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  button: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  closeButton: {
    flexGrow: 1,
    alignItems: 'flex-end',
  },
  iconContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
