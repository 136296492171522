import { useCallback } from 'react';
import * as yup from 'yup';
import { OptionalString, RequiredString } from '@limbic-for-therapists/shared';
import dayjs, { Dayjs } from 'dayjs';
import {
  ICreatePatientBehaviouralActionVariables,
  useCreatePatientBehaviouralAction,
} from '../mutations/createPatientBehavioralAction';
import { getRecurrenceInput } from '../utils/recurrence';
import { RecurrenceFrequency, Weekday } from '../models/IRecurrenceInput';
import { BehaviouralActionTypes } from '../models/IBehaviouralAction';

export interface ScheduleActivityFlowValues {
  signUpCode: string;
  date?: Dayjs;
  time?: Dayjs;
  behaviouralActionType?: BehaviouralActionTypes;
  message?: string;
  durationHours?: number;
  repeatedDays?: Weekday[];
  letUserPickDate?: boolean;
}

export const useScheduleActivity = (signUpCode: string) => {
  const validationSchema = yup.object().shape({
    signUpCode: RequiredString('Please supply a valid sign up code.'),
    type: RequiredString('Please supply a valid behavioural action type.'),
    activity: RequiredString('Please supply a valid activity.'),
    startDate: yup.date().required('Please supply a valid start date.'),
    endDate: yup.date().required('Please supply a valid end date.'),
    recurrence: yup
      .object()
      .shape({
        dtstart: OptionalString(),
        rrule: RequiredString('Please specify a valid recurrence rule.'),
      })
      .optional(),
  });

  const [createPatientBehavioralAction, { loading }] = useCreatePatientBehaviouralAction();

  const getPatientBehavioralActionPayload = useCallback(
    (values: ScheduleActivityFlowValues): ICreatePatientBehaviouralActionVariables | undefined => {
      if (!values.behaviouralActionType) return undefined;

      const baseVariables = {
        signUpCode,
        type: values.behaviouralActionType,
        activity: values.message || '',
      };

      if (values.letUserPickDate) return baseVariables;
      if (!values.time) return undefined;

      let startDate = dayjs(values.date);
      startDate = startDate.set('hour', values.time.hour());
      startDate = startDate.set('minute', values.time.minute());
      startDate = startDate.set('seconds', 0);

      const endDate = dayjs(startDate).add(values.durationHours || 1, 'hours');

      const recurrence = values.repeatedDays?.length
        ? getRecurrenceInput({
            startDate: startDate.toDate(),
            freq: RecurrenceFrequency.Week,
            weekdays: values.repeatedDays,
          })
        : undefined;

      return {
        ...baseVariables,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        recurrence,
      };
    },
    [signUpCode]
  );

  return {
    createPatientBehavioralAction,
    getPatientBehavioralActionPayload,
    loading,
    validationSchema,
  };
};
