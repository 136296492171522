import React, { useRef, useEffect } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { Colors, PositivityColors } from '../../config/colors';

interface NotchIndicatorProps {
  value: number;
  maxValue?: number;
  color: Colors | PositivityColors;
  notchCount: number;
  animationDelay?: number;
}

export const NotchIndicator = ({
  value,
  maxValue,
  notchCount,
  color,
  animationDelay,
}: NotchIndicatorProps) => {
  const notches = new Array(notchCount).fill(null);
  const animatedValues = useRef<Animated.Value[]>(notches.map(() => new Animated.Value(0)));
  const activeIndex = maxValue
    ? Math.max(1, Math.min(Math.floor((value / maxValue) * notchCount), notchCount))
    : 0;

  useEffect(() => {
    Animated.sequence([
      Animated.delay(animationDelay || 0),
      Animated.stagger(
        200,
        animatedValues.current.map((el) =>
          Animated.timing(el, {
            toValue: 1,
            duration: 600,
            useNativeDriver: false,
          })
        )
      ),
    ]).start();
  }, [animationDelay]);

  return (
    <View style={styles.notchContainer}>
      {notches.map((el, idx) => {
        const animatedValue = animatedValues.current[idx];
        const opacity = animatedValue ?? 1;
        const top = animatedValue?.interpolate({ inputRange: [0, 1], outputRange: [10, 0] });
        const isActive = notchCount - activeIndex === idx;
        const backgroundColor = color;
        return (
          <Animated.View
            key={`${idx}`}
            style={[
              styles.notch, //
              isActive && styles.notchActive,
              isActive && { backgroundColor },
              { opacity, top },
            ]}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  notchContainer: {
    alignItems: 'center',
  },
  notch: {
    height: 4,
    width: 14,
    marginTop: 2,
    borderRadius: 2,
    backgroundColor: Colors.grey3,
  },
  notchActive: {
    width: 20,
  },
});
