import { useCreateInterventionMutation } from '@limbic-for-therapists/backend';
import { ModalContent } from '@limbic-for-therapists/components';
import React, { useCallback, useState } from 'react';
import { getTitanApolloClient } from '../../../backend/helpers/getApolloClient';
import { useFlowContext } from '../../../context/Flow';
import { useToastContext } from '../../../context/Toast';
import { AddCustomInterventionFlowValues } from '../AddCustomInterventionFlow';

export default () => {
  const { values, setValues, moveToNextStep } = useFlowContext<AddCustomInterventionFlowValues>();
  const { setErrorToast } = useToastContext();
  const [name, setName] = useState('');
  const [createIntervention] = useCreateInterventionMutation(name, getTitanApolloClient());

  const onNext = useCallback(async () => {
    if (!name) {
      setErrorToast('Activity name must not be empty');
      return;
    }
    try {
      const res = await createIntervention();
      const id = res.data?.createIntervention.id;
      if (!id) {
        setErrorToast('An error occured creating a new activity');
        return;
      }
      setValues({ ...values, name, id });
      moveToNextStep();
    } catch (err) {
      console.log(err.extensions);
      setErrorToast(err.message);
    }
  }, [setErrorToast, createIntervention, name, moveToNextStep, setValues, values]);

  return (
    <ModalContent scrollable>
      <ModalContent.Title>Let&apos;s name it</ModalContent.Title>
      <ModalContent.TextInput
        style={{ marginBottom: 16 }}
        placeholder="Name this intervention"
        value={name}
        onChangeText={(text) => setName(text)}
      ></ModalContent.TextInput>
      <ModalContent.CTAButton
        disabled={!name}
        title="Next"
        onPress={onNext}
      ></ModalContent.CTAButton>
    </ModalContent>
  );
};
