import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface SnackBarProps {
  content?: string;
  onPress?: () => void;
}

export const SnackBar = ({ content, onPress }: SnackBarProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <LimbicText variant={TextVariants.Base} color={Colors.white} style={styles.content}>
          {content}
        </LimbicText>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={onPress}>
          <Icon name="close" size={13} color={Colors.white} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { alignItems: 'center', justifyContent: 'center' },
  contentContainer: {
    backgroundColor: Colors.black,
    justifyContent: 'center',
  },
  content: { fontWeight: '500', fontSize: 14, lineHeight: 20 },
  buttonContainer: {
    position: 'absolute',
    right: 0,
  },
  button: { backgroundColor: Colors.darkGrey2, borderRadius: 100 },
});
