import React, { useMemo } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { noop } from '../../utils';
import { AnimatedHoverable } from '../AnimatedHoverable/AnimatedHoverable';
import { PatientCard, PatientCardVariant, PatientCardProps } from '../Card/PatientCard';
import InviteCard from '../InviteCard/InviteCard';

const CARD_WIDTH = 340;
const DEFAULT_SCALE = 1.1;

interface DashboardCardProps extends Partial<PatientCardProps> {
  width?: string | null;
  scaleTo?: number | null;
  animatedValue?: Animated.Value;
  isPending: boolean;
  title?: string;
  onPress?: () => void;
  showTag?: boolean;
  canRemindSignup?: boolean;
  handleRemindSignup?: () => void;
  showSettingsButton?: boolean;
  onSettingsPress?: () => void;
}

export const DashboardCard = ({
  width = `${CARD_WIDTH}px`,
  scaleTo = DEFAULT_SCALE,
  animatedValue,
  isPending,
  title,
  onPress,
  tagTitle,
  showTag = true,
  canRemindSignup = false,
  handleRemindSignup = noop,
  showSettingsButton = false,
  onSettingsPress = noop,
  ...rest
}: DashboardCardProps) => {
  const content = useMemo(
    () =>
      isPending ? (
        <InviteCard
          testID="pending-patient-card"
          canRemindSignup={canRemindSignup}
          handleRemindSignup={handleRemindSignup}
          showSettingsButton={showSettingsButton}
          onSettingsPress={onSettingsPress}
        >
          {title || ''}
        </InviteCard>
      ) : (
        <PatientCard
          testID="patient-card"
          variant={PatientCardVariant.TASK}
          tagTitle={showTag ? tagTitle || '' : undefined}
          onPress={onPress}
          textColor={!title ? Colors.grey3 : undefined}
          showSettingsButton={showSettingsButton}
          onSettingsPress={onSettingsPress}
          {...rest}
        >
          {title || 'No User Name'}
        </PatientCard>
      ),
    [
      isPending,
      canRemindSignup,
      handleRemindSignup,
      showSettingsButton,
      onSettingsPress,
      title,
      showTag,
      tagTitle,
      onPress,
      rest,
    ]
  );

  return (
    <AnimatedHoverable
      style={[styles.patientCardContainer, { width: width || CARD_WIDTH }]}
      scaleTo={scaleTo || DEFAULT_SCALE}
    >
      {animatedValue ? (
        <Animated.View
          style={[
            {
              opacity: animatedValue.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
              transform: [
                {
                  translateY: animatedValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [50, 0],
                  }),
                },
              ],
            },
          ]}
        >
          {content}
        </Animated.View>
      ) : (
        content
      )}
    </AnimatedHoverable>
  );
};

const styles = StyleSheet.create({
  patientCardContainer: {
    borderRadius: 16,
    marginRight: 15,
    marginTop: 20,
  },
});
