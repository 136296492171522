import React, { useEffect, useMemo, useRef } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';
import { Colors } from '../../config/colors';

type ValenceRange = {
  title: string;
  range: [number, number];
  description: string;
  color: string;
};

interface Props {
  value: number;
  valence: ValenceRange;
  maxHeight: number;
  width: number;
  animationDelay?: number;
  shouldAnimate?: boolean;
  isHighlighted?: boolean;
}

export const MoodLogGraphBar = (props: Props) => {
  const {
    value,
    valence,
    maxHeight,
    width,
    animationDelay,
    shouldAnimate,
    isHighlighted = true,
  } = props;
  const animatedValue = useRef<Animated.Value>(new Animated.Value(0)).current;

  const style = useMemo(() => {
    const itemHeight = Math.max(Math.abs(value * maxHeight), 2);
    const differenceFromMax = maxHeight - itemHeight;
    const offset = itemHeight + differenceFromMax;

    const height = shouldAnimate
      ? animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [0, itemHeight],
        })
      : itemHeight;

    return {
      height,
      width,
      alignSelf: value >= 0 ? 'flex-end' : 'flex-start',
      marginTop: value < 0 ? offset : 0,
      marginBottom: value >= 0 ? offset : 0,
      backgroundColor: valence?.color || Colors.grey3,
      borderTopLeftRadius: value > 0 ? width / 2 : 0,
      borderBottomLeftRadius: value < 0 ? width / 2 : 0,
      opacity: isHighlighted ? 1.0 : 0.3,
    } as StyleProp<ViewStyle>;
  }, [animatedValue, isHighlighted, maxHeight, shouldAnimate, valence, value, width]);

  useEffect(() => {
    if (shouldAnimate) {
      animatedValue.setValue(0);
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 400,
        delay: animationDelay,
        useNativeDriver: false,
      }).start();
    }
  }, [animatedValue, animationDelay, shouldAnimate]);

  return <Animated.View style={style} testID="mood-log-graph-bar" />;
};
