// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQueryParams = (query: string): Record<string, any> => {
  try {
    const search = query.substring(1);
    return JSON.parse(
      '{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}'
    );
  } catch {
    return {};
  }
};
