import { CORE10ScoreRanges } from '../models/questionnaires/CORE10';
import { GAD7ScoreRanges } from '../models/questionnaires/GAD7';
import { IESRScoreRanges } from '../models/questionnaires/IESR';
import { OCIRScoreRanges } from '../models/questionnaires/OCIR';
import { PAMScoreRanges } from '../models/questionnaires/PAM';
import { PCL5ScoreRanges } from '../models/questionnaires/PCL5';
import { PHQ9ScoreRanges } from '../models/questionnaires/PHQ9';
import { PSS10ScoreRanges } from '../models/questionnaires/PSS10';
import { SPINScoreRanges } from '../models/questionnaires/SPIN';
import { YPCORE10ScoreRanges } from '../models/questionnaires/YPCORE10';
import { MoodEventType } from '../models/MoodEventType';
import { getValueRange, IValueRange } from '../models/IValueRange';
import { PHOBIASScoreRanges } from '../models/questionnaires/PHOBIA';
import { WSASScoreRanges } from '../models/questionnaires/WSAS';

const ranges = {
  [MoodEventType.CORE10]: CORE10ScoreRanges,
  [MoodEventType.GAD7]: GAD7ScoreRanges,
  [MoodEventType.IESR]: IESRScoreRanges,
  [MoodEventType.OCIR]: OCIRScoreRanges,
  [MoodEventType.PAM]: PAMScoreRanges,
  [MoodEventType.PCL5]: PCL5ScoreRanges,
  [MoodEventType.PHQ9]: PHQ9ScoreRanges,
  [MoodEventType.PSS10]: PSS10ScoreRanges,
  [MoodEventType.SPIN]: SPINScoreRanges,
  [MoodEventType.YPCORE10]: YPCORE10ScoreRanges,
  [MoodEventType.PHOBIA]: PHOBIASScoreRanges,
  [MoodEventType.WSAS]: WSASScoreRanges,
};

export function getQuestionnaireScoreRange(type: MoodEventType, value: number): IValueRange {
  return getValueRange(value, ranges[type as keyof typeof ranges]);
}
