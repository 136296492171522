import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SelectBehaviouralActionTypeProps {
  behaviouralActionTypes: React.ReactNode[];
}

export const SelectBehaviouralActionType = ({
  behaviouralActionTypes,
}: SelectBehaviouralActionTypeProps) => {
  return (
    <ModalContent>
      <ModalContent.Title>Behavioural Actions</ModalContent.Title>
      <ModalContent.Body>
        Schedule activities for your clients. Limbic will remind them to start the activity, and
        then it will report the effects.
      </ModalContent.Body>
      <ModalContent.Body style={styles.bodyTitle}>What type of activity?</ModalContent.Body>
      <View style={styles.activityContainer}>
        {behaviouralActionTypes.map((el, idx) => (
          <View key={idx} style={styles.itemColumn}>
            {el}
          </View>
        ))}
      </View>
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  bodyTitle: {
    textAlign: 'center',
    color: Colors.darkGrey2,
    paddingTop: 24,
  },
  activityContainer: {
    flexDirection: 'row',
    paddingVertical: 16,
  },
  itemColumn: {
    flex: 1,
    paddingHorizontal: 4,
  },
});
