import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { Colors, Icons } from '../../config';
import { getIcon } from '../../utils/getIcon';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

type NiceToKnowCardProps = {
  category: string;
  title: string;
  icon: keyof typeof Icons;
};

const NiceToKnowCard = ({ category, title, icon }: NiceToKnowCardProps) => {
  const Icon = getIcon(icon);
  return (
    <View style={[styles.cardContainer, category === 'TIP' && styles.tipCardContainer]}>
      <View>
        <LimbicText variant={TextVariants.XS} style={styles.cardText}>
          {category}
        </LimbicText>
        <LimbicText bold variant={TextVariants.M} style={[styles.cardText, styles.titleText]}>
          {title}
        </LimbicText>
      </View>
      <View style={styles.cardImageContainer}>
        <Image resizeMode="cover" source={Icon} style={styles.cardImage} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 188,
    minHeight: 240,
    backgroundColor: Colors.pink2,
    borderRadius: 10,
    padding: 16,
    marginRight: 8,
    justifyContent: 'space-between',
  },
  tipCardContainer: {
    backgroundColor: Colors.purple,
  },
  cardText: {
    color: Colors.white,
  },
  titleText: {
    marginTop: 5,
  },
  cardImageContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  cardImage: {
    marginTop: 'auto',
    alignSelf: 'center',
    width: 90,
    height: 90,
  },
});

export { NiceToKnowCard };
