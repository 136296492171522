import React from 'react';
import { Colors } from '@limbic-for-therapists/components';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { WithTestID } from '../../shared/withTestID';

export const Link = ({ testID, ...props }: WithTestID<LinkProps>) => {
  return (
    <RouterLink
      {...props}
      style={{ textDecorationLine: 'none', flexGrow: 0, ...(props.style || {}) }}
      data-testid={testID}
    />
  );
};

interface OutboundLinkProps {
  href: string;
  target?: string;
  children: string;
}

export const OutboundLink = ({ href, target, children, testID }: WithTestID<OutboundLinkProps>) => {
  return (
    <a style={{ color: Colors.pink4 }} href={href} target={target} data-testid={testID}>
      {children}
    </a>
  );
};
