import NetworkError from '../models/NetworkError';
import { IRecurrenceInput } from '../models/IRecurrenceInput';
import { IWeeklyTriggerEvery } from '../models/QuestionnaireTriggers';
import { Stage } from '../consts/stage';
import { PATIENTS_REGISTER_ENDPOINT } from '@limbic-for-therapists/shared';
import { UnauthorizedError } from '../consts/errors';

const method = 'POST';
const headers = new Headers();
headers.set('Content-Type', 'application/json');

export interface RegisterPatientResponse {
  signupCode: string;
}

interface IRegisterPayload {
  nextSessionDate: Date | undefined;
  weeklyQuestionnaires: string[];
  recurrenceAppointment?: IRecurrenceInput;
  weekliesTriggeredEvery: IWeeklyTriggerEvery[];
  iesTrauma?: string;
  name?: string;
  email?: string;
  analyticsData?: Record<string, unknown>;
  demoUser?: boolean;
}

export const registerPatient = async (
  stage: Stage,
  token: string,
  payload: IRegisterPayload
): Promise<RegisterPatientResponse> => {
  const REGISTER_URL = PATIENTS_REGISTER_ENDPOINT[stage];
  if (!token) throw new UnauthorizedError();
  headers.set('Authorization', `Bearer ${token}`);
  const body = JSON.stringify(payload);
  const result = await fetch(REGISTER_URL, { body, method, headers });
  const { data } = await result.json();
  if (data?.error) {
    throw new NetworkError(data?.code, data?.error);
  }
  if (data?.message) {
    throw new NetworkError(data?.code, data?.message);
  }
  return data;
};
