import React, { useCallback, useState } from 'react';
import {
  Button,
  Colors,
  LimbicText,
  Logo,
  TextInput,
  TextVariants,
} from '@limbic-for-therapists/components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useScreenDimensionsContext } from '../../context/ScreenDimensions';
import { useToastContext } from '../../context/Toast';
import { RequiredString, validate } from '@limbic-for-therapists/shared';
import { ICredentials, login } from '@limbic-for-therapists/backend';
import { setAuthToken, setPermissions } from '../../backend/helpers/authToken';
import { useActiveClinicianContext } from '../../context/ActiveClinician';
import { STAGE } from '../../consts/stage';

const LOGO_WIDTH = 125;

const validationSchema = yup.object().shape({
  email: RequiredString('Please enter a valid email address.'),
  password: RequiredString('Please enter a valid password.'),
});

export const LogInForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchActiveClinician } = useActiveClinicianContext();
  const { setErrorToast, setToast } = useToastContext();
  const history = useHistory();
  const { currentSize } = useScreenDimensionsContext();
  const { control, getValues } = useForm<ICredentials>();

  const navigateToSignUpPage = useCallback(() => {
    history.push('/auth/register');
  }, [history]);

  const navigateToForgotPasswordPage = useCallback(() => {
    history.push('/auth/forgot');
  }, [history]);

  const handleLogIn = useCallback(async () => {
    try {
      setLoading(true);
      const values = getValues();
      await validate(validationSchema, values);
      const { accessToken, permissions } = await login(STAGE, values);
      setLoading(false);
      setPermissions(permissions || []);
      setAuthToken(accessToken);
      setToast();
      await fetchActiveClinician();
    } catch (e) {
      setLoading(false);
      setErrorToast(e.message);
    }
  }, [fetchActiveClinician, getValues, setErrorToast, setToast]);

  return (
    <View style={styles.form}>
      {currentSize === 'xs' ? (
        <View style={styles.row}>
          <Logo color={Colors.black} width={LOGO_WIDTH} />
        </View>
      ) : null}
      <form>
        <View style={styles.row}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <TextInput
                testID="login-email-field"
                label="E-mail Address"
                keyboardType="email-address"
                onBlur={onBlur}
                value={value}
                onChangeText={(value) => onChange(value)}
                textContentType="emailAddress"
                autoComplete="email"
              />
            )}
          />
        </View>
        <View style={styles.row}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <TextInput
                testID="login-password-field"
                label="Password"
                secureTextEntry
                value={value}
                onBlur={onBlur}
                onChangeText={(value) => onChange(value)}
                autoComplete="password"
                textContentType="password"
              />
            )}
          />
        </View>
      </form>

      <View style={styles.row}>
        <Button
          testID="login-button"
          fixed
          onPress={handleLogIn}
          variant="main"
          title="Log In"
          loading={loading}
        />
      </View>
      <TouchableOpacity
        onPress={navigateToForgotPasswordPage}
        testID="login-forgot-password-button"
      >
        <View style={styles.row}>
          <LimbicText variant={TextVariants.S} color={Colors.darkGrey1} bold>
            Forgot your password?
          </LimbicText>
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={navigateToSignUpPage} testID="login-signup-button">
        <View style={styles.row}>
          <LimbicText variant={TextVariants.M} color={Colors.purpleDark}>
            Click here to sign up
          </LimbicText>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  form: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    padding: 44,
    width: 500,
  },
  row: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
});
