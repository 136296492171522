import React from 'react';
import { View, StyleSheet } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config/colors';

export interface NumberCircleProps {
  index: number;
  color: Colors;
  textColor: Colors;
  outline: boolean;
}

export const NumberCircle = ({ index, color, textColor, outline }: NumberCircleProps) => {
  const backgroundColor = !outline ? color : undefined;
  const borderColor = outline ? color : undefined;
  const borderWidth = outline ? 1 : undefined;
  return (
    <View
      style={[
        styles.container,
        backgroundColor && { backgroundColor },
        borderColor && { borderColor, borderWidth },
      ]}
    >
      <LimbicText variant={TextVariants.M} color={textColor} center>
        {`${index}`}
      </LimbicText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
  },
});
