import React from 'react';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { SpeechBubble } from '../SpeechBubble/SpeechBubble';

interface AdditionalInsightLogProps {
  message: string;
}

export const AdditionalInsightLog = ({ message }: AdditionalInsightLogProps) => {
  return (
    <>
      <SpeechBubble>
        <LimbicText variant={TextVariants.M} color={Colors.black}>
          {message}
        </LimbicText>
      </SpeechBubble>
    </>
  );
};
