import React from 'react';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface ReportHeaderProps {
  title: string;
  subtitle: string;
}

export const ReportHeader = ({ title, subtitle }: ReportHeaderProps) => {
  return (
    <>
      <LimbicText variant={TextVariants.M} bold color={Colors.black}>
        {title}
      </LimbicText>
      <LimbicText variant={TextVariants.Base} color={Colors.grey3}>
        {subtitle}
      </LimbicText>
    </>
  );
};
