import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  MedicalDeviceEifuIcon,
  MedicalDeviceLotIcon,
  MedicalDeviceManufactureDateIcon,
  MedicalDeviceManufacturerIcon,
  MedicalDeviceRefIcon,
  MedicalDeviceUdiIcon,
  MedicalDeviceUkcaIcon,
} from '../Icons/MedicalDevice';
import { Colors, LimbicText, TextVariants } from '@limbic-for-therapists/components';
import packageJson from '../../../package.json';

export const MedicalDeviceInformation = () => {
  const majorVersion = useMemo(() => packageJson.version.split('.')[0], []);

  return (
    <View style={styles.medicalDeviceRow}>
      <View>
        <View style={styles.medicalDeviceIconRow}>
          <MedicalDeviceManufacturerIcon width={50} />
          <View style={styles.medicalDeviceTextContainer}>
            <LimbicText
              variant={TextVariants.S}
              color={Colors.black}
              style={styles.medicalDeviceText}
            >
              Limbic Limited
            </LimbicText>
            <LimbicText
              variant={TextVariants.S}
              color={Colors.black}
              style={styles.medicalDeviceText}
            >
              Kemp House, 160 City Road
            </LimbicText>
            <LimbicText
              variant={TextVariants.S}
              color={Colors.black}
              style={styles.medicalDeviceText}
            >
              LONDON
            </LimbicText>
            <LimbicText
              variant={TextVariants.S}
              color={Colors.black}
              style={styles.medicalDeviceText}
            >
              EC1V 2NX
            </LimbicText>
          </View>
        </View>
        <View style={styles.medicalDeviceIconRow}>
          <MedicalDeviceManufactureDateIcon width={50} />
          <View style={styles.medicalDeviceTextContainer}>
            <LimbicText
              variant={TextVariants.S}
              color={Colors.black}
              style={styles.medicalDeviceText}
            >
              2023-08
            </LimbicText>
          </View>
        </View>
        <View style={styles.medicalDeviceIconRow}>
          <MedicalDeviceEifuIcon width={50} />
        </View>
      </View>
      <View>
        <View>
          <View style={styles.medicalDeviceIconRow}>
            <MedicalDeviceRefIcon width={50} />
            <View style={styles.medicalDeviceTextContainer}>
              <LimbicText
                variant={TextVariants.S}
                color={Colors.black}
                style={styles.medicalDeviceText}
              >
                Limbic Care
              </LimbicText>
            </View>
          </View>
          <View style={styles.medicalDeviceIconRow}>
            <MedicalDeviceUdiIcon width={50} />
            <View style={styles.medicalDeviceTextContainer}>
              <LimbicText
                variant={TextVariants.S}
                color={Colors.black}
                style={styles.medicalDeviceText}
              >
                (01)5065011383028(8012){packageJson.version}
              </LimbicText>
            </View>
          </View>
        </View>
      </View>
      <View>
        <View>
          <View style={styles.medicalDeviceIconRow}>
            <MedicalDeviceLotIcon width={50} />
            <View style={styles.medicalDeviceTextContainer}>
              <LimbicText
                variant={TextVariants.S}
                color={Colors.black}
                style={styles.medicalDeviceText}
              >
                V
                <LimbicText
                  variant={TextVariants.S}
                  color={Colors.black}
                  style={[styles.medicalDeviceText, styles.medicalDeviceTextBold]}
                >
                  {majorVersion}
                </LimbicText>
              </LimbicText>
            </View>
          </View>
          <View style={styles.medicalDeviceIconRow}>
            <MedicalDeviceUkcaIcon width={50} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  medicalDeviceRow: {
    paddingHorizontal: 10,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  medicalDeviceIconRow: {
    marginVertical: 10,
    flexGrow: 0,
    alignItems: 'center',
    flexDirection: 'row',
  },
  medicalDeviceTextContainer: {
    marginLeft: 10,
  },
  medicalDeviceText: {
    fontWeight: '500',
    lineHeight: 14,
  },
  medicalDeviceTextBold: {
    fontWeight: '700',
  },
});
