import React, { useCallback } from 'react';
import { useFlowContext } from '../../../context/Flow';
import { useToastContext } from '../../../context/Toast';
import { PatientDetails } from '@limbic-for-therapists/components/';
import { useForm } from 'react-hook-form';
import { useUpdatePatient } from '@limbic-for-therapists/backend/src/hooks/use-update-patient';
import type { IEditPatientDetailsFlowProps } from '../EditPatientDetailsFlow';
import type { IPatientDetailsFormValues, ToastDetails } from '@limbic-for-therapists/components';
import { IUpdatePatientVariables } from '@limbic-for-therapists/backend';

const UPDATE_SUCCESSFUL: ToastDetails = {
  title: 'Patient details updated successfully',
  variant: 'green',
};

export default function EditPatientDetailsScreen() {
  const { values, finishFlow } = useFlowContext<IEditPatientDetailsFlowProps>();
  const { setToast, setErrorToast } = useToastContext();
  const { updatePatient } = useUpdatePatient();
  const defaultValues = React.useMemo(
    () => ({
      name: values.name ?? '',
      email: values.email ?? '',
      phoneMobile: values.analyticsData?.phoneMobile ?? '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const formProps = useForm<IPatientDetailsFormValues>({ defaultValues });

  const onSubmit = useCallback(
    async (data: IPatientDetailsFormValues) => {
      try {
        const variables = {} as IUpdatePatientVariables;
        if (data.name !== defaultValues.name) variables.name = data.name;
        if (data.email !== defaultValues.email) variables.email = data.email;
        if (data.phoneMobile !== defaultValues.phoneMobile) {
          variables.analyticsData = { ...values.analyticsData, phoneMobile: data.phoneMobile };
        }
        if (Object.keys(variables).length) {
          // Only attempt the update if we actually
          // have any updates, otherwise just close
          // the flow without any request triggered
          variables.signUpCode = values.signupCode;
          await updatePatient({ variables });
          setToast(UPDATE_SUCCESSFUL);
        }

        finishFlow();
      } catch (e) {
        setErrorToast(e.message);
      }
    },
    [
      values.analyticsData,
      values.signupCode,
      defaultValues.email,
      defaultValues.name,
      defaultValues.phoneMobile,
      finishFlow,
      setErrorToast,
      setToast,
      updatePatient,
    ]
  );

  return (
    <PatientDetails
      formProps={formProps}
      signupCode={values.signupCode}
      onSubmit={formProps.handleSubmit(onSubmit)}
    />
  );
}
