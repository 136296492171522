import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Colors,
  LimbicText,
  Logo,
  TextInput,
  TextVariants,
} from '@limbic-for-therapists/components';
import { resetPassword, ISendEmailToResetPasswordVariables } from '@limbic-for-therapists/backend';
import { RequiredString, useAnalytics, validate } from '@limbic-for-therapists/shared';
import { useScreenDimensionsContext } from '../../context/ScreenDimensions';
import { useToastContext } from '../../context/Toast';
import { STAGE } from '../../consts/stage';
import mixpanel from 'mixpanel-browser';

const LOGO_WIDTH = 125;

const validationSchema = yup.object().shape({
  email: RequiredString('Please enter a valid email address.'),
});

export const RequestPasswordForm = () => {
  const { logAnalytic } = useAnalytics(mixpanel);
  const [loading, setLoading] = useState<boolean>(false);
  const { setErrorToast, setToast } = useToastContext();
  const history = useHistory();
  const { currentSize } = useScreenDimensionsContext();
  const { control, getValues } = useForm<ISendEmailToResetPasswordVariables>();

  const navigateToLoginPage = useCallback(() => {
    history.push('/auth/login');
  }, [history]);

  const handleSendEmailToResetPassword = useCallback(async () => {
    try {
      setLoading(true);
      const values = getValues();
      await validate(validationSchema, values);
      await resetPassword(STAGE, { email: values.email });
      setToast({
        title: 'Reset password email sent.',
        subtitle: 'Check your email address for further instructions.',
      });
      logAnalytic({ eventName: 'user-reset-password', params: undefined });
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setLoading(false);
    }
  }, [getValues, logAnalytic, setErrorToast, setToast]);

  return (
    <View style={styles.form}>
      {currentSize === 'xs' ? (
        <View style={styles.row}>
          <Logo color={Colors.black} width={LOGO_WIDTH} />
        </View>
      ) : null}
      <form>
        <View style={styles.row}>
          <LimbicText variant={TextVariants.M}>
            Enter your email address below, and we&apos;ll send you an email to reset your password.
          </LimbicText>
        </View>

        <View style={styles.row}>
          <Controller
            name="email"
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                testID="signup-email-field"
                label="E-mail Address"
                keyboardType="email-address"
                onBlur={onBlur}
                value={value}
                onChangeText={(value) => onChange(value)}
              />
            )}
          />
        </View>
      </form>

      <View style={styles.row}>
        <Button
          fixed
          onPress={handleSendEmailToResetPassword}
          variant="main"
          title="Reset Password"
          loading={loading}
          testID="signup-button"
        />
      </View>
      <TouchableOpacity onPress={navigateToLoginPage} testID="signup-login-button">
        <View style={styles.row}>
          <LimbicText variant={TextVariants.M}>Click here to log in</LimbicText>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  form: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    padding: 44,
    width: 500,
  },
  row: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
});
