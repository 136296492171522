import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Colors } from '../../config';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Tag, TagVariant } from '../Tag/Tag';
import { Button } from '../Button/Button';
import Icon from 'react-native-vector-icons/Ionicons';

const ICON_SIZE = 24;

interface InviteCardProps {
  children?: string;
  canRemindSignup: boolean;
  handleRemindSignup: () => void;
  showSettingsButton: boolean;
  onSettingsPress: () => void;
}

export default function InviteCard({
  children,
  testID,
  canRemindSignup,
  handleRemindSignup,
  showSettingsButton,
  onSettingsPress,
}: WithTestID<InviteCardProps>) {
  return (
    <View style={styles.cardContainer} testID={testID}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {canRemindSignup ? (
          <Button
            title="SEND REMINDER"
            titleVariant={TextVariants.S}
            onPress={handleRemindSignup}
            small
            style={{
              alignSelf: 'flex-start',
              backgroundColor: Colors.pink2,
              borderRadius: 8,
              padding: '8px',
            }}
            textStyle={{
              color: Colors.white,
              textTransform: 'uppercase',
              letterSpacing: 2,
              fontSize: 10,
            }}
          />
        ) : (
          <Tag
            variant={TagVariant.NoVariant}
            textColor={Colors.black}
            backgroundColor={Colors.grey2}
            icon="user-plus"
          >
            INVITING CLIENT
          </Tag>
        )}
        {showSettingsButton ? (
          <TouchableOpacity onPress={onSettingsPress} testID="edit-patient-button">
            <Icon name="create-outline" color={Colors.pink1} size={ICON_SIZE} />
          </TouchableOpacity>
        ) : null}
      </View>
      {children ? <LimbicText variant={TextVariants.L}>{children}</LimbicText> : null}
      <View style={styles.stepContainer}>
        <LimbicText center variant={TextVariants.S} style={[styles.stepTitle, styles.pinkText]}>
          Invite client{'\n'}with code
        </LimbicText>
        <LimbicText center variant={TextVariants.S} style={styles.stepTitle}>
          Client{'\n'}installs app
        </LimbicText>
        <LimbicText center variant={TextVariants.S} style={styles.stepTitle}>
          Apps are{'\n'}connected
        </LimbicText>
      </View>
      <View style={styles.dotContainer}>
        <View style={styles.dotLineContainer}>
          <View style={styles.dotLineEmpty} />
          <View style={styles.dotLine} />
          <View style={styles.dotLineEmpty} />
        </View>
        <View style={styles.dotStep}>
          <View style={[styles.dot, styles.pinkDot]} />
        </View>
        <View style={styles.dotStep}>
          <View style={styles.dot} />
        </View>
        <View style={styles.dotStep}>
          <View style={styles.dot} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    flexGrow: 1,
    padding: 16,
    borderStyle: 'dashed',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.darkGrey1,
  },
  cardTag: {
    alignSelf: 'flex-start',
    backgroundColor: Colors.pink2,
  },
  stepContainer: {
    flexDirection: 'row',
    marginTop: 21,
  },
  dotContainer: {
    flexDirection: 'row',
    position: 'relative',
    marginTop: 10,
  },
  dotStep: {
    flex: 1,
    alignItems: 'center',
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: Colors.darkGrey3,
  },
  dotLineContainer: {
    position: 'absolute',
    top: 4,
    left: 0,
    right: 0,
    height: 5,
    flexDirection: 'row',
  },
  dotLineEmpty: {
    flex: 0.25,
    height: 2,
  },
  dotLine: {
    flex: 1,
    height: 2,
    backgroundColor: Colors.darkGrey3,
  },
  stepTitle: {
    flex: 1,
    color: Colors.darkGrey2,
    lineHeight: 16,
  },
  pinkText: {
    color: Colors.pink2,
  },
  pinkDot: {
    backgroundColor: Colors.pink2,
  },
});
