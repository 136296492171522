import React from 'react';
import {
  StyleSheet,
  View,
  ViewStyle,
  TouchableWithoutFeedback,
  StyleProp,
  TouchableOpacity,
} from 'react-native';
import { Colors, getShadowStyles } from '../../config';
import { cursorStyle } from '../../utils/cursorStyle';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Tag, TagVariant } from '../Tag/Tag';
import Icon from 'react-native-vector-icons/Ionicons';

const ICON_SIZE = 24;

export enum PatientCardVariant {
  TASK = 'task',
  SMALL = 'small',
}

export interface PatientCardProps {
  variant?: PatientCardVariant;
  tagTitle?: string;
  tagIcon?: string;
  tagBackgroundColor?: Colors;
  tagTextColor?: Colors;
  children: string;
  bottomLine?: string;
  style?: StyleProp<ViewStyle>;
  textColor?: Colors;
  onPress?: () => void;
  showSettingsButton: boolean;
  onSettingsPress: () => void;
}

export const PatientCard = ({
  variant,
  tagTitle,
  tagIcon,
  children,
  bottomLine,
  style,
  tagBackgroundColor = Colors.pink2,
  tagTextColor = Colors.white,
  textColor = Colors.purpleDark,
  onPress,
  testID,
  showSettingsButton,
  onSettingsPress,
}: WithTestID<PatientCardProps>) => {
  // TODO: The need to extract from tagStyle is because the Tag
  //       component needs the background and text color separately.
  //       It should instead ask for two different styles that would
  //       be merged as-is instead of needing to deconstruct them.
  //       When that's done then sort this component out accordingly.
  const isSmall = variant === PatientCardVariant.SMALL;
  return (
    <TouchableWithoutFeedback onPress={onPress} testID={testID}>
      <View
        style={[
          styles.cardContainer,
          isSmall ? styles.cardContainerSmall : styles.cardContainer,
          cursorStyle('pointer'),
          style as any,
        ]}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {!!tagTitle && (
            <Tag
              variant={TagVariant.NoVariant}
              backgroundColor={tagBackgroundColor}
              textColor={tagTextColor as Colors}
              icon={tagIcon}
            >
              {tagTitle}
            </Tag>
          )}
          {showSettingsButton ? (
            <TouchableOpacity onPress={onSettingsPress} testID="edit-patient-button">
              <Icon name="create-outline" color={Colors.pink1} size={ICON_SIZE} />
            </TouchableOpacity>
          ) : null}
        </View>
        <LimbicText variant={TextVariants.L} color={textColor}>
          {children}
        </LimbicText>
        {!!bottomLine && (
          <LimbicText style={styles.cardDate} variant={TextVariants.S}>
            {bottomLine}
          </LimbicText>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

PatientCard.defaultProps = {
  variant: PatientCardVariant.TASK,
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: Colors.white,
    flexGrow: 1,
    height: 140,
    borderRadius: 10,
    padding: 16,
    ...getShadowStyles(Colors.black, 0.2, 1.41, 0, 1),
    elevation: 2,
  },
  cardContainerSmall: {
    height: 120,
  },
  cardTag: {
    alignSelf: 'flex-start',
    backgroundColor: Colors.pink2,
  },
  cardDate: {
    marginTop: 'auto',
    color: Colors.darkGrey1,
    justifyContent: 'flex-end',
  },
});
