import React, { useState, useEffect, useCallback, useContext, createContext } from 'react';
import { DimensionType, grid, ResponsiveSize } from '../consts/dimensions';

interface ScreenDimensionsContextProps {
  children?: React.ReactNode;
}

const getWindowDimensions = () => ({
  width: typeof window !== 'undefined' ? window.innerWidth : grid.breakpoints[0],
  height: typeof window !== 'undefined' ? window.innerHeight : grid.breakpoints[0],
});

const determineSize = (dimensions: DimensionType): ResponsiveSize => {
  if (dimensions.width < grid.breakpoints[0]) return 'xs';
  if (dimensions.width < grid.breakpoints[1]) return 'sm';
  if (dimensions.width < grid.breakpoints[2]) return 'md';
  return 'lg';
};

const ScreenDimensionsContext = createContext<{
  currentDimensions: DimensionType;
  currentSize: 'xs' | 'sm' | 'md' | 'lg';
}>({
  currentDimensions: getWindowDimensions(),
  currentSize: determineSize(getWindowDimensions()),
});

const ScreenDimensionsContextProvider = ({ children }: ScreenDimensionsContextProps) => {
  const initialDimensions = { width: 800, height: 1000 };
  const [currentDimensions, setCurrentDimensions] = useState<{
    width: number;
    height: number;
  }>(initialDimensions);

  const handleDimensionsChanged = useCallback(() => {
    setCurrentDimensions(getWindowDimensions());
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('resize', handleDimensionsChanged);
    handleDimensionsChanged();

    return () => window.removeEventListener('resize', handleDimensionsChanged);
  }, [handleDimensionsChanged]);

  const values = {
    currentDimensions: typeof window === 'undefined' ? initialDimensions : currentDimensions,
    currentSize: typeof window === 'undefined' ? 'md' : determineSize(currentDimensions),
  };

  return (
    <ScreenDimensionsContext.Provider value={values}>{children}</ScreenDimensionsContext.Provider>
  );
};

const useScreenDimensionsContext = () => useContext(ScreenDimensionsContext);

export { ScreenDimensionsContextProvider, useScreenDimensionsContext };
