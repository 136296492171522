import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config/colors';
import { CheckList, CheckListItem } from '../CheckList/CheckList';
import { Button } from '../Button/Button';

const CHECK_LIST_ITEMS: CheckListItem[] = [
  {
    content:
      'In that patients app this and this is already available. Here you can share the whole report.',
  },
  {
    content:
      'In that patients app this and this is already available. Here you can share the whole report.',
  },
];

interface ShareInformationModalProps {
  visible: boolean;
  onClose?: (cancelled: boolean) => void;
}

export const ShareInformationModal = ({ visible, onClose }: ShareInformationModalProps) => {
  const [internalIsVisible, setInternalIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (visible === internalIsVisible) return;
    setInternalIsVisible(visible);
  }, [internalIsVisible, visible]);

  const handleClose = useCallback(
    (didCancel: boolean) => {
      setInternalIsVisible(false);
      if (onClose) onClose(didCancel);
    },
    [onClose]
  );

  return (
    <>
      <LimbicText variant={TextVariants.L} bold>
        Share Information
      </LimbicText>
      <LimbicText variant={TextVariants.M} color={Colors.darkGrey1}>
        In that patients app this and this is already available. Here you can share the whole
        report.
      </LimbicText>
      <CheckList items={CHECK_LIST_ITEMS} color={Colors.darkGrey1} />
      <Button
        title="Share Information"
        fixed
        style={styles.button}
        onPress={() => handleClose(false)}
      />
      <Button
        title="Cancel"
        small
        fixed
        style={styles.cancelButton}
        textStyle={styles.cancelButtonText}
        onPress={() => handleClose(true)}
      />
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 30,
  },
  cancelButton: {
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  cancelButtonText: {
    color: Colors.darkGrey2,
  },
});
