import React, { useCallback, useState } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config';
import { Hoverable } from '../Hoverable/Hoverable';

const STAR_SIZE = 30;

interface StarProps {
  filled: boolean;
  onHover: () => void;
  onPress: () => void;
}

const Star = ({ filled, onHover, onPress }: StarProps) => {
  return (
    <TouchableOpacity onPress={onPress} testID="star">
      <Hoverable onHoverIn={onHover}>
        <Icon name={filled ? 'star' : 'star-outline'} size={STAR_SIZE} color={Colors.purpleDark} />
      </Hoverable>
    </TouchableOpacity>
  );
};

interface StarRatingProps {
  style?: StyleProp<ViewStyle>;
  onPressedStar?: (rating: number) => void;
}

export const StarRating = ({ style, onPressedStar }: StarRatingProps) => {
  const [filledStars, setFilledStars] = useState<number | undefined>(undefined);
  const [selectedStars, setSelectedStars] = useState<number | undefined>(undefined);

  const handleHoveredOverStar = useCallback((index: number) => {
    setFilledStars(index);
  }, []);

  const handlePressedStar = useCallback(
    (index: number) => {
      setSelectedStars(index);
      setFilledStars(index);
      onPressedStar?.(index + 1);
    },
    [onPressedStar]
  );

  const handleCursorLeftComponent = useCallback(() => {
    setFilledStars(selectedStars);
  }, [selectedStars]);

  return (
    <Hoverable onHoverOut={handleCursorLeftComponent}>
      <View style={[styles.container, style]}>
        {new Array(5).fill(null).map((el, i) => (
          <Star
            key={i}
            filled={typeof filledStars === 'undefined' ? false : filledStars >= i}
            onHover={() => handleHoveredOverStar(i)}
            onPress={() => handlePressedStar(i)}
          />
        ))}
      </View>
    </Hoverable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});
