import { useCallback, useMemo } from 'react';

export const useClinicId = () => {
  const findHostParts = useCallback(() => {
    const { host } = global.location;
    const parts = host.split('.');
    return parts;
  }, []);

  const subdomain = useMemo(() => {
    const parts = findHostParts();
    return parts.length >= 3 ? parts[0] : undefined;
  }, [findHostParts]);

  return subdomain || '';
};
