import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { useMemo } from 'react';
import { IPatientFragment, ITherapistDigestFragment, fragments } from '../fragments';
import { useListTherapistReportsForPatient } from '../queries/listTherapistReportsForPatient';
import { IMoodLogGroup, groupMoodLogsByDate } from '../utils/groupMoodLogsByDate';

dayjs.extend(calendar);

const GET_PATIENT_SCREEN_QUERY = gql`
  query getPatientScreenQuery($signUpCode: String) {
    patient: getPatient(code: $signUpCode, mergeResponse: true) {
      ...Patient
    }
  }
  ${fragments.patient}
`;

export interface IPatientInteventionHomework {
  id: string;
  createdAt: Date;
  intervention: {
    id: string;
    name: string;
  };
  triggers: Array<{
    rrule: string;
  }>;
}

export interface PatientInterventionsQueryResponse {
  patientInterventions: IPatientInteventionHomework[];
}

export interface PatientScreenQueryResponse {
  patient: IPatientFragment;
}

export type Report = ITherapistDigestFragment & {
  totalLogs: number;
  moodLogGroups: IMoodLogGroup[];
  questionnaires: string[];
};

export const usePatientContent = (signUpCode: string) => {
  const variables = { signUpCode };

  const {
    data: reportsData,
    loading: reportsLoading,
    error: reportsError,
    onFetchMore,
  } = useListTherapistReportsForPatient(signUpCode, 10);

  const canFetchMore = reportsData?.listTherapistReportsForPatient
    ? reportsData.listTherapistReportsForPatient.pagination.total >
      reportsData.listTherapistReportsForPatient.data.length
    : false;

  const reports: Report[] | undefined = useMemo(
    () =>
      reportsData?.listTherapistReportsForPatient?.data.map((el) => ({
        ...el,
        totalLogs: el.dailyLogsCounter?.value || 0,
        moodLogGroups: groupMoodLogsByDate(el.dailyLogs?.data || []),
        questionnaires: el.weeklyScores?.data.map((i) => i.type) || [],
      })),
    [reportsData]
  );

  const {
    data: patientData,
    loading: patientLoading,
    error: patientError,
  } = useQuery<PatientScreenQueryResponse>(GET_PATIENT_SCREEN_QUERY, {
    variables,
  });

  return {
    reports: reports || [],
    pendingCopingStrategies: patientData?.patient.pendingCopingStrategies.data,
    patientData,
    loading: reportsLoading || patientLoading,
    error: reportsError || patientError,
    canFetchMore,
    fetchMore: onFetchMore,
  };
};
