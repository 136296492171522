import {
  IInterventionFragment,
  MediaType,
  StepConfigInput,
  StepType,
} from '@limbic-for-therapists/backend';
import {
  LimbicText,
  ModalContent,
  SelectInput,
  TextVariants,
} from '@limbic-for-therapists/components';
import React, { useCallback, useMemo, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { useFeatureFlagsContext } from '../../../../context/FeatureFlags';
import { useFlowContext } from '../../../../context/Flow';
import { AddCustomInterventionFlowValues } from '../../AddCustomInterventionFlow';

type EditInterventionStepsParams = {
  onSave: (step: StepConfigInput) => void;
  step?: IInterventionFragment['steps'][number];
};

const MEDIA_TYPES = [
  { label: 'Show an Image', value: MediaType.image },
  { label: 'Download a PDF', value: MediaType.pdf },
];

const ANSWER_TYPES = [
  { label: 'Nothing, this message is informational', value: StepType.informational },
  { label: 'A free text answer', value: StepType.freeText },
  { label: 'A single choice answer', value: StepType.singleChoice },
  { label: 'A multiple choice answer', value: StepType.multipleChoice },
  { label: 'A thought', value: StepType.thought },
  { label: 'An activity', value: StepType.activity },
  { label: 'An emotion', value: StepType.emotion },
  {
    label: 'Nothing, user will be prompted to view an image or to see a pdf',
    value: StepType.media,
  },
];

export default ({ onSave, step }: EditInterventionStepsParams) => {
  const { values } = useFlowContext<AddCustomInterventionFlowValues>();
  const [type, setType] = useState<StepType>(step?.type ?? StepType.informational);
  const [mediaType, setMediaType] = useState(step?.mediaType ?? MediaType.image);
  const [mediaUrl, setMediaUrl] = useState(step?.mediaUrl ?? '');
  const [prompt, setPrompt] = useState<string>(step?.prompt ?? '');
  const [answers, setAnswers] = useState<string[]>(step?.answers ?? []);
  const [newAnswer, setNewAnswer] = useState<string>('');
  const { featureFlags } = useFeatureFlagsContext();

  const answerTypes = useMemo(() => {
    if (featureFlags.ALPHA) {
      return ANSWER_TYPES;
    } else {
      return ANSWER_TYPES.filter((answerType) => answerType.value !== StepType.media);
    }
  }, [featureFlags.ALPHA]);

  const selectedType = useMemo(
    () => answerTypes.find((answer) => answer.value === type),
    [type, answerTypes]
  );
  const selectedMediaType = useMemo(
    () => MEDIA_TYPES.find((answer) => answer.value === mediaType),
    [mediaType]
  );

  const saveDisabled = useMemo(() => {
    if (type === StepType.media) {
      return !mediaUrl;
    } else if (type === StepType.multipleChoice || type === StepType.singleChoice) {
      return !prompt || !answers.length;
    } else {
      return !prompt;
    }
  }, [prompt, type, answers, mediaUrl]);

  const onAddAnswer = useCallback(() => {
    if (!newAnswer) return;
    setAnswers([...answers, newAnswer]);
    setNewAnswer('');
  }, [answers, newAnswer]);

  const onPressSave = useCallback(() => {
    if (!values.id) throw new Error('Unreachable');
    if (saveDisabled) return;

    const baseStepConfig = {
      interventionId: values.id,
      prompt,
    };

    switch (type) {
      case StepType.informational:
        return onSave({
          informational: baseStepConfig,
        });
      case StepType.freeText:
        return onSave({
          freeText: baseStepConfig,
        });
      case StepType.emotion:
        return onSave({
          emotion: baseStepConfig,
        });
      case StepType.thought:
        return onSave({
          thought: baseStepConfig,
        });
      case StepType.activity:
        return onSave({
          activity: baseStepConfig,
        });
      case StepType.singleChoice:
        return onSave({
          singleChoice: { ...baseStepConfig, answers },
        });
      case StepType.multipleChoice:
        return onSave({
          multipleChoice: { ...baseStepConfig, answers },
        });
      case StepType.media:
        return onSave({
          media: { interventionId: values.id, mediaType, mediaUrl },
        });
      default:
        throw new Error(`Activity step type (${type}) is not implemented.`);
    }
  }, [saveDisabled, onSave, prompt, type, mediaType, mediaUrl, answers, values.id]);

  if (!values.id) return null;

  return (
    <ModalContent scrollable>
      <ModalContent.Title>Send a message to the patient</ModalContent.Title>
      {type !== StepType.media ? (
        <ModalContent.TextArea value={prompt} onChangeText={setPrompt}></ModalContent.TextArea>
      ) : (
        <>
          <View style={styles.answerType}>
            <SelectInput
              selectedItem={selectedMediaType}
              values={MEDIA_TYPES}
              onPressItem={(item) => setMediaType(item.value)}
            ></SelectInput>
          </View>
          <ModalContent.TextInput
            placeholder={`${mediaType} url`}
            value={mediaUrl}
            onChangeText={setMediaUrl}
          ></ModalContent.TextInput>
        </>
      )}
      <ModalContent.Body>
        <LimbicText variant={TextVariants.M}>And the patient should answer with</LimbicText>
      </ModalContent.Body>

      <View style={styles.answerType}>
        <SelectInput
          selectedItem={selectedType}
          values={answerTypes}
          onPressItem={(item) => setType(item.value)}
        ></SelectInput>
      </View>

      {(type === StepType.singleChoice || type === StepType.multipleChoice) && (
        <>
          {answers.length > 0 && (
            <View style={styles.answerContainer}>
              {answers.map((answer, i) => (
                <Pressable
                  key={i}
                  style={styles.answer}
                  onPress={() => {
                    setAnswers([...answers.slice(0, i), ...answers.slice(i + 1)]);
                  }}
                >
                  <LimbicText variant={TextVariants.M}>{answer}</LimbicText>
                  <View style={{ marginLeft: 8 }}>
                    <Icon name="close-outline" size={16} color={'currentColor'} />
                  </View>
                </Pressable>
              ))}
            </View>
          )}

          <View style={styles.newAnswerRow}>
            <ModalContent.TextInput
              style={{ height: 'auto', marginRight: 16, paddingVertical: 8, flexGrow: 1 }}
              numberOfLines={1}
              value={newAnswer}
              onChangeText={setNewAnswer}
              placeholder="Add a choice"
              onSubmitEditing={onAddAnswer}
            ></ModalContent.TextInput>
            <Pressable onPress={onAddAnswer}>
              <Icon name="checkmark-outline" size={24} color={newAnswer ? '#fd81a0' : '#9fa2b4'} />
            </Pressable>
          </View>
        </>
      )}

      {type === StepType.emotion && (
        <LimbicText variant={TextVariants.M}>
          The patient will be asked to choose between a set of emotions or create a new one, if none
          matches their feelings.
        </LimbicText>
      )}

      <View style={{ marginTop: 24 }}>
        <ModalContent.CTAButton
          title="Save"
          disabled={saveDisabled}
          onPress={onPressSave}
        ></ModalContent.CTAButton>
      </View>
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  newAnswerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  answer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'rgb(239, 240, 247)',
    borderRadius: 100,
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginLeft: 6,
    marginVertical: 4,
  },
  answerContainer: {
    marginBottom: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  answerType: {
    marginBottom: 8,
  },
});
