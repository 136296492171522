import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config/colors';
import { ButtonProps } from '../Button/Button';
import { ModalContent } from './ModalContent';

interface Props extends ButtonProps {
  message: string;
}

export const ConfirmDangerousActionModal = (props: Props) => {
  const { message, style, textStyle, ...restProps } = props;
  return (
    <ModalContent>
      <View style={styles.content}>
        <ModalContent.Body style={styles.bodyText}>{message}</ModalContent.Body>
      </View>

      <View style={styles.buttonContainer}>
        <ModalContent.CTAButton
          outline
          style={[styles.button, style]}
          textStyle={[styles.text, textStyle]}
          {...restProps}
        />
      </View>
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
    paddingBottom: 24,
  },
  bodyText: {
    color: Colors.darkGrey2,
  },
  buttonContainer: {
    paddingBottom: 24,
  },
  button: {
    borderColor: Colors.red,
    borderWidth: 2,
    backgroundColor: Colors.white,
  },
  text: {
    color: Colors.red,
    fontSize: 16,
  },
});
