import React from 'react';
import { useCallback } from 'react';
import { FlowContextProvider } from '../../context/Flow';
import { useModalContext } from '../../context/Modal';
import InterventionNameScreen from './screens/InterventionNameScreen';
import InterventionStepsScreen from './screens/InterventionStepsScreen';
import { SuccessScreen } from './screens/SuccessScreen';

export interface AddCustomInterventionFlowValues {
  id?: string;
  name?: string;
  signUpCode: string;
  editStepIndex?: number;
  onInterventionPublished?: (interventionId: string) => void;
}

interface AddCustomInterventionProps {
  signUpCode: string;
  onInterventionPublished?: (interventionId: string) => void;
}

export const AddCustomInterventionFlow = ({
  signUpCode,
  onInterventionPublished,
}: AddCustomInterventionProps) => {
  const { dismissModal } = useModalContext();
  const values: AddCustomInterventionFlowValues = {
    signUpCode,
    editStepIndex: undefined,
    onInterventionPublished,
  };

  const handleFinishFlow = useCallback(() => {
    dismissModal();
  }, [dismissModal]);

  return (
    <FlowContextProvider
      hideBackButtonOnLastStep
      defaultValues={values}
      finishFlow={handleFinishFlow}
      testID="add-custom-intervention-flow"
    >
      <InterventionNameScreen />
      <InterventionStepsScreen />
      <SuccessScreen />
    </FlowContextProvider>
  );
};
