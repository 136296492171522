import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ImageSourcePropType } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface SettingsRowProps {
  imageRight: ImageSourcePropType;
  imageLeft: ImageSourcePropType;
  heading: string;
  details: string;
  onPress?: () => void | Promise<void>;
}

export const SettingsRow: React.FC<SettingsRowProps> = ({
  imageRight,
  imageLeft,
  heading,
  details,
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.cardContainer}>
        <View style={styles.icon}>
          <Image source={imageRight} style={styles.image} />
        </View>
        <View style={styles.content}>
          <LimbicText variant={TextVariants.Base} color={Colors.darkGrey2}>
            {heading}
          </LimbicText>
          <LimbicText variant={TextVariants.S} color={Colors.darkGrey1}>
            {details}
          </LimbicText>
        </View>
        <View style={styles.icon}>
          <Image source={imageLeft} style={styles.image2} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottomWidth: 1,
    borderBottomColor: Colors.grey3,
  },
  content: {
    flex: 8,
    marginHorizontal: 20,
    marginVertical: 10,
    justifyContent: 'center',
  },
  image: {
    width: 26,
    height: 26,
  },
  image2: {
    width: 10,
    height: 18,
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    marginVertical: 10,
    flex: 1.5,
  },
});
