import { gql, useMutation } from '@apollo/client';
import { fragments, ITherapistDigestFragment } from '../fragments';

const UPDATE_THERAPIST_REPORT = gql`
  mutation updateTherapistReport($digestId: ID!) {
    updateTherapistReport(digestId: $digestId, wasRead: true) {
      ...TherapistDigest
    }
  }
  ${fragments.therapistDigest}
`;

export interface IUpdateTherapistReportResponse {
  updateTherapistReport: ITherapistDigestFragment;
}

interface IUpdateTherapistReportVariables {
  digestId: string;
}

export const useMarkTherapistReportAsRead = (digestId: string) => {
  return useMutation<IUpdateTherapistReportResponse, IUpdateTherapistReportVariables>(
    UPDATE_THERAPIST_REPORT,
    { variables: { digestId } }
  );
};
