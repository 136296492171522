import React from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { useInviteFlowContext } from '../../../context/InviteFlow';
import { NewPatientWithHWFlowValues } from '../NewPatientWithHWFlow';
import { Colors, ModalContent, TextInput, TextVariants } from '@limbic-for-therapists/components';

export interface NewPatientFromFormValidationSchema {
  name: string;
  email: string;
  phone: string;
  dob: string;
  demoUser: boolean;
}

export const NewPatientFromForm = () => {
  const {
    formProps: { control: control },
  } = useInviteFlowContext<NewPatientWithHWFlowValues>();

  return (
    <ModalContent scrollable style={styles.container}>
      <View style={styles.row}>
        <Controller
          control={control}
          name="name"
          defaultValue={''}
          render={({ value, onChange, onBlur }) => (
            <TextInput
              label="Name"
              onChange={(value) => onChange(value)}
              value={value}
              style={styles.inputName}
              onBlur={onBlur}
              labelSize={TextVariants.S}
              placeholder="Start typing the name"
            />
          )}
        />
      </View>

      <View style={[styles.row, styles.multiItemRow]}>
        <View style={styles.multiItemRowInput}>
          <Controller
            control={control}
            name="dob"
            defaultValue={''}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                style={[styles.input, styles.inputPadded]}
                label="Date of birth"
                placeholder="DD/MM/YYYY"
                value={value}
                onChange={(value) => onChange(value)}
                onBlur={onBlur}
                labelSize={TextVariants.S}
              />
            )}
          />
        </View>
        <View style={styles.multiItemRowInput}>
          <Controller
            control={control}
            name="phone"
            defaultValue={''}
            render={({ value, onChange, onBlur }) => (
              <TextInput
                label="Phone number"
                onChange={(value) => onChange(value)}
                value={value}
                style={styles.input}
                onBlur={onBlur}
                placeholder="+44"
                labelSize={TextVariants.S}
              />
            )}
          />
        </View>
      </View>

      <View>
        <Controller
          control={control}
          name="email"
          defaultValue={''}
          render={({ value, onChange, onBlur }) => (
            <TextInput
              label="Email"
              onChange={(value) => onChange(value)}
              value={value}
              style={[styles.input]}
              onBlur={onBlur}
              placeholder="Start typing the email"
              labelSize={TextVariants.S}
            />
          )}
        />
      </View>
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
    paddingRight: 0,
    paddingLeft: 0,
    marginTop: 16,
    marginBottom: 0,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyText: {
    color: Colors.darkGrey1,
  },
  inputName: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: Colors.white,
    borderColor: Colors.primaryBlue70,
    height: 32,
    marginTop: 0,
  },
  input: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: Colors.white,
    borderColor: Colors.primaryBlue70,
    height: 32,
    marginTop: 0,
  },
  inputPadded: {
    marginRight: 3,
  },
  row: {
    display: 'flex',
    paddingBottom: 16,
    width: '100%',
    justifyContent: 'space-between',
  },
  multiItemRow: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  multiItemRowInput: {
    flex: 0.49,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  bottomAccessory: {
    flex: 1,
    marginTop: 48,
  },
  dateLabel: {
    color: Colors.darkGrey1,
    paddingBottom: 4,
    paddingTop: 0,
  },
  tag: {
    marginBottom: 8,
    marginRight: 16,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  pickDateButton: {
    width: 320,
    alignSelf: 'flex-start',
    borderWidth: 1,
    backgroundColor: Colors.white,
    justifyContent: 'flex-start',
    borderColor: Colors.darkGrey1,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
  buttonCancel: {
    marginTop: 24,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  buttonConfirm: {
    marginTop: 24,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.pink1,
  },
});
