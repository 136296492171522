import React, { SVGProps } from 'react';

export const MedicalDeviceWarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <path d="m26.917 164.47 73.083-126.45 73.083 126.45z" stroke="#000" strokeWidth={5} />
      <path d="m100 65.825v65.317" fill="none" stroke="#000" strokeWidth={10} />
      <circle cx="100" cy="147.92" r="8.0898" fill="#000" />
    </svg>
  );
};
