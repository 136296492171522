import { gql, useQuery } from '@apollo/client';
import { fragments, IMoodQuestionnaireFragment } from '../fragments';

export interface IListQuestionnairesResponse {
  listQuestionnaires?: {
    data: IMoodQuestionnaireFragment[];
  };
}

const LIST_QUESTIONNAIRES = gql`
  query listQuestionnaires(
    $signUpCode: String!
    $fromDate: Date
    $toDate: Date
    $moodEventType: String
  ) {
    listQuestionnaires(
      code: $signUpCode
      fromDate: $fromDate
      toDate: $toDate
      moodEventType: $moodEventType
    ) {
      data {
        ...MoodQuestionnaire
      }
    }
  }
  ${fragments.moodQuestionnaire}
`;

interface IListQuestionnairesVariables {
  signUpCode: string;
  fromDate?: Date;
  toDate?: Date;
  moodEventType?: string;
}

export const useListQuestionnaires = (variables: IListQuestionnairesVariables) => {
  return useQuery<IListQuestionnairesResponse, IListQuestionnairesVariables>(LIST_QUESTIONNAIRES, {
    variables,
  });
};
