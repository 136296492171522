export enum IntensityDescription {
  Little = 'a little',
  Somewhat = 'somewhat',
  Very = 'very',
}

export function getIntensityDescription(intensity: number): IntensityDescription {
  if (intensity >= 0.8) {
    return IntensityDescription.Very;
  }
  if (intensity >= 0.6) {
    return IntensityDescription.Somewhat;
  }
  return IntensityDescription.Little;
}
