export const featureFlagOptions = {
  ENABLE_GENERATE_REPORT: 'enable_generate_report',
  DISABLE_INVITE_FLOW: 'disable_invite_flow',
  DISABLE_CHANGE_DATE: 'disable_change_date',
  DISABLE_QUESTIONNAIRES: 'disable_questionnaires',
  DISABLE_NEXT_SESSION: 'disable_next_session',
  DISABLE_TODAY_TAB: 'disable_today_tab',
  DISABLE_PATIENT_CARD_TAGS: 'disable_patient_card_tags',
  ALPHA: 'alpha',
  EXTERNAL_QUESTIONNAIRE_SERVICE: 'external_questionnaire_service',
  DISABLE_RECURRING_SESSION_SCHEDULE: 'disable_recurring_session_schedule',
  DISABLE_PATIENT_EDIT: 'disable_patient_edit',
  ENABLE_INTERCOM_THERAPIST_APP: 'enable_intercom_therapist_app',
  SHOW_INTERCOM_THERAPIST_APP: 'show_intercom_therapist_app',
  ENABLE_NEW_PATIENT_INVITE_FLOW: 'enable_new_patient_invite_flow',
};

export type FeatureFlag = keyof typeof featureFlagOptions;

export type FeatureFlags = { [key in FeatureFlag]: boolean };

export const getFeatureFlags = (featureFlagsArr?: string[] | null): FeatureFlags => {
  const flagsArray = featureFlagsArr || [];
  return {
    ENABLE_GENERATE_REPORT: flagsArray.includes('enable_generate_report'),
    DISABLE_INVITE_FLOW: flagsArray.includes('disable_invite_flow'),
    DISABLE_CHANGE_DATE: flagsArray.includes('disable_change_date'),
    DISABLE_QUESTIONNAIRES: flagsArray.includes('disable_questionnaires'),
    DISABLE_NEXT_SESSION: flagsArray.includes('disable_next_session'),
    DISABLE_TODAY_TAB: flagsArray.includes('disable_today_tab'),
    DISABLE_PATIENT_CARD_TAGS: flagsArray.includes('disable_patient_card_tags'),
    ALPHA: flagsArray.includes('alpha'),
    EXTERNAL_QUESTIONNAIRE_SERVICE: flagsArray.includes('external_questionnaire_service'),
    DISABLE_RECURRING_SESSION_SCHEDULE: flagsArray.includes('disable_recurring_session_schedule'),
    DISABLE_PATIENT_EDIT: flagsArray.includes('disable_patient_edit'),
    ENABLE_INTERCOM_THERAPIST_APP: flagsArray.includes('enable_intercom_therapist_app'),
    SHOW_INTERCOM_THERAPIST_APP: flagsArray.includes('show_intercom_therapist_app'),
    ENABLE_NEW_PATIENT_INVITE_FLOW: flagsArray.includes('enable_new_patient_invite_flow'),
  };
};
