import React, { AnchorHTMLAttributes } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { Icons } from '../../config';
import { Colors } from '../../config/colors';
import { getIcon } from '../../utils/getIcon';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import './styles.css';

interface AccountAndNotificationRowProps {
  iconName: keyof typeof Icons;
  placeHolder?: string;
  data?: string;
  onPress?: () => void | Promise<void>;
  isLocalAssetDownloadLink?: boolean;
  localAssetPath?: string;
}

type OutboundLinkProps =
  | {
      isLocalAssetDownloadLink?: false;
      localAssetPath?: never;
    }
  | { isLocalAssetDownloadLink: true; localAssetPath: string };

export const OutboundLink = ({ children, ...props }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a {...props} className="outboundLink">
      <LimbicText
        variant={TextVariants.Base}
        color={Colors.darkGrey1}
        style={styles.placeHolderPadding}
      >
        {children}
      </LimbicText>
    </a>
  );
};

export const AccountAndNotificationRow = ({
  iconName,
  placeHolder,
  data,
  onPress,
  isLocalAssetDownloadLink,
  localAssetPath,
  testID,
}: WithTestID<AccountAndNotificationRowProps & OutboundLinkProps>) => {
  const Icon = getIcon(iconName);
  return (
    <TouchableOpacity onPress={onPress} testID={testID}>
      <View style={styles.cardContainer}>
        <View style={styles.placeHolder}>
          {isLocalAssetDownloadLink ? (
            <OutboundLink href={localAssetPath} download>
              {placeHolder}
            </OutboundLink>
          ) : (
            <LimbicText
              variant={TextVariants.Base}
              color={Colors.darkGrey1}
              style={styles.placeHolderPadding}
            >
              {placeHolder}
            </LimbicText>
          )}
        </View>
        <View style={styles.icon}>
          <LimbicText variant={TextVariants.Base} color={Colors.black} style={styles.textPadding}>
            {data}
          </LimbicText>
          <Image source={Icon} style={styles.image} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.grey3,
    minHeight: 65,
    marginHorizontal: 10,
  },
  placeHolderPadding: { paddingLeft: 3 },
  textPadding: { paddingRight: 18 },
  image: {
    width: 10,
    height: 18,
  },
  placeHolder: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingRight: 10,
  },
});
