import { gql, useQuery } from '@apollo/client';
import { fragments, ITherapistDigestFragment } from '../fragments';

const GET_THERAPIST_DIGEST = gql`
  query getTherapistDigest($digestId: ID!) {
    getTherapistReport(digestId: $digestId) {
      ...TherapistDigest
    }
  }
  ${fragments.therapistDigest}
`;

export interface IGetTherapistDigestResponse {
  getTherapistReport: ITherapistDigestFragment;
}

export const useGetTherapistDigest = (digestId: string) => {
  return useQuery<IGetTherapistDigestResponse>(GET_THERAPIST_DIGEST, { variables: { digestId } });
};
