const strongFn = (t: string) => `<span style='font-weight: bold'>${t}</span>`;
const emFn = (t: string) => `<span style='font-style: italic'>${t}</span>`;
const bigFn = (t: string) => `<span style='font-size: 21px'>${t}</span>`;
const colorFn = (t: string, c: string) => `<span style='color: ${c}'>${t}</span>`;

interface IFormatOptions {
  strong?: string[];
  em?: string[];
  big?: string[];
  colors?: string[][];
}

export default function formatEmailBody(text?: string, formatOptions?: IFormatOptions): string {
  if (!text) {
    return '';
  }
  const { strong = [], em = [], big = [], colors = [] } = formatOptions || {};
  let body = text.replace(/\n/gi, '<br/>');
  strong?.forEach((str) => {
    body = body?.replace(new RegExp(str, 'gi'), strongFn(str));
  });
  em?.forEach((str) => {
    body = body?.replace(new RegExp(str, 'gi'), emFn(str));
  });
  big?.forEach((str) => {
    body = body?.replace(new RegExp(str, 'gi'), bigFn(str));
  });
  colors?.forEach(([str, color]) => {
    body = body?.replace(new RegExp(str, 'gi'), colorFn(str, color));
  });
  return body;
}
