import React, { SVGProps } from 'react';

export const MedicalDeviceManufacturerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <path
        fill="#000"
        d="m28.814 118.25 21.553-37.331 16.605 28.761 16.605-28.761 16.605 28.761 16.605-28.761 16.687 28.903v-75.713h37.712v131.78h-142.37z"
      />
    </svg>
  );
};
