import { gql, useMutation } from '@apollo/client';
import { fragments, IRecurrenceAppointmentFragment } from '../fragments';
import { IRecurrenceInput } from '../models/IRecurrenceInput';

export interface IAddSessionDateResponse {
  addSessionDate: {
    id: string;
    recurrenceAppointment: IRecurrenceAppointmentFragment;
  };
}

const ADD_SESSION_DATE = gql`
  mutation addSessionDate(
    $signUpCode: String!
    $sessionDate: Date!
    $recursiveAppointment: RecurrenceAppointmentInput
  ) {
    addSessionDate(
      code: $signUpCode
      sessionDate: $sessionDate
      recursiveAppointment: $recursiveAppointment
    ) {
      id
      recurrenceAppointment {
        ...RecurrenceAppointment
      }
    }
  }
  ${fragments.recurrenceAppointment}
`;

export interface IAddSessionDateVariables {
  signUpCode: string;
  sessionDate: Date;
  recursiveAppointment?: IRecurrenceInput;
}

export const useAddSessionDateMutation = () => {
  return useMutation<IAddSessionDateResponse, IAddSessionDateVariables>(ADD_SESSION_DATE, {
    refetchQueries: ['getPatientScreenQuery', 'getPatient'],
    awaitRefetchQueries: true,
  });
};
