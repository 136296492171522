import React, { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { EditName } from '@limbic-for-therapists/components';
import { RequiredString, validate } from '@limbic-for-therapists/shared';
import { useForm } from 'react-hook-form';
import { useToastContext } from '../context/Toast';
import { useActiveClinicianContext } from '../context/ActiveClinician';
import { useUpdateClinician } from '@limbic-for-therapists/backend';

const validationSchema = yup.object().shape({
  firstName: RequiredString('Please supply a valid first name.'),
  surname: RequiredString('Please supply a valid surname.'),
});

interface EditNameScreenProps {
  onSuccess: () => void;
}

export const EditNameScreen = ({ onSuccess }: EditNameScreenProps) => {
  const { activeClinician } = useActiveClinicianContext();

  const { firstName, surname } = useMemo(() => {
    const [firstName, ...rest] = activeClinician?.name.split(' ') || [];
    return { firstName, surname: rest.join('') };
  }, [activeClinician]);

  const { setErrorToast } = useToastContext();
  const { updateClinician, loading } = useUpdateClinician();
  const formProps = useForm<{ firstName: string; surname: string }>({
    defaultValues: { firstName, surname },
  });

  const handleSubmit = useCallback(
    async ({ firstName, surname }: { firstName: string; surname: string }) => {
      try {
        await validate(validationSchema, { firstName, surname });
        await updateClinician({ variables: { name: `${firstName} ${surname}` } });
        onSuccess();
      } catch (e) {
        setErrorToast(e.message);
      }
    },
    [onSuccess, setErrorToast, updateClinician]
  );

  return <EditName formProps={formProps} loading={loading} onSubmit={handleSubmit} />;
};
