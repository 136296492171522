import React from 'react';
import { View, StyleSheet } from 'react-native';
import { fixedPositionStyle } from '@limbic-for-therapists/shared';

export const ModalOverlay = ({ children }: React.PropsWithChildren<unknown>) => {
  return <View style={[styles.container, fixedPositionStyle]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
