import React from 'react';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export interface CheckListItem {
  icon?: React.ReactNode;
  content: string;
}

interface CheckListProps {
  items: CheckListItem[];
  color: Colors;
}

export const CheckList = ({ items, color }: CheckListProps) => {
  return (
    <>
      {items.map((checkListItem, idx) => (
        <View key={`${idx}`} style={styles.row}>
          <View style={styles.iconContainer}>
            {checkListItem.icon ? (
              checkListItem
            ) : (
              <Ionicons name="checkmark-outline" color={color} size={20} />
            )}
          </View>
          <View style={styles.textContainer}>
            <LimbicText variant={TextVariants.M} color={color}>
              {checkListItem.content}
            </LimbicText>
          </View>
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  row: {
    marginTop: 24,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  iconContainer: {
    marginRight: 25,
  },
  textContainer: {
    flexBasis: 0,
    flexGrow: 1,
  },
});
