import { gql, useQuery } from '@apollo/client';
import { IClinician } from '../models/IClinician';

export const GET_CLINICIAN = gql`
  query getClinician {
    getClinician(includeClinicInfo: true) {
      id
      name
      email
      clinic {
        id
        name
      }
    }
  }
`;

interface IGetClinicianClinician extends Pick<IClinician, 'id' | 'name' | 'email'> {
  clinic: {
    id: string;
    name: string;
  };
}

export interface IGetClinicianResponse {
  getClinician: IGetClinicianClinician;
}

export const useGetClinician = () => {
  const { data: activeClinician, refetch: refetch } =
    useQuery<IGetClinicianResponse>(GET_CLINICIAN);
  return { getClinician: refetch, activeClinician };
};
