import { Frequency, RRule, rrulestr } from 'rrule';
import {
  IRecurrenceInput,
  IRecurrenceInputParams,
  RecurrenceFrequency,
  Weekday,
} from '../models/IRecurrenceInput';
import { capitaliseFirst, joinWithAnd } from '@limbic-for-therapists/shared';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(utc);
dayjs.extend(weekday);

const recurrenceFrequenciesMap = {
  [RecurrenceFrequency.Year]: RRule.YEARLY,
  [RecurrenceFrequency.Month]: RRule.MONTHLY,
  [RecurrenceFrequency.Week]: RRule.WEEKLY,
  [RecurrenceFrequency.Day]: RRule.DAILY,
  [RecurrenceFrequency.Hour]: RRule.HOURLY,
  [RecurrenceFrequency.Minute]: RRule.MINUTELY,
};

const reverseRecurrenceFrequenciesMap = {
  [Frequency.YEARLY]: RecurrenceFrequency.Year,
  [Frequency.MONTHLY]: RecurrenceFrequency.Month,
  [Frequency.WEEKLY]: RecurrenceFrequency.Week,
  [Frequency.DAILY]: RecurrenceFrequency.Day,
  [Frequency.HOURLY]: RecurrenceFrequency.Hour,
  [Frequency.MINUTELY]: RecurrenceFrequency.Minute,
  [Frequency.SECONDLY]: RecurrenceFrequency.Minute,
};

const weekdaysMap = {
  [Weekday.Mo]: RRule.MO,
  [Weekday.Tu]: RRule.TU,
  [Weekday.We]: RRule.WE,
  [Weekday.Th]: RRule.TH,
  [Weekday.Fr]: RRule.FR,
  [Weekday.Sa]: RRule.SA,
  [Weekday.Su]: RRule.SU,
};

// eslint-disable-next-line consistent-return
export const getRecurrenceInput = (props: IRecurrenceInputParams): IRecurrenceInput | undefined => {
  const { startDate, freq, interval = 1, weekdays } = props;
  const rrule = new RRule({
    freq: recurrenceFrequenciesMap[freq],
    interval,
    byweekday: weekdays?.map((i) => weekdaysMap[i]),
    dtstart: dayjs.utc(startDate || new Date()).toDate(),
  });
  const arr = rrule.toString().split('\n');
  if (arr?.length) {
    return {
      dtstart: arr[0],
      rrule: arr[1],
    };
  }
};

export const getFrequencyString = (
  interval = 0,
  type: RecurrenceFrequency,
  weekdays: Weekday[]
): string => {
  if (!interval) {
    return '';
  }
  const d = joinWithAnd(weekdays);
  if (interval === 1 && type === RecurrenceFrequency.Week && weekdays.length) {
    return `every ${d}`;
  }
  const r = new RRule({
    freq: recurrenceFrequenciesMap[type],
    interval,
  });
  if (!weekdays.length) {
    return r.toText();
  }
  return `${r.toText()} on ${d}`;
};

export const getFrequencyFromRecurrenceInput = (input: Partial<IRecurrenceInput>): string => {
  const params = getRecurrenceInputParams(input);
  return getFrequencyString(params.interval, params.freq, params.weekdays || []);
};

export const getFrequencyTypeStringShort = (interval = 0, type: RecurrenceFrequency) => {
  if (!interval) {
    return '';
  }
  const typeString = capitaliseFirst(type);
  if (interval === 1) {
    return `${typeString}ly`;
  }
  return `${interval} ${typeString}s`;
};

const getWeekdayFromIndex = (index: number) => {
  switch (index) {
    case 0:
      return Weekday.Mo;
    case 1:
      return Weekday.Tu;
    case 2:
      return Weekday.We;
    case 3:
      return Weekday.Th;
    case 4:
      return Weekday.Fr;
    case 5:
      return Weekday.Sa;
    case 6:
      return Weekday.Su;
    default:
      return Weekday.Su;
  }
};

export const getRecurrenceInputParams = (
  input: Partial<IRecurrenceInput>
): IRecurrenceInputParams => {
  let freq = reverseRecurrenceFrequenciesMap[Frequency.WEEKLY];
  let startDate;
  let interval;
  let weekdays;
  try {
    const str = `${input.dtstart}\n${input.rrule}`;
    const r = rrulestr(str);
    freq = reverseRecurrenceFrequenciesMap[r.options.freq] || freq;
    startDate = r.options.dtstart;
    interval = r.options.interval;
    weekdays = (r.options.byweekday || []).map((d) => getWeekdayFromIndex(d + 1));
  } catch {}
  return { freq, startDate, interval, weekdays };
};
