import React, { useState } from 'react';
import {
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config';

const passwordMaskHitSlop = { left: 20, top: 20, right: 20, bottom: 20 };

export interface LimbicTextInputProps extends TextInputProps {
  innerRef?: React.RefObject<RNTextInput>;
  label?: string;
  actionLabel?: string;
  onActionPress?: () => void | Promise<void>;
  actionColor?: Colors;
  icon?: string;
  fullWidth?: boolean;
  isIconLeft?: boolean;
  iconSize?: number;
  // NOTE: 👇🏻 is really a normal prop of TextInput, but we need to override it
  // in order to be able to provide invalid values and thus get around the
  // issue where Chrome doesn't respect autocomplete="off" and still fills
  // in form fields, and sometimes even on the incorrect field. When used
  // in the signup form, we do not want anything to be filled in by Chrome.
  autoComplete?: any;
  labelSize?: TextVariants;
}

export const TextInput = (props: LimbicTextInputProps) => {
  const {
    innerRef,
    label,
    actionLabel,
    actionColor,
    onActionPress,
    secureTextEntry,
    style,
    testID,
    icon,
    fullWidth = false,
    isIconLeft = false,
    iconSize,
    labelSize,
    ...restProps
  } = props;
  const [isMasked, setIsMasked] = useState(secureTextEntry);
  const onToggleMask = () => setIsMasked(!isMasked);
  return (
    <View style={[styles.container, fullWidth && styles.fullWidth]}>
      <View style={[fullWidth && styles.fullWidth]}>
        {!!label && (
          <LimbicText variant={labelSize || TextVariants.M} color={Colors.darkGrey1}>
            {label}
          </LimbicText>
        )}
        {!!actionLabel && (
          <TouchableWithoutFeedback onPress={onActionPress} hitSlop={passwordMaskHitSlop}>
            <LimbicText variant={TextVariants.S} color={actionColor || Colors.pink2}>
              {actionLabel}
            </LimbicText>
          </TouchableWithoutFeedback>
        )}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <RNTextInput
          ref={innerRef}
          secureTextEntry={isMasked}
          style={[
            styles.textInputTypography,
            styles.textInput,
            !!label && styles.textInputWithLabel,
            secureTextEntry && styles.textInputSecure,
            style,
          ]}
          testID={testID}
          {...restProps}
        />
        {icon && (
          <TouchableWithoutFeedback onPress={onToggleMask}>
            <View
              style={{
                position: 'absolute',
                ...(isIconLeft ? { left: 10 } : { right: 16 }),
              }}
            >
              <Icon name={icon} size={iconSize || 24} color={Colors.darkGrey1} />
            </View>
          </TouchableWithoutFeedback>
        )}
        {secureTextEntry && (
          <TouchableWithoutFeedback onPress={onToggleMask}>
            <View
              style={{
                position: 'absolute',
                right: 16,
              }}
            >
              <Icon name={isMasked ? 'eye-off' : 'eye'} size={24} color={Colors.darkGrey1} />
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    </View>
  );
};

const TextArea = (props: LimbicTextInputProps) => {
  const { style, ...restProps } = props;
  return (
    <TextInput
      multiline
      textAlignVertical="top"
      style={[styles.textInputTypography, styles.textArea, style]}
      {...restProps}
    />
  );
};

TextInput.TextArea = TextArea;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  textInputTypography: {
    fontFamily: 'Aeroport',
    fontStyle: 'normal',
    fontWeight: '300',
  },
  textInput: {
    flex: 1,
    height: 44,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.grey3,
    backgroundColor: Colors.grey1,
  },
  textInputWithLabel: {
    marginTop: 4,
  },
  textInputSecure: {
    paddingRight: 40,
  },
  textArea: {
    paddingTop: 16,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 16,
    flex: 1,
    height: 165,
    borderColor: Colors.grey3,
    marginTop: 7,
  },
  fullWidth: {
    width: '100%',
  },
  textInputIconLeft: {
    paddingLeft: 37,
  },
});
