import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export const isToday = (date?: Date | Dayjs): boolean => {
  return dayjs(date).format('DD/MM/YYYY') === dayjs().format('DD/MM/YYYY');
};

export const daysFromNow = (date?: Date | Dayjs): string => {
  return dayjs(date).fromNow();
};

export const isPast = (date?: Date | Dayjs): boolean => {
  return dayjs(date).isBefore(dayjs());
};
