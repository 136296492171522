import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const getRelativeDateFromNow = (date: Date | Dayjs) => dayjs(date).fromNow();
export const getFullDate = (date: Date | Dayjs) => dayjs(date).format('DD MMMM YYYY');
export const getFileDate = (date: Date | Dayjs) => dayjs(date).format('DD-MM-YY');
export const getLongDate = (date: Date | Dayjs) => dayjs(date).format('ddd DD MMM YYYY');
export const getDayDate = (date: Date | Dayjs) => dayjs(date).format('DD MMMM').toLowerCase();
export const getDayWordShort = (date: Date | Dayjs) => dayjs(date).format('dd');
export const getDayMonoGramShort = (date: Date | Dayjs) => getDayWordShort(date).substr(0, 1);
export const getDateNumberShort = (date: Date | Dayjs) => dayjs(date).format('DD');
export const getDateShort = (date: Date | Dayjs) => dayjs(date).format('D MMM');
export const getDottedDate = (date: Date | Dayjs) => dayjs(date).format('DD.MM');
export const getTimeShort = (date: Date | Dayjs) => dayjs(date).format('HH:mm');
export const getTimeAMPM = (date: Date | Dayjs) => dayjs(date).format('hh:mm A');
export const getDaysAgo = (date: Date | Dayjs) => dayjs().diff(date, 'days');
