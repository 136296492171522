import { gql, useQuery } from '@apollo/client';
import { fragments, IEmotionFragment } from '../fragments';

const LIST_EMOTIONS_QUERY = gql`
  query ListEmotions($signUpCode: String) {
    listEmotions(code: $signUpCode, limit: 10000) {
      data {
        ...Emotion
      }
    }
  }
  ${fragments.emotion}
`;

export interface ListEmotionsQueryResponse {
  listEmotions: {
    data: IEmotionFragment[];
  };
}

export const useListEmotionsQuery = (signUpCode: string) => {
  return useQuery<ListEmotionsQueryResponse>(LIST_EMOTIONS_QUERY, {
    variables: { signUpCode },
  });
};
