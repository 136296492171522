import { ApolloClient, gql, useMutation } from '@apollo/client';

const INVITE_PATIENT_VIA_DYNAMIC_LINK = gql`
  mutation invitePatientViaDynamicLink(
    $signupCode: String!
    $overrideConsent: Boolean
    $overrideResend: Boolean
  ) {
    invitePatientViaDynamicLink(
      input: {
        signupCode: $signupCode
        overrideConsent: $overrideConsent
        overrideResend: $overrideResend
      }
    ) {
      notification {
        id
      }
      dynamicLink
    }
  }
`;

interface INotification {
  id: string | number;
}

interface IInvitePatientViaDynamicLinkResponse {
  notification?: INotification;
  dynamicLink?: string;
}

export interface IRemindPatientSignupResponse {
  invitePatientViaDynamicLink: IInvitePatientViaDynamicLinkResponse;
}

export interface IRemindPatientSignupVariables {
  signupCode: string;
  overrideConsent?: boolean;
  overrideResend?: boolean;
}

export const useInvitePatientViaDynamicLinkMutation = <TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) => {
  return useMutation<IRemindPatientSignupResponse, IRemindPatientSignupVariables>(
    INVITE_PATIENT_VIA_DYNAMIC_LINK,
    {
      client: titanClient,
    }
  );
};
