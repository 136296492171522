import React from 'react';
import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { SummaryCard } from '@limbic-for-therapists/components';
import { TouchableOpacity } from 'react-native';
import { TouchableActiveOpacity } from '../../consts';
import { TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config';
import Icon from 'react-native-vector-icons/Ionicons';
import { IterationSummary } from './IterationSummary';

const HOMEWORK_ICON_SIZE = 24;

interface IInterventionSummaryProps {
  id: string;
  name: string;
  subtitle: string;
  scheduledCounter: number;
  startedAnsweringCounter: number;
  finishedAnsweringCouter: number;
  onPress?: (id: string) => void;
  style?: StyleProp<ViewStyle>;
}

export const InterventionSummary = ({
  id,
  name,
  subtitle,
  scheduledCounter,
  startedAnsweringCounter,
  finishedAnsweringCouter,
  style,
  onPress,
}: IInterventionSummaryProps) => {
  return (
    <TouchableOpacity
      onPress={() => onPress?.(id)}
      activeOpacity={TouchableActiveOpacity.light}
      style={style}
      testID="intervention-summary"
    >
      <SummaryCard
        title={name}
        subtitle={subtitle}
        subtitleVariant={TextVariants.XS}
        leftAccessory={
          <Icon name="chatbubbles-outline" color={Colors.darkGrey3} size={HOMEWORK_ICON_SIZE} />
        }
      >
        <View style={styles.interventionSummaryContainer}>
          <IterationSummary title={`${scheduledCounter}x`} subtitle="scheduled" />
          <IterationSummary title={`${startedAnsweringCounter}x`} subtitle="started answering" />
          <IterationSummary
            highlighted
            title={`${finishedAnsweringCouter}x`}
            subtitle="answered everything"
            showRightArrow={false}
          />
        </View>
      </SummaryCard>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  interventionSummaryContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 16,
  },
});
