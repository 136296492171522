import React, { SVGProps } from 'react';

export const MedicalDeviceRefIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <path
        d="m15.873 46.945v105.4h168.51v-105.4h-168.51zm5 5h158.51v95.402h-158.51v-95.402zm13.377 21.277v52.727h10.594v-18.699h6.1367 6.2305l1.0605 0.8125c2.05 1.5715 2.093 1.7813 2.1758 10.375l0.074218 7.5117h11.922c0.1632 0-0.27539-0.57584-0.72852-0.94922-0.16564-0.13649-0.39761-0.41575-0.50781-1.0137-0.0619-0.33588-0.072472-0.73466-0.076172-1.3789-0.007309-1.2666-0.13086-3.124-0.13086-5.9629 0-6.0475-0.054743-6.9783-0.50781-8.6133-0.59123-2.1335-1.1843-3.3666-2.5527-5.3086l-0.98828-1.4043 0.91406-1.1973c2.0688-2.7102 2.8885-5.1887 3.0625-9.2539 0.1459-3.4083-0.16969-5.1146-1.4531-7.8672-2.0501-4.3969-5.504-7.4102-10.35-9.0273-1.7162-0.57277-1.9731-0.58446-13.312-0.66602l-11.562-0.083985zm47.561 0.10742v26.359 26.26h18.25 18.375v-10.389h-26.227v-10.697h23.541v-10.51h-23.541v-10.666h26.227v-10.357h-36.625zm47.564 0-0.06445 26.357-0.0625 26.262h10.316v-21.086h21.186v-10.51h-21.186v-10.666h26.311v-10.357h-36.5zm-80.312 10.494c6.2355-0.10979 7.9281 0.20288 9.7344 2.0957 0.99302 1.0406 1.6918 2.7725 1.6953 4.2051 0.005532 2.2689-1.659 4.8567-3.7422 5.8184-0.69481 0.32073-1.9815 0.42319-6.25 0.5-2.9565 0.05321-5.6562-0.001953-5.6562-0.001953v-12.543l4.2188-0.074219z"
        fill="#000"
      />
    </svg>
  );
};
