import { gql, useMutation } from '@apollo/client';

const DELETE_PATIENT_BEHAVIOURAL_ACTION = gql`
  mutation ($signUpCode: String!, $id: ID!) {
    deletePatientBehavioralAction(code: $signUpCode, behavioralActionId: $id)
  }
`;

interface IDeletePatientBehaviouralactionVariables {
  signUpCode: string;
  id: string;
}

export const useDeleteBehaviouralActionMutation = () => {
  return useMutation<undefined, IDeletePatientBehaviouralactionVariables>(
    DELETE_PATIENT_BEHAVIOURAL_ACTION,
    {
      refetchQueries: ['getPatientScreenQuery'],
      awaitRefetchQueries: true,
    }
  );
};
