import { gql } from '@apollo/client';
import { BehaviouralActionTypes, IBehaviouralActionStatus } from './models/IBehaviouralAction';
import { IRecurrenceInput } from './models/IRecurrenceInput';
import { ITherapistDigestMetadata } from './models/ITherapistDigestMetadata';
import { MoodEventType } from './models/MoodEventType';
import { IWeeklyTriggerEvery } from './models/QuestionnaireTriggers';

export interface IEmotionFragment {
  id: string;
  stringValue: string;
  valence: number;
  emoji?: string;
  color?: string;
}
const emotion = gql`
  fragment Emotion on Emotion {
    id
    stringValue
    valence
    emoji
    color
  }
`;

export interface IPendingCopingStrategyFragment {
  id: string;
  emotion: IEmotionFragment;
  createdAt: Date;
}
const pendingCopingStrategy = gql`
  fragment PendingCopingStrategy on PendingCopingStrategy {
    id
    emotion {
      ...Emotion
    }
    createdAt
  }
  ${emotion}
`;

export interface IRecurrenceAppointmentFragment {
  id: string;
  rrule: string;
  dtstart?: string;
  rdate?: string;
  exrule?: string;
  exdate?: string;
  createdAt: Date;
}
const recurrenceAppointment = gql`
  fragment RecurrenceAppointment on RecurrenceAppointment {
    id
    rrule
    dtstart
    rdate
    exrule
    exdate
    createdAt
  }
`;

interface IInterventionStep {
  id: string;
  prompt: string;
}

interface IInterventionAnswer {
  id: string;
  interventionStepId: string;
  answers: string[];
  emotion?: {
    emoji: string;
    stringValue: string;
  };
}

export enum Period {
  afternoon = 'afternoon',
  evening = 'evening',
  morning = 'morning',
}

interface RecurrenceTrigger {
  __typename: 'RecurrenceTrigger';
  period?: Period;
  rrule: string;
}

export enum InterventionStage {
  assigned = 'assigned',
  iteration_available = 'iteration_available',
  iteration_completed = 'iteration_completed',
  iteration_expired = 'iteration_expired',
  iteration_started = 'iteration_started',
}

interface InterventionStageTrigger {
  __typename: 'InterventionStageTrigger';
  stage: InterventionStage;
}

export type InterventionTrigger = InterventionStageTrigger | RecurrenceTrigger;

export interface IPatientInterventionFragment {
  id: string;
  createdAt: Date;
  intervention: {
    id: string;
    name: string;
    steps: IInterventionStep[];
  };
  triggers: InterventionTrigger[];
  iterations: {
    id: string;
    firstAskedAt: Date | null;
    scheduledAt: Date;
    answers: IInterventionAnswer[];
  }[];
}

export interface IPatientFragment {
  id: string;
  name?: string;
  email: string;
  signupCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsData: any;
  createdAt: Date;
  sessionDate?: Date;
  hasSessionDate: boolean;
  unreadReportsCounter: number;
  weeklyQuestionnaires: string[];
  weekliesTriggeredEvery: IWeeklyTriggerEvery[];
  homeworks: string[];
  recurrenceAppointment?: IRecurrenceAppointmentFragment;
  pendingCopingStrategies: {
    data: IPendingCopingStrategyFragment[];
  };
}
const patient = gql`
  fragment Patient on Patient {
    id
    name
    email
    signupCode
    analyticsData
    createdAt
    sessionDate
    hasSessionDate
    unreadReportsCounter
    weeklyQuestionnaires
    weekliesTriggeredEvery
    homeworks
    recurrenceAppointment {
      ...RecurrenceAppointment
    }
    pendingCopingStrategies {
      data {
        ...PendingCopingStrategy
      }
    }
  }
  ${pendingCopingStrategy}
  ${recurrenceAppointment}
`;

export interface ICopingStrategyFragment {
  id: string;
  message: string;
  emotion: IEmotionFragment;
  createdAt: Date;
}
const copingStrategy = gql`
  fragment CopingStrategy on CopingStrategy {
    id
    message
    emotion {
      ...Emotion
    }
    createdAt
  }
  ${emotion}
`;

export interface ICopingStrategyFragmentWithCounters extends ICopingStrategyFragment {
  likedCounter: number;
  triggeredCounter: number;
  emotionCounter: number;
}
const copingStrategyWithCounters = gql`
  fragment CopingStrategyWithCounters on CopingStrategy {
    likedCounter(fromDate: $fromDate, toDate: $toDate)
    triggeredCounter(fromDate: $fromDate, toDate: $toDate)
    emotionCounter(fromDate: $fromDate, toDate: $toDate)
  }
`;

export interface ILocalCopingStrategyFragment {
  id: string;
  title: string;
  description: string;
  type: string;
  createdAt: Date;
  triggeredCounter: number;
}
const localCopingStrategy = gql`
  fragment LocalCopingStrategy on LocalCopingStrategy {
    id
    description
    title
    type
    createdAt
    triggeredCounter(fromDate: $fromDate, toDate: $toDate)
  }
  ${emotion}
`;

export interface ICopingStrategyFeedbackFragment {
  id: string;
  liked: boolean;
  createdAt: Date;
  copingStrategyId: string;
  copingStrategy: {
    id: string;
    message: string;
  };
  localCopingStrategyId: string;
  localCopingStrategy: ILocalCopingStrategyFragment;
  emotion: IEmotionFragment;
}
const copingStrategyFeedback = gql`
  fragment CopingStrategyFeedback on CopingStrategyFeedback {
    id
    liked
    createdAt
    copingStrategyId
    copingStrategy {
      id
      message
    }
    localCopingStrategyId
    localCopingStrategy {
      ...LocalCopingStrategy
    }
    emotion {
      ...Emotion
    }
  }

  ${localCopingStrategy}
  ${emotion}
`;

export interface IMoodFragment {
  id: string;
  weight: number;
  emotion: IEmotionFragment;
}
const mood = gql`
  fragment Mood on Mood {
    id
    weight
    emotion {
      ...Emotion
    }
  }
  ${emotion}
`;

export interface IWeeklyQuestionnaireScoreFragment {
  id: string;
  type: MoodEventType;
  value: number;
  subscalesScore?: Array<{
    value: number;
    subscales: string;
  }>;
  date: Date;
}
const weeklyQuestionnaireScore = gql`
  fragment WeeklyQuestionnaireScore on WeeklyQuestionnaireScore {
    id
    type
    value
    subscalesScore {
      value
      subscales
    }
    date
  }
`;

export interface IMoodEventFragment {
  id: string;
  type: MoodEventType;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
}
const moodEvent = gql`
  fragment MoodEvent on MoodEvent {
    id
    type
    startDate
    endDate
    createdAt
  }
`;

export interface IMoodQuestionnairePropertyFragment {
  id: string;
  title: string;
  answer: string;
  points?: number;
}
const moodQuestionnaireProperty = gql`
  fragment MoodQuestionnaireProperty on MoodQuestionnaireProperty {
    id
    title
    answer
    points
  }
`;

export interface IMoodQuestionnaireFragment {
  id: string;
  moodEventId: string;
  intensity?: number;
  createdAt: Date;
  moods?: IMoodFragment[];
  moodEvent: IMoodEventFragment;
  moodQuestionnaireProperties?: IMoodQuestionnairePropertyFragment[];
}
const moodQuestionnaire = gql`
  fragment MoodQuestionnaire on MoodQuestionnaire {
    id
    moodEventId
    intensity
    createdAt
    moods {
      ...Mood
    }
    moodEvent {
      ...MoodEvent
    }
    moodQuestionnaireProperties {
      ...MoodQuestionnaireProperty
    }
  }
  ${mood}
  ${moodEvent}
  ${moodQuestionnaireProperty}
`;

export interface ITherapistDigestFragment {
  __typename?: 'TherapistDigest';
  id: string;
  metadata?: ITherapistDigestMetadata;
  fromDate?: Date;
  toDate?: Date;
  createdAt: Date;
  wasRead: boolean;
  dailyLogsCounter?: {
    value: number;
  };
  dailyLogs?: {
    data: IMoodQuestionnaireFragment[];
  };
  weeklyScores?: {
    data: IWeeklyQuestionnaireScoreFragment[];
  };
}
const therapistDigest = gql`
  fragment TherapistDigest on TherapistDigest {
    id
    metadata
    fromDate
    toDate
    createdAt
    wasRead
    dailyLogsCounter {
      value
    }
    dailyLogs {
      data {
        ...MoodQuestionnaire
      }
    }
    weeklyScores {
      data {
        ...WeeklyQuestionnaireScore
      }
    }
  }
  ${moodQuestionnaire}
  ${weeklyQuestionnaireScore}
`;

export interface IBehaviouralActionFragment {
  id: string;
  type: BehaviouralActionTypes;
  status: IBehaviouralActionStatus;
  activity: string;
  startDate?: Date;
  endDate?: Date;
  createdAt?: Date;
  isSkipped?: boolean;
  skippedReason?: string;
  recurrenceId?: string;
  recurrence?: IRecurrenceInput;
  logs?: {
    data: IMoodQuestionnaireFragment[];
  };
}
const behaviouralAction = gql`
  fragment BehaviouralAction on BehavioralAction {
    id
    type
    status
    activity
    startDate
    endDate
    createdAt
    isSkipped
    skippedReason
    recurrenceId
    recurrence {
      dtstart
      rrule
    }
    logs(limit: 10, offset: 0) {
      data {
        ...MoodQuestionnaire
      }
    }
  }
  ${moodQuestionnaire}
`;

export enum MediaType {
  image = 'image',
  pdf = 'pdf',
}

export interface IInterventionFragment {
  id: string;
  name: string;
  description?: string;
  draft: boolean;
  steps: {
    id: string;
    prompt: string;
    type: StepType;
    answers?: string[];
    mediaType?: MediaType;
    mediaUrl?: string;
  }[];
}

export enum PsychoedBlockTypeEnum {
  text = 'text',
  image = 'image',
}

export type PsychoedBlock = {
  id: string | number;
  position: number;
  type: PsychoedBlockTypeEnum;
  text?: string;
  image?: PsychoedImage | null;
};

export type PsychoedSlide = {
  id: string | number;
  title: string;
  subtitle?: string;
  headerImage?: PsychoedImage | null;
  blocks: PsychoedBlock[];
  hidden?: boolean;
  position: number;
};

export type PsychoedImage = {
  id: string | number;
  url: string;
  preferredHeight?: number;
  preferredWidth?: number;
  svg: boolean;
  altText: string;
};

export interface IPsychoedFragment {
  id: string;
  name: string;
  estimatedMinutes: number;
  description?: string;
  draft: boolean;
  archived: boolean;

  slides: PsychoedSlide[];
  thumbnail?: PsychoedImage | null;
}

export type IInterventionOrPsychoedFragment =
  | (IInterventionFragment & { type: 'intervention' })
  | (IPsychoedFragment & { type: 'intervention' });

const intervention = gql`
  fragment Intervention on Intervention {
    id
    name
    description
    draft
    steps {
      id
      prompt
      type
      answers
      mediaType
      mediaUrl
    }
  }
`;

export enum StepType {
  informational = 'informational',
  freeText = 'free_text',
  singleChoice = 'single_choice',
  multipleChoice = 'multiple_choice',
  emotion = 'emotion',
  thought = 'thought',
  activity = 'activity',
  slider = 'slider',
  media = 'media',
}

export enum StepMediaType {
  image = 'image',
  pdf = 'pdf',
}

export interface IInterventionStepFragment {
  id: string;
  interventionId: string;
  prompt: string;
  type: StepType;
  name: string;
  mediaType?: StepMediaType;
  mediaUrl?: string;
}

const interventionStep = gql`
  fragment InterventionStep on InterventionStep {
    id
    prompt
    type
    answers
    mediaType
    mediaUrl
  }
`;

export const fragments = {
  emotion,
  pendingCopingStrategy,
  patient,
  copingStrategy,
  copingStrategyWithCounters,
  localCopingStrategy,
  copingStrategyFeedback,
  behaviouralAction,
  recurrenceAppointment,
  therapistDigest,
  mood,
  moodEvent,
  weeklyQuestionnaireScore,
  moodQuestionnaireProperty,
  moodQuestionnaire,
  intervention,
  interventionStep,
};
