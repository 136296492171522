import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ModalContent } from '../LimbicModal/ModalContent';

const body =
  "The Impact of Events Scale (revised) is a 22-item self-report measure that assesses subjective distress caused by traumatic events. Please enter the trauma you'd like to record for your client. (this is optional)";

interface SetIESRTraumaProps {
  trauma?: string;
  formProps: UseFormMethods<{ message: string }>;
  onSubmit: () => void;
}

export const SetIESRTrauma = ({ onSubmit, formProps }: SetIESRTraumaProps) => {
  const { control } = formProps;

  return (
    <ModalContent>
      <ModalContent.Title>IES-R trauma</ModalContent.Title>
      <ModalContent.Body>{body}</ModalContent.Body>
      <Controller
        control={control}
        name="message"
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <ModalContent.TextArea
            testID="set-iesr-trauma-text-input"
            placeholder="Type the trauma here"
            value={value}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
          />
        )}
      />
      <ModalContent.CTAButton
        title="Set the frequency"
        onPress={onSubmit}
        testID="set-iesr-trauma-submit-button"
      />
    </ModalContent>
  );
};
