import React from 'react';
import { MainButton, MainButtonProps } from './MainButton';
import { noop } from '../../utils/noop';
import { WithTestID } from '../../utils/withTestID';

export interface IMainButton extends MainButtonProps {
  variant?: 'main';
}

export type ButtonProps = WithTestID<IMainButton>;

export const Button = (props: ButtonProps) => {
  const { variant, ...restProps } = props;
  switch (variant) {
    case 'main':
      return <MainButton {...restProps} />;
    default:
      throw new Error(`Button variant is not supported [${variant}]`);
  }
};

// noinspection JSUnusedGlobalSymbols
Button.defaultProps = {
  variant: 'main',
  fixed: false,
  small: false,
  neutral: false,
  disabled: false,
  onPress: noop,
};
