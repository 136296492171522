import { gql, useMutation } from '@apollo/client';

export interface IUpdateClinicianResponse {
  updateClinician: { id: string; name: string; email: string };
}

const UPDATE_CLINICIAN = gql`
  mutation updateClinician($name: String, $email: String) {
    updateClinician(name: $name, email: $email) {
      id
      name
      email
    }
  }
`;

export interface IUpdateClinicianVariables {
  name?: string;
  email?: string;
}

export const useUpdateClinicianMutation = () => {
  return useMutation<IUpdateClinicianResponse, IUpdateClinicianVariables>(UPDATE_CLINICIAN);
};
