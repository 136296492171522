import React from 'react';
import { View, StyleSheet } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const RIGHT_ARROW_SIZE = 16;

interface CopingStrategyResponseProps {
  title: string;
  subtitle: string;
  highlighted?: boolean;
  showRightArrow?: boolean;
}

export const CopingStrategyResponse = ({
  title,
  subtitle,
  highlighted = false,
  showRightArrow = true,
}: CopingStrategyResponseProps) => {
  const textColor = highlighted ? Colors.white : Colors.black;
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.innerContainer,
          ...(highlighted ? [{ backgroundColor: Colors.green3 }] : []),
        ]}
      >
        <LimbicText variant={TextVariants.L} color={textColor} bold>
          {title}
        </LimbicText>
        <LimbicText variant={TextVariants.S} color={textColor}>
          {subtitle}
        </LimbicText>
      </View>
      {showRightArrow ? (
        <View style={styles.rightArrowContainer}>
          <Ionicons name="chevron-forward-outline" color={Colors.black} size={RIGHT_ARROW_SIZE} />
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContainer: {
    alignSelf: 'flex-start',
    borderRadius: 15,
    width: 80,
    padding: 10,
  },
  rightArrowContainer: {
    marginRight: 10,
  },
});
