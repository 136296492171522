import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface InterventionRowProps {
  title: string;
  subtitle: string;
  time: string;
  questionnaireProperties?: React.ReactNode;
  showDate?: boolean;
}

export const InterventionRow = ({
  title,
  subtitle,
  time,
  questionnaireProperties,
  showDate,
}: InterventionRowProps) => {
  return (
    <View style={styles.container} testID="intervention-row">
      <View style={styles.dateContainer}>
        {showDate ? (
          <>
            <LimbicText bold variant={TextVariants.L} color={Colors.black}>
              {title}
            </LimbicText>
            <LimbicText bold variant={TextVariants.S} color={Colors.black}>
              {subtitle}
            </LimbicText>
          </>
        ) : (
          <View style={styles.timeSpacer} />
        )}
        <LimbicText bold variant={TextVariants.S} color={Colors.darkGrey1}>
          {time}
        </LimbicText>
      </View>
      <View style={styles.interventionStepsContainer}>{questionnaireProperties}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  dateContainer: {
    flexBasis: '15%',
  },
  interventionStepsContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  timeSpacer: {
    height: 16,
  },
});
