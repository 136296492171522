import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import dayjs from 'dayjs';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { daysFromNow, isPast, isToday } from '../../utils/date';

interface Props {
  sessionDate?: Date;
  style?: ViewStyle;
  bottomAccessory?: React.ReactNode;
  showNextSessionInfo?: boolean;
}

export const NextReportCard = ({
  sessionDate,
  style,
  bottomAccessory,
  showNextSessionInfo = true,
}: Props) => {
  const sessionString = dayjs(sessionDate).format('DD MMMM').toLowerCase();
  const reportDate = dayjs(sessionDate).subtract(1, 'day');
  const daysLeftForReport = daysFromNow(reportDate);
  const isReportDueToday = isToday(reportDate);
  const isReportPastDue = isPast(reportDate);
  const isSessionDatePast = isPast(sessionDate);
  const isNoSessionCard = sessionDate ? isSessionDatePast : true;

  return (
    <View style={[styles.cardContainer, style]}>
      {showNextSessionInfo ? (
        <>
          {isNoSessionCard ? (
            <>
              <LimbicText
                variant={TextVariants.L}
                color={Colors.darkGrey1}
                style={styles.noSessionTitle}
              >
                No next session planned
              </LimbicText>
              <LimbicText
                variant={TextVariants.M}
                color={Colors.darkGrey1}
                style={styles.textMargin}
              >
                Set a date to receive a report
              </LimbicText>
            </>
          ) : (
            <>
              <LimbicText variant={TextVariants.L} color={Colors.darkGrey1}>
                next session
              </LimbicText>
              <LimbicText bold variant={TextVariants.L} color={Colors.darkGrey1}>
                {sessionString}
              </LimbicText>
              <LimbicText
                variant={TextVariants.M}
                color={Colors.darkGrey1}
                style={styles.textMargin}
              >
                report {isReportPastDue && !isReportDueToday ? '' : 'will be'} delivered{' '}
                {isReportDueToday ? 'today' : daysLeftForReport}
              </LimbicText>
            </>
          )}
        </>
      ) : null}
      {bottomAccessory}
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 205,
    minHeight: 264,
    flexGrow: 1,
    padding: 16,
    marginRight: 16,
    borderRadius: 16,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: Colors.grey3,
  },
  textMargin: {
    marginRight: 24,
  },
  noSessionTitle: {
    fontSize: 21,
  },
});
