import React, { ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { Colors } from '../../config/colors';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface CheckRowProps {
  checked: boolean;
  content: string;
  footer?: string;
  onPress: () => void;
  children?: ReactElement;
}

export const CheckRow = ({
  content,
  onPress,
  checked,
  children,
  footer,
  testID,
}: WithTestID<CheckRowProps>) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID={testID}
        onPress={onPress}
        style={[checked ? styles.checked : styles.unchecked]}
      >
        {checked && <Icon name="check" size={18} color={Colors.white} testID="check-mark" />}
      </TouchableOpacity>
      <LimbicText color={checked ? Colors.darkGrey2 : Colors.darkGrey3} variant={TextVariants.M}>
        {content}
      </LimbicText>
      {children}
      <LimbicText color={checked ? Colors.darkGrey2 : Colors.darkGrey3} variant={TextVariants.M}>
        {footer}
      </LimbicText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  checked: {
    width: 22,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginRight: 11,
    backgroundColor: Colors.pink2,
  },
  unchecked: {
    width: 20,
    height: 20,
    borderRadius: 8,
    marginRight: 11,
    borderWidth: 2,
    borderColor: Colors.grey3,
    backgroundColor: Colors.white,
  },
});
