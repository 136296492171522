import { Dayjs } from 'dayjs';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { Button } from '../Button/Button';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SetBehaviouralActionDayProps {
  isTomorrow: boolean;
  title: string;
  date?: Dayjs | null;
  onPressedTomorrow: () => void;
  onPressedPickADate: () => void;
  onSubmit: () => void;
  onLetPatientPickDate: () => void;
  days: React.ReactNode;
}

export const SetBehaviouralActionDay = ({
  isTomorrow,
  title,
  date,
  days,
  onPressedPickADate,
  onPressedTomorrow,
  onLetPatientPickDate,
  onSubmit,
}: SetBehaviouralActionDayProps) => {
  return (
    <ModalContent>
      <ModalContent.Title>What day?</ModalContent.Title>
      <View style={styles.datesContainer}>
        <Button
          testID="behavioural-action-tomorrow-button"
          small
          fixed
          title="tomorrow"
          style={[
            styles.pickDateButton,
            styles.pickDateButtonSpacer,
            isTomorrow && styles.pickDateButtonActive,
          ]}
          textStyle={[
            styles.pickDateButtonText, //
            isTomorrow && styles.pickDateButtonTextActive,
          ]}
          onPress={onPressedTomorrow}
        />

        <Button
          testID="behavioural-action-pick-a-date-button"
          small
          fixed
          title={title}
          icon="calendar"
          iconProps={{ size: 15, color: date ? Colors.white : Colors.darkGrey2 }}
          style={[styles.pickDateButton, !!date && styles.pickDateButtonActive]}
          textStyle={[
            styles.pickDateButtonText, //
            !!date && styles.pickDateButtonTextActive,
          ]}
          onPress={onPressedPickADate}
        />
      </View>

      <ModalContent.Body style={styles.bodyTitle}>Do you want to repeat it?</ModalContent.Body>
      <ModalContent.Body>
        Limbic will repeat the activity on the selected days until you delete the activity
      </ModalContent.Body>

      {days ? <View style={styles.daysContainer}>{days}</View> : null}

      <View style={styles.buttonContainer}>
        <ModalContent.CTAButton
          testID="behavioural-action-schedule-activity-button"
          title={date ? 'Set the time' : 'Schedule activity'}
          icon={date ? 'chevron-right' : undefined}
          style={{ flexGrow: 1, marginRight: 10 }}
          onPress={onSubmit}
        />
        <ModalContent.CTAButton
          testID="behavioural-action-let-patient-pick-date-button"
          title="Let patient pick the date"
          icon={date ? 'chevron-right' : undefined}
          style={{ flexGrow: 1 }}
          onPress={onLetPatientPickDate}
        />
      </View>
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  datesContainer: {
    flexDirection: 'row',
    paddingTop: 8,
    paddingBottom: 16,
  },
  pickDateButton: {
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  pickDateButtonSpacer: {
    marginRight: 16,
  },
  pickDateButtonActive: {
    borderColor: Colors.pink1,
    backgroundColor: Colors.pink1,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
  pickDateButtonTextActive: {
    color: Colors.white,
  },
  bodyTitle: {
    color: Colors.darkGrey2,
    paddingBottom: 0,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 24,
  },
});
