/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLRequest } from '@apollo/client/link/core';
import { GRAPHQL_ENDPOINT, TITAN_GRAPHQL_ENDPOINT } from '@limbic-for-therapists/shared';
import { STAGE } from '../consts/stage';
import { getAuthToken } from './helpers/authToken';

const cache = new InMemoryCache();
const titanCache = new InMemoryCache();

const httpLink = new HttpLink({ uri: GRAPHQL_ENDPOINT[STAGE] });
const titanHttpLink = new HttpLink({ uri: TITAN_GRAPHQL_ENDPOINT[STAGE] });

const authLink = setContext(async (_: GraphQLRequest, prevContext: Record<string, any>) => {
  const { headers } = prevContext;
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
  };
});

export const client = new ApolloClient({
  name: 'Limbic for Therapists',
  link: from([authLink, httpLink]),
  cache,
});

export const titanClient = new ApolloClient({
  name: 'New Care Backend Titan Server',
  link: from([authLink, titanHttpLink]),
  cache: titanCache,
});
