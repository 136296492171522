import dayjs, { Dayjs } from 'dayjs';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getLongDate } from '@limbic-for-therapists/shared';
import { CustomFrequency } from '../models/customFrequency';
import { getFrequencyString } from '../utils/recurrence';
import { RecurrenceFrequency } from '../models/IRecurrenceInput';

const getFrequencyInformation = (frequency: CustomFrequency | null) => {
  const isCustom = !!frequency?.days.length;
  return {
    isCustom: isCustom,
    isNone: frequency === null,
    isWeekly: !isCustom && frequency?.duration === 1,
    isFortnightly: !isCustom && frequency?.duration === 2,
    is4Weeks: !isCustom && frequency?.duration === 4,
  };
};

export const useSetSessionDate = () => {
  const now = useRef<Dayjs>(dayjs());
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [frequency, setFrequency] = useState<CustomFrequency | null>(null);

  const frequencyInformation = getFrequencyInformation(frequency);
  const frequencyString = getFrequencyString(
    frequency?.duration || undefined,
    frequency?.type || RecurrenceFrequency.Week,
    frequency?.days || []
  );

  const formProps = useForm<CustomFrequency>({
    defaultValues: {
      duration: frequency?.duration || 1,
      type: frequency?.type || RecurrenceFrequency.Week,
      days: frequency?.days || [],
    },
  });

  const isUpcomingSession = date ? now.current.isBefore(date) : false;
  const buttonTitle = date ? getLongDate(date) : 'pick a date';

  return {
    isUpcomingSession,
    buttonTitle,
    formProps,
    date,
    frequency,
    setFrequency,
    setDate,
    frequencyString,
    frequencyInformation,
  };
};
