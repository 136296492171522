import { Dayjs } from 'dayjs';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { CheckRow, Header, LimbicText, TextVariants } from '..';
import { Colors } from '../../config';
import { Button } from '../Button/Button';
import { ModalContent } from '../LimbicModal/ModalContent';

enum Period {
  morning = 'morning',
  afternoon = 'afternoon',
  evening = 'evening',
}

interface SetBehaviouralActionDayProps {
  isToday: boolean;
  startTitle: string;
  endTitle?: string;
  actionTitle: string;
  startDate?: Dayjs;
  endDate?: Dayjs;
  isRecurring: boolean;
  periods: Set<Period>;
  isReady: boolean;
  onToggleRecurring: () => void;
  onPressedToday: () => void;
  onPressedPickAStartDate: () => void;
  onPressedPickAnEndDate: () => void;
  onSubmit: () => void;
  onTogglePeriod: (period: Period) => void;
  days: React.ReactNode;
  newPatientName?: string;
}

export const SetRecurrenceRule = ({
  isToday,
  startTitle,
  endTitle,
  startDate,
  endDate,
  days,
  actionTitle,
  isRecurring,
  periods,
  isReady,
  onToggleRecurring,
  onPressedPickAStartDate,
  onPressedPickAnEndDate,
  onPressedToday,
  onSubmit,
  onTogglePeriod,
  newPatientName,
}: SetBehaviouralActionDayProps) => {
  return (
    <View>
      <Header
        tag="Step 2"
        title={
          newPatientName ? `Schedule the Activity for ${newPatientName}` : 'Schedule the Activity'
        }
        subtitle={'Set a schedule for limbic to prompt the patient with this activity'}
      />

      <View style={[styles.datesContainer, styles.section]}>
        <Button
          testID="behavioural-action-tomorrow-button"
          small
          fixed
          title="Today"
          style={[
            styles.pickDateButton,
            styles.pickDateButtonSpacer,
            isToday && styles.pickDateButtonActive,
          ]}
          textStyle={[
            styles.pickDateButtonText, //
            isToday && styles.pickDateButtonTextActive,
          ]}
          onPress={onPressedToday}
        />

        <Button
          testID="behavioural-action-pick-a-date-button"
          small
          fixed
          title={startTitle ?? (isRecurring ? 'Start date' : 'Date')}
          icon="calendar"
          iconProps={{ size: 15, color: startDate ? Colors.white : Colors.darkGrey2 }}
          style={[styles.pickDateButton, !!startDate && styles.pickDateButtonActive]}
          textStyle={[
            styles.pickDateButtonText, //
            !!startDate && styles.pickDateButtonTextActive,
          ]}
          onPress={onPressedPickAStartDate}
        />
      </View>
      <View style={[styles.periods, styles.section]}>
        <CheckRow
          content="Morning"
          checked={periods.has(Period.morning)}
          onPress={() => onTogglePeriod(Period.morning)}
        ></CheckRow>
        <CheckRow
          content="Afternoon"
          checked={periods.has(Period.afternoon)}
          onPress={() => onTogglePeriod(Period.afternoon)}
        ></CheckRow>
        <CheckRow
          content="Evening"
          checked={periods.has(Period.evening)}
          onPress={() => onTogglePeriod(Period.evening)}
        ></CheckRow>
      </View>

      <View style={styles.repeat}>
        <CheckRow
          content="Does it repeat?"
          checked={isRecurring}
          onPress={onToggleRecurring}
        ></CheckRow>
      </View>

      {isRecurring && (
        <View style={styles.recurringOptions}>
          <View style={styles.section}>
            <View style={styles.subSection}>
              <LimbicText variant={TextVariants.M} color={Colors.darkGrey1}>
                You can pick multiple times in the week to repeat.
              </LimbicText>
            </View>

            {days ? <View style={styles.daysContainer}>{days}</View> : null}
          </View>

          <View style={styles.section}>
            <View style={styles.subSection}>
              <LimbicText variant={TextVariants.M}>When should the activity end?</LimbicText>
            </View>

            <Button
              testID="behavioural-action-pick-a-date-button"
              small
              fixed
              title={endTitle ?? 'End date'}
              icon="calendar"
              iconProps={{ size: 15, color: endDate ? Colors.white : Colors.darkGrey2 }}
              style={[styles.pickDateButton, !!endDate && styles.pickDateButtonActive]}
              textStyle={[
                styles.pickDateButtonText, //
                !!endDate && styles.pickDateButtonTextActive,
              ]}
              onPress={onPressedPickAnEndDate}
            />
          </View>
        </View>
      )}

      <View style={{ marginTop: 24 }}>
        <ModalContent.CTAButton
          testID="recurrence-rule-next-button"
          title={actionTitle}
          icon={'chevron-right'}
          disabled={!isReady}
          style={{ flexGrow: 1, marginRight: 10 }}
          onPress={onSubmit}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  datesContainer: {
    flexDirection: 'row',
  },
  pickDateButton: {
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
    alignSelf: 'flex-start',
  },
  pickDateButtonSpacer: {
    marginRight: 16,
  },
  pickDateButtonActive: {
    borderColor: Colors.pink1,
    backgroundColor: Colors.pink1,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
  pickDateButtonTextActive: {
    color: Colors.white,
  },
  bodyTitle: {
    color: Colors.darkGrey2,
    paddingBottom: 0,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  periods: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  repeat: {},
  recurringOptions: {},
  section: {
    marginBottom: 24,
  },
  subSection: {
    marginBottom: 8,
  },
});
