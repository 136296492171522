import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { Integrations } from '@sentry/tracing';
import { AppRegistry } from 'react-native';
import App from './App';
import { STAGE } from './consts/stage';
import { injectFonts } from './utils/injectFonts';
import { Stage } from '@limbic-for-therapists/shared';

const SUPPRESSED_WARNINGS = ['Invalid DOM property'];
const isSuppressed = (m: any) => SUPPRESSED_WARNINGS.some((w) => m.includes?.(w));
const cWarn = console.warn;
const cError = console.error;
console.warn = (m: string, ...args: any[]) => !isSuppressed(m) && cWarn(m, ...args);
console.error = (m: string, ...args: any[]) => !isSuppressed(m) && cError(m, ...args);

// Error logging
Sentry.init({
  dsn: 'https://c112c6ea92744262bda83021c0524342@o138988.ingest.sentry.io/5653846',
  integrations: [new Integrations.BrowserTracing()],
  environment: STAGE,
  tracesSampleRate: 1.0,
});

// Mixpanel Tracking for production builds
const MIXPANEL_TOKENS = {
  production: '30835a1fd182b0cada54b0b5668e2a8c',
  staging: 'd1c90518c6989ff174426432e18cc2b7',
};

function isMixpanelEnv(stage: Stage): stage is keyof typeof MIXPANEL_TOKENS {
  return Object.keys(MIXPANEL_TOKENS).includes(STAGE);
}

if (isMixpanelEnv(STAGE)) {
  mixpanel.init(MIXPANEL_TOKENS[STAGE]);
}

injectFonts();

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
