import {
  IInterventionFragment,
  IPsychoedFragment,
  MediaType,
  StepType,
} from '@limbic-for-therapists/backend';
import {
  AutoHeightImage,
  Colors,
  EmptyState,
  LimbicText,
  TextVariants,
} from '@limbic-for-therapists/components';
import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { getFormattedTextSpans } from '../../utils/textFormatting';
import { ChatPreview, MessageAuthor, UserResponseMessage } from '../ChatPreview/ChatPreviewV3';
import { OutboundLink } from '../Link/Link';

function UserResponse({ step }: { step: IInterventionFragment['steps'][number] }): JSX.Element {
  switch (step.type) {
    case StepType.freeText:
      return (
        <UserResponseMessage>The patient will respond with a free text answer.</UserResponseMessage>
      );
    case StepType.thought:
      return <UserResponseMessage>The patient will respond with a thought.</UserResponseMessage>;
    case StepType.activity:
      return <UserResponseMessage>The patient will respond with an activity.</UserResponseMessage>;
    case StepType.emotion:
      return <UserResponseMessage>The patient will respond with an emotion.</UserResponseMessage>;
    case StepType.multipleChoice:
    case StepType.singleChoice:
      return (
        <View style={styles.answerList}>
          {step.answers?.map((answer) => (
            <View key={answer} style={styles.answerPill}>
              <UserResponseMessage>{answer}</UserResponseMessage>
            </View>
          ))}
        </View>
      );
    default:
      return <UserResponseMessage>Unknown step with type {step.type}</UserResponseMessage>;
  }
}

export function InterventionPreview({
  intervention,
}: {
  intervention?: IInterventionFragment | IPsychoedFragment;
}) {
  return (
    <React.Fragment>
      <ScrollView style={styles.previewContainer}>
        {intervention && (
          <LimbicText variant={TextVariants.M}>
            Preview &ldquo;{intervention?.name}&rdquo;
          </LimbicText>
        )}
        {(intervention as IInterventionFragment)?.steps ? (
          <ChatPreview
            messages={(intervention as IInterventionFragment)?.steps.flatMap((step) => {
              if (step.type === StepType.media) {
                if (!step.mediaUrl) {
                  throw new Error('Unreachable');
                }
                if (step.mediaType === MediaType.image) {
                  return [
                    {
                      value: <AutoHeightImage width={220} uri={step.mediaUrl} />,
                      author: MessageAuthor.limbic,
                    },
                  ];
                } else if (step.mediaType === MediaType.pdf) {
                  return [
                    {
                      value: <OutboundLink href={step.mediaUrl}>Download PDF</OutboundLink>,
                      author: MessageAuthor.limbic,
                    },
                  ];
                } else {
                  throw new Error('Unreachable');
                }
              }

              const limbicMessage = {
                value: (
                  <Text>
                    {getFormattedTextSpans(step.prompt).map((t, key) => (
                      <span
                        key={key}
                        style={{
                          fontWeight: t.bold ? 'bold' : '300',
                          fontStyle: t.italic ? 'italic' : 'normal',
                          textDecorationLine: t.underline || t.link ? 'underline' : 'none',
                          color: t.link ? Colors.pink5 : undefined,
                        }}
                      >
                        {t.text}
                      </span>
                    ))}
                  </Text>
                ),
                author: MessageAuthor.limbic,
              };

              if (step.type === StepType.informational) {
                return [limbicMessage];
              }

              return [
                limbicMessage,
                {
                  value: <UserResponse step={step} />,
                  author: MessageAuthor.user,
                },
              ];
            })}
            emptyState={
              <EmptyState
                title="Preview"
                text="Select an activity to preview"
                customIcon={<Icon name="chatbubbles-outline" size={60} />}
              ></EmptyState>
            }
          ></ChatPreview>
        ) : (
          <View style={{ marginTop: 24, marginBottom: 24 }}>
            {(intervention as IPsychoedFragment)?.slides &&
            (intervention as IPsychoedFragment).slides.length
              ? [...(intervention as IPsychoedFragment).slides]
                  .sort((a, b) => a.position - b.position)
                  .map((slide, key) => (
                    <React.Fragment key={`psychoed-preview-${key}`}>
                      <LimbicText
                        variant={TextVariants.Base}
                        style={{ fontWeight: 'bold', fontSize: 20 }}
                      >
                        {getFormattedTextSpans(slide.title).map((t, key) => (
                          <span
                            key={key}
                            style={{
                              fontStyle: t.italic ? 'italic' : 'normal',
                              textDecorationLine: t.underline || t.link ? 'underline' : 'none',
                              color: t.link ? Colors.pink5 : undefined,
                            }}
                          >
                            {t.text}
                          </span>
                        ))}
                      </LimbicText>
                      <LimbicText
                        variant={TextVariants.Base}
                        style={{ fontSize: 18, fontWeight: '400' }}
                      >
                        {getFormattedTextSpans(slide.subtitle).map((t, key) => (
                          <span
                            key={key}
                            style={{
                              fontStyle: t.italic ? 'italic' : 'normal',
                              textDecorationLine: t.underline || t.link ? 'underline' : 'none',
                              color: t.link ? Colors.pink5 : undefined,
                            }}
                          >
                            {t.text}
                          </span>
                        ))}
                      </LimbicText>

                      {[...slide.blocks]
                        .sort((a, b) => a.position - b.position)
                        .map((block, key) => (
                          <React.Fragment key={`psychoed-preview-${key}`}>
                            <LimbicText
                              variant={TextVariants.Base}
                              style={{ fontSize: 16, marginTop: 16 }}
                            >
                              {getFormattedTextSpans(block.text).map((t, key) => (
                                <span
                                  key={key}
                                  style={{
                                    fontWeight: t.bold ? 'bold' : '300',
                                    fontStyle: t.italic ? 'italic' : 'normal',
                                    textDecorationLine:
                                      t.underline || t.link ? 'underline' : 'none',
                                    color: t.link ? Colors.pink5 : undefined,
                                  }}
                                >
                                  {t.text}
                                </span>
                              ))}
                            </LimbicText>
                          </React.Fragment>
                        ))}

                      {key !== (intervention as IPsychoedFragment).slides.length - 1 && (
                        <View
                          style={{
                            height: 1,
                            width: '100%',
                            backgroundColor: Colors.darkGrey1,
                            marginVertical: 16,
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))
              : null}
          </View>
        )}
      </ScrollView>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  previewContainer: {
    marginTop: -60,
    backgroundColor: Colors.white,
    borderRadius: 8,
    paddingHorizontal: 36,
    paddingVertical: 18,
    overflow: 'scroll',
    flexShrink: 1,
    flexGrow: 0,
    width: 'clamp(300px, 50%, 400px)',
    height: 'calc(100% + 60px)',
    zIndex: 10,
  },
  answerList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  answerPill: {
    borderColor: 'rgb(239, 240, 247)',
    paddingHorizontal: 18,
    marginLeft: 6,
    marginBottom: 8,
  },
});
