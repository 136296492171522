import React, { ReactElement } from 'react';
import { ErrorState } from '../EmptyState/ErrorState';
import { Loading } from '../Loading/Loading';

interface AsyncDataProps {
  error?: Error;
  loading?: boolean;
  render: () => ReactElement;
}

export function AsyncData({ error, loading, render }: AsyncDataProps) {
  if (loading) return <Loading />;
  if (error) return <ErrorState error={error} />;
  return render();
}
