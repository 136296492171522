export enum RecurrenceFrequency {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
}

export enum Weekday {
  Mo = 'Monday',
  Tu = 'Tuesday',
  We = 'Wednesday',
  Th = 'Thursday',
  Fr = 'Friday',
  Sa = 'Saturday',
  Su = 'Sunday',
}

export interface IRecurrenceInput {
  dtstart?: string;
  rrule: string;
}

export interface IRecurrenceInputParams {
  freq: RecurrenceFrequency;
  startDate?: Date;
  interval?: number;
  weekdays?: Weekday[];
}
