import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Tag, TagVariant } from '../Tag/Tag';

interface TriggerProps {
  date: string;
  emoji?: string;
}

const Trigger = (props: TriggerProps) => {
  const { date, emoji } = props;
  return (
    <View style={styles.triggerContainer}>
      <LimbicText variant={TextVariants.XS} color={Colors.darkGrey2} center>
        {date}
      </LimbicText>
      <LimbicText
        variant={TextVariants.XXL}
        // NOTE: Manual line height override to stop the emoji from being cut off
        // at the top. I'd imagine this might likely be replaced with an image
        // or icon later on down the line, so wrapping this in LimbicText may
        // change later down the line.
        style={[styles.textWithMarginTop, { lineHeight: 50 }]}
      >
        {emoji}
      </LimbicText>
    </View>
  );
};

interface StrategyProps {
  type: string;
  description: string;
}

const Strategy = (props: StrategyProps) => {
  const { type, description } = props;
  return (
    <View style={styles.copingStrategyContainer}>
      <LimbicText variant={TextVariants.XS} color={Colors.black} center>
        {type}
      </LimbicText>
      {!!description && (
        <LimbicText
          variant={TextVariants.Base}
          color={Colors.black}
          style={{ ...styles.textWithMarginTop, ...styles.copingStrategyText }}
        >
          {description}
        </LimbicText>
      )}
    </View>
  );
};

interface ResponseProps {
  liked: boolean;
}

const Response = (props: ResponseProps) => {
  const { liked } = props;
  return (
    <View style={styles.responseContainer}>
      <Tag variant={liked ? TagVariant.GreenFilled : TagVariant.PinkFilled}>
        {liked ? 'Liked' : 'Disliked'}
      </Tag>
    </View>
  );
};

export const CopingStrategiesTableRow = {
  Trigger,
  Response,
  CopingStrategy: Strategy,
};

const styles = StyleSheet.create({
  triggerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  textWithMarginTop: {
    marginTop: 5,
  },
  copingStrategyContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingHorizontal: 8,
  },
  copingStrategyText: {
    lineHeight: 24,
  },
  responseContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
