interface FormattedTextSpan {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  link?: boolean;
}

const Tags: Record<string, keyof FormattedTextSpan> = {
  '<b>': 'bold',
  '<i>': 'italic',
  '<u>': 'underline',
  '<a>': 'link',
};

export const getFormattedTextSpans = (
  element?: string | number | Array<string | number>
): FormattedTextSpan[] => {
  if (element) {
    if (typeof element === 'string') {
      const openTags: any = {
        bold: false,
        italic: false,
        underline: false,
        link: false,
      };
      let currentText = '';
      const spans = [] as FormattedTextSpan[];

      for (let i = 0; i < element.length; i++) {
        const char = element[i];
        if (char === '<') {
          const tag = Tags[element.slice(i, i + 3)];
          if (tag) {
            if (currentText.length !== 0) {
              spans.push({
                text: currentText,
                ...openTags,
              });
              currentText = '';
            }

            openTags[tag] = !openTags[tag];
            i += 2;
            continue;
          }
        } else if (char === '\\') {
          const tag = Tags[element.slice(i + 1, i + 4)];
          if (tag) {
            currentText += element.slice(i + 1, i + 4);
            i += 3;
            continue;
          }
        }

        currentText += char;
      }

      if (currentText.length !== 0) {
        spans.push({
          text: currentText,
          ...openTags,
        });
      }

      return spans;
    }
    if (typeof element === 'number') {
      return getFormattedTextSpans(`${element}`);
    }
    if (element?.length) {
      return (element as string[]).reduce(
        (t, current: string) => t.concat(getFormattedTextSpans(current)),
        [] as any[]
      );
    }
  }
  return [];
};
