import React from 'react';
import { View, StyleSheet, ViewStyle, StyleProp, TouchableOpacity } from 'react-native';
import { TABLE_COLUMNS_PER_ROW, ColumnProps } from './Shared';
import { TouchableActiveOpacity } from '../../consts/touchable';
import { concat } from '../../utils/concat';

interface TableRowProps {
  columns: ColumnProps[];
  content: React.ReactNode[];
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: () => void;
}

export const TableRow = ({ columns, content, containerStyle, onPress }: TableRowProps) => {
  return (
    <TouchableOpacity activeOpacity={TouchableActiveOpacity.mid} onPress={onPress}>
      <View style={concat(styles.container, containerStyle)}>
        {columns.map((column, idx) => {
          const contentElement = content[idx];
          const flexBasis = `${(column.flexWidth / TABLE_COLUMNS_PER_ROW) * 100}%`;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <View key={`${idx}`} style={[styles.column, { flexBasis }]}>
              {contentElement}
            </View>
          );
        })}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  column: {
    flexGrow: 1,
  },
});
