import React, { useCallback, useState } from 'react';
import {
  Button,
  Colors,
  LimbicText,
  Logo,
  TextInput,
  TextVariants,
} from '@limbic-for-therapists/components';
import {
  IRegisterCredentials,
  useGetClinicByDomain,
  register,
  login,
} from '@limbic-for-therapists/backend';
import {
  PasswordString,
  RequiredString,
  useAnalytics,
  validate,
} from '@limbic-for-therapists/shared';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useScreenDimensionsContext } from '../../context/ScreenDimensions';
import { useToastContext } from '../../context/Toast';
import { useActiveClinicianContext } from '../../context/ActiveClinician';
import { setAuthToken } from '../../backend/helpers/authToken';
import { STAGE } from '../../consts/stage';
import { useClinicId } from '../../hooks/use-clinic-id';
import mixpanel from 'mixpanel-browser';
import { getTitanApolloClient } from '../../backend/helpers/getApolloClient';

const LOGO_WIDTH = 125;

const validationSchema = yup.object().shape({
  email: RequiredString('Please enter a valid email address.'),
  password: PasswordString(),
  name: RequiredString('Please enter a valid name.'),
  clinicId: RequiredString(
    'An error has occured - invalid clinic ID. Please check the URL and try again.'
  ),
});

export const SignUpForm = () => {
  const { logAnalytic } = useAnalytics(mixpanel);
  const titanClient = getTitanApolloClient();
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchActiveClinician } = useActiveClinicianContext();
  const { setErrorToast, setToast } = useToastContext();
  const history = useHistory();
  const clinicId = useClinicId();
  const { currentSize } = useScreenDimensionsContext();
  const { control, getValues } = useForm<Omit<IRegisterCredentials, 'clinicId'>>();

  const { data: clinicResponse } = useGetClinicByDomain(clinicId, STAGE, titanClient);

  const navigateToLoginPage = useCallback(() => {
    history.push('/auth/login');
  }, [history]);

  const handleRegister = useCallback(async () => {
    try {
      if (!clinicResponse?.getClinicByDomain.id)
        throw new Error('Invalid Clinic ID supplied. Please contact the Limbic team.');
      setLoading(true);
      const values = {
        ...getValues(),
        clinicId: clinicResponse.getClinicByDomain.id,
      };
      await validate(validationSchema, values);
      await register(STAGE, values);
      // Log the user in immediately with the same credentials if sign up successful
      const { accessToken } = await login(STAGE, {
        email: values.email,
        password: values.password,
      });
      setAuthToken(accessToken);
      const clinician = await fetchActiveClinician();
      setToast({
        title: 'Registration successful',
        subtitle: 'Welcome to Limbic for Therapists!',
      });
      logAnalytic({ eventName: 'user-registered', params: { userId: clinician?.id || null } });
    } catch (e) {
      setErrorToast(e.message);
    } finally {
      setLoading(false);
    }
  }, [
    clinicResponse?.getClinicByDomain.id,
    fetchActiveClinician,
    getValues,
    logAnalytic,
    setErrorToast,
    setToast,
  ]);

  return (
    <View style={styles.form}>
      {currentSize === 'xs' ? (
        <View style={styles.row}>
          <Logo color={Colors.black} width={LOGO_WIDTH} />
        </View>
      ) : null}
      <form>
        <View style={styles.row}>
          <LimbicText variant={TextVariants.M}>
            Enter your details below and start managing clients and records with Limbic&apos;s
            clinician dashboard.
          </LimbicText>
        </View>

        <View style={styles.row}>
          <Controller
            name="email"
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                testID="signup-email-field"
                nativeID="new-email-address"
                label="E-mail Address"
                keyboardType="email-address"
                onBlur={onBlur}
                value={value}
                onChangeText={(value) => onChange(value)}
                textContentType="none"
                autoComplete="new-email-address"
              />
            )}
          />
        </View>
        <View style={styles.row}>
          <Controller
            name="name"
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                testID="signup-name-field"
                nativeID="new-clinician-name"
                label="Name"
                value={value}
                onBlur={onBlur}
                onChangeText={(value) => onChange(value)}
                textContentType="none"
                autoComplete="new-clinician-name"
              />
            )}
          />
        </View>
        <View style={styles.row}>
          <Controller
            name="password"
            control={control}
            render={({ onChange, onBlur, value }) => (
              <TextInput
                testID="signup-password-field"
                nativeID="new-password"
                label="Password"
                secureTextEntry
                value={value}
                onBlur={onBlur}
                onChangeText={(value) => onChange(value)}
                textContentType="none"
                autoComplete="new-password"
              />
            )}
          />
        </View>
      </form>

      <View style={styles.row}>
        <Button
          fixed
          onPress={handleRegister}
          variant="main"
          title="Sign Up"
          loading={loading}
          testID="signup-button"
        />
      </View>
      <TouchableOpacity onPress={navigateToLoginPage} testID="signup-login-button">
        <View style={styles.row}>
          <LimbicText variant={TextVariants.M}>Click here to log in</LimbicText>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  form: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    padding: 44,
    width: 500,
  },
  row: {
    paddingHorizontal: 20,
    marginTop: 20,
  },
});
