import { useEffect, useMemo, useState } from 'react';
import {
  IListTherapistReportsForPatientResponse,
  useListTherapistReportsForPatient,
} from '../queries/listTherapistReportsForPatient';

export const useAllReports = ({ signUpCode }: { signUpCode: string }) => {
  const { data, onFetchMore, loading, loadingMore } = useListTherapistReportsForPatient(signUpCode);
  const [allReports, setAllReports] = useState<IListTherapistReportsForPatientResponse>();
  const total = data?.listTherapistReportsForPatient?.pagination.total;
  const items =
    useMemo(
      () => data?.listTherapistReportsForPatient?.data,
      [data?.listTherapistReportsForPatient?.data]
    ) || [];

  useEffect(() => {
    if (!items.length || !total) return;
    if (items.length < total) onFetchMore();
    if (items.length >= total) setAllReports(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, items.length, total]);

  return { allReports, loading: loading || loadingMore };
};
