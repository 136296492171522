import { RESET_PASSWORD_ENDPOINT } from '@limbic-for-therapists/shared';
import NetworkError from '../models/NetworkError';
import IResetPasswordInput from '../models/IResetPasswordInput';
import { Stage } from '../consts/stage';

const method = 'POST';
const headers = new Headers();
headers.set('Content-Type', 'application/json');

export const resetPassword = async (
  stage: Stage,
  payload: IResetPasswordInput
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<Record<string, any>> => {
  const RESET_PASSWORD_URL = RESET_PASSWORD_ENDPOINT[stage];
  const body = JSON.stringify(payload);
  const result = await fetch(RESET_PASSWORD_URL, { body, method, headers });
  const { data } = await result.json();
  if (data?.error) {
    throw new NetworkError(data?.code, data?.error);
  }
  if (data?.message) {
    throw new NetworkError(data?.code, data?.message);
  }
  return data;
};
