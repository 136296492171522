import React, { useState, useEffect } from 'react';
import { Image } from 'react-native';

export function AutoHeightImage({ uri, width }: { uri: string; width: number }) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    Image.getSize(uri, (originalWidth, originalHeight) => {
      setHeight((width * originalHeight) / originalWidth);
    });
  }, [uri, width]);

  return <Image source={{ uri }} style={{ height, width }} />;
}
