import { useAttachPsychoedToPatientMutation } from '@limbic-for-therapists/backend';
import { SetPsychoedDate, SuccessModal } from '@limbic-for-therapists/components';
import { getLongDate } from '@limbic-for-therapists/shared';
import dayjs, { Dayjs } from 'dayjs';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTitanApolloClient } from '../../backend/helpers/getApolloClient';
import { DatePicker } from '../../components/DatePicker/DatePicker';
import { useModalContext } from '../../context/Modal';
import { useToastContext } from '../../context/Toast';

dayjs.extend(isTomorrow);

export default function SetPsychoedTriggers({
  interventionId,
  signupCode,
}: {
  interventionId: string;
  signupCode: string;
}) {
  const { setContent, dismissModal } = useModalContext();
  const { setErrorToast } = useToastContext();
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);
  const [datePickerUpdateField, setDatePickerUpdateField] = useState<'startDate' | 'endDate'>(
    'startDate'
  );
  const { search } = useLocation();
  const [attachPsychoedMutation] = useAttachPsychoedToPatientMutation(getTitanApolloClient());
  const isTomorrow = startDate?.isTomorrow() || false;

  const newPatientName = useMemo(() => {
    return new URLSearchParams(search).get('newPatientName') || undefined;
  }, [search]);

  const datePickerDate = useMemo(
    () => (datePickerUpdateField === 'startDate' ? startDate : endDate),
    [datePickerUpdateField, startDate, endDate]
  );

  const startTitle = startDate ? getLongDate(startDate) : 'Pick a start date';

  const handlePressedPickADate = useCallback((field) => {
    setDatePickerUpdateField(field);
    setDatePickerVisible(true);
  }, []);

  const handlePressedToday = useCallback(() => {
    setStartDate(!isTomorrow ? dayjs().add(0, 'day') : undefined);
  }, [isTomorrow]);

  const handleSelectedDate = useCallback(
    (date: Dayjs) => {
      if (datePickerUpdateField === 'startDate') {
        setStartDate(date);
      }
      if (datePickerUpdateField === 'endDate') {
        setEndDate(date);
      }
      setDatePickerVisible(false);
    },
    [datePickerUpdateField, setDatePickerVisible]
  );

  const handleSubmit = useCallback(async () => {
    try {
      await attachPsychoedMutation({
        signupCode,
        startAt: startDate?.toISOString(),
        psychoedId: interventionId,
      });
      setContent(
        <Success
          onClose={() => {
            dismissModal();
            window.location.href = `/dashboard/patient/${signupCode}`;
          }}
          newPatientName={newPatientName}
        />
      );
    } catch (e) {
      setErrorToast(e.message);
    }
  }, [
    attachPsychoedMutation,
    dismissModal,
    interventionId,
    setContent,
    setErrorToast,
    signupCode,
    startDate,
    newPatientName,
  ]);

  return (
    <React.Fragment>
      <SetPsychoedDate
        startTitle={startTitle}
        startDate={startDate}
        isTomorrow={isTomorrow}
        actionTitle="Continue"
        onPressedToday={handlePressedToday}
        onPressedPickAStartDate={() => handlePressedPickADate('startDate')}
        onSubmit={handleSubmit}
      />
      <DatePicker
        date={datePickerDate}
        active={datePickerVisible}
        onClose={() => setDatePickerVisible(false)}
        onDayChange={handleSelectedDate}
      />
    </React.Fragment>
  );
}

function Success({ onClose, newPatientName }: { onClose: () => void; newPatientName?: string }) {
  const message = newPatientName
    ? `Once ${newPatientName} installs the app, the activity will be available for them to complete.`
    : `This activity is scheduled, the patient will be prompted at the start date`;
  return <SuccessModal title="The activity is ready" message={message} onClose={onClose} />;
}
