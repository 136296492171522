export enum QuestionnaireTriggers {
  NEXT_SESSION = 'before_next_session',
  'EVERY_1' = 'before_session_1',
  'EVERY_2' = 'before_session_2',
  'EVERY_3' = 'before_session_3',
  'EVERY_4' = 'before_session_4',
  'EVERY_5' = 'before_session_5',
  'EVERY_6' = 'before_session_6',
  'EVERY_7' = 'before_session_7',
  'EVERY_8' = 'before_session_8',
  'EVERY_9' = 'before_session_9',
  'EVERY_10' = 'before_session_10',
}

export interface IQuestionnaireTriggerItem {
  label: string;
  value: QuestionnaireTriggers;
}

export const questionnaireTriggersMap: IQuestionnaireTriggerItem[] = [
  { label: '1st', value: QuestionnaireTriggers.EVERY_1 },
  { label: '2nd', value: QuestionnaireTriggers.EVERY_2 },
  { label: '3rd', value: QuestionnaireTriggers.EVERY_3 },
  { label: '4th', value: QuestionnaireTriggers.EVERY_4 },
  { label: '5th', value: QuestionnaireTriggers.EVERY_5 },
  { label: '6th', value: QuestionnaireTriggers.EVERY_6 },
  { label: '7th', value: QuestionnaireTriggers.EVERY_7 },
  { label: '8th', value: QuestionnaireTriggers.EVERY_8 },
  { label: '9th', value: QuestionnaireTriggers.EVERY_9 },
  { label: '10th', value: QuestionnaireTriggers.EVERY_10 },
];

export const questionnaireTriggerDescriptionsMap = {
  [QuestionnaireTriggers.NEXT_SESSION]: 'before the next session',
  [QuestionnaireTriggers.EVERY_1]: 'before every session',
  [QuestionnaireTriggers.EVERY_2]: 'before every 2nd session',
  [QuestionnaireTriggers.EVERY_3]: 'before every 3rd session',
  [QuestionnaireTriggers.EVERY_4]: 'before every 4th session',
  [QuestionnaireTriggers.EVERY_5]: 'before every 5th session',
  [QuestionnaireTriggers.EVERY_6]: 'before every 6th session',
  [QuestionnaireTriggers.EVERY_7]: 'before every 7th session',
  [QuestionnaireTriggers.EVERY_8]: 'before every 8th session',
  [QuestionnaireTriggers.EVERY_9]: 'before every 9th session',
  [QuestionnaireTriggers.EVERY_10]: 'before every 10th session',
};

export interface IWeeklyTriggerEvery {
  type: string;
  triggeredEvery: QuestionnaireTriggers;
}
