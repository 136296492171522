/* This version of the the chat preview is named v3 and exsits in paralel with the
 * ChatPreview because they represent two different ways of previewing a chat, v3
 * is used for previewing while selecting an intervention and v1 (named just chatPreview)
 * is used for previewing while editting/creating an intervention, with the second part of
 * interventions v3 the naming confusion will go away
 */

import { Colors, LimbicText, TextVariants } from '@limbic-for-therapists/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { AnimatedLimbicBalloon } from '../AnimatedLimbicBalloon/AnimatedLimbicBalloon';

interface MessageProps {
  children: React.ReactNode;
  author: MessageAuthor;
}

export function UserResponseMessage({ children }: { children: React.ReactNode }) {
  return (
    <LimbicText variant={TextVariants.SB} right>
      {children}
    </LimbicText>
  );
}

function Message({ children, author }: MessageProps) {
  if (author === MessageAuthor.limbic) {
    return (
      <View style={styles.messageLine}>
        <AnimatedLimbicBalloon flip size={24} animationId="idle"></AnimatedLimbicBalloon>
        <View style={styles.messageBox}>
          <LimbicText variant={TextVariants.SB}>{children}</LimbicText>
        </View>
      </View>
    );
  } else {
    return (
      <View style={[styles.messageLine, styles.messageBoxRight]} data-debug="message-line">
        <View style={styles.messageBox}>{children}</View>
        <Icon name={'person-circle-outline'} size={16} color={Colors.pink2} />
      </View>
    );
  }
}

export enum MessageAuthor {
  limbic,
  user,
}

interface ChatPreviewParams {
  messages?: Array<{
    value: React.ReactNode;
    author: MessageAuthor;
  }>;
  emptyState: React.ReactNode;
}

export function ChatPreview({ messages, emptyState }: ChatPreviewParams) {
  if (!messages || !messages.length) return <View>{emptyState}</View>;

  return (
    <View>
      {messages.map((message, i) => (
        <Message key={i} author={message.author}>
          {message.value}
        </Message>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  messageLine: {
    marginTop: 24,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexBasis: 'content',
  },
  messageBox: {
    marginLeft: 2,
    marginRight: 2,
    flexShrink: 1,
    flexGrow: 0,
  },
  messageBoxRight: {
    justifyContent: 'flex-end',
  },
});
