import React from 'react';

interface ListParams<T> {
  list?: T[] | null;
  renderItem: (value: T, index: number, array: T[]) => JSX.Element;
  emptyState?: JSX.Element;
}

export function List<T>({ list, renderItem, emptyState }: ListParams<T>) {
  if (list && list.length > 0) return <>{list.map(renderItem)}</>;
  if (emptyState) return emptyState;
  return null;
}
