import React, { useEffect, useRef, useState } from 'react';
import { Animated, StyleProp, ViewStyle } from 'react-native';
import { Colors, getShadowStyles } from '../../config';
import { Hoverable } from '../Hoverable/Hoverable';

interface AnimatedHoverable {
  style?: StyleProp<ViewStyle>;
  scaleTo?: number;
}

const AnimatedHoverable = ({
  children,
  scaleTo = 1.1,
  style,
}: React.PropsWithChildren<AnimatedHoverable>) => {
  const [hover, setHover] = useState<boolean>(false);
  const animatedValue = useRef<Animated.Value>(new Animated.Value(0));

  useEffect(() => {
    Animated.spring(animatedValue.current, {
      toValue: hover ? 1 : 0,
      speed: 100,
      useNativeDriver: false,
    }).start();
  }, [hover]);

  return (
    <Hoverable onHoverIn={() => setHover(true)} onHoverOut={() => setHover(false)}>
      <Animated.View
        style={[
          style,
          {
            zIndex: hover ? 2 : 1,
            ...getShadowStyles(Colors.black, hover ? 0.3 : 0.0, 18),
            transform: [
              {
                scale: animatedValue.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1.0, scaleTo],
                }),
              },
            ],
          },
        ]}
      >
        {children}
      </Animated.View>
    </Hoverable>
  );
};

export { AnimatedHoverable };
