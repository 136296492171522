import { ApolloClient, gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';

export enum HomeworkType {
  behavioral_action = 'behavioral_action',
  intervention = 'intervention',
  psychoed = 'psychoed',
  questionnaire = 'questionnaire',
}

export type SyncPatientHomeworksResponse = {
  syncPatientHomeworks: PatientHomeworks;
};

export type PatientHomework = {
  id: string;
  type: HomeworkType;
  title: string;
  description: string;
  availableFrom: Date;
  completedAt?: Date;
  startedAt?: Date;
  recurrence?:
    | {
        __typename: 'RRuleRecurrence';
        rrules: string[];
      }
    | {
        __typename: 'SessionRecurrence';
      };
  data?: PatientHomeworkData;
};

export type PatientHomeworkData =
  | {
      __typename: 'PatientPsychoed';
      id: string;
      assignedAt: Date;
      psychoed: {
        id: string;
      };
    }
  | {
      __typename: 'BehavioralAction';
      id: string;
      assignedAt: Date;
      answers: Array<{
        id: string;
        question: string;
        answer: string;
      }>;
    }
  | {
      __typename: 'InterventionAnswer';
      answers: Array<{
        answers: string[];
        stepPrompt: string;
        answerOptions?: string[];
      }>;
      patientIntervention: {
        id: string;
        assignedAt: Date;
        intervention: {
          id: string;
        };
      };
    };

export type PatientHomeworks = {
  completed: Array<PatientHomework & { completedAt: Date; startedAt: Date }>;
  todo: Array<PatientHomework & { completedAt: undefined; startedAt: undefined }>;
  inProgress: Array<PatientHomework & { completedAt: undefined; startedAt: Date }>;
};

const SYNC_PATIENT_HOMEWORKS = gql`
  mutation syncPatientHomeworks($signUpCode: String!) {
    syncPatientHomeworks(signupCode: $signUpCode) {
      completed {
        ...Homework
      }
      inProgress {
        ...Homework
      }
      todo {
        ...Homework
      }
    }
  }

  fragment Homework on Homework {
    id
    type
    title
    description
    availableFrom
    expiresAt
    completedAt
    startedAt
    recurrence {
      ... on RRuleRecurrence {
        rrules
      }
      ... on SessionRecurrence {
        __typename
      }
    }
    data {
      ... on InterventionAnswer {
        patientIntervention {
          id
          assignedAt: createdAt
          intervention {
            id
          }
        }
        answers {
          answers
          stepPrompt
          answerOptions
        }
      }
      ... on PatientPsychoed {
        id
        assignedAt: createdAt
        psychoed {
          id
        }
      }
      ... on BehavioralAction {
        id
        assignedAt
        answers {
          id
          question
          answer
        }
      }
    }
  }
`;

export const useSyncHomeworks = <TCacheShape extends Record<string, unknown>>(
  signUpCode: string,
  titanClient: ApolloClient<TCacheShape>
) => {
  const [syncPatientHomeworksMutation, { data: homeworksData, error, loading }] =
    useMutation<SyncPatientHomeworksResponse>(SYNC_PATIENT_HOMEWORKS, {
      client: titanClient,
    });

  const refetch = useCallback(() => {
    syncPatientHomeworksMutation({
      variables: { signUpCode },
    });
  }, [signUpCode, syncPatientHomeworksMutation]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const homeworks = useMemo(() => {
    if (!homeworksData) return undefined;

    return {
      completed: homeworksData.syncPatientHomeworks.completed.sort(
        (a, b) => dayjs(b.completedAt).valueOf() - dayjs(a.completedAt).valueOf()
      ),
      inProgress: homeworksData.syncPatientHomeworks.inProgress.sort(
        (a, b) => dayjs(b.availableFrom).valueOf() - dayjs(a.availableFrom).valueOf()
      ),
      todo: homeworksData.syncPatientHomeworks.todo.sort(
        (a, b) => dayjs(b.availableFrom).valueOf() - dayjs(a.availableFrom).valueOf()
      ),
    };
  }, [homeworksData]);

  return {
    data: homeworks,
    error,
    loading,
    refetch,
  };
};
