import React, { useState } from 'react';

import { TouchableOpacity, View, ViewProps, StyleSheet } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface TabGroupHeader {
  title: string;
  onClickHandler?: () => void;
}

interface TabGroupProps extends ViewProps {
  headers: TabGroupHeader[];
  children: JSX.Element[];
}

const TabGroup = ({ headers, children, ...props }: TabGroupProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <View {...props}>
      <View style={{ flexDirection: 'row' }}>
        {headers.map((header, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              setActiveTab(index);
              if (header.onClickHandler) header.onClickHandler();
            }}
            style={[
              styles.header,
              activeTab === index ? styles.activeBorderColor : styles.inactiveBorderColor,
            ]}
          >
            <LimbicText
              bold={false}
              lineBreakMode="head"
              variant={TextVariants.SB}
              style={styles.headerTitle}
            >
              {header.title}
            </LimbicText>
          </TouchableOpacity>
        ))}
      </View>
      {children[activeTab]}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginRight: 16,
    paddingBottom: 8,
    borderBottomWidth: 2,
  },
  activeBorderColor: {
    borderBottomColor: '#F174B4',
  },
  inactiveBorderColor: {
    borderBottomColor: 'transparent',
  },
  headerTitle: {
    fontWeight: '400',
    color: '#212645',
  },
});

export default TabGroup;
