import { gql, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { fragments, IPatientFragment } from '../fragments';

const PATIENTS_QUERY = gql`
  query listPatients($status: String!, $limit: Int = 10, $offset: Int = 0) {
    listPatientsOfClinician(status: [$status], limit: $limit, offset: $offset) {
      data {
        ...Patient
      }
      pagination {
        total
      }
    }
  }
  ${fragments.patient}
`;

export interface IListPatientsResponse {
  listPatientsOfClinician: {
    data: IPatientFragment[];
    pagination: {
      total: number;
    };
  };
}

export interface IListPatientsVariables {
  status: 'pending' | 'active';
  limit: number;
  offset: number;
}

export const useListPatients = (status: 'pending' | 'active', limit = 10) => {
  const [moreLoading, setMoreLoading] = useState<boolean>(false);
  const { data, loading, fetchMore, refetch } = useQuery<
    IListPatientsResponse,
    IListPatientsVariables
  >(PATIENTS_QUERY, {
    variables: { status, limit, offset: 0 },
    notifyOnNetworkStatusChange: true,
  });

  const canFetchMore = data
    ? data.listPatientsOfClinician.pagination.total > data.listPatientsOfClinician.data.length
    : false;

  const onFetchMore = useCallback(() => {
    if (!loading || !moreLoading) {
      setMoreLoading(true);
      fetchMore({
        variables: {
          status,
          limit,
          offset: data?.listPatientsOfClinician?.data.length,
        },
        updateQuery: (prev: IListPatientsResponse, { fetchMoreResult: curr }) => {
          if (!curr) return prev;
          return {
            ...prev,
            listPatientsOfClinician: {
              ...prev.listPatientsOfClinician,
              data: [...prev.listPatientsOfClinician.data, ...curr.listPatientsOfClinician.data],
            },
          };
        },
      }).finally(() => setMoreLoading(false));
    }
  }, [data?.listPatientsOfClinician?.data.length, fetchMore, limit, loading, moreLoading, status]);

  return {
    patients: data?.listPatientsOfClinician.data,
    refetch,
    loading,
    moreLoading,
    canFetchMore,
    onFetchMore,
  };
};
