import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { StyleSheet, View, Modal } from 'react-native';
import { Colors } from '../../config';
import { toggleItemInArray } from '../../utils/toggleItemInArray';
import { DayCircle } from '../DayCircle/DayCircle';
import { ModalContent } from '../LimbicModal/ModalContent';
import { ModalContentContainer } from '../ModalContentContainer/ModalContentContainer';
import { SelectInput, SelectInputItem } from '../SelectInput/SelectInput';
import { TextInput } from '../TextInput/TextInput';

export enum RecurrenceFrequency {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
}

enum Weekday {
  Mo = 'Monday',
  Tu = 'Tuesday',
  We = 'Wednesday',
  Th = 'Thursday',
  Fr = 'Friday',
  Sa = 'Saturday',
  Su = 'Sunday',
}

export const frequencyTypeOptions: SelectInputItem<
  RecurrenceFrequency.Week | RecurrenceFrequency.Month
>[] = [
  { label: 'weeks', value: RecurrenceFrequency.Week },
  { label: 'months', value: RecurrenceFrequency.Month },
];

const DAYS = [Weekday.Mo, Weekday.Tu, Weekday.We, Weekday.Th, Weekday.Fr, Weekday.Sa, Weekday.Su];

interface SetCustomSessionFrequencyProps {
  formProps: UseFormMethods<{
    duration: number;
    type: RecurrenceFrequency.Week | RecurrenceFrequency.Month;
    days: Weekday[];
  }>;
  active: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const SetCustomSessionFrequency = ({
  formProps,
  active,
  onSubmit,
  onClose,
}: SetCustomSessionFrequencyProps) => {
  const { control, register, watch } = formProps;

  const days = watch('days');

  useEffect(() => {
    register('days');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal transparent animationType="fade" visible={active}>
      <ModalContentContainer onClose={onClose} testID="custom-session-frequency-modal">
        <ModalContent>
          <ModalContent.Title>Set repeat</ModalContent.Title>
          <View style={styles.field}>
            <Controller
              name="duration"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <TextInput
                  testID="set-duration-text-input"
                  value={value}
                  style={styles.textInput}
                  keyboardType="number-pad"
                  onBlur={onBlur}
                  onChangeText={(value) => onChange(parseInt(value, 10))}
                />
              )}
            />
            <View style={styles.pickerContainer}>
              <Controller
                name="type"
                control={control}
                render={({ value, onChange }) => (
                  <SelectInput
                    values={frequencyTypeOptions}
                    selectedItem={frequencyTypeOptions.find((el) => el.value === value)}
                    onPressItem={(value) => onChange(value.value)}
                  />
                )}
              />
            </View>
          </View>
          <View style={styles.daysContainer} testID="custom-session-frequency-day-selector">
            <Controller
              name="days"
              control={control}
              render={({ value, onChange }) => (
                <>
                  {DAYS.map((item) => (
                    <DayCircle
                      day={item}
                      onPress={() => onChange(toggleItemInArray(days, item))}
                      selected={value}
                      key={item}
                    />
                  ))}
                </>
              )}
            />
          </View>
          <ModalContent.CTAButton
            title="OK"
            onPress={onSubmit}
            testID="custom-session-frequency-submit-button"
          />
          <ModalContent.CTAButton
            title="Cancel"
            fixed={false}
            style={styles.button}
            textStyle={styles.buttonText}
            onPress={onClose}
            testID="custom-session-frequency-cancel-button"
          />
        </ModalContent>
      </ModalContentContainer>
    </Modal>
  );
};

const styles = StyleSheet.create({
  field: {
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 34,
  },
  textInput: {
    width: 81,
    height: 47,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.grey3,
    paddingLeft: 10,
  },
  pickerContainer: {
    width: 166,
    height: 48,
    marginLeft: 13,
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 24,
  },
  button: {
    backgroundColor: Colors.white,
  },
  buttonText: {
    color: Colors.darkGrey1,
  },
});
