import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Colors } from '../../config/colors';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface Props {
  title?: string;
  subtitle?: string;
  backgroundColor?: Colors;
}

export const Loading = ({
  title = 'Loading...',
  subtitle,
  backgroundColor,
  testID,
}: WithTestID<Props>) => {
  return (
    <View
      style={[styles.container, ...(backgroundColor ? [{ backgroundColor }] : [])]}
      testID={testID}
    >
      <ActivityIndicator size="large" color={Colors.pink4} />
      <LimbicText variant={TextVariants.M} color={Colors.darkGrey1} style={styles.text}>
        {title}
      </LimbicText>
      {subtitle ? (
        <LimbicText variant={TextVariants.S} color={Colors.darkGrey1} style={styles.text}>
          {subtitle}
        </LimbicText>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginTop: 16,
  },
});
