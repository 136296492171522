import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { WithTestID } from '../../utils/withTestID';
import { Button } from '../Button/Button';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export enum ViewTabs {
  TODAY = 'today',
  ALL = 'all',
}

interface Props {
  availableViewTabs: ViewTabs[];
  view: ViewTabs;
  name?: string | null;
  onViewChange: (newView: ViewTabs) => void;
}

export const GreetingHeader = ({
  availableViewTabs,
  view,
  name,
  onViewChange,
}: WithTestID<Props>) => {
  const greeting = useMemo(() => {
    const currentHour = dayjs().hour();
    // after 4:00AM and before 12:00PM
    return currentHour >= 4 && currentHour < 12
      ? 'Good morning'
      : // after 12:00PM and before 6:00pm
      currentHour >= 12 && currentHour <= 17
      ? 'Good afternoon'
      : // after 5:59pm or before 4:00AM
      currentHour > 17 || currentHour < 4
      ? 'Good evening'
      : // if for some reason the calculation didn't work
        'Welcome';
  }, []);

  return (
    <>
      <LimbicText variant={TextVariants.XL} style={styles.headerText}>
        {greeting},
      </LimbicText>
      <LimbicText bold variant={TextVariants.XL} style={[styles.headerText, styles.nameText]}>
        {name}
      </LimbicText>
      <View style={styles.filterContainer}>
        <View style={styles.filterLeftContainer}>
          {availableViewTabs.length > 1
            ? availableViewTabs.map((viewTab) => (
                <Button
                  testID={viewTab === ViewTabs.TODAY ? 'switch-today-button' : 'switch-all-button'}
                  key={viewTab}
                  title={viewTab === ViewTabs.TODAY ? 'Today' : 'All Patients'}
                  onPress={() => onViewChange(viewTab)}
                  style={[
                    styles.filterButton,
                    ...(viewTab === view ? [styles.filterSelected] : []),
                  ]}
                  textStyle={[
                    styles.filterButtonText,
                    ...(viewTab === view ? [styles.filterSelectedText] : []),
                  ]}
                />
              ))
            : null}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  headerText: {
    lineHeight: 32,
  },
  nameText: {
    marginTop: 5,
  },
  filterContainer: {
    flexDirection: 'row',
    marginTop: 11,
    marginBottom: 11,
  },
  filterLeftContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  filterRightContainer: {
    justifyContent: 'center',
  },
  filterSelected: {
    backgroundColor: Colors.black,
  },
  filterButton: {
    marginRight: 5,
    backgroundColor: Colors.grey2,
    color: Colors.black,
  },
  filterButtonText: {
    fontWeight: 'bold',
    color: Colors.black,
  },
  filterSelectedText: {
    color: Colors.white,
  },
  clientFilterButton: {
    backgroundColor: Colors.grey3,
  },
  clientFilterButtonText: {
    color: Colors.black,
  },
});
