import jwtDecode from 'jwt-decode';
import NetworkError from '../models/NetworkError';
import { ICredentials } from '../models/ICredentials';
import { LOGIN_ENDPOINT } from '@limbic-for-therapists/shared';
import { Stage } from '../consts/stage';

const method = 'POST';
const headers = new Headers();
headers.set('Content-Type', 'application/json');

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  permissions?: string[];
}

export const login = async (stage: Stage, payload: ICredentials): Promise<LoginResponse> => {
  const LOGIN_URL = LOGIN_ENDPOINT[stage];
  const body = JSON.stringify(payload);
  const result = await fetch(LOGIN_URL, { body, method, headers });
  const { data } = await result.json();
  if (data?.error) {
    throw new NetworkError(data?.code, data?.error);
  }
  if (data?.message) {
    throw new NetworkError(data?.code, data?.message);
  }
  const response = data as LoginResponse;
  const decoded = jwtDecode(response.accessToken) as { permissions: string[] | undefined };
  return {
    ...response,
    permissions: decoded.permissions,
  };
};
