import { useAnalytics } from '@limbic-for-therapists/shared';
import { debounce, noop } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useCallback } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { Colors } from '../../config';
import { cursorStyle } from '../../utils/cursorStyle';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface Props {
  onCreateCopingStrategy: () => void;
  onCreateBehaviouralAction: () => void;
  onCreateIntervention: () => void;
  onCreateQuestionnaire?: () => void;
  patientId: string;
}

type EventName =
  | 'worksheets-homework-clicked'
  | 'behavioural-activity-homework-clicked'
  | 'coping-strategy-homework-clicked'
  | 'questionnaire-homework-clicked';

function useLoggedAction(eventName: EventName, patientId: string, action: () => void) {
  const { logAnalytic } = useAnalytics(mixpanel);

  const a = useCallback(() => {
    // schedule the logAnalytic to be called after the action
    Promise.resolve().then(() =>
      logAnalytic({
        eventName,
        params: {
          patientId,
        },
      })
    );
    action();
  }, [action, logAnalytic, eventName, patientId]);

  return a;
}

export const HomeWorkActions = ({
  onCreateCopingStrategy,
  onCreateBehaviouralAction,
  onCreateQuestionnaire,
  onCreateIntervention,
  patientId,
}: Props) => {
  const onPressWorksheets = useLoggedAction(
    'worksheets-homework-clicked',
    patientId,
    onCreateIntervention
  );

  const onPressBehavioralAction = useLoggedAction(
    'behavioural-activity-homework-clicked',
    patientId,
    onCreateBehaviouralAction
  );

  const onPressCopingStrategy = useLoggedAction(
    'coping-strategy-homework-clicked',
    patientId,
    onCreateCopingStrategy
  );

  const onPressQuestionnaire = useLoggedAction(
    'questionnaire-homework-clicked',
    patientId,
    onCreateQuestionnaire || noop
  );

  return (
    <View style={styles.actionsContainer}>
      <TouchableWithoutFeedback
        testID="worksheets-button"
        onPress={debounce(onPressWorksheets || noop, 150, {
          leading: true,
          trailing: false,
          maxWait: 1000,
        })}
      >
        <View
          style={[
            styles.assignHomeworkButton,
            styles.assignHomeworkButtonHighlight,
            cursorStyle('pointer'),
          ]}
        >
          <LimbicText
            center
            variant={TextVariants.M}
            style={[styles.assignHomeworkButtonText, styles.assignHomeworkButtonTextHighlight]}
          >
            Activities and Educational materials
          </LimbicText>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback
        onPress={onPressBehavioralAction}
        testID="create-behavioural-action-button"
      >
        <View style={[styles.assignHomeworkButton, cursorStyle('pointer')]}>
          <LimbicText center variant={TextVariants.Base} style={styles.assignHomeworkButtonText}>
            Schedule activity
          </LimbicText>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback
        onPress={onPressCopingStrategy}
        testID="create-coping-strategy-button"
      >
        <View style={[styles.assignHomeworkButton, cursorStyle('pointer')]}>
          <LimbicText center variant={TextVariants.M} style={styles.assignHomeworkButtonText}>
            Add coping strategy
          </LimbicText>
        </View>
      </TouchableWithoutFeedback>

      {onCreateQuestionnaire ? (
        <TouchableWithoutFeedback
          onPress={onPressQuestionnaire}
          testID="schedule-questionnaire-button"
        >
          <View style={[styles.assignHomeworkButton, cursorStyle('pointer')]}>
            <LimbicText center variant={TextVariants.Base} style={styles.assignHomeworkButtonText}>
              Schedule questionnaire
            </LimbicText>
          </View>
        </TouchableWithoutFeedback>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  actionsContainer: {
    flexDirection: 'column',
    flex: 1,
    flexShrink: 0,
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    gap: 8,
  },
  assignHomeworkButtonText: {
    lineHeight: 20,
    color: Colors.darkGrey5,
    letterSpacing: -0.4,
    fontWeight: '300',
  },
  assignHomeworkButtonTextHighlight: {
    color: Colors.white,
  },
  assignHomeworkButton: {
    flexDirection: 'row',
    flexShrink: 0,
    flexWrap: 'wrap',
    alignContent: 'center',
    height: 44,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    backgroundColor: Colors.grey5,
    padding: 16,
  },
  assignHomeworkButtonHighlight: {
    backgroundColor: Colors.pink6,
  },
});
