import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Tag, TagVariant } from '../Tag/Tag';

const colorsMap = [
  Colors['darkGrey3-twenty'],
  Colors['darkGrey3-forty'],
  Colors['darkGrey3-sixty'],
  Colors['darkGrey3-eighty'],
  Colors.darkGrey3,
  Colors.darkGrey3,
];

interface QuestionProps {
  question: string;
}

function Question(props: QuestionProps) {
  const { question } = props;
  return (
    <View style={styles.questionContainer}>
      <LimbicText variant={TextVariants.S} color={Colors.darkGrey3}>
        {question}
      </LimbicText>
    </View>
  );
}

interface AnswerProps {
  answer: string;
  points?: number;
}

const Answer = ({ answer, points }: AnswerProps) => {
  const color = colorsMap[points ?? 6] || Colors.black;
  return (
    <View style={styles.answerContainer}>
      <View>
        <Tag variant={TagVariant.NoVariant} backgroundColor={color} textColor={Colors.white}>
          {answer}
        </Tag>
      </View>
      <View style={styles.numberContainer}>
        <LimbicText bold variant={TextVariants.XL} color={color}>
          {points}
        </LimbicText>
      </View>
    </View>
  );
};

export const QuestionnaireTableRow = {
  Question,
  Answer,
};

const styles = StyleSheet.create({
  questionContainer: {
    paddingRight: 16,
  },
  answerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  numberContainer: {
    alignSelf: 'flex-end',
    flexShrink: 1,
  },
});
