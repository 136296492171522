import { gql, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import { fragments, ICopingStrategyFeedbackFragment } from '../fragments';

export interface IListPatientCopingStrategiesFeedbackResponse {
  listPatientCopingStrategiesFeedback?: {
    data: ICopingStrategyFeedbackFragment[];
    pagination: {
      total: number;
    };
  };
}

const LIST_PATIENT_COPING_STRATEGIES_FEEDBACK_LOCAL = gql`
  query listPatientCopingStrategiesFeedback(
    $signUpCode: String!
    $fromDate: Date!
    $toDate: Date!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    listPatientCopingStrategiesFeedback(
      code: $signUpCode
      filter: { localCopingStrategyId: { ne: null }, createdAt: { gte: $fromDate, lte: $toDate } }
      limit: $limit
      offset: $offset
    ) {
      data {
        ...CopingStrategyFeedback
      }
      pagination {
        total
      }
    }
  }

  ${fragments.copingStrategyFeedback}
`;

const LIST_PATIENT_COPING_STRATEGIES_FEEDBACK_CUSTOM = gql`
  query listPatientCopingStrategiesFeedback(
    $signUpCode: String!
    $id: ID!
    $fromDate: Date!
    $toDate: Date!
    $limit: Int = 10
    $offset: Int = 0
  ) {
    listPatientCopingStrategiesFeedback(
      code: $signUpCode
      filter: { copingStrategyId: { eq: $id }, createdAt: { gte: $fromDate, lte: $toDate } }
      limit: $limit
      offset: $offset
    ) {
      data {
        ...CopingStrategyFeedback
      }
      pagination {
        total
      }
    }
  }

  ${fragments.copingStrategyFeedback}
`;

export interface IListPatientCopingStrategiesFeedbackVariables {
  signUpCode: string;
  id?: string;
  fromDate: Date;
  toDate: Date;
  limit?: number;
  offset?: number;
}

export function useListPatientCopingStrategiesFeedback(
  variables: IListPatientCopingStrategiesFeedbackVariables
) {
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { id, limit = 10, offset = 0 } = variables;
  const { loading, data, fetchMore, ...rest } = useQuery<
    IListPatientCopingStrategiesFeedbackResponse,
    IListPatientCopingStrategiesFeedbackVariables
  >(
    id
      ? LIST_PATIENT_COPING_STRATEGIES_FEEDBACK_CUSTOM
      : LIST_PATIENT_COPING_STRATEGIES_FEEDBACK_LOCAL,
    { variables: { ...variables, limit, offset } }
  );

  const canFetchMore = data?.listPatientCopingStrategiesFeedback
    ? data?.listPatientCopingStrategiesFeedback.pagination.total >
      data?.listPatientCopingStrategiesFeedback.data.length
    : false;

  const onFetchMore = useCallback(() => {
    if (!loading) {
      setLoadingMore(true);
      fetchMore({
        variables: {
          ...variables,
          limit,
          offset: data?.listPatientCopingStrategiesFeedback?.data.length,
        },
        updateQuery: (
          prev: IListPatientCopingStrategiesFeedbackResponse,
          { fetchMoreResult: curr }
        ) => {
          if (!curr) return prev;
          const previousData = prev.listPatientCopingStrategiesFeedback?.data || [];
          const currentData = curr.listPatientCopingStrategiesFeedback?.data || [];
          return {
            ...prev,
            listPatientCopingStrategiesFeedback: {
              data: [...previousData, ...currentData],
              pagination: curr.listPatientCopingStrategiesFeedback?.pagination || {
                total: curr.listPatientCopingStrategiesFeedback?.data.length || 0,
              },
            },
          };
        },
      }).finally(() => setLoadingMore(false));
    }
  }, [
    data?.listPatientCopingStrategiesFeedback?.data.length,
    fetchMore,
    limit,
    loading,
    variables,
  ]);

  return { ...rest, loading, loadingMore, data, canFetchMore, onFetchMore };
}
