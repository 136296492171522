import React, { useState } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { NewPatientManualEntryValues } from '@limbic-for-therapists/components';
import { StyleSheet, View } from 'react-native';
import { Colors, ModalContent } from '@limbic-for-therapists/components';
import TabGroup from '@limbic-for-therapists/components/src/components/TabGroup/TabGroup';
import { useInviteFlowContext } from '../../../context/InviteFlow';
import { validate } from '@limbic-for-therapists/shared';
import { useToastContext } from '../../../context/Toast';
import { useHistory } from 'react-router-dom';
import { useModalContext } from '../../../context/Modal';
import useInvitePatient from '../../../hooks/use-invite-patient';
import { NewPatientFromReferral } from './NewPatientFromReferral';
import { NewPatientFromForm } from './NewPatientFromForm';
import { phone } from 'phone';
import { NewPatientWithHWFlowValues } from '../NewPatientWithHWFlow';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please supply a name.'),
  dob: yup
    .string()
    .test('is-valid-dob', 'Please supply a valid DD/MM/YYYY Date of Birth.', (value) => {
      if (!value) return false;
      return dayjs(value, 'DD/MM/YYYY').isValid();
    }),
  phone: yup
    .string()
    .required('Please supply a phone number.')
    .test(
      'is-valid-phone',
      'Please supply a valid phone number. It should start with "+" and include the country code.',
      (value) => {
        if (!value) return false;
        const phoneNumber = phone(value);
        return phoneNumber.isValid;
      }
    ),
  email: yup.string().required('Please supply an email.').email('Please supply a valid email.'),
});

export const NewPatientModal = () => {
  const { values, formProps, setValues } = useInviteFlowContext<NewPatientWithHWFlowValues>();

  const { handleSubmit } = formProps;
  const { setErrorToast } = useToastContext();
  const { dismissModal } = useModalContext();
  const {
    invitePatient,
    isLoadingRemindPatientSignup,
    isLoadingUpdatePatient,
    isLoadingUpdatePatientProgress,
  } = useInvitePatient();
  const [isReferralInvite, setIsReferralInvite] = useState(true);
  const history = useHistory();

  const tabGroupHeaders = [
    {
      title: 'Invite from Access',
      onClickHandler: () => {
        setValues(
          {
            signupCode: values.signupCode,
            isAccessReferral: true,
          },
          true
        );
        setIsReferralInvite(true);
      },
    },
    {
      title: 'Invite manually',
      onClickHandler: () => {
        setValues(
          {
            signupCode: values.signupCode,
            isAccessReferral: false,
          },
          true
        );
        setIsReferralInvite(false);
      },
    },
  ];

  const formValues = formProps?.watch();

  const handleConfirmEntry = async (patient: NewPatientManualEntryValues) => {
    try {
      let inviteValues: any = values;
      if (!isReferralInvite) {
        await validate(validationSchema, {
          name: patient.name,
          email: patient.email,
          phone: patient.phone,
          dob: patient.dob,
          demoUser: !!patient.demoUser,
        });

        const { dob, ...rest } = patient;

        setValues({
          clinicianId: values.clinicianId,
          isAccessReferral: false,
          dob: dayjs(dob, 'YYYY-MM-DD'),
          ...rest,
        });
        inviteValues = patient;
      }
      const response = await invitePatient(inviteValues);
      if (response && response.signupCode) {
        history.push(
          `/dashboard/patient/${response.signupCode}/library?newPatientName=${inviteValues.name}`
        );
      }
      dismissModal();
    } catch (e) {
      setErrorToast(e.message);
      throw new Error(e.message);
    }
  };

  const handleButtonDisabled = () => {
    const isReferralInviteValid = Object.keys(validationSchema.fields).every((key) =>
      Object.keys(values).includes(key)
    );
    const isManualInviteValid = Object.keys(validationSchema.fields).every(
      (key) => formValues[key] !== undefined && formValues[key] !== ''
    );
    return (
      (isReferralInvite && !isReferralInviteValid) || (!isReferralInvite && !isManualInviteValid)
    );
  };

  return (
    <>
      <ModalContent
        scrollable
        contentContainerStyle={styles.container}
        style={styles.parentContainerPadding}
      >
        <View style={styles.headerPadding}>
          <ModalContent.Title bold style={styles.titleText}>
            Invite a patient to Limbic
          </ModalContent.Title>

          <ModalContent.Body style={styles.subTitleText}>
            Search a patient from Limbic Access or invite one manually
          </ModalContent.Body>
        </View>

        <TabGroup headers={tabGroupHeaders} style={styles.tabGroupHeight}>
          <NewPatientFromReferral />
          <NewPatientFromForm />
        </TabGroup>
        <View>
          <ModalContent.CTAButton
            title={'Invite & Assign Homework'}
            style={styles.inviteButton}
            disabledColor={Colors.primaryBlue70}
            textStyle={styles.inviteButtonText}
            disabled={handleButtonDisabled()}
            onPress={handleSubmit(handleConfirmEntry)}
            loading={
              isLoadingRemindPatientSignup ||
              isLoadingUpdatePatient ||
              isLoadingUpdatePatientProgress
            }
          />
        </View>
      </ModalContent>
    </>
  );
};

const styles = StyleSheet.create({
  tabRow: {
    flexDirection: 'row',
  },
  titleText: {
    fontSize: 20,
  },
  headerPadding: {
    marginBottom: 26,
  },
  tabGroupHeight: {
    height: 300,
  },
  subTitleText: {
    color: Colors.secondaryOnLight,
    paddingVertical: 0,
    paddingHorizontal: 0,
    fontSize: 14,
    fontWeight: '400',
  },
  container: {
    paddingBottom: 14,
  },
  parentContainerPadding: {
    paddingLeft: 22,
    paddingRight: 22,
  },
  containerTab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#eee',
  },
  bodyText: {
    color: Colors.darkGrey1,
  },
  input: {
    flex: 1,
    width: '100%',
    backgroundColor: Colors.white,
    borderRadius: 16,
    borderColor: Colors.darkGrey1,
  },
  resultRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '100%',
  },
  resultButton: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  rowItemText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 21,
    textAlign: 'center',
    color: '#000000',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
  },
  resultsContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    borderStyle: 'solid',
    borderRadius: 16,
    padding: 16,
    marginTop: 8,
    marginLeft: 4,
    minHeight: 200,
  },
  bottomAccessory: {
    marginTop: 48,
  },
  inviteButton: {
    borderRadius: 12,
    transition: 'all 0.2s ease-in-out',
  },
  inviteButtonText: {
    color: '#fff',
    fontWeight: '400',
  },
  homeworkCheckbox: {
    borderRadius: 4,
    backgroundColor: Colors.primaryBlue93,
    width: 22,
    height: 22,
  },
});

export default NewPatientModal;
