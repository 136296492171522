export enum MoodEventType {
  DailyMood = 'mood-daily',
  Crisis = 'crisis',
  BehavioralAction = 'behavioral_action',
  CORE10 = 'CORE-10',
  GAD7 = 'GAD-7',
  OCIR = 'OCI-R',
  IESR = 'IES-R',
  PDSS = 'PDSS',
  PHQ9 = 'PHQ-9',
  PCL5 = 'PCL-5',
  SPIN = 'SPIN',
  PAM = 'PAM',
  YPCORE10 = 'YP CORE-10',
  PSS10 = 'PSS-10',
  PHOBIA = 'Phobia Scale',
  EMPLOYMENT = 'Employment',
  WSAS = 'WSAS',
  MEDICATION = 'Psychotropic Medication',
  IPCQ2 = 'IPCQ2',
}
