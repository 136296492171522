import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export function Header({
  title,
  subtitle,
  tag,
}: {
  title: string;
  subtitle: string;
  tag?: string;
}) {
  return (
    <View style={styles.container}>
      <View>
        {tag && (
          <View style={styles.pill}>
            <LimbicText bold variant={TextVariants.XS} color={Colors.white}>
              {tag}
            </LimbicText>
          </View>
        )}
        <LimbicText bold variant={TextVariants.L} color={Colors.black} style={styles.title}>
          {title}
        </LimbicText>

        <View>
          <LimbicText variant={TextVariants.M} color={Colors.black}>
            {subtitle}
          </LimbicText>
        </View>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  headerContainer: {
    alignSelf: 'stretch',
  },
  pill: {
    alignSelf: 'flex-start',
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    backgroundColor: Colors.pink1,
  },

  title: {
    // 1.2
    lineHeight: 32 * 1.2,
  },
});
