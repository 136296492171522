import { ApolloClient } from '@apollo/client';
import { useCreateCopingStrategyMutation } from '../mutations/createCopingStrategy';
import { useListEmotionsQuery } from '../queries/listEmotions';

const useAddCopingStrategy = <TCacheShape extends Record<string, unknown>>(
  signUpCode: string,
  titanClient: ApolloClient<TCacheShape>
) => {
  const { data: listEmotionsData, loading: emotionsLoading } = useListEmotionsQuery(signUpCode);

  const [createCopingStrategy, { loading: createCopingStrategyLoading }] =
    useCreateCopingStrategyMutation(titanClient);

  return {
    emotions: listEmotionsData?.listEmotions.data,
    emotionsLoading,
    createCopingStrategy,
    createCopingStrategyLoading,
  };
};

export { useAddCopingStrategy };
