import React from 'react';
import { StyleSheet } from 'react-native';
import { Controller, UseFormMethods } from 'react-hook-form';
import { View } from 'react-native';
import { Colors } from '../../config';
import { Button } from '../Button/Button';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { TextInput } from '../TextInput/TextInput';

interface EditPasswordProps {
  formProps: UseFormMethods<{ oldPassword: string; newPassword: string }>;
  loading: boolean;
  onSubmit: (values: { oldPassword: string; newPassword: string }) => void;
}

export const EditPassword = ({ onSubmit, loading, formProps }: EditPasswordProps) => {
  const { control, handleSubmit } = formProps;
  return (
    <View style={styles.container}>
      <View>
        <Controller
          control={control}
          name="oldPassword"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              testID="edit-password-old-password-text-input"
              secureTextEntry
              style={styles.textInput}
              label="Old Password"
              value={value}
              onChangeText={(value) => onChange(value)}
              onBlur={onBlur}
            />
          )}
        />
        {formProps.errors['oldPassword'] ? (
          <LimbicText center variant={TextVariants.S} color={Colors.pink5}>
            {formProps.errors['oldPassword']}
          </LimbicText>
        ) : null}
        <View style={styles.spacer} />
        <Controller
          control={control}
          name="newPassword"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              testID="edit-password-new-password-text-input"
              secureTextEntry
              style={styles.textInput}
              label="New Password"
              value={value}
              onChangeText={(value) => onChange(value)}
              onBlur={onBlur}
            />
          )}
        />
        {formProps.errors['newPassword'] ? (
          <LimbicText center variant={TextVariants.S} color={Colors.pink5}>
            {formProps.errors['newPassword']}
          </LimbicText>
        ) : null}
      </View>
      <View style={styles.button}>
        <Button
          fixed
          title="Update"
          onPress={handleSubmit(onSubmit)}
          loading={loading}
          testID="edit-password-submit-button"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  header: {
    marginVertical: 22,
  },
  textInput: {
    borderBottomWidth: 1,
    flex: 1,
    height: 44,
    borderColor: Colors.grey3,
  },
  spacer: {
    height: 20,
  },
  button: {
    marginTop: 16,
    paddingBottom: 2,
  },
});
