import { mapValues } from 'lodash';

const LOGIN_PATH = 'api/v1/therapists/login';
const REGISTER_PATH = 'api/v1/therapists/register';
const RESET_PASSWORD_PATH = 'api/v1/users/forgot-password';
const GRAPHQL_PATH = 'graphql';
const TITAN_GRAPHQL_PATH = 'v1/graphql';
const PATIENTS_REGISTER_PATH = 'api/v1/patients/register';
const REFRESH_TOKEN_PATH = 'api/v1/therapists/refresh_token';

const AUTH_SERVER = {
  development: 'http://localhost:7777',
  staging: 'https://auth-staging.limbic.ai',
  production: 'https://auth.limbic.ai',
};

const GRAPQHL_SERVER = {
  development: 'http://localhost:8080',
  staging: 'https://limbic-graphql-server-staging.herokuapp.com',
  production: 'https://limbic-graphql-server.herokuapp.com',
};

const TITAN_SERVER = {
  development: 'http://localhost:50505',
  staging: 'https://titan-stg.limbic.ai',
  production: 'https://titan.limbic.ai',
};

export const LOGIN_ENDPOINT = mapValues(AUTH_SERVER, (server) => new URL(LOGIN_PATH, server).href);
export const REGISTER_ENDPOINT = mapValues(
  AUTH_SERVER,
  (server) => new URL(REGISTER_PATH, server).href
);
export const RESET_PASSWORD_ENDPOINT = mapValues(
  AUTH_SERVER,
  (server) => new URL(RESET_PASSWORD_PATH, server).href
);
export const GRAPHQL_ENDPOINT = mapValues(
  GRAPQHL_SERVER,
  (server) => new URL(GRAPHQL_PATH, server).href
);
export const TITAN_GRAPHQL_ENDPOINT = mapValues(
  TITAN_SERVER,
  (server) => new URL(TITAN_GRAPHQL_PATH, server).href
);

export const PATIENTS_REGISTER_ENDPOINT = mapValues(
  AUTH_SERVER,
  (server) => new URL(PATIENTS_REGISTER_PATH, server).href
);
export const REFRESH_TOKEN_ENDPOINT = mapValues(
  AUTH_SERVER,
  (server) => new URL(REFRESH_TOKEN_PATH, server).href
);
