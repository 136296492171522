import React from 'react';
import { Modal } from 'react-native';
import { ModalContentContainer } from '@limbic-for-therapists/components';
import DayPicker from 'react-day-picker';
import dayjs, { Dayjs } from 'dayjs';

import 'react-day-picker/lib/style.css';
import './styles.css';

interface DatePickerProps {
  date?: Dayjs | null;
  active?: boolean;
  onClose?: () => void;
  onDayChange?: (date: Dayjs) => void;
}

export const DatePicker = ({ date, onClose, onDayChange, active = false }: DatePickerProps) => {
  return (
    <Modal transparent animationType="fade" visible={active}>
      <ModalContentContainer onClose={onClose}>
        <DayPicker onDayClick={(date) => onDayChange?.(dayjs(date))} month={date?.toDate()} />
      </ModalContentContainer>
    </Modal>
  );
};
