import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SetBehaviouralActionMessageProps {
  description?: string;
  formProps: UseFormMethods<{ message: string }>;
  onSubmit: () => void;
}

export const SetBehaviouralActionMessage = ({
  description,
  onSubmit,
  formProps,
}: SetBehaviouralActionMessageProps) => {
  const { control } = formProps;

  return (
    <ModalContent>
      <ModalContent.Title>What is the activity?</ModalContent.Title>
      <ModalContent.Body>{description}</ModalContent.Body>
      <Controller
        control={control}
        name="message"
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <ModalContent.TextArea
            testID="client-activity-text-input"
            forceValue
            placeholder="Type the client's activity here"
            scrollEnabled={false}
            value={value}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
          />
        )}
      />
      <ModalContent.CTAButton
        testID="set-the-day-button"
        title="Set the day"
        icon="chevron-right"
        onPress={onSubmit}
      />
    </ModalContent>
  );
};
