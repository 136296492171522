/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useState } from 'react';
import { noop } from '@limbic-for-therapists/components';
import { createContext } from 'react';
import { View } from 'react-native';
import { UseFormMethods, useForm } from 'react-hook-form';
import { NewPatientFromFormValidationSchema } from '../flows/NewPatientWithHWFlow/screens/NewPatientFromForm';

interface InviteFlowContextProps<T> {
  values: T;
  setValues: (partial: Partial<T>, overwriteValues?: boolean) => void;
  formProps: any;
}

const InviteFlowContext = createContext<InviteFlowContextProps<unknown>>({
  values: {},
  setValues: noop,
  formProps: {},
});

interface InviteFlowContextProviderProps<T extends Record<string, any>> {
  defaultValues: T;
  children: React.ReactNode | React.ReactNode[] | ((values: T) => React.ReactFragment);
}

export const InviteFlowContextProvider = <T extends Record<string, any>>({
  children,
  defaultValues,
}: InviteFlowContextProviderProps<T>) => {
  const [values, setInternalValues] = useState<T>(defaultValues);
  const formProps = useForm<UseFormMethods<NewPatientFromFormValidationSchema>>();

  const setValues = useCallback(
    (value: Partial<T>, overwriteValues = false) => {
      if (overwriteValues) {
        return setInternalValues(value as T);
      }
      return setInternalValues({ ...values, ...value });
    },
    [values]
  );

  return (
    <InviteFlowContext.Provider
      value={{
        values,
        setValues,
        formProps,
      }}
    >
      <View style={{ flexShrink: 1 }}>{children}</View>
    </InviteFlowContext.Provider>
  );
};

export const useInviteFlowContext = <T extends Record<string, any>>() =>
  useContext(InviteFlowContext) as InviteFlowContextProps<T>;
