import { gql, useQuery } from '@apollo/client';
import { fragments, IMoodQuestionnaireFragment } from '../fragments';

export const GET_QUESTIONNAIRE = gql`
  query getQuestionnaire($questionnaireId: String!) {
    getQuestionnaire(questionnaireId: $questionnaireId) {
      ...MoodQuestionnaire
    }
  }
  ${fragments.moodQuestionnaire}
`;

export interface IGetQuestionnaireResponse {
  getQuestionnaire: IMoodQuestionnaireFragment;
}

export interface IGetQuestionnaireVariables {
  questionnaireId: string;
}

export const useGetQuestionnaire = (questionnaireId: string) => {
  return useQuery<IGetQuestionnaireResponse, IGetQuestionnaireVariables>(GET_QUESTIONNAIRE, {
    variables: { questionnaireId },
  });
};
