import { useCallback } from 'react';
import { IWeeklyTriggerEvery } from '../models/QuestionnaireTriggers';
import { useAddPatientWeeklyQuestionnaireMutation } from '../mutations/addPatientWeeklyQuestionnaire';

interface UseDisableQuestionnaireProps {
  signUpCode: string;
  weeklyQuestionnaireTypes: IWeeklyTriggerEvery[];
}

export const useDisableQuestionnaire = ({
  weeklyQuestionnaireTypes,
  signUpCode,
}: UseDisableQuestionnaireProps) => {
  const [addPatientWeeklyQuestionnaire] = useAddPatientWeeklyQuestionnaireMutation();

  const disableQuestionnaire = useCallback(
    async (questionnaireType: string) => {
      const weekliesTriggeredEvery = (weeklyQuestionnaireTypes || []) //
        .filter((el) => el.type !== questionnaireType);
      const weeklyQuestionnaires = weekliesTriggeredEvery.map((el) => el.type);

      await addPatientWeeklyQuestionnaire({
        variables: {
          signUpCode,
          weekliesTriggeredEvery,
          weeklyQuestionnaires,
        },
      });
    },
    [addPatientWeeklyQuestionnaire, signUpCode, weeklyQuestionnaireTypes]
  );

  return { disableQuestionnaire };
};
