import React from 'react';
import { View, StyleProp, ViewStyle, Image, StyleSheet } from 'react-native';
import { Colors, Icons } from '../../config';
import { getIcon } from '../../utils/getIcon';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

type IconRowProps = React.PropsWithChildren<{
  content: string;
  iconName: keyof typeof Icons;
  style?: StyleProp<ViewStyle>;
}>;

export const IconRow = ({ content, iconName, style }: IconRowProps) => {
  const Icon = getIcon(iconName);

  return (
    <View style={[styles.row, style]}>
      <Image source={Icon} style={styles.image} />
      <LimbicText variant={TextVariants.Base} color={Colors.darkGrey2} style={styles.text}>
        {content}
      </LimbicText>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    marginRight: 10,
  },
  text: {
    lineHeight: 18,
  },
});
