import { ApolloClient, gql, useMutation } from '@apollo/client';
import { Dayjs } from 'dayjs';
import { RRule } from 'rrule';
import { InterventionStage, Period, Weekday } from '..';

function startingHourForPeriod(period: Period) {
  if (period === Period.morning) return 6;
  if (period === Period.afternoon) return 12;
  if (period === Period.evening) return 20;

  throw new Error(`Unknown period ${period}`);
}

export interface IAttachInterventionToPatientResponse {
  attachInterventionToPatient: { id: string };
}

const ATTACH_INTERVENTION_TO_PATIENT = gql`
  mutation attachInterventionToPatient(
    $interventionId: ID!
    $signupCode: String!
    $triggers: [InterventionTriggerInput!]!
  ) {
    attachInterventionToPatient(
      interventionId: $interventionId
      signupCode: $signupCode
      triggers: $triggers
    ) {
      id
    }
  }
`;

export interface InterventionTriggerInput {
  onStage?: InterventionStageInputTrigger;
  onRecurrence?: RecurrenceTriggerInput;
}

export interface InterventionStageInputTrigger {
  stage: InterventionStage;
}

export interface RecurrenceTriggerInput {
  period?: Period;
  rrule: string;
}

export interface IAttachInterventionToPatientVariables {
  interventionId: string;
  signupCode: string;
  triggers: InterventionTriggerInput[];
}

function mapWeekdayToRRule(day: Weekday) {
  switch (day) {
    case Weekday.Mo:
      return RRule.MO;
    case Weekday.Tu:
      return RRule.TU;
    case Weekday.We:
      return RRule.WE;
    case Weekday.Th:
      return RRule.TH;
    case Weekday.Fr:
      return RRule.FR;
    case Weekday.Sa:
      return RRule.SA;
    case Weekday.Su:
      return RRule.SU;
  }
}

interface useAttachInterventionToPatientMutationParams {
  interventionId: string;
  signupCode: string;
  startDate: Dayjs;
  periods: Set<Period>;
  repeatDays: Weekday[];
  endDate?: Dayjs;
}

export function useAttachInterventionToPatientMutation<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) {
  const [mutation, ...args] = useMutation<
    IAttachInterventionToPatientResponse,
    IAttachInterventionToPatientVariables
  >(ATTACH_INTERVENTION_TO_PATIENT, { client: titanClient });

  return [
    ({
      interventionId,
      signupCode,
      startDate,
      periods,
      repeatDays,
      endDate,
    }: useAttachInterventionToPatientMutationParams) => {
      const triggers: InterventionTriggerInput[] = [
        Period.morning,
        Period.afternoon,
        Period.evening,
      ]
        .filter((per) => periods.has(per))
        .map((period) => ({
          onRecurrence: {
            period,
            rrule: new RRule({
              freq: RRule.WEEKLY,
              dtstart: startDate.hour(startingHourForPeriod(period)).startOf('hour').toDate(),
              until: endDate?.toDate(),
              count: repeatDays.length > 0 ? undefined : 1,
              byweekday: repeatDays.length > 0 ? repeatDays.map(mapWeekdayToRRule) : undefined,
            }).toString(),
          },
        }));

      return mutation({
        variables: {
          interventionId,
          signupCode,
          triggers,
        },
        refetchQueries: ['getPatientInterventionsQuery'],
      });
    },
    ...args,
  ] as const;
}

const ATTACH_PSYCHOED_TO_PATIENT = gql`
  mutation AttachPsychoedToPatient($psychoedId: ID!, $signupCode: String!, $startAt: Date) {
    attachPsychoedToPatient(psychoedId: $psychoedId, signupCode: $signupCode, startAt: $startAt) {
      id
    }
  }
`;

type IAttachPsychoedToPatientResponse = {
  attachPsychoedToPatient: { id: string };
};

type IAttachPsychoedToPatientVariables = {
  psychoedId: string;
  signupCode: string;
  startAt?: string;
};

export function useAttachPsychoedToPatientMutation<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) {
  const [mutation, ...args] = useMutation<
    IAttachPsychoedToPatientResponse,
    IAttachPsychoedToPatientVariables
  >(ATTACH_PSYCHOED_TO_PATIENT, { client: titanClient });

  return [
    ({ psychoedId, signupCode, startAt }: IAttachPsychoedToPatientVariables) =>
      mutation({
        variables: {
          psychoedId,
          signupCode,
          startAt,
        },
        refetchQueries: ['getPatientPsychoedsQuery'],
        awaitRefetchQueries: true,
      }),
    ...args,
  ] as const;
}
