import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { client, titanClient } from '../client';

let _client: ApolloClient<NormalizedCacheObject>;
export function getApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (!_client) {
    _client = client;
  }
  return _client;
}

let _titanClient: ApolloClient<NormalizedCacheObject>;

export function getTitanApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (!_titanClient) {
    _titanClient = titanClient;
  }

  return _titanClient;
}
