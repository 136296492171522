import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const TOAST_WIDTH = 322;

export interface ToastDetails {
  title: string;
  subtitle?: string;
  variant?: 'green' | 'red';
}

interface ToastProps {
  details?: ToastDetails;
}

export const Toast = ({ details }: ToastProps) => {
  if (!details) return null;

  return (
    <View
      testID={details.variant === 'red' ? 'error-toast' : 'success-toast'}
      style={[
        styles.container,
        { backgroundColor: details.variant === 'red' ? Colors.red : Colors.green3 },
      ]}
    >
      <LimbicText variant={TextVariants.Base} color={Colors.white} bold>
        {details.title}
      </LimbicText>
      <LimbicText variant={TextVariants.M} color={Colors.white}>
        {details.subtitle}
      </LimbicText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    borderRadius: 16,
    width: TOAST_WIDTH,
    padding: 20,
  },
});
