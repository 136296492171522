import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Toggle } from '../Toggle/Toggle';

interface PushNotificationRowProps {
  text?: string;
  toggle: boolean;
  onToggle: () => void | Promise<void>;
}

export const PushNotificationRow: React.FC<PushNotificationRowProps> = ({
  text,
  onToggle,
  toggle,
}) => {
  return (
    <View style={styles.row}>
      <View style={styles.textBox}>
        <LimbicText variant={TextVariants.Base} color={Colors.black}>
          {text}
        </LimbicText>
      </View>
      <View style={styles.togglePadding}>
        <Toggle onToggle={onToggle} toggle={toggle} />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  row: {
    paddingVertical: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.grey3,
  },
  textBox: { justifyContent: 'center', paddingLeft: 10 },
  togglePadding: { paddingRight: 10 },
});
