import React, { useState, useContext, createContext, useEffect, useRef, useCallback } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import { ToastDetails, Toast, getShadowStyles, Colors } from '@limbic-for-therapists/components';
import {
  fixedPositionStyle,
  timeout,
  pointerEventsStyle,
  DEFAULT_ERROR_MESSAGE,
} from '@limbic-for-therapists/shared';

// 5 seconds
const DEFAULT_PAUSE_TIME = 5000;

interface ToastContextProps {
  children?: React.ReactNode;
}

const ToastContext = createContext<{
  toast?: ToastDetails;
  setToast: (toast?: ToastDetails) => void;
  setErrorToast: (error: string, title?: string) => void;
}>({
  toast: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToast: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorToast: () => {},
});

const ToastContextProvider = ({ children }: ToastContextProps) => {
  const animatedValue = useRef<Animated.Value>(new Animated.Value(0));
  const timer = useRef<NodeJS.Timeout | undefined>();
  const [toast, setToast] = useState<ToastDetails | undefined>();
  const [internalToast, setInternalToast] = useState<ToastDetails | undefined>();

  const setErrorToast = useCallback((error: string, title?: string) => {
    setToast({ title: title || DEFAULT_ERROR_MESSAGE, subtitle: error, variant: 'red' });
  }, []);

  useEffect(() => {
    (async () => {
      if (toast) {
        setInternalToast(undefined);
        if (timer.current) clearTimeout(timer.current);
        timer.current = undefined;
      }
      await timeout(250);
      setInternalToast(toast);
    })();
  }, [toast]);

  useEffect(() => {
    if (internalToast) timer.current = setTimeout(() => setToast(undefined), DEFAULT_PAUSE_TIME);
    return () => (timer.current = undefined);
  }, [internalToast]);

  useEffect(() => {
    Animated.spring(animatedValue.current, {
      toValue: internalToast && toast ? 1 : 0,
      useNativeDriver: false,
    }).start();
  }, [internalToast, toast]);

  const values = { toast, setToast, setErrorToast };

  return (
    <ToastContext.Provider value={values}>
      {children}
      <View style={[styles.container, fixedPositionStyle, pointerEventsStyle('none')]}>
        <Animated.View
          style={[
            {
              transform: [
                {
                  translateY: animatedValue.current.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-200, 0],
                  }),
                },
              ],
            },
            styles.toast,
            pointerEventsStyle('auto'),
          ]}
        >
          <Toast details={internalToast} />
        </Animated.View>
      </View>
    </ToastContext.Provider>
  );
};

const useToastContext = () => useContext(ToastContext);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  toast: {
    marginTop: 20,
    marginRight: 20,
    borderRadius: 16,
    ...getShadowStyles(Colors.black, 0.4, 10),
  },
});

export { ToastContextProvider, useToastContext };
