import { ApolloClient, gql, useMutation } from '@apollo/client';

const DETACH_INTERVENTION_FROM_PATIENT = gql`
  mutation ($patientInterventionId: ID!) {
    detachInterventionFromPatient(patientInterventionId: $patientInterventionId)
  }
`;

interface IDetachInterventionFromPatientVariables {
  patientInterventionId: string;
}

export const useDetachInterventionFromPatientMutation = <
  TCacheShape extends Record<string, unknown>
>(
  titanClient: ApolloClient<TCacheShape>
) => {
  return useMutation<undefined, IDetachInterventionFromPatientVariables>(
    DETACH_INTERVENTION_FROM_PATIENT,
    {
      refetchQueries: ['getPatientInterventionsQuery'],
      awaitRefetchQueries: true,
      client: titanClient,
    }
  );
};
