/**
 * This is a beautiful string manipulation function
 * inspired by the stackoverflow formatUnicorn.
 * It enables string subbing using curly braces as the delimiter.
 * @param {string} data The translation key.
 * @param {Object} values The values to substitute in the string
 * @returns {string}
 *
 * @example: formatUnicorn("Hi {name}!", { name: "George" }) // 'Hi George!'
 */
export const formatUnicorn = (data: string, values?: Record<string, string>): string => {
  try {
    if (!values || typeof data !== 'string') {
      return data;
    }

    let string = data;
    for (const value in values) {
      // noinspection JSUnfilteredForInLoop
      const valueRegExp = new RegExp(`{${value}}`, 'gi');
      // noinspection JSUnfilteredForInLoop
      string = string.replace(valueRegExp, values[value]);
    }

    return string;
  } catch (e) {
    return data;
  }
};
