import { gql, useMutation } from '@apollo/client';

export interface IAddPatientWeeklyQuestionnaireResponse {
  addPatientWeeklyQuestionnaire: { id: string };
}

const ADD_PATIENT_WEEKLY_QUESTIONNAIRE = gql`
  mutation addPatientWeeklyQuestionnaire(
    $signUpCode: String!
    $weeklyQuestionnaires: [String]!
    $iesrTrauma: String
    $weekliesTriggeredEvery: [WeekliesTriggeredEveryInput]
  ) {
    addPatientWeeklyQuestionnaire(
      code: $signUpCode
      weeklyQuestionnaires: $weeklyQuestionnaires
      iesrTrauma: $iesrTrauma
      weekliesTriggeredEvery: $weekliesTriggeredEvery
    ) {
      id
    }
  }
`;

export interface IAddPatientWeeklyQuestionnaireVariables {
  signUpCode: string;
  weeklyQuestionnaires: string[];
  iesTrauma?: string;
  // eslint-disable-next-line
  weekliesTriggeredEvery?: any[];
}

export function useAddPatientWeeklyQuestionnaireMutation() {
  return useMutation<
    IAddPatientWeeklyQuestionnaireResponse,
    IAddPatientWeeklyQuestionnaireVariables
  >(ADD_PATIENT_WEEKLY_QUESTIONNAIRE, {
    refetchQueries: ['getPatientScreenQuery', 'getPatient'],
    awaitRefetchQueries: true,
  });
}
