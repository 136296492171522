import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  AccountAndNotificationRow,
  Colors,
  LimbicText,
  TextVariants,
} from '@limbic-for-therapists/components';
import { useActiveClinicianContext } from '../context/ActiveClinician';
import { ScreenContainer } from '../components/ScreenContainer';
import { useModalContext } from '../context/Modal';
import { EditNameScreen } from './EditNameScreen';
import { EditEmailScreen } from './EditEmailScreen';
import { EditPasswordScreen } from './EditPasswordScreen';
import { GridColumn, GridContainer, GridRow } from '../components/ResponsiveGrid';
import { Intercom } from '../components/Intercom/Intercom';
import { useToastContext } from '../context/Toast';
import { MedicalDeviceInformation } from '../components/MedicalDeviceInformation/MedicalDeviceInformation';

const SuccessToastMap = {
  name: {
    title: 'Successfully updated name!',
    subtitle: undefined,
  },
  email: {
    title: 'Successfully updated email address!',
    subtitle: 'Next time you log in, please ensure you use the new email address.',
  },
  password: {
    title: 'Successfully updated password!',
    subtitle: 'Next time you log in, please use your new password.',
  },
};

export const AccountScreen = () => {
  const { setToast } = useToastContext();
  const { logOut, fetchActiveClinician } = useActiveClinicianContext();
  const [intercomOpen, setIntercomOpen] = useState<boolean>(false);
  const { setContent, dismissModal } = useModalContext();
  const { activeClinician } = useActiveClinicianContext();

  const handleUpdatedUser = useCallback(
    (type: 'name' | 'email' | 'password') => {
      fetchActiveClinician();
      const { title, subtitle } = SuccessToastMap[type];
      setToast({
        title,
        subtitle,
      });
      dismissModal();
    },
    [dismissModal, fetchActiveClinician, setToast]
  );

  const handlePressedName = useCallback(() => {
    setContent(<EditNameScreen onSuccess={() => handleUpdatedUser('name')} />);
  }, [handleUpdatedUser, setContent]);

  const handlePressedEmail = useCallback(() => {
    setContent(<EditEmailScreen onSuccess={() => handleUpdatedUser('email')} />);
  }, [handleUpdatedUser, setContent]);

  const handlePressedPassword = useCallback(() => {
    setContent(<EditPasswordScreen onSuccess={() => handleUpdatedUser('password')} />);
  }, [handleUpdatedUser, setContent]);

  const handlePressedHelpAndFeedback = useCallback(() => {
    setIntercomOpen(true);
  }, []);

  const handlePressedLogOut = useCallback(() => {
    logOut();
  }, [logOut]);

  return (
    <>
      <ScreenContainer backPath="/dashboard" backgroundColor={Colors.grey1} title="My Account">
        <GridContainer>
          <GridRow>
            <GridColumn span={12}>
              <LimbicText variant={TextVariants.XL} color={Colors.black} style={styles.header}>
                Account
              </LimbicText>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn span={12}>
              <AccountAndNotificationRow
                testID="account-name"
                iconName="right"
                placeHolder="Name"
                data={activeClinician?.name}
                onPress={handlePressedName}
              />
              <AccountAndNotificationRow
                testID="account-email"
                iconName="right"
                placeHolder="Email"
                data={activeClinician?.email}
                onPress={handlePressedEmail}
              />
              <AccountAndNotificationRow
                testID="account-password"
                iconName="right"
                placeHolder="Password"
                data="****"
                onPress={handlePressedPassword}
              />
              <AccountAndNotificationRow
                iconName="right"
                placeHolder="Clinic"
                data={process.env.REACT_APP_CLINIC_ID}
              />
              <AccountAndNotificationRow
                iconName="right"
                placeHolder="Instructions for use"
                isLocalAssetDownloadLink
                localAssetPath="/manuals/Dashboard-Manual-V01.pdf"
              />
              <AccountAndNotificationRow
                testID="account-feedback"
                iconName="right"
                placeHolder="Help &amp; Feedback"
                onPress={handlePressedHelpAndFeedback}
              />
              <AccountAndNotificationRow
                testID="account-logout"
                iconName="right"
                placeHolder="Log Out"
                onPress={handlePressedLogOut}
              />
            </GridColumn>
          </GridRow>
          <MedicalDeviceInformation />
        </GridContainer>
      </ScreenContainer>
      <Intercom open={intercomOpen} onClose={() => setIntercomOpen(false)} />
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    marginVertical: 10,
    marginLeft: 10,
  },
  cardContainer: {
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.grey3,
    minHeight: 65,
    marginHorizontal: 10,
  },
  placeHolder: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingRight: 10,
  },
  medicalDeviceRow: {
    paddingHorizontal: 10,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  medicalDeviceIconRow: {
    marginVertical: 10,
    flexGrow: 0,
    alignItems: 'center',
    flexDirection: 'row',
  },
  medicalDeviceTextContainer: {
    marginLeft: 10,
  },
  medicalDeviceText: {
    fontWeight: '500',
    lineHeight: 14,
  },
  medicalDeviceTextBold: {
    fontWeight: '700',
  },
});
