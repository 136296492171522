import React from 'react';
import { StyleSheet, TouchableOpacity, View, StyleProp, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export interface PickerHeaderProps {
  icon?: string;
  title?: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}

export const PickerHeader = ({ onPress, title, style }: PickerHeaderProps) => {
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <LimbicText variant={TextVariants.Base} color={Colors.darkGrey2} style={styles.textStyle}>
        {title}
      </LimbicText>
      <View style={styles.iconContainer}>
        <Icon name="ios-caret-down-outline" size={18} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    width: 247,
    borderColor: Colors.darkGrey1,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 22,
    marginHorizontal: 4,
  },
  iconContainer: { justifyContent: 'center' },
  textStyle: { paddingVertical: 16, paddingLeft: 15 },
});
