import { Dayjs } from 'dayjs';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Header } from '..';
import { Colors } from '../../config';
import { Button } from '../Button/Button';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SetBehaviouralActionDayProps {
  isTomorrow: boolean;
  startTitle: string;
  actionTitle: string;
  startDate?: Dayjs;
  onPressedToday: () => void;
  onPressedPickAStartDate: () => void;
  onSubmit: () => void;
}

export const SetPsychoedDate = ({
  isTomorrow,
  startTitle,
  startDate,
  actionTitle,
  onPressedPickAStartDate,
  onPressedToday,
  onSubmit,
}: SetBehaviouralActionDayProps) => {
  return (
    <View>
      <Header
        tag="Step 2"
        title="Schedule the Intervention (optional)"
        subtitle={'If you want to, you can pick a day to start the intervention'}
      />

      <View style={[styles.datesContainer, styles.section]}>
        <Button
          testID="behavioural-action-tomorrow-button"
          small
          fixed
          title="Today"
          style={[
            styles.pickDateButton,
            styles.pickDateButtonSpacer,
            isTomorrow && styles.pickDateButtonActive,
          ]}
          textStyle={[
            styles.pickDateButtonText, //
            isTomorrow && styles.pickDateButtonTextActive,
          ]}
          onPress={onPressedToday}
        />

        <Button
          testID="behavioural-action-pick-a-date-button"
          small
          fixed
          title={startTitle ?? 'Start date'}
          icon="calendar"
          iconProps={{ size: 15, color: startDate ? Colors.white : Colors.darkGrey2 }}
          style={[styles.pickDateButton, !!startDate && styles.pickDateButtonActive]}
          textStyle={[
            styles.pickDateButtonText, //
            !!startDate && styles.pickDateButtonTextActive,
          ]}
          onPress={onPressedPickAStartDate}
        />
      </View>

      <View style={{ marginTop: 0 }}>
        <ModalContent.CTAButton
          testID="recurrence-rule-next-button"
          title={actionTitle}
          icon={'chevron-right'}
          style={{ flexGrow: 1, marginRight: 10 }}
          onPress={onSubmit}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  datesContainer: {
    flexDirection: 'row',
  },
  pickDateButton: {
    paddingHorizontal: 8,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
    alignSelf: 'flex-start',
  },
  pickDateButtonSpacer: {
    marginRight: 16,
  },
  pickDateButtonActive: {
    borderColor: Colors.pink1,
    backgroundColor: Colors.pink1,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
  pickDateButtonTextActive: {
    color: Colors.white,
  },
  bodyTitle: {
    color: Colors.darkGrey2,
    paddingBottom: 0,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  periods: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  repeat: {},
  recurringOptions: {},
  section: {
    marginBottom: 24,
  },
  subSection: {
    marginBottom: 8,
  },
});
