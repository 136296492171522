/* eslint-disable no-plusplus */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SectionListData } from 'react-native';
import { IMoodQuestionnaireFragment } from '../fragments';
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const format = `DD-MM-YYYY`;

export interface IMoodLogGroup {
  date: Date;
  logs: Array<IMoodQuestionnaireFragment & { index: number }>;
}

export const groupMoodLogsByDate = (data: IMoodQuestionnaireFragment[]): IMoodLogGroup[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const datesMap: Record<string, any[]> = {};

  for (let i = 0, { length } = data; i < length; i++) {
    const moodLog = data[i];
    const date = dayjs.utc(moodLog.moodEvent.startDate).format(format);
    datesMap[date] = [...(datesMap[date] || []), moodLog];
  }
  const groups = Object.keys(datesMap)
    .sort((a, b) => dayjs.utc(a, format).unix() - dayjs.utc(b, format).unix())
    .map((date) => {
      return {
        date: dayjs(date, format).toDate(),
        logs: datesMap[date],
      };
    });
  let counter = 0;
  for (let i = 0; i < groups.length; i++) {
    for (let j = 0; j < groups[i].logs.length; j++) {
      groups[i].logs[j] = { ...groups[i].logs[j], index: counter };
      counter++;
    }
  }
  return groups;
};

interface MoodLogsSection extends SectionListData<IMoodQuestionnaireFragment & { index: number }> {
  sectionIndex: number;
  date: Date;
}

export type MoodLogsSectionList = MoodLogsSection[];

export function moodLogGroupsToSectionList(groups: IMoodLogGroup[]): MoodLogsSectionList {
  return groups.map((g, index) => ({
    sectionIndex: index,
    date: g.date,
    data: g.logs,
  }));
}
