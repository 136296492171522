import { ApolloClient, gql, useQuery } from '@apollo/client';
import { Stage } from '@limbic-for-therapists/shared';

const GET_CLINIC_BY_DOMAIN = gql`
  query getClinicByDomain($domain: String!) {
    getClinicByDomain(domain: $domain) {
      id
      name
      domain
      metadata
      featureFlags
    }
  }
`;

export type IGetClinicByDomainResponse = {
  getClinicByDomain: {
    id: string;
    name: string;
    domain: string;
    metadata: string;
    featureFlags?: string[];
  };
};

export type IGetClinicByDomainVariables = {
  domain: string;
};

export const useGetClinicByDomain = <TCacheShape extends Record<string, unknown>>(
  subdomain: string | undefined,
  stage: Stage,
  titanClient: ApolloClient<TCacheShape>
) => {
  if (stage === 'development') {
    console.warn('Cannot get clinic by domain is development mode, will default to staging');
  }

  return useQuery<IGetClinicByDomainResponse, IGetClinicByDomainVariables>(GET_CLINIC_BY_DOMAIN, {
    skip: typeof subdomain === undefined,
    client: titanClient,
    variables: {
      domain: `${subdomain}${stage !== 'production' ? '-staging' : ''}.limbic.ai`,
    },
  });
};
