import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config/colors';

export const EmotionCircleSizeMap = {
  sm: 38,
  md: 46,
  lg: 55,
  xlg: 55,
};

const LARGEST_EMOTION_CIRCLE_SIZE = [...Object.values(EmotionCircleSizeMap)].pop();

interface EmotionCircleProps {
  emoji: string;
  color?: string;
  size: 'sm' | 'md' | 'lg' | 'xlg';
}

export const EmotionCircle = (props: EmotionCircleProps) => {
  const { emoji, color, size } = props;
  return (
    <View style={styles.container}>
      <View
        style={{
          ...styles.circle,
          width: EmotionCircleSizeMap[size],
          height: EmotionCircleSizeMap[size],
          borderRadius: EmotionCircleSizeMap[size] / 2,
          backgroundColor: color || Colors.grey3,
        }}
      >
        <LimbicText variant={TextVariants.XL} style={styles.emoji}>
          {emoji}
        </LimbicText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: LARGEST_EMOTION_CIRCLE_SIZE,
    height: LARGEST_EMOTION_CIRCLE_SIZE,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  emoji: {
    lineHeight: 42,
  },
});
