import { gql, useMutation } from '@apollo/client';
import { fragments, ITherapistDigestFragment } from '../fragments';

export interface ICreateTherapistDigestResponse {
  createTherapistDigest: ITherapistDigestFragment;
}

const CREATE_THERAPIST_DIGEST = gql`
  mutation createTherapistDigest(
    $signUpCode: String!
    $clinicianId: ID!
    $fromDate: Date!
    $toDate: Date!
  ) {
    createTherapistDigest(
      code: $signUpCode
      clinicianId: $clinicianId
      dateFrom: $fromDate
      dateTo: $toDate
    ) {
      ...TherapistDigest
    }
  }
  ${fragments.therapistDigest}
`;

export interface ICreateTherapistDigestVariables {
  signUpCode: string;
  clinicianId: string;
  fromDate: Date;
  toDate: Date;
}

export const useCreateTherapistDigestMutation = () => {
  return useMutation<ICreateTherapistDigestResponse, ICreateTherapistDigestVariables>(
    CREATE_THERAPIST_DIGEST,
    {
      refetchQueries: ['listTherapistReportsForPatient'],
      awaitRefetchQueries: true,
    }
  );
};
