import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SetCopingStrategyMessageProps {
  body: React.ReactNode;
  formProps: UseFormMethods<{ message: string }>;
  loading: boolean;
  onSubmit: () => void;
  onBackPress: () => void;
}

export const SetCopingStrategyMessage = ({
  body,
  formProps,
  loading,
  onSubmit,
}: SetCopingStrategyMessageProps) => {
  const { control } = formProps;

  return (
    <ModalContent>
      <ModalContent.Title>Set your strategy</ModalContent.Title>
      <ModalContent.Body>{body}</ModalContent.Body>
      <Controller
        control={control}
        name="message"
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <ModalContent.TextArea
            testID="enter-strategy-input"
            forceValue
            placeholder="Type your activity here"
            scrollEnabled={false}
            value={value}
            onBlur={onBlur}
            onChangeText={(value) => onChange(value)}
          />
        )}
      />
      <ModalContent.CTAButton
        testID="add-strategy-button"
        title="Add strategy"
        loading={loading}
        onPress={onSubmit}
      />
    </ModalContent>
  );
};
