import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import { Colors, LimbicText, TextVariants } from '@limbic-for-therapists/components';

interface Props {
  fromDate: string;
  toDate: string;
  moodLogGraph?: React.ReactNode;
  totalMoodLogs: number;
  daysSpan: number;
}

export const ReportScreenHeader = ({
  fromDate,
  toDate,
  totalMoodLogs,
  moodLogGraph,
  daysSpan,
}: Props) => {
  const headerContainerAnimationValue = useRef<Animated.Value>(new Animated.Value(0)).current;
  const top = headerContainerAnimationValue.interpolate({
    inputRange: [0, 1],
    outputRange: [20, 0],
  });

  useEffect(() => {
    Animated.timing(headerContainerAnimationValue, {
      toValue: 1,
      duration: 800,
      useNativeDriver: false,
    }).start();
  }, [headerContainerAnimationValue]);

  return (
    <Animated.View style={[styles.container, { top, opacity: headerContainerAnimationValue }]}>
      <View>
        <LimbicText variant={TextVariants.XS} color={Colors.pink1}>
          Report
        </LimbicText>
        <LimbicText variant={TextVariants.XXL} color={Colors.white} style={styles.dateText}>
          {fromDate}
        </LimbicText>
        <LimbicText bold variant={TextVariants.XXL} color={Colors.white} style={styles.dateText}>
          {toDate}
        </LimbicText>
        <View style={styles.subtitleContainer}>
          <LimbicText variant={TextVariants.S} color={Colors.white}>
            Our insights based on {totalMoodLogs} logs over {daysSpan} days
          </LimbicText>
        </View>
      </View>
      {!!moodLogGraph && <View style={styles.logGraphContainer}>{moodLogGraph}</View>}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subtitleContainer: {
    marginTop: 5,
    width: 200,
  },
  dateText: {
    // 1.2
    lineHeight: 40 * 1.2,
  },
  logGraphContainer: {
    paddingTop: 20,
    paddingBottom: 20,
  },
});
