import { ApolloClient, gql, useMutation } from '@apollo/client';
import { fragments, IInterventionFragment } from '../fragments';

export interface ICreateInterventionResponse {
  createIntervention: IInterventionFragment;
}

const CREATE_INTERVENTION = gql`
  mutation createIntervention($name: String!) {
    createIntervention(name: $name) {
      ...Intervention
    }
  }
  ${fragments.intervention}
`;

export interface ICreateInterventionVariables {
  name: string;
}

export function useCreateInterventionMutation<TCacheShape extends Record<string, unknown>>(
  name: string,
  client: ApolloClient<TCacheShape>
) {
  return useMutation<ICreateInterventionResponse, ICreateInterventionVariables>(
    CREATE_INTERVENTION,
    { variables: { name }, client }
  );
}
