import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from '../../config';
import { IconRow } from '../IconRow/IconRow';
import { ModalContent } from '../LimbicModal/ModalContent';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Line } from '../Line/Line';

interface InviteClientProps {
  signUpCode?: string;
  emailTemplate: string;
  copiedToClipboard?: boolean;
  onSaveSignUpCodeToClipboard: () => void;
  onSaveEmailToClipboard: () => void;
  onSaveEmailToClipboardAndOpeMailApp: () => void;
  onClose?: () => void;
}

export const InviteClient = ({
  signUpCode,
  emailTemplate,
  copiedToClipboard = false,
  onSaveSignUpCodeToClipboard,
  onSaveEmailToClipboard,
  onSaveEmailToClipboardAndOpeMailApp,
  onClose,
}: InviteClientProps) => {
  return (
    <>
      <ModalContent scrollable>
        <ModalContent.Title>Invite the client</ModalContent.Title>
        <ModalContent.Body>
          Almost there. Last step is to let your client install the Limbic app.
        </ModalContent.Body>

        <View style={styles.vSpacer} />
        <Line />
        <View style={styles.vSpacer} />

        <IconRow iconName="users" content="Client is sitting next to me" />
        <ModalContent.Body>
          They could install the Limbic app and provide this personal code when asked.
        </ModalContent.Body>

        <TouchableOpacity onPress={onSaveSignUpCodeToClipboard}>
          <View style={styles.signUpCodeLine}>
            <LimbicText color={Colors.pink4} variant={TextVariants.Base} style={styles.text}>
              {signUpCode}
            </LimbicText>
          </View>
        </TouchableOpacity>

        <View style={styles.vSpacer} />
        <View style={styles.vSpacer} />
        <Line />
        <View style={styles.vSpacer} />

        <IconRow iconName="mail" content="Invite by e-mail" />
        <ModalContent.Body>
          Send this email with explanation, code and links to your patient.
        </ModalContent.Body>

        <ModalContent.TextArea
          editable={false}
          value={emailTemplate}
          style={[styles.text, styles.emailText]}
        />

        <View style={styles.vSpacer} />

        <ModalContent.CTAButton
          outline={!copiedToClipboard}
          title={copiedToClipboard ? 'copied to clipboard!' : 'copy message to clipboard'}
          onPress={onSaveEmailToClipboard}
        />

        <View style={styles.vSpacer} />

        <ModalContent.CTAButton
          title="Open e-mail app"
          subTitle="message will be copied for you"
          onPress={onSaveEmailToClipboardAndOpeMailApp}
        />
        <View style={styles.vSpacer} />

        <ModalContent.CTAButton
          title="Back to home"
          fixed={false}
          style={styles.button}
          textStyle={styles.buttonText}
          onPress={onClose}
        />
      </ModalContent>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  vSpacer: {
    height: 16,
  },
  signUpCodeLine: {
    height: 48,
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: Colors.grey1,
    borderRadius: 8,
  },
  emailText: {
    color: Colors.pink2,
  },
  button: {
    backgroundColor: Colors.white,
  },
  buttonText: {
    color: Colors.darkGrey1,
  },
  text: {
    lineHeight: 18,
  },
});
