import React, { ReactElement } from 'react';
import { StyleSheet, TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import { getShadowStyles } from '../../config';
import { Colors } from '../../config/colors';
import { TouchableActiveOpacity } from '../../consts/touchable';

interface ContentCardProps {
  onPress?: () => void;
  children: ReactElement;
  style?: StyleProp<ViewStyle>;
  minHeight?: number;
  width?: number;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  children,
  style,
  minHeight = 266,
  width = 205,
  onPress,
}: ContentCardProps) => {
  return (
    <TouchableOpacity
      activeOpacity={TouchableActiveOpacity.mid}
      onPress={onPress}
      style={[styles.cardContainer, style, { minHeight, width }]}
    >
      {children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: Colors.pink1,
    borderRadius: 15.2,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    ...getShadowStyles(Colors.black, 0.2, 1.41, 0, 2),
    elevation: 2,
  },
});
