import { ApolloClient, gql, useQuery } from '@apollo/client';

const GET_CLINIC_BY_ID = gql`
  query clinic($id: ID!) {
    clinic(id: $id) {
      id
      name
      domain
      metadata
      featureFlags
    }
  }
`;

export type IGetClinicByIdResponse = {
  clinic: {
    id: string;
    name: string;
    domain: string;
    metadata: string;
    featureFlags?: string[];
  };
};

export type IGetClinicByIdVariables = {
  id: string;
};

export const useGetClinicById = <TCacheShape extends Record<string, unknown>>(
  id: string | undefined,
  titanClient: ApolloClient<TCacheShape>
) => {
  return useQuery<IGetClinicByIdResponse, IGetClinicByIdVariables>(GET_CLINIC_BY_ID, {
    client: titanClient,
    variables: {
      id: id ?? '',
    },
    skip: !id,
  });
};
