export enum Colors {
  white = '#FFFFFF',
  grey1 = '#F6F6F6',
  grey2 = '#EBEBF0',
  grey3 = '#C5C6CD',
  grey4 = '#EDEDED',
  grey5 = '#EFF0F5',
  grey6 = '#8389AF',
  grey7 = '#C1C4D7',
  darkGrey1 = '#9FA2B4',
  darkGrey2 = '#393E60',
  darkGrey3 = '#4B506D',
  darkGrey4 = '#252733',
  darkGrey5 = '#212645',
  // SP: Using RGBA to allow backgrounds to have semi-opaque
  // backgrounds without altering the opacity of the contents.
  // TODO: Might be worth looking into converting all of these hexes
  // into RGBA for consistency?
  'darkGrey3-eighty' = 'rgba(75,80,109,0.8)',
  'darkGrey3-sixty' = 'rgba(75,80,109,0.6)',
  'darkGrey3-forty' = 'rgba(75,80,109,0.4)',
  'darkGrey3-twenty' = 'rgba(75,80,109,0.4)',
  black = '#1F2133',
  black2 = '#252733',
  pink1 = '#FF9BB4',
  pink2 = '#FD81A0',
  pink3 = '#FD6D91',
  pink4 = '#FC5881',
  pink5 = '#FB1D54',
  pink6 = '#F174B4',
  red = '#ee1d1d',
  green1 = '#5DDEB4',
  green2 = '#40D9A7',
  green3 = '#29CC97',
  purple = '#3F456B',
  purpleDark = '#343958',
  transparentPink1 = 'rgba(255, 155, 180, 0.1)',
  secondaryOnLight = '#4F557D',
  primaryBlue93 = '#E9EAF1',
  primaryBlue70 = '#A2A7C3',
  primaryOnLight = '#212645',
}

export enum PositivityColors {
  negative10 = '#FB1D54',
  negative9 = '#FB1D54',
  negative8 = '#FB1D54',
  negative7 = '#FC5881',
  negative6 = '#FC5881',
  negative5 = '#FD6D91',
  negative4 = '#FD6D91',
  negative3 = '#FD81A0',
  negative2 = '#FF9BB4',
  negative1 = '#f8dee5',
  neutral1 = '#ffffff',
  neutral2 = '#e5fcf4',
  neutral3 = '#cefce9',
  neutral4 = '#bafae0',
  neutral5 = '#8ff3d2',
  positive1 = '#5ddeb4',
  positive2 = '#5DDEB4',
  positive3 = '#40D9A7',
  positive4 = '#40D9A7',
  positive5 = '#29CC97',
}
