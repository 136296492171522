import { ApolloClient, gql, useMutation } from '@apollo/client';
import { fragments, IInterventionFragment } from '../fragments';

export interface IPublishInterventionResponse {
  publishIntervention: IInterventionFragment;
}

const PUBLISH_INTERVENTION = gql`
  mutation publishIntervention($id: ID!) {
    publishIntervention(id: $id) {
      ...Intervention
    }
  }
  ${fragments.intervention}
`;

export interface IPublishInterventionVariables {
  id: string;
}

export function usePublishInterventionMutation<TCacheShape extends Record<string, unknown>>(
  id: string,
  client: ApolloClient<TCacheShape>
) {
  return useMutation<IPublishInterventionResponse, IPublishInterventionVariables>(
    PUBLISH_INTERVENTION,
    { variables: { id }, client, refetchQueries: ['listInterventions'] }
  );
}
