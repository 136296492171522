import { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { CustomFrequency } from '../models/customFrequency';
import { RecurrenceFrequency } from '../models/IRecurrenceInput';
import { IAddSessionDateVariables, useAddSessionDateMutation } from '../mutations/addSessionDate';
import { getRecurrenceInput } from '../utils/recurrence';

interface ChangeSessionDateInput {
  signUpCode: string;
  sessionDate: Dayjs;
  recurrenceFrequency?: CustomFrequency | null;
}

export const useChangeSessionDate = () => {
  const [addSessionDate, { loading }] = useAddSessionDateMutation();

  const changeSessionDate = useCallback(
    async ({ signUpCode, sessionDate, recurrenceFrequency }: ChangeSessionDateInput) => {
      const recursiveAppointment = recurrenceFrequency
        ? getRecurrenceInput({
            startDate: sessionDate.toDate(),
            interval: recurrenceFrequency.duration,
            freq: recurrenceFrequency.type as RecurrenceFrequency.Month | RecurrenceFrequency.Week,
            weekdays: recurrenceFrequency.days,
          })
        : undefined;

      const variables: IAddSessionDateVariables = {
        signUpCode,
        sessionDate: sessionDate.toDate(),
        recursiveAppointment,
      };
      await addSessionDate({ variables });
    },
    [addSessionDate]
  );

  return { changeSessionDate, loading };
};
