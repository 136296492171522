import NetworkError from '../models/NetworkError';
import { IRegisterCredentials } from '../models/IRegisterCredentials';
import { REGISTER_ENDPOINT } from '@limbic-for-therapists/shared';
import { Stage } from '../consts/stage';

const method = 'POST';
const headers = new Headers();
headers.set('Content-Type', 'application/json');

export interface RegisterResponse {
  id: string;
}

export const register = async (
  stage: Stage,
  payload: IRegisterCredentials
): Promise<RegisterResponse> => {
  const REGISTER_URL = REGISTER_ENDPOINT[stage];
  const body = JSON.stringify(payload);
  const result = await fetch(REGISTER_URL, { body, method, headers });
  const { data } = await result.json();
  if (data?.error) {
    throw new NetworkError(data?.code, data?.error);
  }
  if (data?.message) {
    throw new NetworkError(data?.code, data?.message);
  }
  return data as RegisterResponse;
};
