import React, { useEffect, useMemo } from 'react';
import { useGetClinicById } from '@limbic-for-therapists/backend';
import { Loading } from '@limbic-for-therapists/components';
import { getFeatureFlags, FeatureFlags } from '@limbic-for-therapists/shared';
import { useActiveClinicianContext } from './ActiveClinician';
import { getTitanApolloClient } from '../backend/helpers/getApolloClient';

interface FeatureFlagsFilterContextProps {
  featureFlags: FeatureFlags;
}

const ctx = React.createContext<FeatureFlagsFilterContextProps | null>(null);

export const FeatureFlagsFilterProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const titanClient = getTitanApolloClient();
  const { activeClinician } = useActiveClinicianContext();
  const { data: clinicResponse, loading } = useGetClinicById(
    activeClinician?.clinic.id,
    titanClient
  );

  const featureFlags = useMemo(
    () => getFeatureFlags(clinicResponse?.clinic.featureFlags),
    [clinicResponse?.clinic.featureFlags]
  );

  useEffect(() => {
    // Adding featureFlags to the window element for Cypress testing
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).featureFlags = featureFlags;
    }
  }, [featureFlags]);

  const value: FeatureFlagsFilterContextProps = { featureFlags };
  if (loading) return <Loading />;
  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export const useFeatureFlagsContext = () => {
  const context = React.useContext(ctx);
  if (!context) {
    throw new Error('useFeatureFlagsFilter must be used within a FeatureFlagsFilterProvider');
  }
  return context;
};
