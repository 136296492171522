import React from 'react';
import { TouchableOpacity, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { TouchableActiveOpacity } from '../../consts';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config';

const ICON_SIZE = 24;

interface LocalCopingStrategyFeedbackPreviewProps {
  count: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export const LocalCopingStrategyFeedbackPreview = ({
  count,
  onPress,
  style,
}: LocalCopingStrategyFeedbackPreviewProps) => {
  return (
    <TouchableOpacity
      testID="local-coping-strategy-feedback-preview"
      onPress={onPress}
      activeOpacity={TouchableActiveOpacity.light}
      style={[styles.summaryCardContainer, style]}
    >
      <SummaryCard
        title={`${count}x default coping strategies`}
        subtitle="For a lot of emotions we offer direct help"
        subtitleVariant={TextVariants.S}
        showLine={false}
        leftAccessory={<Icon name="help-buoy-outline" color={Colors.darkGrey3} size={ICON_SIZE} />}
        bottomAccessory={
          <View style={{ alignItems: 'flex-end' }}>
            <LimbicText variant={TextVariants.S} color={Colors.grey3}>
              show client feedback
            </LimbicText>
          </View>
        }
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  summaryCardContainer: {
    paddingVertical: 8,
  },
});
