import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';

const Dot = ({ fill }: { fill?: boolean }) => (
  <View style={styles.dotWrapper}>
    <View style={fill ? styles.dotFilled : styles.dot} />
  </View>
);

const Avatar = ({ name }: { name?: string }) => {
  const letters: { [key: string]: number } = {
    ' ': 0,
    a: 0,
    b: 1,
    c: 2,
    d: 3,
    e: 4,
    f: 5,
    g: 6,
    h: 7,
    i: 8,
    j: 0,
    k: 0,
    l: 1,
    m: 2,
    n: 3,
    o: 4,
    p: 5,
    q: 6,
    r: 7,
    s: 8,
    t: 0,
    u: 0,
    v: 1,
    w: 2,
    x: 3,
    y: 4,
    z: 5,
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 0,
  };

  const values = name
    ? name
        .toLowerCase()
        .split('')
        .map((letter) => letters[letter])
    : '';

  return (
    <View style={styles.avatarWrapper}>
      <View style={styles.avatar}>
        {values && values.slice(0, 9).map((value, index) => <Dot fill={value !== 0} key={index} />)}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  dotWrapper: {
    padding: 2,
  },
  dot: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
  },
  dotFilled: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    backgroundColor: Colors.darkGrey2,
  },
  avatarWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    margin: 'auto',
  },
  avatar: {
    display: 'flex',
    flexFlow: 'row wrap',
    width: 21,
    height: 21,
    margin: 'auto',
  },
});

export default Avatar;
