import React, { useState } from 'react';
import { View, StyleSheet, ColorValue } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export enum TagVariant {
  GreenFilled = 'GreenFilled',
  GreenOutline = 'GreenOutline',
  PinkFilled = 'PinkFilled',
  PinkOutline = 'PinkOutline',
  BlackOutline = 'BlackOutline',
  // Should only be used when using `backgroundColor` and `textColor` overrides
  NoVariant = 'NoVariant',
}

interface TagVariantProps {
  backgroundColor?: Colors;
  borderColor?: Colors;
  textColor?: Colors;
}

const TagVariants: { [key: string]: TagVariantProps } = {
  [TagVariant.GreenFilled]: {
    backgroundColor: Colors.green3,
    borderColor: Colors.green3,
    textColor: Colors.white,
  },
  [TagVariant.GreenOutline]: {
    backgroundColor: undefined,
    borderColor: Colors.green3,
    textColor: Colors.green3,
  },
  [TagVariant.PinkFilled]: {
    backgroundColor: Colors.pink2,
    borderColor: Colors.pink2,
    textColor: Colors.white,
  },
  [TagVariant.PinkOutline]: {
    backgroundColor: undefined,
    borderColor: Colors.pink2,
    textColor: Colors.pink2,
  },
  [TagVariant.BlackOutline]: {
    backgroundColor: undefined,
    borderColor: Colors.black,
    textColor: Colors.black,
  },
  [TagVariant.NoVariant]: {
    backgroundColor: undefined,
    borderColor: undefined,
    textColor: undefined,
  },
};

interface TagProps {
  leftAccessory?: React.ReactNode;
  children: string;
  variant: TagVariant;
  textVariant?: TextVariants;
  rounded?: boolean;
  // Variant overrides. Only use these if you have to,
  // usage of variants is preferred
  backgroundColor?: Colors | ColorValue;
  alignSelf?: 'flex-end' | 'flex-start';
  textColor?: Colors;
  icon?: string;
}

export const Tag = ({
  leftAccessory,
  children,
  variant,
  textVariant = TextVariants.XS,
  rounded = false,
  alignSelf = 'flex-start',
  backgroundColor,
  textColor,
  icon,
}: TagProps) => {
  const [tagHeight, setTagHeight] = useState<number>(0);
  const {
    backgroundColor: variantBackgroundColor,
    borderColor,
    textColor: variantTextColor,
  } = TagVariants[variant];
  const borderRadius = rounded ? tagHeight / 2 : 8;
  const paddingX = rounded ? tagHeight / 4 : 8;
  const borderWidth = borderColor === undefined ? 0 : 1;
  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: backgroundColor ?? variantBackgroundColor,
          borderColor,
          borderWidth,
          borderRadius,
          paddingLeft: paddingX,
          paddingRight: paddingX,
          alignSelf,
        },
      ]}
      onLayout={(e) => setTagHeight(e.nativeEvent.layout.height)}
    >
      <View style={styles.innerContainer}>
        {leftAccessory ? <View style={styles.leftAccessoryContainer}>{leftAccessory}</View> : null}
        {!!icon && <Icon name={icon} size={12} color={Colors.white} style={styles.icon} />}
        <LimbicText variant={textVariant} color={textColor ?? variantTextColor} center>
          {children}
        </LimbicText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 6,
    paddingBottom: 6,
    borderWidth: 1,
  },
  leftAccessoryContainer: {
    marginRight: 10,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
    width: 12,
    height: 12,
  },
});
