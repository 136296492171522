import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SelectQuestionnaireProps {
  isFirstTime?: boolean;
  questionnaires: React.ReactNode;
  onSubmit: (questionnaire?: string) => void;
}

export const SelectQuestionnaire = ({
  isFirstTime,
  questionnaires,
  onSubmit,
}: SelectQuestionnaireProps) => {
  return (
    <ModalContent scrollable contentContainerStyle={styles.container}>
      <ModalContent.Title>
        {isFirstTime ? 'Set first homework' : 'Send questionnaire'}
      </ModalContent.Title>
      {isFirstTime ? (
        <ModalContent.Body>
          Most therapists will start with a questionnaire. When a client installs the app, they will
          be invited to fill in this test.
        </ModalContent.Body>
      ) : (
        <ModalContent.Body>
          Schedule questionnaires for your clients. Limbic will send them a reminder to do them
          before your next session together, and then it will report the outcomes.
        </ModalContent.Body>
      )}
      <View style={styles.questionnairesContainer}>{questionnaires}</View>
      {isFirstTime && (
        <ModalContent.Body small>
          We just set one. After they accept the invite and signup, you could add more
          questionnaires if you want.
        </ModalContent.Body>
      )}
      <ModalContent.CTAButton
        title="Schedule it"
        onPress={onSubmit}
        style={styles.button}
        testID="select-questionnaire-submit-button"
      />
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  questionnairesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
  },
  button: {
    marginTop: 16,
  },
  buttonText: {
    color: Colors.darkGrey1,
  },
});
