import { useCallback, useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

enum RISK_LEVEL {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

enum SOURCE {
  UNKNOWN = 'UNKNOWN',
  ACCESS = 'ACCESS',
  CLINICIAN = 'CLINICIAN',
  CLINIC = 'CLINIC',
}

enum PATIENT_PROGRESS {
  UNKNOWN = 'UNKNOWN',
  REFERRAL = 'REFERRAL',
  ASSESSMENT_WAITING = 'ASSESSMENT_WAITING',
  ASSESSMENT_COMPLETE = 'ASSESSMENT_COMPLETE',
  ON_WAITLIST = 'ON_WAITLIST',
  ASSIGNED_AWAITING_THERAPY = 'ASSIGNED_AWAITING_THERAPY',
  IN_THERAPY = 'IN_THERAPY',
  DISCHARGED = 'DISCHARGED',
  SELF_CARE = 'SELF_CARE',
  PREVENTATIVE_CARE = 'PREVENTATIVE_CARE',
  STEPPED_UP = 'STEPPED_UP',
  WEBINAR_DEPRESSION = 'WEBINAR_DEPRESSION',
  WEBINAR_WORRY = 'WEBINAR_WORRY',
  WEBINAR_PANIC = 'WEBINAR_PANIC',
  COURSE_ENROLLED = 'COURSE_ENROLLED',
}

interface IClinic {
  id: number;
  name: string;
  displayName: string;
}

interface IPatient {
  id: string;
  signupCode: string;
  analyticsData?: any;
  progress: PATIENT_PROGRESS;
  status: 'active' | 'inactive' | 'pending';
  name?: string;
  email?: string;
  clinicId?: string;
  clinic?: IClinic;
  clinicianId?: string;
  createdAt: string;
  updatedAt: string;
  weeklyQuestionnaires?: string[];
  sessionDate?: string;
  previousSessionDate?: string;
  riskLevel: RISK_LEVEL;
  source: SOURCE;
}

export interface IAccessReferral extends IPatient {
  source: SOURCE.ACCESS;
  progress: PATIENT_PROGRESS.REFERRAL;
}

export interface IListPatientsFilteredQueryResponse {
  listPatientsFiltered?: {
    data?: IPatient[];
  };
}

export const LIST_PATIENTS_FILTERED = gql`
  query listPatientsFiltered(
    $fuzzyName: String
    $fuzzySignupCode: String
    $fuzzyEmail: String
    $clinicId: [ID!]!
    $limit: Int
    $offset: Int
  ) {
    listPatientsFiltered(
      progress: "REFERRAL"
      fuzzyName: $fuzzyName
      fuzzySignupCode: $fuzzySignupCode
      fuzzyEmail: $fuzzyEmail
      clinicId: $clinicId
      demoUser: false
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        analyticsData
        signupCode
        status
        name
        email
        clinicId
        clinicianId
        progress
        sessionDate
        source
        riskLevel
        clinic {
          name
          displayName
        }
      }
      pagination {
        total
        limit
        offset
      }
    }
  }
`;

interface IListPatientsFilteredQueryVariables {
  fuzzyName?: string;
  fuzzySignupCode?: string;
  fuzzyEmail?: string;
  limit?: number;
  clinicId?: string[];
}

// NOTES: In part because we're still in the middle of trying to upgrade the legacy backend to
// a new node version (and thus have staging and production as well as the `main-database-oki-doki`
// package on incompatible branches), as well as that there is the possibility of expanding this
// hook to include other types of patient searches, I'm not going to update the db pkg to
// include a way of filtering by `source` and do that here instead. This is still a filtered list
// and the clinician can only see patients that are assigned to their clinic, so it's not so bad.
const filterAccessSource = (data?: IPatient[]): IAccessReferral[] => {
  return (data || []).filter((p) => p.source === SOURCE.ACCESS) as IAccessReferral[];
};

export function useAccessReferral(clinicId: string) {
  // set up lazy query to be able to call as needed
  const [listPatientsFiltered, { data, loading }] = useLazyQuery<
    IListPatientsFilteredQueryResponse,
    IListPatientsFilteredQueryVariables
  >(LIST_PATIENTS_FILTERED);

  // set up memoized callback to be able to call as needed
  const findAccessReferrals = useCallback(
    ({ fuzzyName, fuzzySignupCode, fuzzyEmail, limit }: IListPatientsFilteredQueryVariables) => {
      listPatientsFiltered({
        variables: {
          fuzzyName,
          fuzzySignupCode,
          fuzzyEmail,
          limit,
          clinicId: [clinicId],
        },
      });
    },
    [listPatientsFiltered, clinicId]
  );

  const results = useMemo(() => {
    return filterAccessSource(data?.listPatientsFiltered?.data);
  }, [data]);

  return {
    results,
    findAccessReferrals,
    loading,
  };
}
