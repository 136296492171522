/* eslint-disable prefer-object-spread */
import { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { uniqBy } from 'lodash';
import { fragments, ITherapistDigestFragment } from '../fragments';
import { groupMoodLogsByDate } from '../utils/groupMoodLogsByDate';

const LIST_THERAPIST_REPORTS_FOR_PATIENT = gql`
  query listTherapistReportsForPatient($signUpCode: String!, $limit: Int = 10, $offset: Int = 0) {
    listTherapistReportsForPatient(code: $signUpCode, limit: $limit, offset: $offset) {
      data {
        ...TherapistDigest
      }
      pagination {
        total
      }
    }
  }
  ${fragments.therapistDigest}
`;

export interface IListTherapistReportsForPatientResponse {
  listTherapistReportsForPatient?: {
    data: ITherapistDigestFragment[];
    pagination: {
      total: number;
    };
  };
}

export interface IListTherapistReportsForPatientVariables {
  signUpCode: string;
  limit: number;
  offset: number;
}

export const useListTherapistReportsForPatient = (signUpCode: string, limit = 10) => {
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { loading, data, fetchMore, ...rest } = useQuery<
    IListTherapistReportsForPatientResponse,
    IListTherapistReportsForPatientVariables
  >(LIST_THERAPIST_REPORTS_FOR_PATIENT, { variables: { signUpCode, limit, offset: 0 } });

  const onFetchMore = useCallback(async () => {
    if (loading || loadingMore) return;
    try {
      setLoadingMore(true);
      await fetchMore({
        variables: {
          signUpCode,
          limit,
          offset: data?.listTherapistReportsForPatient?.data.length,
        },
        updateQuery: (prev: IListTherapistReportsForPatientResponse, { fetchMoreResult: curr }) => {
          if (!curr) return prev;
          const previousData = prev.listTherapistReportsForPatient?.data || [];
          const currentData = curr.listTherapistReportsForPatient?.data || [];
          const mergedData = uniqBy([...previousData, ...currentData], 'id');
          return Object.assign({}, prev, {
            listTherapistReportsForPatient: {
              ...prev.listTherapistReportsForPatient,
              data: mergedData,
            },
          });
        },
      });
    } finally {
      setLoadingMore(false);
    }
  }, [
    data?.listTherapistReportsForPatient?.data.length,
    fetchMore,
    limit,
    loading,
    loadingMore,
    signUpCode,
  ]);

  return { ...rest, loading, loadingMore, data, onFetchMore };
};

export const transformTherapistDigest = (item: ITherapistDigestFragment) => {
  return {
    ...item,
    totalLogs: item.dailyLogsCounter?.value || 0,
    moodLogGroups: groupMoodLogsByDate(item.dailyLogs?.data || []),
    questionnaires: item.weeklyScores?.data.map((i) => i.type) || [],
  };
};
