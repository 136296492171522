import React from 'react';
import { View, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config';
import { EmptyState } from './EmptyState';
import { LimbicText } from '../LimbicText/LimbicText';

interface ErrorStateArgs {
  error: Error;
}

export function ErrorState({ error }: ErrorStateArgs) {
  return (
    <EmptyState
      customIcon={<Icon size={100} name="alert-circle" color={Colors.pink2} />}
      title="We had an unexpected error"
      text={error.message}
    />
  );
}

export function ErrorStateWithErrorCode({
  code,
  title = 'We had an unexpected problem',
  subtitle = 'Please try refreshing the page',
}: {
  title?: string;
  subtitle?: string;
  code: string;
}) {
  return (
    <EmptyState
      customIcon={<Icon size={100} name="alert-circle" color={Colors.pink2} />}
      title={title}
      text={subtitle}
      bottomAccessory={
        <View style={styles.container}>
          <LimbicText style={styles.contactSupportText}>
            If the error persists please contact support mentioning this error code
          </LimbicText>
          <View style={styles.errorCodeContainer}>
            <LimbicText style={styles.errorCodeText}>{code}</LimbicText>
          </View>
        </View>
      }
    />
  );
}

const styles = StyleSheet.create({
  container: { marginTop: 24, alignItems: 'center', gap: 8 },
  contactSupportText: { fontSize: 14, color: Colors.darkGrey1 },
  errorCodeContainer: {
    fontSize: 14,
    backgroundColor: Colors.grey1,
    border: `1px solid ${Colors.grey2}`,
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  errorCodeText: {
    fontSize: 14,
    fontWeight: '500',
    color: Colors.darkGrey2,
  },
});
