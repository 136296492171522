import { IAccessReferral } from '@limbic-for-therapists/backend';
import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  NativeSyntheticEvent,
  TextInputChangeEventData,
} from 'react-native';
import { Colors } from '../../config';
import Avatar from '../Avatar/Avatar';
import { ModalContent } from '../LimbicModal/ModalContent';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { TextInput } from '../TextInput/TextInput';
import { Loading } from '../Loading/Loading';

interface LookupAccessReferralProps {
  lookup?: string;
  onChangeLookup: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  results?: IAccessReferral[];
  loading?: boolean;
  onSelectReferral: (referral: IAccessReferral) => void;
  bottomAccessory?: JSX.Element;
}

export const LookupAccessReferral = ({
  lookup,
  onChangeLookup,
  results = [],
  loading = false,
  onSelectReferral,
  bottomAccessory,
}: LookupAccessReferralProps) => {
  const handleSelectReferral = (referral: IAccessReferral) => () => {
    onSelectReferral(referral);
  };

  return (
    <ModalContent scrollable contentContainerStyle={styles.container}>
      <ModalContent.Title>Invite a patient to Limbic</ModalContent.Title>

      <ModalContent.Body>Search for a referral from Limbic Access</ModalContent.Body>

      <ModalContent.Body style={styles.input}>
        <TextInput
          label="Type to search"
          onChange={onChangeLookup}
          value={lookup}
          style={styles.input}
        />
      </ModalContent.Body>

      <View style={styles.resultsContainer}>
        {loading && <Loading subtitle="Please be patient, search could take several seconds." />}

        {!loading && !!lookup && lookup.length >= 2 && results.length === 0 && (
          <LimbicText variant={TextVariants.Base} color={Colors.darkGrey1}>
            No results found
          </LimbicText>
        )}

        {results.length > 0 &&
          results.map((result) => (
            <TouchableOpacity onPress={handleSelectReferral(result)} key={result.id}>
              <View style={styles.resultRow}>
                <View style={styles.rowItemText}>
                  <Avatar name={result.name} />
                </View>
                <View>
                  <LimbicText
                    variant={TextVariants.Base}
                    color={Colors.darkGrey1}
                    style={styles.rowItemText}
                  >
                    {result.name}
                  </LimbicText>
                </View>
                <View>
                  <LimbicText
                    variant={TextVariants.Base}
                    color={Colors.darkGrey1}
                    style={styles.rowItemText}
                  >
                    {result.analyticsData?.dob || 'No DOB'}
                  </LimbicText>
                </View>
                <View>
                  <LimbicText
                    variant={TextVariants.Base}
                    color={Colors.darkGrey1}
                    style={styles.rowItemText}
                  >
                    {result.email || 'No email'}
                  </LimbicText>
                </View>
              </View>
            </TouchableOpacity>
          ))}
      </View>

      {!!bottomAccessory && <View style={styles.bottomAccessory}>{bottomAccessory}</View>}
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  bodyText: {
    color: Colors.darkGrey1,
  },
  input: {
    flex: 1,
    width: 670,
    backgroundColor: Colors.white,
    borderRadius: 16,
    borderColor: Colors.darkGrey1,
  },
  resultRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '100%',
  },
  resultButton: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  rowItemText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 21,
    textAlign: 'center',
    color: '#000000',
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
  },
  resultsContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    borderStyle: 'solid',
    borderRadius: 16,
    padding: 16,
    marginTop: 8,
    marginLeft: 4,
    minHeight: 200,
  },
  bottomAccessory: {
    marginTop: 48,
  },
});
