import React, { useState, useCallback } from 'react';
import { LimbicText, LimbicTextProps, TextVariants } from '../LimbicText/LimbicText';
import { Colors } from '../../config/colors';

interface LimbicCharacterLimitedTextProps
  extends Omit<LimbicTextProps, 'left' | 'center' | 'right'> {
  maxCharacterCount: number;
  children: string;
  ellipsis?: string;
  onPressMore?: () => void;
}

export const LimbicCharacterLimitedText = ({
  maxCharacterCount,
  children,
  ellipsis = '...',
  onPressMore,
  ...rest
}: LimbicCharacterLimitedTextProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const requiresSlicing = children && children.length >= maxCharacterCount;

  const handlePressedMore = useCallback(() => {
    setShowMore(!showMore);
    if (onPressMore) onPressMore();
  }, [onPressMore, showMore]);

  return (
    <>
      <LimbicText {...rest}>
        <>
          {requiresSlicing && !showMore ? children.slice(0, maxCharacterCount).trim() : children}
          {requiresSlicing && !showMore ? ellipsis : ''}
          {requiresSlicing ? (
            <LimbicText
              {...rest}
              variant={TextVariants.S}
              bold
              color={Colors.darkGrey1}
              onPress={handlePressedMore}
            >
              {!showMore ? ' MORE' : ' LESS'}
            </LimbicText>
          ) : null}
        </>
      </LimbicText>
    </>
  );
};
