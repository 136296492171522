/* eslint-disable react/no-unused-prop-types */
import React, { ComponentClass, Fragment, MouseEvent, ReactNode, useState } from 'react';
import { StyleSheet, GestureResponderEvent, TouchableWithoutFeedback } from 'react-native';
import { LineChart } from 'react-native-svg-charts';
import { Line, Circle, Text, G, Rect, Color, CircleProps } from 'react-native-svg';
import { Colors } from '../../config/colors';

/** Setup */
const lightLineColor = 'rgba(255, 255, 255, 0.3)';
const contentInset = { left: 24, right: 24, bottom: 0, top: 0 };

interface CircleExtendedProps extends CircleProps {
  onClick?: (event: MouseEvent<SVGElement>) => void;
  onMouseEnter?: (event: MouseEvent<SVGElement>) => void;
  onMouseLeave?: (event: MouseEvent<SVGElement>) => void;
}

// NOTE: This is a workaround for a bug in react-native-svg
// SEE: https://github.com/react-native-svg/react-native-svg/issues/1483
// As of 16-May-2022, still an open issue, react-native-svg does not properly
// map the web events like `onClick` to the native events like `onPress`.
const CircleExtended = Circle as unknown as ComponentClass<CircleExtendedProps>;

interface IDataPoint {
  id: string;
  value: number;
  label?: string;
  tooltip?: string;
}

export interface LimbicLineChartProps {
  max: number;
  min?: number;
  data: IDataPoint[];
}

export const LimbicLineChart = (props: LimbicLineChartProps) => {
  const { data, max = 0, min = 0 } = props;

  // noinspection RequiredAttributes
  return (
    <LineChart
      data={data}
      yMax={max}
      yMin={min}
      yAccessor={({ item }: { item: IDataPoint }) => item.value}
      animationDuration={1000}
      contentInset={contentInset}
      style={styles.container}
    >
      {/* NOTE: The horizontal grid has been removed here because simply breaking up the graph
          with evenly-spaced lines can be visually deceiving as the scale of each graph is 
          usually unique, having different max levels. It is recommeded to refactor the component
          to allow passing in the increments that should be used for the grid lines.  */}
      {/*
          LineChart passes props to its children
          and if you want to type your components
          without TSX complaining about missing
          props when you know they're there, you
          gotta do this. ‾\_(ツ)_/‾
          @ts-ignore */}
      <HorizontalGrid max={max} />
      {/*
          LineChart passes props to its children
          and if you want to type your components
          without TSX complaining about missing
          props when you know they're there, you
          gotta do this. ‾\_(ツ)_/‾
          @ts-ignore */}
      <VerticalGrid max={max} />
      {/*
          LineChart passes props to its children
          and if you want to type your components
          without TSX complaining about missing
          props when you know they're there, you
          gotta do this. ‾\_(ツ)_/‾
          @ts-ignore */}
      <ConnectedLines />
      {/*
          LineChart passes props to its children
          and if you want to type your components
          without TSX complaining about missing
          props when you know they're there, you
          gotta do this. ‾\_(ツ)_/‾
          @ts-ignore */}
      <Points max={max} />
    </LineChart>
  );
};

interface ChartGridProps {
  x: (index: number) => number;
  y: (value: number) => number;
  data: IDataPoint[];
  ticks: number[];
  width: number;
  height: number;
  path: string;
  line: string;
}

interface ITooltipProps {
  x: number;
  y: number;
  value: number;
  valueMax: number;
  index: number;
  content: ReactNode | undefined;
  color?: Color;
  dataLength: number;
  visible: boolean;
  placement?: 'top' | 'bottom';
}

const HorizontalGrid = (
  props: ChartGridProps & { increment?: number; min?: number; max: number }
) => {
  const { height, min = 0, max, increment = 8 } = props;

  // calculate number of increments needed
  const increments = new Array(Math.floor((max - min) / increment)).fill(undefined);
  // calculate percentage of height for each increment amount
  const incrementPercent = height / increments.length / height;

  return (
    <>
      {increments.map((_, index) => (
        <Line
          key={index}
          x1="0%"
          x2="100%"
          y1={height * ((index + 1) * incrementPercent)}
          y2={height * ((index + 1) * incrementPercent)}
          stroke={lightLineColor}
        />
      ))}
    </>
  );
};

const VerticalGrid = (props: ChartGridProps & { max: number; min?: number }) => {
  const { data, x, max, min = 0 } = props;
  return (
    <>
      <Text
        x="6px"
        y="15%"
        textAnchor="start"
        fill={lightLineColor}
        fontSize={12}
        fontFamily="Aeroport"
      >
        {max}
      </Text>
      <Text
        x="6px"
        y="90%"
        textAnchor="start"
        fill={lightLineColor}
        fontSize={12}
        fontFamily="Aeroport"
      >
        {min}
      </Text>
      {data.map((item, index, col) => {
        const xPoint = x(index);
        return (
          <React.Fragment key={item.id}>
            <Line
              y1="0%"
              y2="100%"
              x1={xPoint}
              x2={xPoint}
              strokeWidth={index === col.length - 1 ? 1 : StyleSheet.hairlineWidth}
              stroke={index === col.length - 1 ? Colors.white : lightLineColor}
            />
            <Text x={xPoint} y="90%" alignmentBaseline="middle" textAnchor="middle" fill="white">
              {item.label}
            </Text>
          </React.Fragment>
        );
      })}
    </>
  );
};

const ConnectedLines = (props: ChartGridProps) => {
  const { data, x, y } = props;
  return data.map((item, index, col) => {
    const next = col[index + 1];
    if (next) {
      const y1 = y(item.value);
      const y2 = y(next.value);
      const x1 = x(index);
      const x2 = x(index + 1);
      return (
        <Line
          key={item.id}
          y1={y1}
          y2={y2}
          x1={x1}
          x2={x2}
          stroke={Colors.pink4}
          strokeWidth={2}
          strokeDasharray={[4, 4]}
        />
      );
    }
    return null;
  });
};

const Points = (props: ChartGridProps & { max: number }) => {
  const { data, x, y, max } = props;
  const [tooltipShown, setTooltipShown] = useState<string | null>(null);

  const handleOnMouseEnter =
    (id: string) => (event: GestureResponderEvent | MouseEvent<SVGElement>) => {
      // stop propagation to prevent mouse events from bubbling up to the parent
      event.stopPropagation?.();
      if (tooltipShown === id) {
        return setTooltipShown(null);
      }
      setTooltipShown(id);
    };

  const handleOnMouseLeave = () => {
    setTooltipShown(null);
  };

  return data.map((item, index, col) => {
    return (
      <Fragment key={item.id}>
        <TouchableWithoutFeedback
          onPress={handleOnMouseEnter(item.id)}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <CircleExtended
            cx={x(index)}
            cy={y(item.value)}
            r={6}
            stroke={Colors.pink4}
            strokeWidth={index === col.length - 1 ? 2 : 1}
            fill={index === col.length - 1 ? Colors.pink4 : Colors.purple}
            onMouseEnter={handleOnMouseEnter(item.id)}
            onMouseLeave={handleOnMouseLeave}
          />
        </TouchableWithoutFeedback>
        <Tooltip
          x={x(index)}
          y={y(item.value)}
          value={item.value}
          valueMax={max}
          content={item.tooltip}
          index={index}
          dataLength={col.length}
          color={Colors.pink4}
          visible={tooltipShown === item.id}
        />
      </Fragment>
    );
  });
};

const Tooltip = (props: ITooltipProps) => {
  const {
    x,
    y,
    value,
    valueMax,
    content,
    color = Colors.pink4,
    index,
    dataLength,
    visible,
    placement,
  } = props;

  if (!visible) return null;
  if (!content) return null;

  // handle placement, either calculated or passed in
  let placementNormalized = placement;
  if (!placementNormalized) {
    // use value and valueMax to determine if above or below
    const valuePercent = (value / valueMax) * 100;
    placementNormalized = valuePercent > 66 ? 'bottom' : 'top';
  }

  // if its the first point or the last point, then we need to adjust the x position
  // as the overall width is too wide and it will be cut off by the edge of the chart
  let containerShiftX = 0;
  if (index === 0 || (dataLength > 4 && index === 1)) {
    containerShiftX = 20;
  } else if (index === dataLength - 1 || (dataLength > 4 && index === dataLength - 2)) {
    containerShiftX = -20;
  }

  const containerShiftY = placementNormalized === 'top' ? -35 : 35;

  // box needs to be wider if its a 2-digit value
  const valueBoxW = value > 9 ? 12 : 10;
  // text for content needs to be further to the right if value is a 2-digit value
  const contentMarginLeft = value > 9 ? 26 : 24;

  return (
    <G x={x - 35} y={y}>
      <G x={containerShiftX} y={containerShiftY}>
        <Rect x={-2} y={0} height={22} width={70} stroke={color} fill="white" ry={6} rx={6} />
        <Rect x={-2} y={0} height={22} width={18} fill={color} ry={6} rx={6} />
        <Rect x={10} y={0} height={22} width={valueBoxW} fill={color} />
        <Text x={6} y={14} stroke="#fff" fontFamily="Aeroport" fontSize={11} fontWeight={300}>
          {value}
        </Text>
        <Text x={contentMarginLeft} y={14} fontFamily="Aeroport" fontSize={11} fontWeight={300}>
          {content}
        </Text>
      </G>
    </G>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.purple,
  },
});
