import { HomeworkTypes } from '../models/IHomework';

export const getIconNameForHomeworkType = (homeworkType: HomeworkTypes) => {
  switch (homeworkType) {
    case HomeworkTypes.BEHAVIOURAL_ACTION:
      return 'bicycle-outline';
    case HomeworkTypes.QUESTIONNAIRE:
      return 'clipboard-outline';
    case HomeworkTypes.STRATEGY:
      return 'help-buoy-outline';
    case HomeworkTypes.INTERVENTION:
      return 'chatbubbles-outline';
    case HomeworkTypes.PSYCHOED:
      return 'book-outline';
  }
};
