import React from 'react';
import { StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';

interface NewPatientIntroProps {
  onSubmit: () => void;
  onCancel?: () => void;
}

export const NewPatientIntro = ({ onSubmit, onCancel }: NewPatientIntroProps) => {
  return (
    <ModalContent scrollable>
      <ModalContent.Title bold>Add new patient</ModalContent.Title>
      <ModalContent.Body>
        We will create together a personal invite for your patient which you can share by e-mail
      </ModalContent.Body>

      <View style={styles.checkRow}>
        <Icon name="check" size={44} color={Colors.grey3} style={styles.checkIcon} />
        <ModalContent.Body>
          Patients will set their approval to share sensitive data with you (according to GPDR)
        </ModalContent.Body>
      </View>
      <View style={styles.checkRow}>
        <Icon name="check" size={44} color={Colors.grey3} style={styles.checkIcon} />
        <ModalContent.Body>
          Set your first homework, like an PHQ-9, directly with the invite
        </ModalContent.Body>
      </View>

      <ModalContent.CTAButton
        style={styles.buttonMargin}
        title="Invite a new client"
        testID="new-patient-invite-button"
        onPress={onSubmit}
      />

      <ModalContent.CTAButton
        title="Cancel"
        style={[styles.button]}
        textStyle={styles.buttonText}
        onPress={onCancel}
      />
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  checkRow: {
    flexDirection: 'row',
    marginTop: 10,
    paddingRight: 32,
  },
  checkIcon: {
    marginRight: 10,
  },
  buttonMargin: {
    marginTop: 16,
    marginBottom: 8,
  },
  button: {
    backgroundColor: Colors.white,
  },
  buttonText: {
    color: Colors.darkGrey1,
  },
});
