import { MoodEventType } from '../models/MoodEventType';
import ICutoffSet from '../models/ICutoffSet';

// NOTE: questionnaires that do not use points (i.e. Employment, etc.) will not have a cutoff.
// This is important because the absence of a cutoff object means to components that graphs
// will not be shown and other UI components instead.

export const cutoffs: Partial<Record<MoodEventType, ICutoffSet>> = {
  [MoodEventType.PHQ9]: {
    max: 27,
    high: 14,
    medium: 9,
    low: 4,
  },
  [MoodEventType.GAD7]: {
    max: 21,
    high: 15,
    medium: 10,
    low: 5,
  },
  [MoodEventType.CORE10]: {
    max: 36,
    high: 25,
    medium: 20,
    low: 15,
  },
  // 17 questions, each is rated from 0-4 with a total probable
  // score of 0-68. A SPIN score of 21-30 indicates mild social phobia,
  // 31-40 (moderate), 41-50 (severe) and above 50 (very severe).
  // http://www.fortunejournals.com/articles/validation-of-the-social-phobia-inventory-spin-in-nigeria.html
  [MoodEventType.SPIN]: {
    max: 68,
    high: 50,
    medium: 40,
    low: 30,
  },
  [MoodEventType.OCIR]: {
    max: 72,
    high: 36,
    medium: 28,
    low: 21,
  },
  [MoodEventType.IESR]: {
    max: 88,
    high: 37,
    medium: 33,
    low: 24,
  },
  [MoodEventType.YPCORE10]: {
    max: 40,
    high: 25,
    medium: 15,
    low: 10,
  },
  [MoodEventType.PAM]: {
    max: 100,
    high: 67.1,
    medium: 55.1,
    low: 47,
  },
  [MoodEventType.PSS10]: {
    max: 40,
    high: 40,
    medium: 26,
    low: 13,
  },
  [MoodEventType.PCL5]: {
    max: 80,
    high: 56,
    medium: 33,
    low: 16,
  },
  [MoodEventType.PHOBIA]: {
    max: 24,
    high: 18,
    medium: 12,
    low: 4,
  },
  [MoodEventType.WSAS]: {
    max: 40,
    high: 19,
    medium: 15,
    low: 9,
  },
};
