import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface Props {
  triggers: React.ReactNode;
  submitTitle: string;
  loading: boolean;
  onSubmit: () => void;
}

export const SetQuestionnaireTrigger = ({ triggers, submitTitle, loading, onSubmit }: Props) => {
  return (
    <View style={styles.container}>
      <ModalContent>
        <ModalContent.Title>Questionnaire Frequency</ModalContent.Title>
        <ModalContent.Body>
          You can control how often you want your client to do the questionnaire.
        </ModalContent.Body>

        <View style={styles.editContainer}>
          {triggers}
          <LimbicText color={Colors.darkGrey1} variant={TextVariants.S} style={styles.footnoteText}>
            If you want to send it before the last session, set the total amount of sessions until
            the last one (for example 4 to send it before the 4th next session).
          </LimbicText>
        </View>

        <ModalContent.CTAButton
          title={submitTitle}
          onPress={onSubmit}
          loading={loading}
          testID="set-questionnaire-trigger-submit-button"
        />
      </ModalContent>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  editContainer: {
    paddingVertical: 0,
  },
  footnoteText: {
    paddingTop: 8,
    paddingBottom: 24,
  },
  pickerContainer: {
    padding: 8,
  },
  dropdownCaret: {
    paddingTop: 15,
    paddingRight: 8,
  },
});
