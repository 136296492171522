import { Dayjs } from 'dayjs';
import React from 'react';
import { InviteFlowContextProvider } from '../../context/InviteFlow';
import NewPatientModal from './screens/NewPatientModal';

export interface NewPatientWithHWFlowValues {
  [key: string]: string | boolean | Dayjs | undefined;
  clinicianId: string;
  isAccessReferral: boolean;
  signupCode?: string;
  name?: string;
  email?: string;
  phone?: string;
  dob?: Dayjs;
  sessionDate?: Dayjs;
  demoUser?: boolean;
}

interface NewPatientWithHWFlowProps {
  clinicianId: string;
}

export const NewPatientWithHWFlow = ({ clinicianId }: NewPatientWithHWFlowProps) => {
  const values: NewPatientWithHWFlowValues = {
    clinicianId,
    isAccessReferral: false,
  };

  return (
    <>
      <InviteFlowContextProvider defaultValues={values}>
        <NewPatientModal />
      </InviteFlowContextProvider>
    </>
  );
};
