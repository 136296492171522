import { IValueRange } from '../IValueRange';
import { cutoffs } from '../../consts/questionnaireCutoffs';
import { MoodEventType } from '../MoodEventType';
import { PositivityColors } from '@limbic-for-therapists/components';

const cutoff = cutoffs[MoodEventType.GAD7]!;

export const GAD7ScoreRanges: IValueRange[] = [
  {
    title: `0 - ${cutoff.low}`,
    description: '',
    range: [0, Math.floor(cutoff.low / 2)],
    color: PositivityColors.neutral5,
  },
  {
    title: `0 - ${cutoff.low}`,
    description: '',
    range: [Math.floor(cutoff.low / 2), cutoff.low],
    color: PositivityColors.neutral3,
  },
  {
    title: `${cutoff.low + 1} - ${cutoff.medium}`,
    description: '',
    range: [cutoff.low + 1, cutoff.medium],
    color: PositivityColors.negative2,
  },
  {
    title: `${cutoff.medium + 1} - ${cutoff.high}`,
    description: '',
    range: [cutoff.medium + 1, cutoff.high],
    color: PositivityColors.negative5,
  },
  {
    title: `${cutoff.high + 1} - ${cutoff.max}`,
    description: '',
    range: [cutoff.high + 1, cutoff.max],
    color: PositivityColors.negative10,
  },
];
