import React, { useEffect } from 'react';
import { SuccessModal } from '@limbic-for-therapists/components';
import { useFlowContext } from '../../../context/Flow';
import { useAnalytics } from '@limbic-for-therapists/shared';
import mixpanel from 'mixpanel-browser';
import { AddCustomInterventionFlowValues } from '../AddCustomInterventionFlow';

export const SuccessScreen = () => {
  const { logAnalytic } = useAnalytics(mixpanel);
  const { values, finishFlow } = useFlowContext<AddCustomInterventionFlowValues>();

  useEffect(() => {
    logAnalytic({
      eventName: 'add-custom-intervention',
      params: {
        patientId: values.signUpCode,
      },
    });
  }, [values, logAnalytic]);

  return (
    <SuccessModal
      title="Your new intervention is ready"
      message={`You can now schedule this intervention for this and other patients`}
      onClose={finishFlow}
    />
  );
};
