export enum BehaviouralActionTypes {
  ACTIVATION = 'behavorial_activation',
  EXPERIMENT = 'behavorial_experiments',
}

export enum IBehaviouralActionStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const behaviouralActivitiesMap = {
  [BehaviouralActionTypes.ACTIVATION]: 'Behavioural Activation',
  [BehaviouralActionTypes.EXPERIMENT]: 'Behavioural Experiment',
};
