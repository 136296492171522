import { ApolloClient, gql, useMutation } from '@apollo/client';
import { fragments, ICopingStrategyFragment } from '../fragments';

export interface ICreateCopingStrategyResponse {
  createCopingStrategy: ICopingStrategyFragment;
}

const CREATE_COPING_STRATEGY = gql`
  mutation createCopingStrategy($signUpCode: String!, $emotionId: ID!, $message: String!) {
    createCopingStrategy(signupCode: $signUpCode, emotionId: $emotionId, message: $message) {
      ...CopingStrategy
    }
  }
  ${fragments.copingStrategy}
`;

export interface ICreateCopingStrategyVariables {
  signUpCode: string;
  emotionId: string;
  message: string;
}

export function useCreateCopingStrategyMutation<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) {
  return useMutation<ICreateCopingStrategyResponse, ICreateCopingStrategyVariables>(
    CREATE_COPING_STRATEGY,
    {
      refetchQueries: ['getPatientScreenQuery'],
      awaitRefetchQueries: true,
      client: titanClient,
    }
  );
}
