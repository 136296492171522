import React from 'react';
import { StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config/colors';
import { WithTestID } from '../../utils/withTestID';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const ICON_SIZE = 24;

interface CurrentHomeWorkCardProps {
  icon: string;
  title: string;
  subTitle: string;
  onPress?: () => void;
  onMorePress?: () => void;
}

export const CurrentHomeWorkCard: React.FC<WithTestID<CurrentHomeWorkCardProps>> = ({
  icon,
  title,
  subTitle,
  testID,
  onPress,
  onMorePress,
}) => {
  return (
    <TouchableWithoutFeedback onPress={onPress} testID={testID}>
      <View style={styles.container}>
        <View style={styles.icon}>
          <Icon size={20} name={icon} />
        </View>
        <View style={styles.content}>
          <LimbicText variant={TextVariants.Base} style={styles.text1}>
            {title}
          </LimbicText>
          <LimbicText variant={TextVariants.S} style={styles.text2}>
            {subTitle}
          </LimbicText>
        </View>
        <TouchableOpacity onPress={onMorePress} testID="homework-more-button">
          <View style={styles.icon2}>
            <Feather name="more-vertical" size={25} color={Colors.darkGrey1} />
          </View>
        </TouchableOpacity>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  image: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  text2: {
    color: Colors.darkGrey1,
  },
  text1: {
    color: Colors.darkGrey2,
  },
  content: {
    flex: 8,
    marginHorizontal: 20,
    marginVertical: 10,
  },
  icon2: { flex: 1, marginVertical: 10, height: 40 },
  icon: {
    backgroundColor: Colors.grey4,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    borderRadius: 10,
    marginVertical: 10,
    flex: 1.5,
  },
});
