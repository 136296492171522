import React, { useEffect, useRef } from 'react';
import { StyleSheet, Image, View, Animated, StyleProp, ViewStyle } from 'react-native';
import { Colors } from '../../config';
import { getIcon } from '../../utils/getIcon';
import { WithTestID } from '../../utils/withTestID';
import { ContentCard } from '../Card/ContentCard';

const BALLOON_SIZE = 60;

interface LimbicPopUpProps {
  content: React.ReactNode;
  visible: boolean;
  style?: StyleProp<ViewStyle>;
  width?: number;
  animationDelay?: number;
  balloon?: React.ReactNode;
  onPress?: () => void;
}

export const LimbicPopUp = ({
  content,
  visible,
  style,
  width = 300,
  animationDelay = 0,
  onPress,
  balloon,
  testID,
}: WithTestID<LimbicPopUpProps>) => {
  const Icon = getIcon('balloon');
  const animatedContentRef = useRef<Animated.Value>(new Animated.Value(0));
  const animatedBalloonRef = useRef<Animated.Value>(new Animated.Value(0));

  useEffect(() => {
    Animated.sequence([
      Animated.delay(animationDelay),
      Animated.timing(animatedBalloonRef.current, {
        toValue: visible ? 1 : 0,
        useNativeDriver: false,
      }),
      Animated.timing(animatedContentRef.current, {
        toValue: visible ? 1 : 0,
        useNativeDriver: false,
      }),
    ]).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <View style={[styles.container, style]}>
      <Animated.View
        testID={testID}
        style={{
          opacity: animatedContentRef.current.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
          }),
          transform: [
            {
              translateY: animatedContentRef.current.interpolate({
                inputRange: [0, 1],
                outputRange: [-50, 0],
              }),
            },
          ],
        }}
      >
        <ContentCard style={styles.contentContainer} width={width} minHeight={0} onPress={onPress}>
          <>{content}</>
        </ContentCard>
      </Animated.View>
      <Animated.View
        style={[
          styles.balloonContainer,
          {
            transform: [
              {
                translateX: animatedBalloonRef.current.interpolate({
                  inputRange: [0, 1],
                  outputRange: [100, 0],
                }),
              },
            ],
          },
        ]}
      >
        {balloon || <Image source={Icon} style={styles.balloon} />}
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  contentContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 16,
    marginRight: 10,
    marginBottom: 16,
    backgroundColor: Colors.white,
  },
  balloonContainer: {
    width: BALLOON_SIZE,
    height: BALLOON_SIZE,
  },
  balloon: {
    width: '100%',
    height: '100%',
    transform: [{ scaleX: -1 }],
  },
});
