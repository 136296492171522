import React, { useEffect, useMemo, useState } from 'react';
import { NativeSyntheticEvent, TextInputChangeEventData, View } from 'react-native';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import {
  NewInvitePatientConfirmDetails,
  NewLookupAccessReferral,
  PatientLookupResultsList,
} from '@limbic-for-therapists/components';
import { IAccessReferral, useAccessReferral } from '@limbic-for-therapists/backend';
import { useActiveClinicianContext } from '../../../context/ActiveClinician';
import { useInviteFlowContext } from '../../../context/InviteFlow';
import { NewPatientWithHWFlowValues } from '../NewPatientWithHWFlow';

export const NewPatientFromReferral = () => {
  const { activeClinician } = useActiveClinicianContext();
  const { setValues, values } = useInviteFlowContext<NewPatientWithHWFlowValues>();
  const [lookup, setLookup] = useState('');
  const { results, findAccessReferrals, loading } = useAccessReferral(
    activeClinician?.clinic.id || ''
  );

  useEffect(() => {
    findAccessReferrals({
      limit: 10,
    });
  }, [findAccessReferrals]);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((eventText: string) => {
        findAccessReferrals({
          fuzzyName: eventText,
        });
      }, 600),
    [findAccessReferrals]
  );

  // handle possible unmounting of component while a query request is in flight
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const handleOnChangeLookup = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setLookup(e.nativeEvent.text);
    if (e.nativeEvent.text.length >= 2) {
      debouncedChangeHandler(e.nativeEvent.text);
    }
  };

  const handleSelectReferral = (referral: IAccessReferral) => {
    setValues({
      isAccessReferral: true,
      signupCode: referral.signupCode,
      name: referral.name,
      email: referral.email,
      phone: referral.analyticsData?.phoneMobile,
      dob: referral.analyticsData?.dob
        ? dayjs(referral.analyticsData.dob, 'YYYY-MM-DD')
        : undefined,
    });
    setLookup('');
  };

  const filterResultsBasedOnEmailAndPhone = useMemo(
    () =>
      results.filter((result) => {
        return result.email || result.analyticsData?.phoneMobile;
      }),
    [results]
  );

  return (
    <View>
      <NewLookupAccessReferral
        lookup={lookup}
        onChangeLookup={handleOnChangeLookup}
        results={filterResultsBasedOnEmailAndPhone}
        loading={loading}
        onSelectReferral={handleSelectReferral}
      />
      {values.name ? (
        <NewInvitePatientConfirmDetails patient={values} />
      ) : (
        <PatientLookupResultsList
          onSelectReferral={handleSelectReferral}
          results={filterResultsBasedOnEmailAndPhone}
          loading={loading}
        />
      )}
    </View>
  );
};
