import { ApolloClient, gql, useQuery } from '@apollo/client';

export const GET_CLINIC_FEATURE_FLAGS = gql`
  query getFeatureFlags($clinicId: ID!) {
    clinic(id: $clinicId) {
      featureFlags
    }
  }
`;

export interface GetFeatureFlagsResponse {
  clinic: {
    __typename: 'Clinic';
    featureFlags: Array<string>;
  };
}

export function useGetClinicFeatureFlags<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>,
  clinicId: number | string
) {
  const { data } = useQuery<GetFeatureFlagsResponse, { clinicId: string | number }>(
    GET_CLINIC_FEATURE_FLAGS,
    {
      client: titanClient,
      variables: {
        clinicId: clinicId,
      },
      skip: clinicId === -1,
    }
  );

  return { featureFlags: data?.clinic.featureFlags };
}
