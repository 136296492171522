export type WeeklyQuestionnaire = {
  id: number;
  name: string;
  description: string;
};

export const weeklies: WeeklyQuestionnaire[] = [
  { id: 1, name: 'PHQ-9', description: 'Measure depression' },
  { id: 2, name: 'GAD-7', description: 'Measure anxiety' },
  { id: 3, name: 'CORE-10', description: 'General well-being' },
  { id: 4, name: 'OCI-R', description: 'Measure obsessive habits' },
  { id: 5, name: 'IES-R', description: 'Measure impact of events' },
  { id: 6, name: 'PCL-5', description: 'Measure PTSD' },
  // { id: 7, name: 'YP CORE-10', description: 'General well-being' },
  { id: 8, name: 'PSS-10', description: 'Measure depression' },
  { id: 9, name: 'Employment', description: 'Employment Questionnaire' },
  { id: 10, name: 'Phobia Scale', description: 'Phobia Scale' },
  { id: 11, name: 'WSAS', description: 'WSAS' },
  { id: 12, name: 'Psychotropic Medication', description: 'Psychotropic Medication' },
  { id: 13, name: 'IPCQ2', description: 'IPCQ2' },
  { id: 14, name: 'SPIN', description: 'Social Phobia Inventory' },
];
