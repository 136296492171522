import { PositivityColors } from '@limbic-for-therapists/components';
import { getValueRange, IValueRange } from '../models/IValueRange';

const VALENCE_RANGES: IValueRange<'Negative' | 'Neutral' | 'Positive'>[] = [
  {
    title: '-100 - -91',
    range: [-1, -0.91],
    description: 'Negative',
    color: PositivityColors.negative10,
  },
  {
    title: '-90 - -81',
    range: [-0.9, -0.81],
    description: 'Negative',
    color: PositivityColors.negative9,
  },
  {
    title: '-80 - -71',
    range: [-0.8, -0.71],
    description: 'Negative',
    color: PositivityColors.negative8,
  },
  {
    title: '-70 - -61',
    range: [-0.7, -0.61],
    description: 'Negative',
    color: PositivityColors.negative7,
  },
  {
    title: '-60 - -51',
    range: [-0.6, -0.51],
    description: 'Negative',
    color: PositivityColors.negative6,
  },
  {
    title: '-50 - -41',
    range: [-0.5, -0.41],
    description: 'Negative',
    color: PositivityColors.negative5,
  },
  {
    title: '-40 - -31',
    range: [-0.4, -0.31],
    description: 'Negative',
    color: PositivityColors.negative4,
  },
  {
    title: '-30 - -21',
    range: [-0.3, -0.21],
    description: 'Negative',
    color: PositivityColors.negative3,
  },
  {
    title: '-20 - -11',
    range: [-0.2, -0.11],
    description: 'Negative',
    color: PositivityColors.negative2,
  },
  {
    title: '-10 - 0',
    range: [-0.1, 0],
    description: 'Negative',
    color: PositivityColors.negative1,
  },
  {
    title: '0 - 10',
    range: [0, 0.1],
    description: 'Neutral',
    color: PositivityColors.neutral1,
  },
  {
    title: '11 - 20',
    range: [0.11, 0.2],
    description: 'Neutral',
    color: PositivityColors.neutral2,
  },
  {
    title: '21 - 30',
    range: [0.21, 0.3],
    description: 'Neutral',
    color: PositivityColors.neutral3,
  },
  {
    title: '31 - 40',
    range: [0.31, 0.4],
    description: 'Neutral',
    color: PositivityColors.neutral4,
  },
  {
    title: '41 - 50',
    range: [0.41, 0.5],
    description: 'Neutral',
    color: PositivityColors.neutral5,
  },
  {
    title: '51 - 60',
    range: [0.51, 0.6],
    description: 'Positive',
    color: PositivityColors.positive1,
  },
  {
    title: '61 - 70',
    range: [0.61, 0.7],
    description: 'Positive',
    color: PositivityColors.positive2,
  },
  {
    title: '71 - 80',
    range: [0.71, 0.8],
    description: 'Positive',
    color: PositivityColors.positive3,
  },
  {
    title: '81 - 90',
    range: [0.81, 0.9],
    description: 'Positive',
    color: PositivityColors.positive4,
  },
  {
    title: '91 - 100',
    range: [0.91, 1],
    description: 'Positive',
    color: PositivityColors.positive5,
  },
];

export function getValenceRange(valence: number) {
  return getValueRange<'Negative' | 'Neutral' | 'Positive'>(valence, VALENCE_RANGES);
}
