import { gql, useMutation } from '@apollo/client';
import { fragments, IPatientFragment } from '../fragments';
import { PatientProgress } from './updatePatientProgress';

const UPDATE_PATIENT = gql`
  mutation updatePatient(
    $signUpCode: String!
    $progress: PatientProgress
    $name: String
    $email: String
    $analyticsData: JSON
  ) {
    updatePatient(
      code: $signUpCode
      progress: $progress
      name: $name
      email: $email
      analyticsData: $analyticsData
    ) {
      ...Patient
    }
  }
  ${fragments.patient}
`;

export interface IUpdatePatientResponse {
  updatePatient: IPatientFragment;
}

export interface IUpdatePatientVariables {
  signUpCode: string;
  progress?: PatientProgress;
  name?: string;
  email?: string;
  analyticsData?: any;
}

export const useUpdatePatientMutation = () => {
  return useMutation<IUpdatePatientResponse, IUpdatePatientVariables>(UPDATE_PATIENT);
};
