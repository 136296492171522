export enum HomeworkTypes {
  STRATEGY = 'STRATEGY',
  BEHAVIOURAL_ACTION = 'BEHAVIOURAL_ACTION',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  INTERVENTION = 'INTERVENTION',
  PSYCHOED = 'PSYCHOED',
}

export interface IHomework {
  id: string;
  homeworkId: string;
  title: string;
  subTitle: string;
  homeworkType: HomeworkTypes;
  createdAt?: Date;
}
