import React from 'react';
import { Colors, LimbicText } from '@limbic-for-therapists/components';
import { fixedPositionStyle } from '@limbic-for-therapists/shared';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import { GridColumn, GridContainer, GridRow } from './ResponsiveGrid';
import { useHistory } from 'react-router-dom';

const BACK_ICON_SIZE = 24;
const PADDING_TOP = 32;
const PADDING_BOTTOM = 16;

export const NAVIGATION_BAR_HEIGHT = BACK_ICON_SIZE + PADDING_BOTTOM + PADDING_TOP;

interface NavigationBarProps {
  backPath?: string;
  header?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  refreshOnBack?: boolean;
}

const NavigationBar = ({
  header,
  backPath,
  rightAccessory,
  refreshOnBack = false,
}: NavigationBarProps) => {
  const history = useHistory();

  return (
    <View style={[styles.container, fixedPositionStyle]}>
      <GridContainer>
        <GridRow>
          <GridColumn span={12}>
            <View
              style={[
                styles.buttonContainer,
                !backPath && rightAccessory ? { justifyContent: 'flex-end' } : {},
              ]}
            >
              <View style={styles.header}>
                {backPath ? (
                  <>
                    <TouchableOpacity
                      onPress={() =>
                        refreshOnBack ? (window.location.href = backPath) : history.push(backPath)
                      }
                      testID="navigation-bar-back-button"
                    >
                      <Icon
                        name="arrow-back-outline"
                        size={BACK_ICON_SIZE}
                        color={Colors.darkGrey1}
                      />
                    </TouchableOpacity>
                    {header && <LimbicText style={styles.headerText}>{header}</LimbicText>}
                  </>
                ) : null}
              </View>

              <View style={styles.accessoryContainer}>{rightAccessory}</View>
            </View>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    top: 0,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 32,
    paddingBottom: 16,
    width: '100%',
    zIndex: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: BACK_ICON_SIZE,
    justifyContent: 'space-between',
  },
  accessoryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 0,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  headerText: {
    fontSize: 20,
    fontWeight: '400',
    lineHeight: 26,
    letterSpacing: -0.41,
  },
});

export { NavigationBar };
