import { gql, useMutation } from '@apollo/client';
import { fragments, IPatientFragment } from '../fragments';

const UPDATE_PATIENT_PROGRESS = gql`
  mutation updatePatientProgress(
    $signUpCode: String!
    $progress: PatientProgress
    $sessionDate: Date
    $clinicianId: ID
  ) {
    updatePatientProgress(
      code: $signUpCode
      progress: $progress
      sessionDate: $sessionDate
      clinicianId: $clinicianId
    ) {
      ...Patient
    }
  }
  ${fragments.patient}
`;

export enum PatientProgress {
  DISCHARGED = 'DISCHARGED',
  ON_WAITLIST = 'ON_WAITLIST',
  IN_THERAPY = 'IN_THERAPY',
}

export interface IUpdatePatientProgressResponse {
  updatePatientProgress: IPatientFragment;
}

export interface IUpdatePatientProgressVariables {
  signUpCode: string;
  progress: PatientProgress;
  sessionDate?: Date;
  clinicianId?: string;
}

export const useUpdatePatientProgressMutation = () => {
  return useMutation<IUpdatePatientProgressResponse, IUpdatePatientProgressVariables>(
    UPDATE_PATIENT_PROGRESS
  );
};
