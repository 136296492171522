import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Colors } from '../../config/colors';
import { Icons } from '../../config/icons';
import { Button } from '../Button/Button';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { ModalContent } from './ModalContent';
import { ModalHeader } from './ModalHeader';

interface ModalScheduledSuccessProps {
  headerTitle?: string;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  title?: string;
  message?: string;
  onClose?: () => void;
}

export const ErrorModal = (props: ModalScheduledSuccessProps) => {
  const { headerTitle, headerLeft, headerRight, title, message, onClose } = props;
  return (
    <ModalContent scrollable>
      <View style={styles.container}>
        <ModalHeader title={headerTitle} leftContent={headerLeft} rightContent={headerRight} />
        <View style={styles.content}>
          <Image source={Icons.scheduled} />
          <LimbicText center variant={TextVariants.L} color={Colors.darkGrey2}>
            {title}
          </LimbicText>
          <LimbicText
            center
            color={Colors.darkGrey1}
            variant={TextVariants.M}
            style={styles.messageText}
          >
            {message}
          </LimbicText>

          <Button
            fixed
            title="Close"
            style={styles.button}
            textStyle={styles.buttonText}
            onPress={onClose}
          />
        </View>
      </View>
    </ModalContent>
  );
};

ErrorModal.HeaderIcon = ModalHeader.HeaderIcon;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 32,
    paddingBottom: 24,
    backgroundColor: Colors.white,
  },
  messageText: {
    paddingVertical: 24,
  },
  button: {
    backgroundColor: Colors.white,
    borderWidth: 2,
    borderColor: Colors.grey3,
  },
  buttonText: {
    color: Colors.pink3,
    fontWeight: '400',
  },
});
