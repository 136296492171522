const shuffled = <T>(array: Array<T>) => {
  const arrayCopy = [...array];
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], array[i]];
  }
  return arrayCopy;
};

export default shuffled;
