import React from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { EmotionCard, EmotionCardVariants } from '../Card/EmotionCard';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface SelectCopingStrategyEmotionProps {
  isLoading: boolean;
  emotions: React.ReactNode;
}

export const SelectCopingStrategyEmotion = ({
  isLoading,
  emotions,
}: SelectCopingStrategyEmotionProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <LimbicText variant={TextVariants.L} color={Colors.darkGrey2}>
          Coping strategy
        </LimbicText>
        <LimbicText variant={TextVariants.M} color={Colors.darkGrey1}>
          Record coping strategies for your client so that they are reminded of them when they need
          them the most.
        </LimbicText>
      </View>
      <ScrollView
        horizontal
        contentContainerStyle={styles.scrollViewContentContainer}
        showsHorizontalScrollIndicator={Platform.select({ web: true, default: false })}
      >
        <View style={styles.cardSpacer} />
        {isLoading && (
          <EmotionCard
            testID="loading-card"
            variant={EmotionCardVariants.Base}
            feeling="Loading..."
            emoji="⏳"
            style={styles.cardMargin}
          />
        )}
        {emotions}
        <View style={styles.cardSpacer} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    paddingBottom: 16,
  },
  textContainer: {
    paddingHorizontal: 24,
    paddingBottom: 24,
  },
  scrollViewContentContainer: {
    marginBottom: 10,
  },
  cardSpacer: {
    width: 24,
  },
  cardMargin: {
    marginRight: 8,
  },
  button: {
    paddingVertical: 36,
    backgroundColor: Colors.white,
  },
  buttonText: {
    color: Colors.darkGrey1,
    fontWeight: '400',
  },
});
