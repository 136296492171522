import { REFRESH_TOKEN_ENDPOINT, NetworkError, Stage } from '@limbic-for-therapists/shared';

const method = 'POST';
const headers = new Headers();
headers.set('Content-Type', 'application/json');

interface Response {
  accessToken: string;
  refreshToken: string;
}

export async function getNewToken(stage: Stage, refreshToken: string): Promise<Response> {
  const REFRESH_TOKEN_URL = REFRESH_TOKEN_ENDPOINT[stage];
  const body = JSON.stringify({ refreshToken });
  const result = await fetch(REFRESH_TOKEN_URL, { body, method, headers });
  const response = await result.json();
  const { data } = response;
  if (data?.error) {
    throw new NetworkError(data?.code, data?.error);
  }
  if (data?.message) {
    throw new NetworkError(data?.code, data?.message);
  }
  return data;
}
