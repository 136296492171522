import React from 'react';
import {
  Colors,
  LimbicText,
  TextVariants,
  getLineHeight,
  Logo,
} from '@limbic-for-therapists/components';
import { View, StyleSheet, ImageBackground } from 'react-native';
import { responsiveValue } from '../consts/dimensions';
import { useScreenDimensionsContext } from '../context/ScreenDimensions';
import { ScreenContainer } from '../components/ScreenContainer';

const LOGO_WIDTH = 125;

interface SignUpOrLogInScreenProps {
  children: React.ReactNode;
}

export const SignUpOrLogInScreen = ({ children }: SignUpOrLogInScreenProps) => {
  const { currentSize } = useScreenDimensionsContext();
  const headerTextVariant = responsiveValue(
    currentSize,
    TextVariants.S,
    TextVariants.XXL,
    TextVariants.XXXL,
    TextVariants.Title
  );

  return (
    <ScreenContainer backgroundColor={Colors.white} title="Welcome" style={styles.container}>
      <View style={styles.login}>
        {currentSize !== 'xs' ? (
          <ImageBackground
            source={{ uri: '/img/welcome.jpg' }}
            resizeMode="cover"
            style={styles.hero}
          >
            <Logo color={Colors.white} width={LOGO_WIDTH} />
            <View style={styles.title}>
              <LimbicText
                bold
                variant={headerTextVariant}
                color={Colors.white}
                style={{ lineHeight: getLineHeight(headerTextVariant) }}
              >
                Your Assistant Therapist
              </LimbicText>
            </View>
            <View style={styles.description}>
              <LimbicText variant={TextVariants.M} color={Colors.white}>
                The Limbic dashboard analyses all data collected via the client mobile app. We make
                it easy to identify patterns in thoughts, feelings and behaviour.
              </LimbicText>
            </View>
          </ImageBackground>
        ) : null}
        {children}
      </View>
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '100%',
    alignItems: 'stretch',
  },
  login: {
    height: '100%',
    flexDirection: 'row',
  },
  logo: {
    width: 108,
    height: 25,
  },
  hero: {
    padding: 88,
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  description: {
    maxWidth: 500,
    color: Colors.white,
  },
});
