import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, Text as RNText, TextProps, TextStyle } from 'react-native';
import { Colors } from '../../config/colors';
import { concat } from '../../utils/concat';

export enum TextVariants {
  Title = 'title',
  XXXL = 'xxxlarge',
  XXL = 'xxlarge',
  XL = 'xlarge',
  L = 'large',
  M = 'medium',
  Base = 'base',
  SB = 'subbase',
  S = 'small',
  XS = 'xsmall',
}

export interface LimbicTextProps extends TextProps {
  bold?: boolean;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  doubleHeight?: boolean;
  variant?: TextVariants;
  color?: Colors;
  style?: StyleProp<TextStyle>;
  children?: React.ReactNode;
}

interface LeftProps extends LimbicTextProps {
  left: true;
  center?: undefined;
  right?: undefined;
}

interface CenterProps extends LimbicTextProps {
  left?: undefined;
  center: true;
  right?: undefined;
}

interface RightProps extends LimbicTextProps {
  left?: undefined;
  center?: undefined;
  right: true;
}

type Props = LeftProps | CenterProps | RightProps;

export const getLineHeight = (variant: TextVariants) => {
  return styles[variant].lineHeight;
};

export const LimbicText = (props: Props) => {
  const {
    bold,
    left,
    center,
    right,
    doubleHeight,
    variant = TextVariants.Base,
    color,
    style,
    children,
    ...restProps
  } = props;
  const styling = useMemo(() => {
    const variantStyle = styles[variant];
    const lineHeight = variantStyle?.lineHeight * (doubleHeight ? 2 : 1);
    return concat(
      styles.default,
      bold && styles.bold,
      left && styles.left,
      center && styles.center,
      right && styles.right,
      variantStyle,
      { color, lineHeight },
      style
    );
  }, [bold, left, center, right, doubleHeight, color, variant, style]);
  return (
    <RNText style={styling} {...restProps}>
      {children}
    </RNText>
  );
};

LimbicText.defaultProps = {
  bold: false,
  left: true,
  color: Colors.black,
};

const styles = StyleSheet.create({
  default: {
    fontFamily: 'Aeroport',
    fontStyle: 'normal',
    fontWeight: '300',
  },
  title: {
    fontSize: 80,
    lineHeight: 80,
  },
  xxxlarge: {
    fontSize: 48,
    lineHeight: 48,
  },
  xxlarge: {
    fontSize: 40,
    lineHeight: 40,
  },
  xlarge: {
    fontSize: 32,
    lineHeight: 32,
  },
  large: {
    fontSize: 24,
    lineHeight: 32,
  },
  medium: {
    fontSize: 16,
    lineHeight: 24,
  },
  base: {
    fontSize: 16,
    lineHeight: 16,
  },
  subbase: {
    fontSize: 14,
    lineHeight: 16,
  },
  small: {
    fontSize: 12,
    lineHeight: 16,
  },
  xsmall: {
    fontSize: 10,
    lineHeight: 14,
    letterSpacing: 2,
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: '500',
  },
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
});
