import React, { FC } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

interface PublicRouteProps extends RouteProps {
  isAuthenticated: boolean;
  defaultAuthenticatedPath: string;
}

interface LocationState {
  from: Location;
}

/**
 * A route that is only accessible to unauthenticated users. Authenticated
 * users will be redirected to the last page they tried to view or the default.
 */
const UnauthenticatedRoute: FC<PublicRouteProps> = (props) => {
  const { isAuthenticated, defaultAuthenticatedPath, children, ...rest } = props;
  const location = useLocation<LocationState>();

  if (isAuthenticated) {
    return <Redirect to={location?.state?.from?.pathname || defaultAuthenticatedPath || '/'} />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default UnauthenticatedRoute;
