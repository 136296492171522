import React from 'react';
import { TouchableOpacity, View, StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config';
import { TouchableActiveOpacity } from '../../consts';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

const ICON_SIZE = 24;

interface ActivityGroupPreviewProps {
  onPress?: () => void;
  title: string;
  subtitle: string;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

export const ActivityGroupPreview = ({
  title,
  subtitle,
  children,
  onPress,
  style,
}: ActivityGroupPreviewProps) => {
  return (
    <TouchableOpacity
      activeOpacity={TouchableActiveOpacity.light}
      onPress={onPress}
      style={style}
      testID="activity-group-preview"
    >
      <SummaryCard
        title={title}
        subtitle={subtitle}
        leftAccessory={<Icon name="barbell-outline" color={Colors.darkGrey3} size={ICON_SIZE} />}
        bottomAccessory={
          <View style={{ alignItems: 'flex-end' }}>
            <LimbicText variant={TextVariants.S} color={Colors.grey3}>
              show more
            </LimbicText>
          </View>
        }
      >
        <View style={styles.childrenContainer}>{children}</View>
      </SummaryCard>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  childrenContainer: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
});
