import React from 'react';
import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  UIManager,
  View,
} from 'react-native';
import { getShadowStyles } from '../../config';
import { Colors } from '../../config/colors';

export interface ToggleProps {
  toggle: boolean;
  onToggle: () => void | Promise<void>;
}

export const Toggle: React.FC<ToggleProps> = ({ onToggle, toggle }) => {
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS === 'android') {
          if (UIManager.setLayoutAnimationEnabledExperimental)
            UIManager.setLayoutAnimationEnabledExperimental(true);
        }
        LayoutAnimation.configureNext(LayoutAnimation.Presets.spring);
        onToggle();
      }}
    >
      <View
        style={[styles.viewToggle, !toggle ? styles.toggleViewStyle1 : styles.toggleViewStyle2]}
      >
        <View
          style={[
            styles.circleToggle,
            !toggle ? styles.circleToggleStyle1 : styles.circleToggleStyle2,
          ]}
        />
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  viewToggle: {
    height: 11,
    width: 36,
    borderRadius: 20,
    justifyContent: 'center',
  },
  circleToggle: {
    width: 23,
    height: 23,
    borderRadius: 20,
  },
  toggleViewStyle1: { backgroundColor: Colors.grey3 },
  toggleViewStyle2: { backgroundColor: Colors.darkGrey2, alignItems: 'flex-end' },
  circleToggleStyle1: { backgroundColor: Colors.white, borderWidth: 1, borderColor: Colors.grey3 },
  circleToggleStyle2: {
    backgroundColor: Colors.pink2,
    elevation: 2,
    ...getShadowStyles(Colors.black, 0.5, 10, 0, 2),
  },
});
