import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export interface DayCircleProps {
  day?: string;
  onPress?: () => void;
  selected?: string[];
}

export const DayCircle = ({ day, onPress, selected }: DayCircleProps) => {
  const toggle = day && selected && selected.includes(day);
  return (
    <TouchableOpacity
      delayPressIn={0}
      activeOpacity={0.7}
      style={[styles.circle, toggle ? styles.selctedCircle : styles.unselectedCircle]}
      onPress={onPress}
      testID={`day-circle-${toggle ? 'selected' : 'unselected'}`}
    >
      {day && (
        <LimbicText
          variant={TextVariants.M}
          center
          color={toggle ? Colors.white : Colors.darkGrey2}
        >
          {day.charAt(0)}
        </LimbicText>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  circle: {
    width: 39.6,
    height: 39.6,
    borderRadius: 100,
    justifyContent: 'center',
    borderWidth: 1,
    backgroundColor: Colors.white,
  },
  selctedCircle: {
    backgroundColor: Colors.pink2,
    borderColor: Colors.pink2,
  },
  unselectedCircle: {
    borderColor: Colors.darkGrey1,
  },
});
