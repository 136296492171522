import React, { PropsWithChildren, useCallback, useState } from 'react';
import {
  ScrollView,
  ScrollViewProps,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewProps,
} from 'react-native';
import { Colors } from '../../config';
import { Button, ButtonProps } from '../Button/Button';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { TextInput, LimbicTextInputProps } from '../TextInput/TextInput';
import { concat } from '../../utils';
import { WithTestID } from '../../utils/withTestID';
import { Toggle, ToggleProps } from '../Toggle/Toggle';

interface Props extends ViewProps {
  scrollable?: false;
  children?: React.ReactNode;
}

interface ScrollProps extends ScrollViewProps {
  scrollable?: true;
  children?: React.ReactNode;
}

export const ModalContent = (props: Props | ScrollProps) => {
  const { scrollable, style, ...restProps } = props;
  return (
    <ScrollView
      scrollEnabled={scrollable}
      style={concat(style, styles.container)}
      showsVerticalScrollIndicator={false}
      {...restProps}
    />
  );
};

type ModalContentTextProps = React.PropsWithChildren<{
  bold?: boolean;
  small?: boolean;
  style?: StyleProp<TextStyle>;
}>;

const Title = (props: ModalContentTextProps) => {
  const { style, bold, small, children } = props;
  return (
    <LimbicText
      bold={bold}
      variant={small ? TextVariants.XS : TextVariants.L}
      color={Colors.darkGrey2}
      style={style}
    >
      {children}
    </LimbicText>
  );
};

const Body = (props: ModalContentTextProps) => {
  const { style, bold, small, children } = props;
  return (
    <LimbicText
      bold={bold}
      lineBreakMode="head"
      variant={small ? TextVariants.S : TextVariants.M}
      color={Colors.darkGrey1}
      style={[styles.bodyText, style]}
    >
      {children}
    </LimbicText>
  );
};

interface ErrorTextProps {
  children: React.ReactNode;
}

const ErrorText = (props: ErrorTextProps) => {
  const { children } = props;
  if (!children) {
    return null;
  }
  return (
    <LimbicText variant={TextVariants.S} color={Colors.pink5} style={styles.errorText}>
      {children}
    </LimbicText>
  );
};

interface TextInputProps extends LimbicTextInputProps {
  error?: React.ReactNode;
}

const ModalTextInput = (props: TextInputProps) => {
  const { value, error, onChangeText, onBlur, style, ...restProps } = props;

  return (
    <View style={styles.textInputContainer}>
      <TextInput
        style={style}
        value={value}
        onChangeText={onChangeText}
        onBlur={onBlur}
        {...restProps}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </View>
  );
};

interface TextAreaProps extends LimbicTextInputProps {
  forceValue?: boolean;
}

const ModalTextArea = (props: TextAreaProps) => {
  const { value, forceValue, onChangeText, style, ...restProps } = props;
  const [error, setError] = useState<string>();
  const onBlur = useCallback(() => {
    if (forceValue && !value) {
      setError('Field cannot be empty');
    } else {
      setError(undefined);
    }
  }, [value, forceValue]);
  const onTextChange = useCallback(
    (text: string) => {
      if (forceValue && text && error) {
        setError(undefined);
      }
      onChangeText?.(text);
    },
    [forceValue, error, onChangeText]
  );
  return (
    <View style={styles.textAreaContainer}>
      <TextInput.TextArea
        style={style}
        value={value}
        onBlur={onBlur}
        onChangeText={onTextChange}
        {...restProps}
      />
      <ErrorText>{error}</ErrorText>
    </View>
  );
};

const CTAButton = (props: WithTestID<ButtonProps>) => {
  return <Button fixed {...props} />;
};

const ToggleButton = (props: PropsWithChildren<WithTestID<ToggleProps & { color?: Colors }>>) => {
  const { children, color, ...restProps } = props;
  return (
    <View style={styles.toggleContainer}>
      <Toggle {...restProps} />
      <LimbicText variant={TextVariants.M} color={color} style={styles.toggleText}>
        {children}
      </LimbicText>
    </View>
  );
};

ModalContent.Title = Title;
ModalContent.Body = Body;
ModalContent.ErrorText = ErrorText;
ModalContent.TextArea = ModalTextArea;
ModalContent.TextInput = ModalTextInput;
ModalContent.CTAButton = CTAButton;
ModalContent.Toggle = ToggleButton;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    marginVertical: 16,
    backgroundColor: Colors.white,
  },
  bodyText: {
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  textAreaContainer: {
    paddingTop: 8,
    paddingBottom: 16,
  },
  textInputContainer: {
    paddingTop: 8,
    paddingBottom: 8,
    flexGrow: 1,
  },
  errorText: {
    paddingVertical: 8,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 8,
    alignItems: 'center',
  },
  toggleText: {
    paddingLeft: 8,
  },
});
