import { gql, useMutation } from '@apollo/client';
import { fragments, IPatientFragment } from '../fragments';

const UPDATE_PATIENT = gql`
  mutation updatePatientHomeworks($signupCode: String, $homeworkId: String, $enabled: Boolean) {
    updatePatientHomeworks(signupCode: $signupCode, homeworkId: $homeworkId, enabled: $enabled) {
      ...Patient
    }
  }
  ${fragments.patient}
`;

export interface IUpdatePatientHomeworksResponse {
  updatePatient: IPatientFragment;
}

export const useUpdatePatientHomeworkMutation = () => {
  return useMutation<IUpdatePatientHomeworksResponse>(UPDATE_PATIENT, {
    refetchQueries: ['getPatientScreenQuery', 'getPatient'],
    awaitRefetchQueries: true,
  });
};
