import { IAccessReferral } from '@limbic-for-therapists/backend';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import dayjs from 'dayjs';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { Loading } from '../Loading/Loading';

interface PatientLookupResultsListProps {
  lookup?: string;
  results?: IAccessReferral[];
  loading?: boolean;
  onSelectReferral: (referral: IAccessReferral) => void;
}

export const PatientLookupResultsList = ({
  results = [],
  loading = false,
  onSelectReferral,
}: PatientLookupResultsListProps) => {
  const handleFormatDate = (date: string) => {
    return dayjs(date).format('DD/MM/YYYY');
  };

  return (
    <View style={[styles.resultsContainer]}>
      {loading && (
        <View style={styles.loadingContainer}>
          <Loading subtitle="Please be patient, search could take several seconds." />
        </View>
      )}

      {!loading && results.length === 0 && (
        <LimbicText variant={TextVariants.Base} color={Colors.darkGrey1}>
          No results found
        </LimbicText>
      )}
      <View style={styles.innerResultsContainer}>
        {results.length > 0 &&
          results.map((result) => (
            <TouchableOpacity onPress={() => onSelectReferral(result)} key={result.id}>
              <View style={styles.resultRow}>
                <View style={styles.resultNameContainer}>
                  <LimbicText
                    variant={TextVariants.Base}
                    color={Colors.darkGrey1}
                    style={styles.rowItemText}
                  >
                    {result.name}
                  </LimbicText>
                </View>
                <View>
                  <LimbicText
                    variant={TextVariants.Base}
                    color={Colors.darkGrey1}
                    style={styles.rowItemText}
                  >
                    {handleFormatDate(result.analyticsData?.dob) ?? '-'}
                  </LimbicText>
                </View>
              </View>
            </TouchableOpacity>
          ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    marginTop: 10,
  },
  transparentBorder: {
    borderColor: 'transparent',
  },
  tabRow: {
    flexDirection: 'row',
  },
  titleText: {
    fontSize: 20,
  },
  subTitleText: {
    color: Colors.secondaryOnLight,
    paddingVertical: 0,
    paddingHorizontal: 0,
    fontSize: 14,
    fontWeight: '400',
  },
  modalContainer: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  container: {
    paddingBottom: 24,
  },
  containerTab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#eee',
  },
  bodyText: {
    color: Colors.darkGrey1,
  },
  input: {
    flex: 1,
    backgroundColor: Colors.white,
    borderRadius: 8,
    borderColor: Colors.darkGrey1,
    height: 32,
  },
  resultRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '100%',
    flexShrink: 0,
  },
  resultButton: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  rowItemText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 21,
    textAlign: 'left',
    color: Colors.primaryOnLight,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
  },
  resultsContainer: {
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    borderStyle: 'solid',
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 12,
    marginTop: 1,
    height: 200,
    overflow: 'hidden',
  },
  innerResultsContainer: {
    height: 200,
    overflowY: 'auto',
    maxHeight: 200,
    flexShrink: 1,
  },
  bottomAccessory: {
    marginTop: 48,
  },
  resultNameContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
});

export default PatientLookupResultsList;
