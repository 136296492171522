import React from 'react';
import { StyleSheet } from 'react-native';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SetSessionDateChangeAllProps {
  sessionDate?: string;
  sessionIsPast?: boolean;
  onSetChangeAllDates: (type: 'change-next' | 'change-all') => void;
  canSetRecurrent: boolean;
}

export const SetSessionDateChangeAll = ({
  sessionDate,
  sessionIsPast,
  onSetChangeAllDates,
  canSetRecurrent,
}: SetSessionDateChangeAllProps) => {
  return (
    <ModalContent>
      <ModalContent.Title>Change session date</ModalContent.Title>
      {!!sessionDate && (
        <>
          {sessionIsPast ? (
            <ModalContent.Body>
              Last session was set for <ModalContent.Body bold>{sessionDate}</ModalContent.Body>.
            </ModalContent.Body>
          ) : (
            <ModalContent.Body>
              Next session will be on <ModalContent.Body bold>{sessionDate}</ModalContent.Body>.
              Reports will be sent <ModalContent.Body bold>24h</ModalContent.Body> before your
              session.
            </ModalContent.Body>
          )}
        </>
      )}

      <ModalContent.CTAButton
        testID="change-next-session-date-button"
        style={styles.buttonMargin}
        title="Change next session date"
        onPress={() => onSetChangeAllDates('change-next')}
      />
      {canSetRecurrent && (
        <ModalContent.CTAButton
          outline
          testID="change-all-session-dates-button"
          style={styles.buttonMargin}
          title="Change frequency of sessions"
          onPress={() => onSetChangeAllDates('change-all')}
        />
      )}
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  buttonMargin: {
    marginVertical: 8,
  },
});
