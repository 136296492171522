import { IHomework, HomeworkTypes } from '../models/IHomework';

interface IListPatientHomeworksVariables {
  signUpCode: string;
}

// NOTE: this should probably come from the backend, and be based upon the selected
// patient, but it was statically set inside some component, so its at least abstracted
// out into a hook, where it should be called from.

const defaultHomeworks: IHomework[] = [
  {
    id: '1',
    homeworkId: 'fiveColumn',
    title: '5 Column Thought Record',
    subTitle: 'Shows if the 7 Column Thought Record is not enabled',
    homeworkType: HomeworkTypes.STRATEGY,
  },
  {
    id: '2',
    homeworkId: 'sevenColumn',
    title: '7 Column Thought Record',
    subTitle: '',
    homeworkType: HomeworkTypes.STRATEGY,
  },
  // Hiding while worry logs aren't implemented in the patient app
  /*{
    id: '3',
    homeworkId: 'worryLog',
    title: 'Worry Log',
    subTitle: 'Shows 3 times a day',
    homeworkType: HomeworkTypes.STRATEGY,
  },
  {
    id: '4',
    homeworkId: 'extendedWorryLog',
    title: 'Extended Worry Log',
    subTitle: 'Shows full worry log',
    homeworkType: HomeworkTypes.STRATEGY,
  },*/
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useListPatientHomeworks = (variables: IListPatientHomeworksVariables) => {
  return {
    homeworks: defaultHomeworks,
  };
};
