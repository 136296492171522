import React, { useCallback, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { concat } from '../../utils/concat';
import { WithTestID } from '../../utils/withTestID';

export enum EmotionCardVariants {
  Base = 'base',
  Custom = 'custom',
  SetYourOwn = 'setYourOwn',
}

interface Props {
  feeling: string;
  emoji?: string;
  variant: EmotionCardVariants;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  emojiStyle?: StyleProp<TextStyle>;
  onPress?: (emotion: string) => void;
}

export const EmotionCard = ({
  feeling,
  emoji,
  variant,
  style,
  textStyle,
  emojiStyle,
  onPress,
  testID,
}: WithTestID<Props>) => {
  const onCardPressed = useCallback(() => onPress?.(feeling), [feeling, onPress]);
  const styling = useMemo(() => concat(styles.default, styles[variant], style), [variant, style]);
  const textColor = variant === EmotionCardVariants.SetYourOwn ? Colors.white : Colors.black;
  return (
    <TouchableOpacity disabled={!onCardPressed} onPress={onCardPressed} testID={testID}>
      <View style={styling}>
        {!!emoji && (
          <LimbicText variant={TextVariants.L} color={textColor} style={emojiStyle}>
            {emoji}
          </LimbicText>
        )}
        <LimbicText variant={TextVariants.S} color={textColor} style={textStyle} center>
          {feeling}
        </LimbicText>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  default: {
    height: 110,
    width: 96,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: Colors.grey3,
  },
  base: {
    backgroundColor: Colors.white,
  },
  custom: {
    backgroundColor: Colors.white,
  },
  setYourOwn: {
    borderWidth: 0,
    backgroundColor: Colors.pink1,
  },
});
