import { ApolloClient, gql, useMutation } from '@apollo/client';

const DETACH_PSYCHOED_FROM_PATIENT = gql`
  mutation DetachPsychoedFromPatient($patientPsychoedId: ID!) {
    detachPsychoedFromPatient(patientPsychoedId: $patientPsychoedId)
  }
`;

interface IDetachPsychoedFromPatientVariables {
  patientPsychoedId: string;
}

export const useDetachPsychoedFromPatientMutation = <TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) => {
  return useMutation<undefined, IDetachPsychoedFromPatientVariables>(DETACH_PSYCHOED_FROM_PATIENT, {
    refetchQueries: ['getPatientPsychoedsQuery'],
    awaitRefetchQueries: true,
    client: titanClient,
  });
};
