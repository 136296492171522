import React, { useEffect } from 'react';
import lottie, { AnimationItem } from 'lottie-web';
import { useRef } from 'react';

interface LottieAnimationProps {
  path: string;
  playing: boolean;
}

export const LottieAnimation = ({ path, playing }: LottieAnimationProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animation = useRef<AnimationItem | null>(null);

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      autoplay: false,
      container: containerRef.current as HTMLDivElement,
      path,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!animation.current) return;
    if (playing) animation.current.play();
    else animation.current.stop();
  }, [playing]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={containerRef}
    />
  );
};
