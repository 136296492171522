import React from 'react';
import { View, StyleSheet } from 'react-native';
import { LimbicLineChart, LimbicLineChartProps } from '../LimbicLineChart/LimbicLineChart';

// NOTE: this is just a wrapper for LimbicLineChart, but adds the needed container size styles
// for the graph svg to be displayed, which we may not always want, depending on where used.

export const QuestionnaireLineChartContainer = (props: LimbicLineChartProps) => {
  return (
    <View style={styles.contentContainer}>
      <LimbicLineChart {...props} />
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    height: 120,
    borderRadius: 16,
    overflow: 'hidden',
  },
});
