import React from 'react';
import { StyleSheet } from 'react-native';
import { Colors } from '@limbic-for-therapists/components';
import { TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';

const ICON_SIZE = 24;

interface ModalNavigationBarProps {
  leftButton?: React.ReactNode;
  rightButton?: React.ReactNode;
}

const BackButton = ({ onPress }: { onPress?: () => void }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.button}
      testID="modal-navigation-bar-back-button"
    >
      <Icon name="arrow-back-outline" size={ICON_SIZE} color={Colors.white} />
    </TouchableOpacity>
  );
};

const CloseButton = ({ onPress }: { onPress?: () => void }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.button}
      testID="modal-navigation-bar-close-button"
    >
      <Icon name="close-outline" size={ICON_SIZE} color={Colors.white} />
    </TouchableOpacity>
  );
};

export const ModalNavigationBar = ({ leftButton, rightButton }: ModalNavigationBarProps) => {
  return (
    <View style={styles.buttonContainer}>
      {leftButton}
      {rightButton}
    </View>
  );
};

ModalNavigationBar.CloseButton = CloseButton;
ModalNavigationBar.BackButton = BackButton;

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: Colors.purple,
    padding: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    width: ICON_SIZE,
    height: ICON_SIZE,
  },
  closeButton: {
    flexGrow: 1,
    alignItems: 'flex-end',
  },
});
