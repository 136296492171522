import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Controller, UseFormMethods } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { ModalContent } from '../LimbicModal/ModalContent';

export interface IPatientDetailsFormValues {
  signupCode: string;
  name?: string;
  email?: string;
  phoneMobile?: string;
}

interface IProps {
  formProps: UseFormMethods<IPatientDetailsFormValues>;
  signupCode: string;
  onSubmit: () => void;
}

export function PatientDetails(props: IProps) {
  const { formProps, signupCode, onSubmit } = props;
  return (
    <ModalContent>
      <ModalContent.Title>Edit Patient Details</ModalContent.Title>
      <ModalContent.Body>{signupCode}</ModalContent.Body>
      <View style={styles.form}>
        <Controller
          control={formProps.control}
          name="name"
          rules={{ validate: nameValidator }}
          render={({ onChange, onBlur, value, ref }) => (
            <ModalContent.TextInput
              innerRef={ref}
              testID="enter-patient-name"
              defaultValue={value}
              label="Name"
              placeholder="Type the patient's name"
              error={formProps.errors.name?.message}
              onBlur={onBlur}
              onChangeText={(value) => onChange(value)}
            />
          )}
        />
        <Controller
          control={formProps.control}
          name="email"
          rules={{ validate: emailValidator }}
          render={({ onChange, onBlur, value, ref }) => (
            <ModalContent.TextInput
              innerRef={ref}
              testID="enter-patient-email"
              defaultValue={value}
              label="Email"
              placeholder="Type the patient's email"
              error={formProps.errors.email?.message}
              onBlur={onBlur}
              onChangeText={(value) => onChange(value)}
            />
          )}
        />
        <Controller
          control={formProps.control}
          name="phoneMobile"
          rules={{ validate: phoneNumberValidator }}
          render={({ onChange, onBlur, value, ref }) => (
            <ModalContent.TextInput
              innerRef={ref}
              testID="enter-patient-phone-number"
              defaultValue={value}
              label="Phone Number"
              placeholder="Type the patient's phone number"
              error={formProps.errors.phoneMobile?.message}
              onBlur={onBlur}
              onChangeText={(value) => onChange(value)}
            />
          )}
        />
      </View>
      <ModalContent.CTAButton
        testID="save-patient-details"
        title="Save"
        loading={formProps.formState.isSubmitting}
        onPress={onSubmit}
      />
    </ModalContent>
  );
}

function nameValidator(value: string): string | undefined {
  if (!value) return 'Name is required';
  if (!/(.+\s).+/gi.test(value)) return 'Please type the full name';
}

function emailValidator(value: string): string | undefined {
  if (value && !isEmail(value)) return 'Invalid email address';
}

function phoneNumberValidator(phoneNumber: string): string | undefined {
  if (phoneNumber && !/^07\d{9}$/.test(phoneNumber))
    return 'Phone number must be 11 digits starting with 07';
}

const styles = StyleSheet.create({
  form: {
    paddingBottom: 16,
  },
});
