/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, createContext, useMemo } from 'react';
import { StyleProp, View, ViewProps, ViewStyle } from 'react-native';
import { responsiveValue, ResponsiveSize, grid } from '../consts/dimensions';
import { useScreenDimensionsContext } from '../context/ScreenDimensions';

const GRID_MAX_WIDTH = `${grid.maxWidth}px`;

const GRID_DIMENSIONS = {
  xs: {
    gutter: 16,
    margin: 20,
  },
  sm: {
    gutter: 20,
    margin: 20,
  },
  md: {
    gutter: 20,
    margin: 20,
  },
};

interface GridContainerProps {
  children: React.ReactNode;
  currentSizeOverride?: ResponsiveSize;
  gridMargin?: { [size in ResponsiveSize]?: number };
  gridGutter?: { [size in ResponsiveSize]?: number };
  gridColumns?: { [size in ResponsiveSize]?: number };
  customWidth?: string | number;
  style?: StyleProp<ViewStyle> | undefined;
}

interface GridColumnProps extends Omit<ViewProps, 'children' | 'color'> {
  span?: number;
  offset?: number;
  children: React.ReactNode;
  columnIdx?: number;
  style?: StyleProp<ViewStyle>;
}

interface GridRowProps extends Omit<ViewProps, 'children' | 'color'> {
  children: React.ReactNode;
  wrap?: 'wrap' | 'auto';
  withGutter?: boolean;
  flexDirection?: 'column' | 'row';
}

const GridContext = createContext<{
  gridGutter: number;
  gridColumns: number;
}>({
  gridGutter: 0,
  gridColumns: 0,
});

const GridContainer = ({
  currentSizeOverride,
  children,
  gridGutter: customGridGutter,
  gridColumns: customGridColumns,
  style,
}: GridContainerProps) => {
  const { currentSize: currentSizeFromScreenDimensions } = useScreenDimensionsContext();
  const currentSize = currentSizeOverride || currentSizeFromScreenDimensions;

  const gridGutter = useMemo(
    () =>
      responsiveValue(
        currentSize,
        customGridGutter?.xs || GRID_DIMENSIONS.xs.gutter,
        customGridGutter?.sm || GRID_DIMENSIONS.sm.gutter,
        customGridGutter?.md || GRID_DIMENSIONS.md.gutter,
        customGridGutter?.lg
      ),
    [currentSize, customGridGutter]
  );
  const gridColumns = useMemo(
    () =>
      responsiveValue(
        currentSize,
        customGridColumns?.xs || 4,
        customGridColumns?.sm || 12,
        customGridColumns?.md,
        customGridColumns?.lg
      ),
    [currentSize, customGridColumns]
  );

  const values = { gridGutter, gridColumns };

  return (
    <GridContext.Provider value={values}>
      <View
        style={[
          {
            maxWidth: GRID_MAX_WIDTH,
            width: responsiveValue(currentSize, '100%', '90%'),
            paddingHorizontal: responsiveValue(currentSize, 20, 0),
            flexDirection: 'column',
          },
          style,
        ]}
      >
        {children}
      </View>
    </GridContext.Provider>
  );
};

const useGridContext = () => useContext(GridContext);

const GridRowContext = createContext<{
  withGutter: boolean;
  columnCount: number;
}>({
  withGutter: false,
  columnCount: 0,
});

const GridRow = ({
  flexDirection = 'row',
  children,
  withGutter = false,
  wrap,
  style,
}: GridRowProps) => {
  const columnCount = React.Children.count(children);
  const values = { withGutter, columnCount };

  return (
    <GridRowContext.Provider value={values}>
      <View
        style={[
          {
            flexDirection,
            flexGrow: 1,
            flexWrap: wrap ? 'wrap' : 'nowrap',
          },
          style,
        ]}
      >
        {React.Children.toArray(children).map((el: any, idx: number) =>
          React.cloneElement(el, { columnIdx: idx })
        )}
      </View>
    </GridRowContext.Provider>
  );
};

const useGridRowContext = () => useContext(GridRowContext);

interface StyledGridColumnProps {
  span: number;
  gutter: number;
  isLastColumn: boolean;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

const StyledGridColumn = ({
  span,
  gutter,
  isLastColumn,
  children,
  style,
}: StyledGridColumnProps) => {
  return (
    <View
      style={[
        {
          flexDirection: 'column',
          flexShrink: 1,
          width: `${(span / 12) * 100}%`,
          paddingRight: isLastColumn ? 0 : gutter,
        },
        style,
      ]}
    >
      {children}
    </View>
  );
};

const GridColumn = ({ children, span = 12, offset = 0, columnIdx, ...rest }: GridColumnProps) => {
  const { gridGutter } = useGridContext();
  const { withGutter, columnCount } = useGridRowContext();

  return (
    <>
      {offset ? (
        <StyledGridColumn
          span={offset}
          gutter={0}
          isLastColumn={columnIdx === columnCount}
          {...rest}
        />
      ) : null}
      <StyledGridColumn
        span={span}
        gutter={withGutter ? gridGutter : 0}
        isLastColumn={typeof columnIdx === 'undefined' ? false : columnIdx + 1 === columnCount}
        {...rest}
      >
        {children}
      </StyledGridColumn>
    </>
  );
};

export { GridContainer, GridColumn, GridRow };
