import { Dayjs } from 'dayjs';
import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface InvitePatientDetails {
  clinicianId: string;
  isAccessReferral: boolean;
  signupCode?: string;
  name?: string;
  email?: string;
  phone?: string;
  dob?: Dayjs;
}

interface NewInvitePatientConfirmDetailsProps {
  patient: InvitePatientDetails;
}

export const NewInvitePatientConfirmDetails = ({
  patient,
}: NewInvitePatientConfirmDetailsProps) => {
  const dob = useMemo(
    () => (patient.dob ? patient.dob.format('DD/MM/YYYY') : 'No date of birth provided'),
    [patient.dob]
  );

  return (
    <>
      <ModalContent.Body style={styles.detailsContainer}>
        <View style={styles.detailsRow}>
          <LimbicText variant={TextVariants.Base} bold>
            {patient.name}
          </LimbicText>
        </View>
        <View style={styles.detailsRow}>
          <View style={styles.detailsCell}>
            <LimbicText variant={TextVariants.S} style={styles.detailsLabel}>
              Date of Birth
            </LimbicText>
            <LimbicText variant={TextVariants.Base} bold>
              {dob || '-'}
            </LimbicText>
          </View>
          <View style={styles.detailsCell}>
            <LimbicText variant={TextVariants.S} style={styles.detailsLabel}>
              Phone Number
            </LimbicText>
            <LimbicText variant={TextVariants.Base} bold>
              {patient.phone || '-'}
            </LimbicText>
          </View>
        </View>
        <View style={styles.detailsRow}>
          <View style={styles.detailsCell}>
            <LimbicText variant={TextVariants.S} style={styles.detailsLabel}>
              Email
            </LimbicText>
            <LimbicText variant={TextVariants.Base} bold>
              {patient.email || '-'}
            </LimbicText>
          </View>
        </View>
      </ModalContent.Body>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  detailsContainer: {
    paddingTop: 16,
    paddingRight: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    backgroundColor: '#EFF0F5',
    borderRadius: 16,
    flexDirection: 'column',
    display: 'flex',
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
  },
  detailsCell: {
    display: 'flex',
    flexDirection: 'column',
    width: 220,
  },
  detailsLabel: {
    fontFamily: 'Aeroport',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 10,
    lineHeight: 16,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: Colors.black,
    paddingBottom: 4,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyText: {
    color: Colors.darkGrey1,
  },
  input: {
    width: '100%',
  },
  resultRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  resultButton: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  intervalText: {
    textDecorationLine: 'underline',
    fontSize: 12,
    lineHeight: 18,
  },
  resultsContainer: {
    flexDirection: 'column',
  },
  bottomAccessory: {
    marginTop: 24,
  },
  tag: {
    marginBottom: 8,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  pickDateButton: {
    width: 300,
    alignSelf: 'flex-start',
    borderWidth: 1,
    backgroundColor: Colors.white,
    justifyContent: 'flex-start',
    borderColor: Colors.darkGrey1,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
  ctaButton: {
    marginRight: 16,
    width: 140,
  },
});
