import React, { useCallback } from 'react';
import { useModalContext } from '../../context/Modal';
import { FlowContextProvider } from '../../context/Flow';
import EditPatientDetailsScreen from './screens/EditPatientDetailsScreen';

export interface IEditPatientDetailsFlowProps {
  signupCode: string;
  name?: string;
  email?: string;
  analyticsData?: any;
}

export default function EditPatientDetailsFlow(props: IEditPatientDetailsFlowProps) {
  const { signupCode, name, email, analyticsData } = props;
  const { dismissModal } = useModalContext();
  const values = { signupCode, name, email, analyticsData };

  const handleFinishFlow = useCallback(() => {
    dismissModal();
  }, [dismissModal]);
  return (
    <FlowContextProvider
      hideBackButtonOnLastStep
      defaultValues={values}
      finishFlow={handleFinishFlow}
      testID="edit-patient-details-flow"
    >
      {() => (
        <>
          <EditPatientDetailsScreen />
        </>
      )}
    </FlowContextProvider>
  );
}
