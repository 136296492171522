import * as yup from 'yup';
import { ValidationError } from '../consts/errors';

export const RequiredString = (message?: string) => yup.string().trim().required(message);
export const OptionalString = () => yup.string().trim().optional();
export const PasswordString = () =>
  yup
    .string()
    .trim()
    .min(12, 'Password must be at least 12 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[^a-zA-Z0-9]/, 'Password must contain at least one special character')
    .required('Password is required');

export const validate = async (schema: yup.AnySchema, value: unknown) => {
  try {
    await schema.validate(value, { abortEarly: true });
  } catch (e) {
    throw new ValidationError(e.message);
  }
};
