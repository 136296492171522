import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { getApolloClient } from './backend/helpers/getApolloClient';
import { ActiveClinicianContextProvider } from './context/ActiveClinician';
import { ModalContextProvider } from './context/Modal';
import { ScreenDimensionsContextProvider } from './context/ScreenDimensions';
import { ToastContextProvider } from './context/Toast';
import { RootRouter } from './routers/Root';
import { FeatureFlagsFilterProvider } from './context/FeatureFlags';

const App = () => {
  const client = getApolloClient();

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <ScreenDimensionsContextProvider>
          <IntercomProvider appId="ihaet4un" shouldInitialize>
            <Router>
              <ActiveClinicianContextProvider>
                <ToastContextProvider>
                  <ModalContextProvider>
                    <FeatureFlagsFilterProvider>
                      <RootRouter />
                    </FeatureFlagsFilterProvider>
                  </ModalContextProvider>
                </ToastContextProvider>
              </ActiveClinicianContextProvider>
            </Router>
          </IntercomProvider>
        </ScreenDimensionsContextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
