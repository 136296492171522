import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config/colors';

const HEIGHT = 5;
const WIDTH = 60;

export const ModalHandler = () => {
  return (
    <View style={styles.header}>
      <View style={styles.panelHeader}>
        <View style={styles.panelHandle} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: Colors.white,
    paddingTop: 24,
    paddingBottom: 24 - HEIGHT,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
  },
  panelHeader: {
    alignItems: 'center',
  },
  panelHandle: {
    width: WIDTH,
    height: HEIGHT,
    borderRadius: 4,
    backgroundColor: Colors.grey4,
  },
});
