import { useMemo } from 'react';
import { ApolloClient, gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import {
  fragments,
  IBehaviouralActionFragment,
  ICopingStrategyFeedbackFragment,
  ICopingStrategyFragmentWithCounters,
  IMoodQuestionnaireFragment,
  IWeeklyQuestionnaireScoreFragment,
  IPatientInterventionFragment,
} from '../fragments';
import { IBehaviouralActionsGroup } from '../models/IBehaviouralActionsGroup';
import { groupMoodLogsByDate, moodLogGroupsToSectionList } from '../utils/groupMoodLogsByDate';
import { useGetTherapistDigest } from '../queries/getTherapistDigest';

export interface IReportsScreenQueryResponse {
  questionnaires?: {
    data: IWeeklyQuestionnaireScoreFragment[];
  };
  activities?: {
    data?: Array<{
      data?: IBehaviouralActionFragment[];
    }>;
  };
  copingStrategies?: {
    __typename?: 'CopingStrategiesList';
    data: ICopingStrategyFragmentWithCounters[];
  };
  localCopingStrategyFeedbacks?: {
    data: ICopingStrategyFeedbackFragment[];
  };
  moodLogsCount?: {
    value: number;
  };
  moodLogs?: {
    data: IMoodQuestionnaireFragment[];
  };
}

export interface IReportPatientInterventionsQueryResponse {
  patientInterventions?: IPatientInterventionFragment[];
}

const REPORTS_SCREEN_QUERY = gql`
  query reportsScreenQuery($signUpCode: String!, $fromDate: Date!, $toDate: Date!) {
    questionnaires: listWeeklyQuestionnaireScore(
      code: $signUpCode
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        ...WeeklyQuestionnaireScore
      }
    }

    activities: groupPatientBehavioralActions(
      code: $signUpCode
      limit: 10
      offset: 0
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        data {
          ...BehaviouralAction
        }
      }
    }

    copingStrategies: listCopingStrategies(code: $signUpCode) {
      data {
        ...CopingStrategy
        ...CopingStrategyWithCounters
      }
    }

    moodLogsCount: countQuestionnaires(
      code: $signUpCode
      moodEventTypes: ["mood-daily"]
      fromDate: $fromDate
      toDate: $toDate
    ) {
      value
    }

    moodLogs: listQuestionnaires(
      code: $signUpCode
      moodEventType: "mood-daily"
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        ...MoodQuestionnaire
      }
    }

    localCopingStrategyFeedbacks: listPatientCopingStrategiesFeedback(
      code: $signUpCode
      limit: 10
      offset: 0
      filter: { localCopingStrategyId: { ne: null }, createdAt: { gte: $fromDate, lte: $toDate } }
    ) {
      data {
        ...CopingStrategyFeedback
      }
    }
  }

  ${fragments.copingStrategy}
  ${fragments.copingStrategyWithCounters}
  ${fragments.copingStrategyFeedback}
  ${fragments.weeklyQuestionnaireScore}
  ${fragments.moodQuestionnaire}
  ${fragments.behaviouralAction}
`;

const INTERVENTIONS_REPORTS_SCREEN_QUERY = gql`
  query interventionsReportsScreenQuery($signUpCode: String!, $fromDate: Date!, $toDate: Date!) {
    patientInterventions: patientInterventionsBySignupCode(signupCode: $signUpCode) {
      id
      intervention {
        name
        steps {
          id
          prompt
        }
      }
      triggers {
        rrule
      }
      iterations(scheduledFrom: $fromDate, scheduledUntil: $toDate) {
        id
        scheduledAt
        firstAskedAt
        answers {
          id
          interventionStepId
          answers
          emotion {
            emoji
            stringValue
          }
        }
      }
    }
  }
`;

export interface IReportsScreenQueryVariables {
  signUpCode: string;
  fromDate: Date;
  toDate: Date;
}

const transformBehaviouralActionGroups = (
  data?: Array<{ data?: IBehaviouralActionFragment[] }>
): IBehaviouralActionsGroup[] => {
  const result = [] as IBehaviouralActionsGroup[];
  if (data?.length) {
    for (let i = 0; i < data.length; i++) {
      const entries = data[i]?.data;
      if (entries?.length) {
        const firstItem = entries[0];
        const id = firstItem.recurrenceId || firstItem.id;
        const { activity, type, startDate, recurrence } = firstItem;
        result.push({ id, activity, type, startDate, recurrence, entries });
      }
    }
  }
  return result;
};

export function useReportData<TCacheShape extends Record<string, unknown>>(
  digestId: string,
  signUpCode: string,
  titanClient: ApolloClient<TCacheShape>
) {
  const { data: digest } = useGetTherapistDigest(digestId);

  const { data } = useQuery<IReportsScreenQueryResponse, IReportsScreenQueryVariables>(
    REPORTS_SCREEN_QUERY,
    {
      skip: !digest?.getTherapistReport,
      variables: {
        signUpCode: signUpCode,
        fromDate: digest?.getTherapistReport.fromDate as Date,
        toDate: digest?.getTherapistReport.toDate as Date,
      },
    }
  );

  const { data: interventionsData } = useQuery<
    IReportPatientInterventionsQueryResponse,
    IReportsScreenQueryVariables
  >(INTERVENTIONS_REPORTS_SCREEN_QUERY, {
    skip: !digest?.getTherapistReport,
    variables: {
      signUpCode: signUpCode,
      fromDate: digest?.getTherapistReport.fromDate as Date,
      toDate: digest?.getTherapistReport.toDate as Date,
    },
    client: titanClient,
  });

  const activities = useMemo(() => {
    return transformBehaviouralActionGroups(data?.activities?.data || []);
  }, [data]);

  const moodLogGroups = moodLogGroupsToSectionList(
    groupMoodLogsByDate(digest?.getTherapistReport.dailyLogs?.data || [])
  );

  return {
    digest,
    report: data,
    activities,
    moodLogGroups,
    interventions: interventionsData?.patientInterventions,
    fromDate: dayjs(digest?.getTherapistReport.fromDate),
    toDate: dayjs(digest?.getTherapistReport.toDate),
    daySpan: dayjs(digest?.getTherapistReport?.toDate).diff(
      dayjs(digest?.getTherapistReport.fromDate),
      'days'
    ),
  };
}
