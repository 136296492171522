export interface ColumnProps {
  title: string;
  flexWidth: number;
}

// The combined flexWidth of all of the columns in the Table should arrive
// at this figure, otherwise you'll experience weird layout quirks with the
// table. So for example, in the instance that TABLE_COLUMNS_PER_ROW is set to 10:

// Column 1: flexWidth: 3
// Column 2: flexWidth: 6
// Column 3: flexWidth: 1

// The above example would be valid as the total arrives at 10 perfectly.

// Column 1: flexWidth: 1
// Column 2: flexWidth: 1
// Column 3: flexWidth: 1

// The above example would yield odd layout quirks because the total is 3, not 10.
export const TABLE_COLUMNS_PER_ROW = 10;
