import React, { useCallback, useEffect, useState } from 'react';
import {
  Colors,
  DashboardCard,
  LimbicText,
  TextVariants,
  ToastDetails,
} from '@limbic-for-therapists/components';
import { Animated, StyleSheet, View } from 'react-native';
import {
  DashboardSection,
  DashboardSectionType,
  getPatientCardProps,
  IPatientFragment,
  useInvitePatientViaDynamicLinkMutation,
} from '@limbic-for-therapists/backend';
import { responsiveValue } from '../../consts/dimensions';
import { useScreenDimensionsContext } from '../../context/ScreenDimensions';
import { useFeatureFlagsContext } from '../../context/FeatureFlags';
import { useToastContext } from '../../context/Toast';
import { titanClient } from '../../backend/client';

const REMINDER_SUCCESSFUL: ToastDetails = {
  title: 'Notification sent successfully',
  variant: 'green',
};

interface DashboardCardSectionProps {
  section: DashboardSection;
  onPressedPatient: (patient: IPatientFragment) => void;
  onEditPatientPress: (patient: IPatientFragment) => void;
  sectionFooter?: (section: DashboardSection) => React.ReactNode;
}

export const DashboardCardSection = ({
  section,
  onPressedPatient,
  onEditPatientPress,
  sectionFooter,
}: DashboardCardSectionProps) => {
  const { featureFlags } = useFeatureFlagsContext();
  const { currentSize } = useScreenDimensionsContext();
  const { type, title, data } = section;
  const [animatedIndices, setAnimatedIndices] = useState<number>(0);
  const [animatedValues, setAnimatedValues] = useState<Animated.Value[]>([]);
  const [remindUseSignupCode] = useInvitePatientViaDynamicLinkMutation(titanClient);
  const { setErrorToast, setToast } = useToastContext();

  useEffect(() => {
    const newItemsToAdd = new Array(section.data.length - animatedValues.length)
      .fill(null)
      .map(() => new Animated.Value(0));
    setAnimatedValues([...animatedValues, ...newItemsToAdd]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section.data.length]);

  useEffect(() => {
    const valuesToAnimate = animatedValues.slice(animatedIndices, animatedValues.length);
    if (!valuesToAnimate.length) return;
    Animated.stagger(
      100,
      valuesToAnimate.map((animatedValue) =>
        Animated.spring(animatedValue, {
          useNativeDriver: false,
          toValue: 1,
        })
      )
    ).start(() => setAnimatedIndices(animatedValues.length));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedValues]);

  const getCard = useCallback(
    (
      animatedValue: Animated.Value,
      patient: IPatientFragment,
      type: DashboardSectionType,
      index: number
    ) => {
      const isPending = type === DashboardSectionType.Pending;
      const patientCardProps = getPatientCardProps(patient, type);
      const canRemindSignup = !!(patient.email || patient.analyticsData?.phoneMobile);
      if (!patientCardProps) return null;

      const handleRemindSignup = () =>
        remindUseSignupCode({
          variables: {
            signupCode: patient.signupCode,
            overrideConsent: true,
            overrideResend: true,
          },
        })
          .then(() => setToast(REMINDER_SUCCESSFUL))
          .catch((e) => setErrorToast(e.message));

      return (
        <DashboardCard
          key={index?.toString()}
          width={responsiveValue(currentSize, '100%', '')}
          scaleTo={responsiveValue(currentSize, 1.0, 0)}
          animatedValue={animatedValue}
          isPending={isPending}
          title={patient.name ? patient.name : patient.signupCode}
          onPress={() => onPressedPatient(patient)}
          showTag={!featureFlags.DISABLE_PATIENT_CARD_TAGS}
          canRemindSignup={canRemindSignup}
          handleRemindSignup={handleRemindSignup}
          showSettingsButton={!featureFlags.DISABLE_PATIENT_EDIT && isPending}
          onSettingsPress={() => onEditPatientPress(patient)}
          {...patientCardProps}
        />
      );
    },
    [
      currentSize,
      featureFlags.DISABLE_PATIENT_CARD_TAGS,
      featureFlags.DISABLE_PATIENT_EDIT,
      onEditPatientPress,
      onPressedPatient,
      remindUseSignupCode,
      setErrorToast,
      setToast,
    ]
  );

  return (
    <View key={title}>
      {title ? (
        <LimbicText variant={TextVariants.Base} style={styles.sectionHeading}>
          {title}
        </LimbicText>
      ) : null}
      <View style={styles.sectionContainer}>
        {animatedValues.map((el, idx) => {
          const patient = data[idx];
          return getCard(el, patient, type, idx);
        })}
      </View>
      {sectionFooter?.(section)}
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeading: {
    marginTop: 22,
    marginLeft: 16,
    color: Colors.darkGrey1,
  },
  sectionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  patientCardContainer: {
    borderRadius: 16,
    marginRight: 15,
    marginTop: 20,
  },
});
