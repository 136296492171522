import { useCallback } from 'react';
import { useDischargePatientMutation } from '../mutations/dischargePatient';

export const useDischargePatient = (signUpCode: string) => {
  const [dischargePatientMutation, { loading }] = useDischargePatientMutation();

  const dischargePatient = useCallback(async () => {
    await dischargePatientMutation({
      variables: { signUpCode },
    });
  }, [dischargePatientMutation, signUpCode]);

  return { dischargePatient, loading };
};
