import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

export interface TitleBarProps {
  title: string;
  subtitle?: string;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  subtitleVariant?: TextVariants;
}

export const TitleBar = ({
  title,
  subtitle,
  leftAccessory,
  rightAccessory,
  subtitleVariant = TextVariants.XS,
}: TitleBarProps) => {
  return (
    <View style={styles.topContainer}>
      {leftAccessory ? <View style={styles.leftAccessoryContainer}>{leftAccessory}</View> : null}
      <View style={styles.textContainer}>
        <LimbicText variant={TextVariants.Base} color={Colors.darkGrey3}>
          {title}
        </LimbicText>
        {subtitle ? (
          <LimbicText variant={subtitleVariant} color={Colors.grey3}>
            {subtitle}
          </LimbicText>
        ) : null}
      </View>
      {rightAccessory ? <View style={styles.rightAccessoryContainer}>{rightAccessory}</View> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  leftAccessoryContainer: {
    marginRight: 10,
  },
  rightAccessoryContainer: {
    marginLeft: 10,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  textContainer: {
    flexDirection: 'column',
    flexBasis: 0,
    flexGrow: 1,
  },
});
