import React from 'react';
import { StyleSheet, View, TouchableOpacity, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { Colors } from '../../config/colors';
import { WithTestID } from '../../utils/withTestID';

import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface ActivityCardProps {
  header?: string;
  contentHeader?: string;
  contentFooter?: string;
  highlighted?: string;
  plainContent?: string;
  numberOfLines?: number;
  style?: ViewStyle;
  titleStyle?: StyleProp<TextStyle>;
  descriptionStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
  disabled?: boolean;
}

export const ActivityCard = ({
  header,
  contentHeader,
  contentFooter,
  highlighted,
  onPress,
  style,
  plainContent,
  numberOfLines,
  titleStyle,
  descriptionStyle,
  disabled = false,
  testID,
}: WithTestID<ActivityCardProps>) => {
  return (
    <TouchableOpacity onPress={!disabled ? onPress : undefined} testID={testID}>
      <View style={[styles.cardContainer, style, ...(disabled ? [styles.disabled] : [])]}>
        <LimbicText
          bold
          variant={TextVariants.Base}
          numberOfLines={2}
          style={[styles.header, titleStyle]}
        >
          {header}
        </LimbicText>
        {highlighted && contentFooter && contentHeader && (
          <LimbicText variant={TextVariants.S} color={Colors.grey3} style={styles.content}>
            <>
              {contentHeader}{' '}
              <LimbicText variant={TextVariants.S} color={Colors.pink2}>
                {highlighted}
              </LimbicText>{' '}
              {contentFooter}
            </>
          </LimbicText>
        )}
        {plainContent && (
          <LimbicText
            variant={TextVariants.S}
            color={Colors.grey3}
            numberOfLines={numberOfLines}
            style={[styles.content, descriptionStyle]}
          >
            {plainContent}
          </LimbicText>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    minHeight: 167,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: Colors.grey3,
  },
  disabled: {
    opacity: 0.3,
  },
  header: {
    paddingLeft: 16,
    paddingTop: 16,
  },
  content: {
    paddingHorizontal: 16,
  },
});
