import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  Colors,
  EmptyState,
  Fab,
  Icons,
  Loading,
  TextVariants,
  GreetingHeader,
  ViewTabs,
} from '@limbic-for-therapists/components';
import { ScreenContainer } from '../components/ScreenContainer';
import { GridColumn, GridContainer, GridRow } from '../components/ResponsiveGrid';
import { useActiveClinicianContext } from '../context/ActiveClinician';
import {
  IPatientFragment,
  DashboardSectionType,
  useDashboardContent,
  DashboardSection,
} from '@limbic-for-therapists/backend';
import { Link } from '../components/Link/Link';
import { useModalContext } from '../context/Modal';
import { fixedPositionStyle, useAnalytics } from '@limbic-for-therapists/shared';
import Icon from 'react-native-vector-icons/Ionicons';
import { DashboardCardSection } from '../components/DashboardCardSection/DashboardCardSection';
import { getPermissions } from '../backend/helpers/authToken';
import { useFeatureFlagsContext } from '../context/FeatureFlags';
import mixpanel from 'mixpanel-browser';
import EditPatientDetailsFlow from '../flows/EditPatientDetailsFlow/EditPatientDetailsFlow';
import { NewPatientWithHWFlow } from '../flows/NewPatientWithHWFlow/NewPatientWithHWFlow';

const EmptyStateMap = {
  [ViewTabs.TODAY]: {
    title: 'Peace out!',
    text: `We have no reports for you,\nso that means no\nscheduled patients today\nor tomorrow.`,
    iconName: `balloonrelax` as keyof typeof Icons,
  },
  [ViewTabs.ALL]: {
    title: `Let's start!`,
    text: `You don't have patients yet.\nLet's start by adding one...`,
    iconName: `balloon` as keyof typeof Icons,
  },
};

const ICON_SIZE = 24;

const DashboardScreen = () => {
  const { featureFlags } = useFeatureFlagsContext();
  const { logAnalytic } = useAnalytics(mixpanel);
  const { setContent } = useModalContext();
  const { activeClinician } = useActiveClinicianContext();

  const AVAILABLE_VIEW_TABS: ViewTabs[] = useMemo(
    () => [...(!featureFlags.DISABLE_TODAY_TAB ? [ViewTabs.TODAY] : []), ViewTabs.ALL],
    [featureFlags.DISABLE_TODAY_TAB]
  );

  const {
    todaySections,
    allSections,
    isToday,
    loading,
    moreLoading,
    canFetchMorePatients,
    canFetchMorePendingPatients,
    fetchMorePatients,
    fetchMorePendingPatients,
    setIsToday,
  } = useDashboardContent(AVAILABLE_VIEW_TABS[0]);

  const handleViewChange = useCallback(
    (newView: ViewTabs) => {
      logAnalytic({ eventName: 'dashboard-toggled-tab', params: { tab: newView } });
      if (!AVAILABLE_VIEW_TABS.includes(newView)) return;
      setIsToday(newView === ViewTabs.TODAY);
    },
    [AVAILABLE_VIEW_TABS, logAnalytic, setIsToday]
  );

  const handlePressedPatient = useCallback((patient: IPatientFragment) => {
    window.location.href = `/dashboard/patient/${patient.signupCode}`;
  }, []);

  const handleEditPatientPress = useCallback(
    (patient: IPatientFragment) => {
      if (featureFlags.DISABLE_PATIENT_EDIT) return;
      setContent(
        <EditPatientDetailsFlow
          signupCode={patient.signupCode}
          name={patient.name}
          email={patient.email}
          analyticsData={patient.analyticsData}
        />,
        undefined,
        false
      );
    },
    [featureFlags.DISABLE_PATIENT_EDIT, setContent]
  );

  const handlePressedNewUser = useCallback(() => {
    if (featureFlags.DISABLE_INVITE_FLOW) return;
    if (!activeClinician?.id) return; // NOTE: clinicianId is required for the invite flow
    logAnalytic({ eventName: 'invite-patient-started' });
    return setContent(
      <NewPatientWithHWFlow clinicianId={activeClinician.id} />,
      { width: 516 },
      false,
      true
    );
  }, [activeClinician?.id, featureFlags.DISABLE_INVITE_FLOW, logAnalytic, setContent]);

  const getSectionFooter = useCallback(
    (section: DashboardSection) => {
      if (
        section.type !== DashboardSectionType.All &&
        section.type !== DashboardSectionType.Pending
      )
        return null;
      if (section.type === DashboardSectionType.Pending && !canFetchMorePendingPatients)
        return null;
      if (section.type === DashboardSectionType.All && !canFetchMorePatients) return null;
      return (
        <View style={styles.sectionFooter}>
          <Button
            testID={
              section.type === DashboardSectionType.All
                ? 'fetch-more-all-button'
                : 'fetch-more-pending-button'
            }
            title="Fetch More"
            onPress={
              section.type === DashboardSectionType.Pending
                ? fetchMorePendingPatients
                : fetchMorePatients
            }
            loading={moreLoading}
          />
        </View>
      );
    },
    [
      canFetchMorePatients,
      canFetchMorePendingPatients,
      fetchMorePatients,
      fetchMorePendingPatients,
      moreLoading,
    ]
  );

  const sections = isToday ? todaySections : allSections;
  const totalItems = sections.flatMap((el) => el.data).length;

  return (
    <ScreenContainer
      backgroundColor={Colors.grey1}
      navBarRightAccessory={
        <>
          {getPermissions().includes('PATIENT_EDIT_FOR_CLINICS') ? (
            <Link to="/dashboard/transfer-patient" style={{ marginRight: 10 }}>
              <Button
                variant="main"
                small
                titleVariant={TextVariants.S}
                title="Transfer Patient"
                style={styles.transferClinicianButton}
                textStyle={styles.transferClinicianButtonText}
                testID="transfer-patient-button"
              />
            </Link>
          ) : null}
          <Link to="/dashboard/settings" testID="dashboard-settings-button">
            <Icon name="settings-outline" color={Colors.darkGrey1} size={ICON_SIZE} />
          </Link>
        </>
      }
      title="Home"
    >
      {loading ? (
        <Loading backgroundColor={Colors.grey1} />
      ) : (
        <>
          {!featureFlags.DISABLE_INVITE_FLOW ? (
            <Fab
              testID="invite-flow-fab"
              style={[styles.newUserFab, fixedPositionStyle]}
              icon="person-add-outline"
              onPress={handlePressedNewUser}
            />
          ) : null}
          <GridContainer>
            <GridRow>
              <GridColumn span={12}>
                <GreetingHeader
                  availableViewTabs={AVAILABLE_VIEW_TABS}
                  name={activeClinician?.name ?? activeClinician?.email}
                  view={isToday ? ViewTabs.TODAY : ViewTabs.ALL}
                  onViewChange={handleViewChange}
                />
                {totalItems ? (
                  sections.map((section) => (
                    <DashboardCardSection
                      key={section.title}
                      section={section}
                      onPressedPatient={handlePressedPatient}
                      onEditPatientPress={handleEditPatientPress}
                      sectionFooter={getSectionFooter}
                    />
                  ))
                ) : (
                  <EmptyState {...EmptyStateMap[isToday ? ViewTabs.TODAY : ViewTabs.ALL]} />
                )}
              </GridColumn>
            </GridRow>
          </GridContainer>
        </>
      )}
    </ScreenContainer>
  );
};

const styles = StyleSheet.create({
  newUserFab: {
    zIndex: 10,
    bottom: 24,
    right: 24,
  },
  niceToKnowOuterContainer: {
    width: '100%',
  },
  niceToKnowContentContainer: {
    paddingHorizontal: 16,
  },
  sectionHeading: {
    marginTop: 22,
    marginLeft: 16,
    color: Colors.darkGrey1,
  },
  sectionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  sectionFooter: {
    marginTop: 20,
  },
  transferClinicianButton: {
    padding: 10,
  },
  transferClinicianButtonText: {
    fontWeight: '700',
  },
});

export { DashboardScreen };
