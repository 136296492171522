import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LimbicText, TextVariants } from '..';
import { Colors } from '../../config';
// react-native-web do not have typescript yet, so I have to ignore this for now
// it is needed here because the normal react-native switch does not change the handle color on web
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Switch } from 'react-native-web';

type Props = {
  title: string;
  subtitle?: string;
  isEnabled: boolean;
  onToggle: (isEnabled: boolean) => void | Promise<void>;
  disabled?: boolean;
};

export function ToggleRow(props: Props) {
  const { title, subtitle, isEnabled, onToggle, disabled = false } = props;

  return (
    <>
      <View style={styles.container}>
        <View style={styles.container}>
          <View style={styles.content}>
            <LimbicText variant={TextVariants.Base} style={styles.text1}>
              {title}
            </LimbicText>
            <LimbicText variant={TextVariants.S} style={styles.text2}>
              {subtitle}
            </LimbicText>
          </View>
        </View>
        <View style={styles.toggleContainer}>
          <Switch
            trackColor={{ false: Colors.grey2, true: Colors.pink1 }}
            thumbColor={isEnabled ? Colors.pink2 : Colors.grey2}
            activeThumbColor={Colors.pink2}
            onValueChange={onToggle}
            value={isEnabled}
            disabled={disabled}
          />
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 8,
  },
  text2: {
    color: Colors.darkGrey1,
  },
  text1: {
    color: Colors.darkGrey2,
  },
  content: {
    flex: 8,
    marginHorizontal: 20,
    marginVertical: 10,
  },
  toggleContainer: {
    flex: 1,
    alignItems: 'flex-end',
    marginHorizontal: 20,
  },
  subChoiceExtraStyle: {
    backgroundColor: Colors.transparentPink1,
    borderRadius: 10,
  },
});
