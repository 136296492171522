import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { CheckRow } from '../CheckRow/CheckRow';
import { ModalContent } from '../LimbicModal/ModalContent';

interface SetBehaviouralActionTimeProps {
  select: React.ReactNode;
  timePicker: React.ReactNode;
  showLongDuration: boolean;
  loading: boolean;
  onSetShowLongDuration: (showing: boolean) => void;
  onSubmit: () => void;
}

export const SetBehaviouralActionTime = ({
  select,
  timePicker,
  showLongDuration,
  loading,
  onSubmit,
  onSetShowLongDuration,
}: SetBehaviouralActionTimeProps) => {
  return (
    <ModalContent testID="set-behavioural-action-time-modal-content">
      <ModalContent.Title>What time?</ModalContent.Title>
      <ModalContent.Body>
        Limbic will remind them 30 minutes before the start. Afterwards, Limbic will ask if they
        completed the activity.
      </ModalContent.Body>
      <ModalContent.Body style={styles.bodyTitle}>Time of the activity</ModalContent.Body>
      {timePicker}
      <View style={styles.durationContainer}>
        <CheckRow
          testID="set-behavioural-action-longer-than-one-hour-checkbox"
          checked={showLongDuration}
          content="This activity takes longer than 1 hour"
          onPress={() => onSetShowLongDuration(!showLongDuration)}
        />
        {showLongDuration && (
          <>
            <ModalContent.Body>Total activity duration</ModalContent.Body>
            <View>{select}</View>
          </>
        )}
      </View>
      <ModalContent.CTAButton
        title="Schedule activity"
        onPress={onSubmit}
        loading={loading}
        testID="schedule-activity-button"
      />
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  bodyTitle: {
    color: Colors.darkGrey2,
  },
  durationContainer: {
    paddingTop: 16,
    paddingBottom: 24,
  },
});
