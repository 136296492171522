import React, { useMemo } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  TextStyle,
  Insets,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { debounce } from 'lodash';
import Icon from 'react-native-vector-icons/Feather';
import { Colors } from '../../config/colors';
import { LimbicText, LimbicTextProps, TextVariants } from '../LimbicText/LimbicText';
import { noop } from '../../utils/noop';
import { concat } from '../../utils/concat';
import { cursorStyle } from '../../utils/cursorStyle';
import { WithTestID } from '../../utils/withTestID';

const DEFAULT_BUTTON_HIT_SLOP: Insets = { top: 10, left: 10, bottom: 10, right: 10 };

interface Props {
  title: string;
  subTitle?: string;
  fixed?: boolean;
  small?: boolean;
  neutral?: boolean;
  outline?: boolean;
  disabled?: boolean;
  icon?: string;
  iconProps?: {
    color?: Colors;
    size?: number;
  };
  titleVariant?: TextVariants;
  subTitleVariant?: TextVariants;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  loading?: boolean;
  onPress?: () => void | Promise<void>;
  disabledColor?: string;
}

export type MainButtonProps = Props;

export const MainButton = ({
  title,
  subTitle,
  fixed,
  small,
  neutral,
  outline,
  disabled,
  icon,
  iconProps,
  titleVariant,
  subTitleVariant,
  style,
  textStyle,
  loading = false,
  onPress,
  testID,
  disabledColor,
}: WithTestID<Props>) => {
  const backgroundColor = useMemo(() => (outline ? Colors.white : Colors.pink2), [outline]);
  const styling = useMemo(() => {
    return concat(
      styles.container,
      fixed && styles.fixed,
      small && styles.small,
      outline && styles.outline,
      disabled && styles.disabledContainer,
      { backgroundColor: disabled && disabledColor ? disabledColor : backgroundColor },
      cursorStyle('pointer'),
      style
    );
  }, [fixed, small, outline, disabled, backgroundColor, style, disabledColor]);

  const textStyling = useMemo(
    () =>
      concat(
        disabled && styles.disabledElement,
        icon && styles.textWithIcon,
        neutral && styles.textNeutral,
        textStyle
      ) as TextStyle,
    [disabled, icon, neutral, textStyle]
  );

  const renderedIcon = useMemo(() => {
    if (!icon) return null;
    return (
      <Icon
        name={icon}
        size={iconProps?.size || 24}
        color={iconProps?.color || Colors.white}
        style={[styles.icon, disabled && styles.disabledElement]}
      />
    );
  }, [disabled, icon, iconProps?.color, iconProps?.size]);

  return (
    <TouchableOpacity
      testID={testID}
      disabled={disabled || loading}
      delayPressIn={0}
      activeOpacity={0.5}
      hitSlop={DEFAULT_BUTTON_HIT_SLOP}
      onPress={debounce(onPress || noop, 150, { leading: true, trailing: false, maxWait: 500 })}
    >
      <View style={styling}>
        <WithSubtitle
          subTitle={subTitle}
          center
          variant={subTitleVariant || TextVariants.S}
          color={outline ? Colors.pink4 : Colors.white}
          textStyle={textStyling}
          style={loading ? styles.invisible : undefined}
        >
          <LimbicText
            center
            variant={titleVariant || TextVariants.Base}
            color={outline ? Colors.pink4 : Colors.white}
            style={textStyling}
          >
            {title}
            {/* Spacing for icon if displayed inline */}
            {!!icon && small ? ' ' : ''}
            {!!icon && small ? renderedIcon : ''}
          </LimbicText>
          {!!icon && !small ? renderedIcon : null}
        </WithSubtitle>
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator
              color={outline ? iconProps?.color || Colors.pink4 : Colors.white}
              size={21}
            />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

interface WithSubtitleProps extends Omit<LimbicTextProps, 'style'> {
  subTitle?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  children?: React.ReactNode;
}

const WithSubtitle = (props: WithSubtitleProps) => {
  const { subTitle, style, variant, children, textStyle, ...restProps } = props;
  if (!subTitle) {
    return <View style={[styles.subTitleContainer, style]}>{children}</View>;
  }
  return (
    <View style={[styles.subTitleContainer, style]}>
      <View style={{ flexDirection: 'column' }}>
        {children}
        <LimbicText
          variant={variant || TextVariants.S}
          style={concat(styles.subTitleText, textStyle)}
          // Casting as any to prevent type error
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...(restProps as any)}
        >
          {subTitle}
        </LimbicText>
      </View>
    </View>
  );
};

// noinspection JSUnusedGlobalSymbols
MainButton.defaultProps = {
  fixed: false,
  small: false,
  neutral: false,
  disabled: false,
  onPress: noop,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 16,
  },
  invisible: {
    opacity: 0,
  },
  subTitleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  loadingContainer: {
    position: 'absolute',
  },
  subTitleText: {
    paddingTop: 8,
    lineHeight: 12,
  },
  inlineIconContainer: {
    marginLeft: 5,
  },
  fixed: {
    alignSelf: 'stretch',
  },
  small: {
    alignSelf: 'center',
    paddingVertical: 8,
  },
  outline: {
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
  },
  text: {
    color: Colors.white,
  },
  textNeutral: {
    color: Colors.darkGrey2,
  },
  textWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 10,
  },
  disabledContainer: {
    backgroundColor: Colors.pink1,
  },
  disabledElement: {
    opacity: 0.5,
  },
});
