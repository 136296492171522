import {
  StepConfigInput,
  useAddInterventionStepMutation,
  useGetIntervention,
  usePublishInterventionMutation,
} from '@limbic-for-therapists/backend';
import { ErrorModal, Loading } from '@limbic-for-therapists/components';
import React, { useCallback, useState } from 'react';
import { getTitanApolloClient } from '../../../../backend/helpers/getApolloClient';
import { useFlowContext } from '../../../../context/Flow';
import { useToastContext } from '../../../../context/Toast';
import { AddCustomInterventionFlowValues } from '../../AddCustomInterventionFlow';
import EditInterventionStep from './EditInterventionStep';
import InterventionStepsSummary from './InterventionStepsSummary';

const titanClient = getTitanApolloClient();

export default function InterventionStepsScreen() {
  const { values, moveToNextStep, finishFlow } = useFlowContext<AddCustomInterventionFlowValues>();

  const [addInterventionStep] = useAddInterventionStepMutation(getTitanApolloClient());
  const [showSummary, setShowSummary] = useState(true);
  const [editStepId, setEditStepId] = useState<string>();
  const { intervention, loading } = useGetIntervention(values.id, getTitanApolloClient());
  const [publishIntervention] = usePublishInterventionMutation(intervention?.id ?? '', titanClient);
  const { setErrorToast } = useToastContext();

  const onSaveStep = useCallback(
    async (step: StepConfigInput) => {
      await addInterventionStep({
        variables: {
          input: step,
        },
        client: titanClient,
      });
      setShowSummary(true);
      setEditStepId(undefined);
    },
    [addInterventionStep]
  );

  const onAddStep = useCallback(
    (id?: string) => {
      setEditStepId(id);
      setShowSummary(false);
    },
    [setEditStepId, setShowSummary]
  );

  if (loading) return <Loading></Loading>;
  if (!intervention) {
    return (
      <ErrorModal
        message="Unable to load your activity."
        title="Something is wrong on our end."
        onClose={finishFlow}
      />
    );
  }

  if (showSummary) {
    return (
      <InterventionStepsSummary
        steps={intervention.steps}
        onAddStep={onAddStep}
        onSave={async () => {
          if (!intervention.id) return;
          try {
            await publishIntervention();
            values.onInterventionPublished?.(intervention.id);
            moveToNextStep();
          } catch (e) {
            setErrorToast('Unable to save the activity');
          }
        }}
      />
    );
  } else {
    return (
      <EditInterventionStep
        step={intervention.steps.find((step) => step.id === editStepId)}
        onSave={onSaveStep}
      />
    );
  }
}
