import {
  IGetClinicianResponse,
  useGetClinicFeatureFlags,
  useGetClinician,
} from '@limbic-for-therapists/backend';
import { noop } from '@limbic-for-therapists/components';
import { featureFlagOptions, useAnalytics } from '@limbic-for-therapists/shared';
import mixpanel from 'mixpanel-browser';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { client, titanClient } from '../backend/client';
import { setAuthToken, setPermissions } from '../backend/helpers/authToken';
import { useHistory } from 'react-router-dom';

const ActiveClinicianContext = createContext<{
  activeClinician: IGetClinicianResponse['getClinician'] | null | undefined;
  fetchActiveClinician: () => Promise<IGetClinicianResponse['getClinician'] | null>;
  logOut: () => void;
}>({
  activeClinician: undefined,
  fetchActiveClinician: noop as () => Promise<IGetClinicianResponse['getClinician'] | null>,
  logOut: noop,
});

export const ActiveClinicianContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [activeClinician, setActiveClinician] = useState<
    IGetClinicianResponse['getClinician'] | null | undefined
  >();
  const { getClinician } = useGetClinician();
  const { featureFlags } = useGetClinicFeatureFlags(titanClient, activeClinician?.clinic.id || -1);
  const { setUserProperties } = useAnalytics(mixpanel);
  const { shutdown: shutdownIntercom, boot } = useIntercom();
  const history = useHistory();

  const fetchActiveClinician = useCallback(async () => {
    try {
      const activeClinician = await getClinician();
      setActiveClinician(activeClinician.data.getClinician);
      if (activeClinician) setUserProperties(activeClinician.data.getClinician);
      return activeClinician.data.getClinician || null;
    } catch (e) {
      setActiveClinician(null);
      return null;
    }
  }, [getClinician, setUserProperties]);

  const logOut = useCallback(async () => {
    await client.clearStore();
    // 👇🏻 A user is always indirectly routed to authenticated or unauthenticated routes
    // as a side effect of the activeClinician having a value. Since we want to save the
    // desired path in state when redirecting to login (so that users will go back to what
    // they had been trying to access before login), then an edge case is created where
    // a user logs out and is then redirected to the page where the logout occurred. This
    // ensures that the user doesn't have any other site page in state to redirect to.
    history.replace({ pathname: '/' });
    setActiveClinician(null);
    setAuthToken(null);
    setPermissions(null);
    shutdownIntercom();
  }, [shutdownIntercom, history]);

  useEffect(() => {
    if (
      activeClinician &&
      featureFlags?.includes(featureFlagOptions.ENABLE_INTERCOM_THERAPIST_APP)
    ) {
      boot({
        userId: activeClinician.id,
        name: `${activeClinician.name} (Clinician)`,
        email: activeClinician.email,
        hideDefaultLauncher: !featureFlags?.includes(
          featureFlagOptions.SHOW_INTERCOM_THERAPIST_APP
        ),
        horizontalPadding: 120,
        verticalPadding: 40,
        customAttributes: {
          user_type: 'clinician',
          user_clinic: activeClinician.clinic.name,
          user_clinicId: activeClinician.clinic.id,
          environment:
            process.env.NODE_ENV.toLowerCase() === 'production' ? 'production' : 'staging',
        },
      });
    }
  }, [activeClinician, boot, featureFlags]);

  return (
    <ActiveClinicianContext.Provider value={{ activeClinician, fetchActiveClinician, logOut }}>
      {children}
    </ActiveClinicianContext.Provider>
  );
};

export const useActiveClinicianContext = () => useContext(ActiveClinicianContext);
