import React, { useCallback } from 'react';
import * as yup from 'yup';
import { EditPassword } from '@limbic-for-therapists/components';
import { PasswordString, RequiredString, validate } from '@limbic-for-therapists/shared';
import { useForm } from 'react-hook-form';
import { useActiveClinicianContext } from '../context/ActiveClinician';
import { useToastContext } from '../context/Toast';
import { useUpdateClinician } from '@limbic-for-therapists/backend';

const validationSchema = yup.object().shape({
  email: RequiredString('Please supply a valid email address.'),
  oldPassword: RequiredString('Please supply your old password.'),
  newPassword: PasswordString(),
});

interface EditPasswordScreenProps {
  onSuccess: () => void;
}

export const EditPasswordScreen = ({ onSuccess }: EditPasswordScreenProps) => {
  const { setErrorToast } = useToastContext();
  const { activeClinician } = useActiveClinicianContext();
  const formProps = useForm<{ oldPassword: string; newPassword: string }>();
  const { updateClinicianPassword, loading } = useUpdateClinician();

  const handleSubmit = useCallback(
    async ({ oldPassword, newPassword }: { newPassword: string; oldPassword: string }) => {
      try {
        const variables = { email: activeClinician?.email as string, oldPassword, newPassword };
        await validate(validationSchema, variables);
        await updateClinicianPassword({
          variables,
        });
        onSuccess();
      } catch (e) {
        setErrorToast(e.message);
      }
    },
    [activeClinician?.email, onSuccess, setErrorToast, updateClinicianPassword]
  );

  return <EditPassword formProps={formProps} loading={loading} onSubmit={handleSubmit} />;
};
