import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Colors } from '../../config/colors';

const LineSize = {
  sm: StyleSheet.hairlineWidth,
  md: StyleSheet.hairlineWidth * 2,
  lg: StyleSheet.hairlineWidth * 5,
  xlg: StyleSheet.hairlineWidth * 10,
};

export interface LineProps {
  size?: keyof typeof LineSize;
  color?: Colors;
  vertical?: boolean;
}

export const Line = ({ size = 'sm', color = Colors.grey3, vertical = false }: LineProps) => {
  return (
    <View
      style={{
        backgroundColor: color,
        ...(vertical
          ? {
              flexDirection: 'column',
              height: '100%',
              width: LineSize[size],
            }
          : {
              flexDirection: 'row',
              width: '100%',
              height: LineSize[size],
            }),
      }}
    />
  );
};
