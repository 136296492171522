import { useUpdateClinicianMutation } from '../mutations/updateClinician';
import { useUpdateClinicianPasswordMutation } from '../mutations/updateClinicianPassword';

export const useUpdateClinician = () => {
  const [updateClinician, { loading: updateClinicianLoading }] = useUpdateClinicianMutation();
  const [updateClinicianPassword, { loading: updateClinicianPasswordLoading }] =
    useUpdateClinicianPasswordMutation();
  return {
    updateClinician,
    updateClinicianPassword,
    loading: updateClinicianLoading || updateClinicianPasswordLoading,
  };
};
