import React from 'react';
import {
  Image,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { debounce } from 'lodash';
import Icon from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config/colors';
import { concat } from '../../utils/concat';
import { noop } from '../../utils/noop';
import { getShadowStyles, Icons } from '../../config';
import { cursorStyle } from '../../utils/cursorStyle';
import { WithTestID } from '../../utils/withTestID';
import { getIcon } from '../../utils/getIcon';

interface FabProps {
  icon?: string;
  iconImage?: keyof typeof Icons;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ViewStyle | TextStyle>;
}

export const Fab = ({
  icon,
  iconImage,
  onPress,
  style,
  iconStyle,
  testID,
}: WithTestID<FabProps>) => {
  const IconImage = iconImage ? getIcon(iconImage) : undefined;
  return (
    <TouchableWithoutFeedback
      onPress={debounce(onPress || noop, 150, { leading: true, trailing: false, maxWait: 1000 })}
      testID={testID}
    >
      <View style={[styles.fabContainer, cursorStyle('pointer'), style]}>
        <>
          {icon && (
            <Icon
              name={icon}
              size={42}
              color={Colors.white}
              style={concat(styles.icon, iconStyle)}
            />
          )}
          {IconImage ? (
            <Image source={IconImage} style={concat(styles.imageStyle, iconStyle)} />
          ) : null}
        </>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  fabContainer: {
    position: 'absolute',
    bottom: 22,
    right: 22,
    height: 82,
    width: 82,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 60,
    backgroundColor: Colors.pink2,
    padding: 16,
    ...getShadowStyles(Colors.black, 0.3, 4, 0, 4),
  },
  icon: {
    color: Colors.white,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
});
