import { Dayjs } from 'dayjs';
import React from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Colors } from '../../config';
import { ModalContent } from '../LimbicModal/ModalContent';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { TextInput } from '../TextInput/TextInput';

export interface NewPatientManualEntryValidationSchema {
  name: string;
  email: string;
  phone: string;
  dob: string;
  demoUser: boolean;
}

export interface NewPatientManualEntryValues {
  name?: string;
  email?: string;
  phone?: string;
  dob?: string;
  sessionDate?: Dayjs;
  demoUser?: boolean;
}

interface InvitePatientManualEntryProps {
  formProps: UseFormMethods<NewPatientManualEntryValidationSchema>;
  sessionDate: Dayjs | undefined;
  onShowDatePickerSessionDate: () => void;
  onConfirm: (values: NewPatientManualEntryValues) => void;
  onCancel?: () => void;
}

export const InvitePatientManualEntry = ({
  formProps,
  sessionDate,
  onShowDatePickerSessionDate,
  onConfirm,
}: InvitePatientManualEntryProps) => {
  const { control, handleSubmit } = formProps;

  const handleOnPressConfirm = (values: NewPatientManualEntryValidationSchema) => {
    onConfirm({
      ...values,
      demoUser: false,
      sessionDate,
    });
  };

  return (
    <ModalContent scrollable contentContainerStyle={styles.container}>
      <ModalContent.Title>Invite a patient to Limbic</ModalContent.Title>

      <ModalContent.Body>Add patient details</ModalContent.Body>

      <ModalContent.Body style={styles.row}>
        <Controller
          control={control}
          name="name"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              label="Name"
              onChange={(value) => onChange(value)}
              value={value}
              style={styles.inputName}
              onBlur={onBlur}
            />
          )}
        />
      </ModalContent.Body>

      <ModalContent.Body style={styles.row}>
        <Controller
          control={control}
          name="dob"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              style={[styles.input, styles.inputPadded]}
              label="Date of birth"
              placeholder="DD-MM-YYYY"
              value={value}
              onChange={(value) => onChange(value)}
              onBlur={onBlur}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              label="Phone number"
              onChange={(value) => onChange(value)}
              value={value}
              style={styles.input}
              onBlur={onBlur}
            />
          )}
        />
      </ModalContent.Body>

      <ModalContent.Body style={styles.row}>
        <Controller
          control={control}
          name="email"
          render={({ value, onChange, onBlur }) => (
            <TextInput
              label="Email"
              onChange={(value) => onChange(value)}
              value={value}
              style={[styles.input, styles.inputPadded]}
              onBlur={onBlur}
            />
          )}
        />
        <ModalContent.Body style={styles.column}>
          <LimbicText variant={TextVariants.Base} style={styles.dateLabel}>
            Next session date
          </LimbicText>
          <ModalContent.CTAButton
            fixed
            small
            title={sessionDate?.format('DD MMM YYYY') || 'Choose date'}
            icon="calendar"
            iconProps={{ size: 16, color: Colors.white }}
            style={[styles.tag, styles.pickDateButton]}
            textStyle={[styles.pickDateButtonText]}
            onPress={onShowDatePickerSessionDate}
          />
        </ModalContent.Body>
      </ModalContent.Body>

      <View style={styles.bottomAccessory}>
        <ModalContent.CTAButton
          title="Manually register patient"
          onPress={handleSubmit(handleOnPressConfirm)}
        />
      </View>
    </ModalContent>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyText: {
    color: Colors.darkGrey1,
  },
  inputName: {
    width: 660,
    borderRadius: 16,
    backgroundColor: Colors.white,
    borderColor: Colors.darkGrey1,
  },
  input: {
    width: 320,
    borderRadius: 16,
    backgroundColor: Colors.white,
    borderColor: Colors.darkGrey1,
  },
  inputPadded: {
    marginRight: 16,
  },
  row: {
    display: 'flex',
    paddingBottom: 24,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  bottomAccessory: {
    flex: 1,
    marginTop: 48,
  },
  dateLabel: {
    color: Colors.darkGrey1,
    paddingBottom: 4,
    paddingTop: 0,
  },
  tag: {
    marginBottom: 8,
    marginRight: 16,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  pickDateButton: {
    width: 320,
    alignSelf: 'flex-start',
    borderWidth: 1,
    backgroundColor: Colors.white,
    justifyContent: 'flex-start',
    borderColor: Colors.darkGrey1,
  },
  pickDateButtonText: {
    color: Colors.darkGrey2,
  },
  buttonCancel: {
    marginTop: 24,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.white,
  },
  buttonConfirm: {
    marginTop: 24,
    marginRight: 8,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGrey1,
    backgroundColor: Colors.pink1,
  },
});
