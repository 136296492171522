import React from 'react';
import { Colors } from '../../config';
import { LimbicCharacterLimitedText } from '../LimbicCharacterLimitedText/LimbicCharacterLimitedText';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';
import { SpeechBubbleWithDetails } from '../SpeechBubbleWithDetails/SpeechBubbleWithDetails';

interface NegativeEmotionLogProps {
  message: string;
  emoji: string;
  activity: string;
  maxCharacterCount: number;
}

export const NegativeEmotionLog = ({
  message,
  emoji,
  activity,
  maxCharacterCount,
}: NegativeEmotionLogProps) => {
  return (
    <>
      <SpeechBubbleWithDetails
        testID="negative-emotion-log"
        title={activity}
        subtitle="Activity"
        leftAccessory={<LimbicText variant={TextVariants.L}>{emoji}</LimbicText>}
        backgroundColor={Colors.pink4}
      >
        <LimbicCharacterLimitedText
          maxCharacterCount={maxCharacterCount}
          variant={TextVariants.M}
          color={Colors.black}
          bold={false}
        >
          {message}
        </LimbicCharacterLimitedText>
      </SpeechBubbleWithDetails>
    </>
  );
};
