import {
  PatientProgress,
  registerPatient,
  useInvitePatientViaDynamicLinkMutation,
  useUpdatePatientMutation,
  useUpdatePatientProgressMutation,
} from '@limbic-for-therapists/backend';
import { useAnalytics } from '@limbic-for-therapists/shared';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { phone } from 'phone';
import { useCallback } from 'react';
import { titanClient } from '../backend/client';
import { getAuthToken } from '../backend/helpers/authToken';
import { STAGE } from '../consts/stage';
import { useInviteFlowContext } from '../context/InviteFlow';
import { useToastContext } from '../context/Toast';
import { NewPatientFlowValues } from '../flows/NewPatientFlow/NewPatientFlow';

type UseInvitePatientType = {
  invitePatient: (patient: any) => any;
  isLoadingUpdatePatientProgress: boolean;
  isLoadingRemindPatientSignup: boolean;
  isLoadingUpdatePatient: boolean;
};

const useInvitePatient = (): UseInvitePatientType => {
  const { logAnalytic } = useAnalytics(mixpanel);
  const { setErrorToast, setToast } = useToastContext();
  const { values, setValues } = useInviteFlowContext<NewPatientFlowValues>();
  const [updatePatientProgress, { loading: isLoadingUpdatePatientProgress }] =
    useUpdatePatientProgressMutation();
  const [invitePatientViaDynamicLink, { loading: isLoadingRemindPatientSignup }] =
    useInvitePatientViaDynamicLinkMutation(titanClient);
  const [updatePatient, { loading: isLoadingUpdatePatient }] = useUpdatePatientMutation();

  const invitePatient = useCallback(
    async (patient: any) => {
      let response;

      try {
        const jwt = getAuthToken();
        if (!jwt) return;
        // signup code needed for notification call
        let signupCode;

        if (values.isAccessReferral) {
          signupCode = values.signupCode;

          if (signupCode == null) {
            throw new Error('Unreachable: signupCode must always be available at this point');
          }

          await updatePatientProgress({
            variables: {
              signUpCode: signupCode,
              clinicianId: values.clinicianId!,
              progress: PatientProgress.IN_THERAPY,
              ...(values.sessionDate !== undefined && {
                sessionDate: values.sessionDate.toDate(),
              }),
            },
          });

          // override communication permissions since this patient is being linked by a clinician directly
          await updatePatient({
            variables: {
              signUpCode: signupCode,
              analyticsData: {
                allowEmail: true,
                allowSms: true,
              },
            },
          });
        } else {
          // use auth server register endpoint to create patient
          response = await registerPatient(STAGE, jwt, {
            nextSessionDate: values.sessionDate?.toDate(),
            weeklyQuestionnaires: [],
            weekliesTriggeredEvery: [],
            name: patient.name,
            email: patient.email,
            analyticsData: {
              allowEmail: true,
              ...(patient.phone !== undefined &&
                phone(patient.phone).isValid && {
                  phoneMobile: phone(patient.phone).phoneNumber,
                  allowSms: true,
                }),
              ...(patient.dob !== undefined && {
                dob: dayjs(patient.dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              }),
            },
            demoUser: patient.demoUser,
          });

          setValues({ signupCode: response.signupCode });
          signupCode = response.signupCode;
        }

        // manually trigger the sending of the patient signup reminder so it happens immediately
        await invitePatientViaDynamicLink({
          variables: {
            signupCode,
            overrideConsent: true,
            overrideResend: true,
          },
        });

        logAnalytic({
          eventName: 'invite-patient',
          params: {
            patientId: (response?.signupCode || values.signupCode)!,
            questionnaireTypes: [],
            name: values.name,
            email: values.email,
            ...(values.phone !== undefined &&
              phone(patient.phone).isValid && {
                phone: phone(patient.phone).phoneNumber,
              }),
          },
        });

        setToast({
          title: 'Patient invited',
          subtitle:
            'We have texted a download link to the patient if we have a phone number. This can take a few minutes to arrive. Once they sign up, you will be able to generate reports and assign homework for your client.',
        });
        return { ...response, signupCode };
      } catch (e) {
        setErrorToast(e.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values.isAccessReferral,
      values.signupCode,
      values.name,
      values.email,
      values.phone,
      values.clinicianId,
      values.sessionDate,
      values.dob,
      invitePatientViaDynamicLink,
      logAnalytic,
      updatePatientProgress,
      updatePatient,
      setValues,
      setErrorToast,
    ]
  );

  return {
    invitePatient,
    isLoadingUpdatePatientProgress,
    isLoadingRemindPatientSignup,
    isLoadingUpdatePatient,
  };
};

export default useInvitePatient;
