/* eslint-disable @typescript-eslint/no-explicit-any,prefer-object-spread */
import { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { DashboardSectionType } from '../utils/getPatientCardProps';
import { fragments, IPatientFragment } from '../fragments';
import { useListPatients } from '../queries/listPatients';

enum ViewTabs {
  TODAY = 'today',
  ALL = 'all',
}

export type DashboardSection = {
  type: DashboardSectionType;
  title: string;
  data: IPatientFragment[];
};

const HOME_SCREEN_QUERY = gql`
  query homeScreenQuery($limit: Int = 10, $offset: Int = 0) {
    activePatientsToday: listPatients(
      status: ["active"]
      limit: $limit
      offset: $offset
      today: true
    ) {
      data {
        ...Patient
      }
      pagination {
        total
      }
    }
  }
  ${fragments.patient}
`;

export interface HomeScreenQueryResponse {
  activePatientsToday: {
    data: IPatientFragment[];
    pagination: {
      total: number;
    };
  };
}

export const useDashboardContent = (initialViewTab: ViewTabs) => {
  const [isToday, setIsToday] = useState<boolean>(initialViewTab === ViewTabs.TODAY);

  const {
    patients,
    refetch,
    onFetchMore: fetchMorePatients,
    moreLoading: patientsMoreLoading,
    canFetchMore: canFetchMorePatients,
  } = useListPatients('active');
  const {
    patients: pendingPatients,
    onFetchMore: fetchMorePendingPatients,
    moreLoading: pendingPatientsMoreLoading,
    canFetchMore: canFetchMorePendingPatients,
  } = useListPatients('pending');
  const { loading, data } = useQuery<HomeScreenQueryResponse>(HOME_SCREEN_QUERY);

  const allSections = useMemo((): DashboardSection[] => {
    return [
      ...(patients?.length
        ? [
            {
              type: DashboardSectionType.All,
              title: 'All Patients',
              data: patients || [],
            },
          ]
        : []),
      ...(pendingPatients?.length
        ? [
            {
              type: DashboardSectionType.Pending,
              title: 'Pending Patients',
              data: pendingPatients || [],
            },
          ]
        : []),
    ];
  }, [patients, pendingPatients]);

  const todaySections = useMemo((): DashboardSection[] => {
    const patients: IPatientFragment[] = data?.activePatientsToday?.data || [];
    const dataWithReport = patients.filter((i) => i.unreadReportsCounter);
    const dataWithPendingCS = patients.filter((i) => i.pendingCopingStrategies.data.length);
    return [
      ...(dataWithReport?.length
        ? [
            {
              type: DashboardSectionType.Report,
              title: 'Reports ready to review',
              data: dataWithReport || [],
            },
          ]
        : []),
      ...(dataWithPendingCS?.length
        ? [
            {
              type: DashboardSectionType.CS,
              title: 'Requests from your patients',
              data: dataWithPendingCS || [],
            },
          ]
        : []),
    ];
  }, [data?.activePatientsToday?.data]);

  return {
    allSections,
    todaySections,
    loading,
    isToday,
    moreLoading: patientsMoreLoading || pendingPatientsMoreLoading,
    canFetchMorePatients,
    canFetchMorePendingPatients,
    refetch,
    setIsToday,
    fetchMorePatients,
    fetchMorePendingPatients,
  };
};
