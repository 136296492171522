import { gql, useMutation } from '@apollo/client';
import { fragments, IBehaviouralActionFragment } from '../fragments';
import { BehaviouralActionTypes } from '../models/IBehaviouralAction';
import { IRecurrenceInput } from '../models/IRecurrenceInput';

export interface ICreatePatientBehaviouralActionResponse {
  createPatientBehavioralAction: IBehaviouralActionFragment;
}

export interface ICreatePatientBehaviouralActionVariables {
  signUpCode: string;
  type: BehaviouralActionTypes;
  activity: string;
  startDate?: Date;
  endDate?: Date;
  recurrence?: IRecurrenceInput;
}

const CREATE_PATIENT_BEHAVIOURAL_ACTION = gql`
  mutation createPatientBehavioralAction(
    $signUpCode: String!
    $type: String!
    $activity: String!
    $startDate: Date
    $endDate: Date
    $recurrence: RecurrenceInput
  ) {
    createPatientBehavioralAction(
      code: $signUpCode
      type: $type
      activity: $activity
      startDate: $startDate
      endDate: $endDate
      recurrence: $recurrence
    ) {
      ...BehaviouralAction
    }
  }
  ${fragments.behaviouralAction}
`;

export const useCreatePatientBehaviouralAction = () => {
  return useMutation<
    ICreatePatientBehaviouralActionResponse,
    ICreatePatientBehaviouralActionVariables
  >(CREATE_PATIENT_BEHAVIOURAL_ACTION, {
    refetchQueries: ['getPatientScreenQuery'],
    awaitRefetchQueries: true,
  });
};
