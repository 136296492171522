export class NetworkError extends Error {
  code?: string;

  constructor(code?: string, message?: string) {
    super(message);
    this.code = code;
  }
}

export class ValidationError extends Error {
  code?: string;

  constructor(message?: string) {
    super(message || 'Unexpected data provided. Please check your inputs and try again.');
  }
}

export class UnauthorizedError extends Error {
  code?: string;

  constructor(message?: string) {
    super(
      message ||
        'Invalid or no access token provided to the endpoint. Try logging out and logging in again.'
    );
  }
}
