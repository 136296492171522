import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Colors } from '../../config/colors';
import { LimbicText, TextVariants } from '../LimbicText/LimbicText';

interface Props {
  title?: string;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

export const ModalHeader = (props: Props) => {
  const { title, leftContent, rightContent } = props;
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>{leftContent}</View>
      <LimbicText bold variant={TextVariants.XS} color={Colors.grey3}>
        {title}
      </LimbicText>
      <View style={styles.rightContainer}>{rightContent}</View>
    </View>
  );
};

export interface IHeaderIconProps {
  icon: string;
  color?: string;
  size?: number;
  onPress?: () => void;
}

// "ios-arrow-back"
// "close-outline"
const HeaderIcon = (props: IHeaderIconProps) => {
  const { icon, color, size, onPress } = props;
  return (
    <TouchableOpacity onPress={onPress}>
      <Ionicons name={icon} size={size ?? 25} color={color ?? Colors.grey3} />
    </TouchableOpacity>
  );
};

ModalHeader.HeaderIcon = HeaderIcon;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 28,
    paddingVertical: 12,
    backgroundColor: Colors.white,
  },
  leftContainer: {
    flex: 1,
    alignItems: 'flex-start',
  },
  rightContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
});
