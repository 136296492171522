import { gql, useMutation } from '@apollo/client';

const DELETE_COPING_STRATEGY = gql`
  mutation deleteCopingStrategy($signUpCode: String!, $id: ID!) {
    deleteCopingStrategy(code: $signUpCode, strategyId: $id)
  }
`;

interface IDeleteCopingStrategyVariables {
  signUpCode: string;
  id: string;
}

export function useDeleteCopingStrategyMutation() {
  return useMutation<undefined, IDeleteCopingStrategyVariables>(DELETE_COPING_STRATEGY, {
    refetchQueries: ['getPatientScreenQuery'],
    awaitRefetchQueries: true,
  });
}
