import { Platform } from 'react-native';
import { useEffect } from 'react';
import { useActiveClinicianContext } from '../../context/ActiveClinician';

const canUseIntercom =
  Platform.select({ web: true, default: false }) && typeof window !== 'undefined';

interface IntercomProps {
  open: boolean;
  onClose: () => void;
}

export const Intercom = ({ open, onClose }: IntercomProps) => {
  const { activeClinician } = useActiveClinicianContext();

  useEffect(() => {
    if (!canUseIntercom) return;
    if (!open) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const IntercomInstance = (window as any).Intercom;

    IntercomInstance('show', {
      user_id: activeClinician?.id,
      name: activeClinician?.name,
      email: activeClinician?.email,
    });

    IntercomInstance('onHide', onClose);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return null;
};
