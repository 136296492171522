import { ApolloClient, gql, useMutation } from '@apollo/client';
import { fragments, IInterventionStepFragment, MediaType } from '../fragments';

export interface IAddInterventionStepResponse {
  addInterventionStepByType: IInterventionStepFragment;
}

export interface SliderLabelInput {
  label: string;
  value: number;
}

export interface BaseStepConfigInput {
  interventionId: string | number;
  prompt: string;
}

export interface ChoiceStepConfigInput extends BaseStepConfigInput {
  answers: string[];
}

export interface SliderConfigInput extends BaseStepConfigInput {
  max: number;
  min: number;
  labels: SliderLabelInput[];
}

export interface MediaStepConfigInput {
  interventionId: string | number;
  mediaType: MediaType;
  mediaUrl: string;
}

export type StepConfigInput =
  | { informational: BaseStepConfigInput }
  | { freeText: BaseStepConfigInput }
  | { emotion: BaseStepConfigInput }
  | { thought: BaseStepConfigInput }
  | { activity: BaseStepConfigInput }
  | { singleChoice: ChoiceStepConfigInput }
  | { multipleChoice: ChoiceStepConfigInput }
  | { slider: SliderConfigInput }
  | { media: MediaStepConfigInput };

export interface IAddInterventionStepByTypeVariables {
  input: StepConfigInput;
}

export const QUERY = gql`
  mutation addInterventionStepByType($input: StepConfigInput!) {
    addInterventionStepByType(input: $input) {
      ...InterventionStep
    }
  }

  ${fragments.interventionStep}
`;

export function useAddInterventionStepMutation<TCacheShape extends Record<string, unknown>>(
  titanClient: ApolloClient<TCacheShape>
) {
  return useMutation<IAddInterventionStepResponse, IAddInterventionStepByTypeVariables>(QUERY, {
    refetchQueries: ['getIntervention'],
    client: titanClient,
  });
}
