import { Colors } from '@limbic-for-therapists/components';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GridColumn, GridContainer, GridRow } from '../components/ResponsiveGrid';
import { ScreenContainer } from '../components/ScreenContainer';
import SetPsychoedTriggers from './PsychoedScheduleScreen/SetPsychoedTriggers';

export function PsychoedScheduleScreen() {
  const { interventionId, signUpCode } = useParams<{
    interventionId: string;
    signUpCode: string;
  }>();

  return (
    <React.Fragment>
      <ScreenContainer backgroundColor={Colors.grey1} title="Activities" backPath="../library">
        <GridContainer
          gridColumns={{
            xs: 4,
          }}
        >
          <GridRow>
            <GridColumn span={4}>
              <SetPsychoedTriggers interventionId={interventionId} signupCode={signUpCode} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </ScreenContainer>
    </React.Fragment>
  );
}
