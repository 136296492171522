import { Colors } from './colors';

export const getShadowStyles = (
  color: Colors,
  opacity: number,
  radius: number,
  offsetX = 0,
  offsetY = 0
) => ({
  shadowColor: color,
  shadowOpacity: opacity,
  shadowRadius: radius,
  shadowOffset: { width: offsetX, height: offsetY },
});
